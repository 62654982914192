import { useReducer } from 'react'
import { EmpadronamientoState } from '../../../interfaces/Empadronamiento';
import EmpadronamientoContext from './EmpadronamientoContext';
import EmpadronamientoReducer from './EmpadronamientoReducer';
import { SelectChangeEvent } from "@mui/material";
import axios from 'axios';
import moment from 'moment'

const INITIAL_STATE: EmpadronamientoState = {
    enrollmentData: [],
    enrollmentStatus: [],
    allEnrollmentsData: [],
    allEnrollmentsDetail: {
        name: '',
        lastName: '',
        circleId: 0,
        collectiveFile: '',
        officesResponse: [{
            officeId: 0,
            height: 0 ,
            street: '' ,
            cellPhone: '',
            phone: '', 
            hasXRay: false, 
            email: '',
            secondEmail: '',
            betweenStreet: '', 
            observations: '', 
            attentionHours: '', 
            availabilityDate: '', 
            availability: '', 
            officeStateId: 0
        }],
        registrationHistoryResponses: [{status: 0, name: '', lastname: '', comment: '', date: '', registrationStateId: 0}], 
        secureId: 0,
        specialitiesResponse: [{specialityId: 0, awardedBy: 0, awardedYear: 0, isExclusive: false, description: '', idFopbaInsider: 0}],
        insurances: [{insurancesId: 0, name: '', generalCoverages: [], idFopbaInsider: 0}],
        registrationState: {registrationStatesId: 0, description: ''},
        professional: {nationalEnrollment: '', provincialEnrollment: '', startActivity: '', fopbaRegistration: ''},
        secureStartDate: '',
        secureFinishDate: '',
        userFile: ''
    },
    enrollmentDetail: {
        name: '',
        lastName: '',
        circleId: 0,
        collectiveFile: '',
        officesResponse: [{
            officeId: 0,
            height: 0 ,
            street: '' ,
            cellPhone: '',
            phone: '', 
            hasXRay: false, 
            email: '',
            secondEmail: '',
            betweenStreet: '', 
            observations: '', 
            attentionHours: '', 
            availabilityDate: '', 
            availability: '', 
            officeStateId: 0
        }],
        registrationHistoryResponses: [{status: 0, name: '', lastname: '', comment: '', date: '', registrationStateId: 0}], 
        secureId: 0,
        specialitiesResponse: [{specialityId: 0, awardedBy: 0, awardedYear: 0, isExclusive: false, description: '', idFopbaInsider: 0}],
        insurances: [{insurancesId: 0, name: '', generalCoverages: [], idFopbaInsider: 0}],
        registrationState: {registrationStatesId: 0, description: ''},
        professional: {nationalEnrollment: '', provincialEnrollment: '', startActivity: '', fopbaRegistration: ''},
        secureStartDate: '',
        secureFinishDate: '',
        userFile: ''
    },
    viewToShow: 'start',
    dataProfessional: {
        nationalEnrollment: '',
        provincialEnrollment: '',
        startActivity: '',
        fopbaRegistration: ''
    },
    professionalItems: {
        nationalEnrollment: '',
        provincialEnrollment: '',
        startActivity: '',
        fopbaRegistration: ''
    },
    professionalsData: [{
        professionalsId: 0,
        fullName: {
        name: '',
        lastName: ''
    },
    nationalEnrollment: 0,
    provincialEnrollment: 0,
    professionalStates: [
        {
            professionalStateId: 0,
            description: ''
        },
        {
            professionalStateId: 0,
            description: ''
        }
    ],
    cuit: ''
    }],
    professionalStatusData: [{
        professionalStateId: 0,
        description: ''
    }],
    professionalStateId: 0,
    dataCircles: [],
    circleName: '',
    activeStep: 0,
    steps: ['', '', '', '', ''],
    consultorioItems: {
        id: 0,
        street: '',
        height: 0,
        floor: 0,
        apartment: '',
        postalCode: 0,
        town: '',
        department: '',
        state: '',
        country: '',
        googlePlaceId: '',
        phone: '',
        cellphone: '',
        hasXRay: ''
    },
    consultorioCargado: [],
    insuranceData: [],
    insurancesChecked: [],
    insuranceFiltered: [],
    insurancesSelected: [],
    InsurancesSelectedItems: {
        insuranceId: 0,
        generalCoverageIds: []
    },
    loadingInsurance: false,
    insuranceTypeData: [],
    insuranceTypeItems: {
        insuranceTypeId: 0,
        description: '',
        requireDocumentation: false,
        collectiveFile: null,
        collectiveFileBase64: ''
    },
    nameFilter: '',
    filtered: false,
    mainSpecialityData: [],
    mainSpecialityItems: {
        specialityId: 0, 
        generalCoverageId: 0,
        description: ''
    },
    mainSpecialityName: '',
    specialityItems: {
        specialityId: 0,
        isExclusive: '',
        certifyFile: null,
        certifyFileBase64: '',
        antiquity: 0,
        awardedBy: '',
        awardedYear: 0
    },
    generalRules: [{
        generalRulesId: 0,
        description: '',
        insurances: [],
        from: '',
        to: ''
    }],
    
    generalRulesDetails: {
        generalRulesId: 0,
        description: '',
        insurances: [],
        from: '',
        to: ''
    },
    particularRules: [{
        particularRulesId: 0,
        userId: 0,
        generalRuleId: 0,
        toDate: ''
    }],
    generalRuleIdSelected: 0,
    generalRuleDateSelected: '',
    generalRuleDescriptionSelected: '',
    particularRuletoDateSelect: moment().format('YYYY-MM-DD'),
    generalRuleName: '',
    buttonDisabled: true,
    generalRuleFromDate: moment().format('YYYY-MM-DD'),
    generalRuleToDate: moment().format('YYYY-MM-DD'),
    generalRuleInsurancesDetail: [],
    generalRuleNameDetail: '',
    specialityLoad: [],
    messageError: [],
    openAlertErrorDate: false,
    secureId: 0,
    openSummaryModal: false,
    openConfirm: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    loading: true,
    loadingEnrollmentDetail: false,
    loadingEnrollment: false,
    loadingAllEnrollments: false,
    enrollmentNameFilter: '',
    enrollmentLastNameFilter: '',
    enrollmentRegistrationFilter: '',
    enrollmentStatusFilter: '',
    enrollmentCuilFilter: '',
    enrollmentFiltered: false,
    licenseProfessionalFilter: '',
    lastnameProfessionalFilter: '',
    cuilProffesionalFilter: '',
    professionalStatusFiltered: false,
    generalRulesInsurancesInitial: [],
    offset: 0,
    noMoreResults: false,
    offsetEnrollment: 0,
    noMoreResultsEnrollment: false
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const EmpadronamientoProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(EmpadronamientoReducer, INITIAL_STATE)

    const getEnrollment = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingEnrollment', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/users/${userId}`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollment', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingEnrollment', payload: false});
        })
    }


    const getEnrollmentStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}enrollment/status`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollmentStatus', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getAllEnrollments = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingAllEnrollments', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}enrollments?offset=0&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'getAllEnrollments', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingAllEnrollments', payload: false});
        })
    }

    const enrollmentNextPage = async () => {

        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        const offsetValue = state.offsetEnrollment + 50
        state.offsetEnrollment = offsetValue

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}enrollments?offset=${offsetValue}&limit=50`, {headers})
        .then(response => {
          dispatch({type: 'enrollmentNextPage', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }

    const getAllEnrollmentDetail = async (registrationsId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingAllEnrollments', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}enrollments/${registrationsId}`, {headers})
        .then(response => {
            dispatch({type: 'getAllEnrollmentDetail', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingAllEnrollments', payload: false});
        })
    
    }

    const getAllEnrollmentsFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        let param = ''
        if(state.enrollmentRegistrationFilter){
            param = `&license=${state.enrollmentRegistrationFilter}`
        } 
        let param2 = ''
        if(state.enrollmentCuilFilter){
            param2 = `&cuil=${state.enrollmentCuilFilter}`
        }

        const url = `${process.env.REACT_APP_BACKOFFICE_API}enrollments?name=${state.enrollmentNameFilter}&lastname=${state.enrollmentLastNameFilter}${param}&status=${state.enrollmentStatusFilter}${param2}`

        dispatch({type: 'setLoadingAllEnrollments', payload: true});

        await axios.get(url, {headers})
        .then(response => {
            dispatch({type: 'getAllEnrollmentsFilter', payload: response.data})
            dispatch({type: 'enrollmentFiltered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingAllEnrollments', payload: false});
        })
    }

    const clearEnrollmentFilter = () => {
        dispatch({type: 'clearEnrollmentFilter'})
        // si se filtro llamo al get de empadronamientos para traer todos los resultado nuevamente
        if(state.enrollmentFiltered){
            dispatch({type: 'enrollmentFiltered', payload: false})
            getAllEnrollments()
        }
    }

    const modifyEnrollmentStatus = async (registrationsId: number, status: number, comment?: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "status" : status,
            "comment" : comment,
        }

        dispatch({type: 'setLoading', payload: true});

        await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}enrollments/${registrationsId}/status`, data, {headers})
        .then(() => {
            window.location.reload()
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
        
    }

    const getCircles = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}circles`, {headers})
        .then(response => {
            dispatch({type: 'getCircles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const handleChangeSelectCircle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeSelectCircle', payload: {value: event.target.value}})
    };

    const isLastStep = () => {
        return state.activeStep === 4;
    };
    
    const handlePrevStep = () => {
        const newActiveStep = state.activeStep - 1;
        dispatch({type: 'handleStep', payload: newActiveStep});
    };

    const handleNextStep = () => {
        const newActiveStep = isLastStep() ? state.steps.findIndex((step, i) => !(i in {})) : state.activeStep + 1;
        dispatch({type: 'handleStep', payload: newActiveStep});
    };

    const getInsuranceType = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurancesTypes`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceType', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const convertFileToBase64 = (file: FileList | null, name: string, typeDispatch: 'handleChangeFile' | 'handleChangeFileSpeciality') => {
        const fileValue = file ? file : []
        if(fileValue.length){
            Array.from(fileValue).forEach((archivo: any) => {
                const reader = new FileReader();
                reader.readAsDataURL(archivo)
                reader.onload = () => {
                    const base64 = reader.result
                    const base64String = typeof base64 === 'string' ? base64 : ''
                    // hago split para quitar todo lo que esta antes de la coma ya que eso no se necesita
                    let base64Split = []
                    base64Split = base64String.split(',')
                    dispatch({type: typeDispatch, payload: {valueBase64: base64Split[1], value: file, name: name}})
                }
            })
        }
    }

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        convertFileToBase64(event.target.files, event.target.name, 'handleChangeFile')
    }

    const handleChangeFileSpeciality = (event: React.ChangeEvent<HTMLInputElement>) => {
        convertFileToBase64(event.target.files, event.target.name, 'handleChangeFileSpeciality')
    }

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingInsurance', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingInsurance', payload: false});
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de obras sociales para traer todos los resultado nuevamente
        if(state.filtered){
            dispatch({type: 'filtered', payload: false})
        }
    }

    const handleClickCoverage = (event: React.ChangeEvent<HTMLInputElement>, insuranceId: number, coverageSelected: number) => {
        dispatch({type: 'handleClickCoverage', payload: {checked: event.target.checked, insuranceId, coverageSelected}})
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const handleChangeSelectFilter = (event: SelectChangeEvent<string>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const getMainSpeciality = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}speciality?limit=15`, {headers})
        .then(response => {
            dispatch({type: 'getMainSpeciality', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const handleChangeSecureCheck = (secureId: number) => {
        dispatch({type: 'handleChangeSecureCheck', payload: secureId})
    };


    const getProvincias = async () => {
        const urlProvincias = 'https://apis.datos.gob.ar/georef/api/provincias'
        const res = await axios.get(urlProvincias)
        dispatch({type: 'getProvincias', payload: res.data.provincias});
    }

    const getDepartamentos = async () => {
        const urlDepartamentos = `https://apis.datos.gob.ar/georef/api/departamentos?provincia=${state.consultorioItems.state}&max=200`
        const res = await axios.get(urlDepartamentos)
        dispatch({type: 'getDepartamentos', payload: res.data.departamentos});
    }

    const getLocalidades = async () => {
        const urlLocalidades = `https://apis.datos.gob.ar/georef/api/localidades?departamento=${state.consultorioItems.department}&max=300`
        const res = await axios.get(urlLocalidades)
        dispatch({type: 'getLocalidades', payload: res.data.localidades});
    }

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeInputConsultorio = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInputConsultorio', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({type: 'changeDatePicker', payload: {value: value, name: name}})
    }

    const handleChangeYearPicker = (value: string, name: string) => {
        dispatch({type: 'changeYearPicker', payload: {value: value, name: name}})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const handleChangeView = (view: string) => {
        dispatch({type: 'viewToShow', payload: view})
    }

    const closeSummaryModal = () => {
        dispatch({type: 'openSummaryModal', payload: false})
    };

    const handleChangeSelect = (event: SelectChangeEvent <string | number[]>) => {
    let {value} = event.target;
    if (Number.isInteger(value) && event.target.name === 'insurances') value = [Number(value)]
      dispatch({
        type: "handleChangeSelect",
        payload: { name: event.target.name, value},
      });
    };

    const openAlert = () => {
        dispatch({ type: "openAlert", payload: true });
      };

    const closeAlert = () => {
        dispatch({ type: "openAlert", payload: false });
      };

      const clearValues = () => {
        dispatch({
          type: "clearValues",
          payload: {
            //Items reglas particulares
           generalRuleId: '',
           toDate: (moment().format('YYYY-MM-DD')),
           //Items reglas generales
           description: '',
           fromDate: (moment().format('YYYY-MM-DD')),
           //uso el toDate de la regla particular
           insurances: []
          },
        });
      };

    {/*Reempadronamiento Provider*/}

    const getGeneralRules = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}generalRules`, {headers})
        .then(response => {
            dispatch({type: 'getGeneralRules', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getParticularRulesByProfessionalId = async (professionalId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}particularRules?professionalId=${professionalId}`, {headers})
        .then(response => {
            dispatch({type: 'getParticularRulesByProfessionalId', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const saveParticularRuleByProfessionalId = async (professionalId: number) => {

    const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const data = {
        professionalId: professionalId,
        generalRuleId: state.generalRuleIdSelected,
        toDate: state.particularRuletoDateSelect
    }

    dispatch({type: 'setLoading', payload: true});

    if(
        professionalId &&
        state.generalRuleIdSelected &&
        state.particularRuletoDateSelect > state.generalRuleDateSelected
    ){
        await axios
        .post(`${process.env.REACT_APP_BACKOFFICE_API}particularRules`, data, { headers })
        .then((response) => {
          if (response.status === 201) {
            dispatch({ type: "setLoading", payload: false });
            dispatch({ type: "messageError", payload: [] });
            window.location.reload();
            
          }
        })
        .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            if (error.response.status === 500) {
                dispatch({type: "messageError", payload: ["Error interno del servidor al agregar regla particular"]});
                dispatch({ type: "setLoading", payload: false });
            }
            dispatch({type: "messageError",payload: error.response.data?.errors});
            dispatch({ type: "setLoading", payload: false });
          });
    } else {
        dispatch({ type: "setLoading", payload: false });
        openAlert()
    }
    }
    const saveGeneralRules = async () => {

        const headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
        const data = {
            description: state.generalRuleName,
            insurancesId: state.insurancesChecked,
            fromDate: state.generalRuleFromDate,
            toDate: state.generalRuleToDate
        }
        dispatch({type: 'setLoading', payload: true});
        if(
            state.generalRuleName &&
            state.generalRuleFromDate &&
            state.insurancesChecked[0] &&
            state.generalRuleToDate > state.generalRuleFromDate
        ){
            await axios
            .post(`${process.env.REACT_APP_BACKOFFICE_API}generalRules`, data, { headers })
            .then((response) => {
                dispatch({type: "messageError", payload: []});
                if (response.status === 201) {
                    dispatch({ type: "setLoading", payload: false });
                    window.location.reload();
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                  window.location.href = "/";
                }
                if (error.response.status === 500) {
                    dispatch({type: "messageError", payload: ["Error interno del servidor al agregar regla general"]});
                    dispatch({ type: "setLoading", payload: false });
                }
                dispatch({type: "messageError", payload: error.response.data?.errors});
                dispatch({ type: "setLoading", payload: false });
              });
        } else {
            dispatch({ type: "setLoading", payload: false });
        }
    }

    const getGeneralRulesById = async (generalRulesId: number) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}generalRules/${generalRulesId}`, {headers})
        .then(response => {
            dispatch({type: 'getGeneralRulesById', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const deleteGeneralRule = async (generalRulesId: string | undefined) => {

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        const idFormat = Number(generalRulesId)
    
        const response = await axios.delete(
          `${process.env.REACT_APP_BACKOFFICE_API}generalRules/${idFormat}`,
          { headers }
        );
        
        if (response.status === 200) {
          window.location.href = "/reempadronamiento";
        }
        dispatch({ type: "openConfirm", payload: false });
      };

      const editGeneralRule = async (generalRulesId: number) => {

        const headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          const insuranceChecked = state.generalRuleInsurancesDetail.filter(item => !(state.generalRulesInsurancesInitial.includes(item.insurancesId) && item.status));

          const data = {
            description: state.generalRulesDetails.description,
            insurances: insuranceChecked,
            fromDate: state.generalRulesDetails.from,
            toDate: state.generalRulesDetails.to
          }
          
          if(
            state.generalRulesDetails.description &&
            state.generalRulesDetails.from && 
            state.generalRulesDetails.to
          ){
                await axios
                  .put(
                    `${process.env.REACT_APP_BACKOFFICE_API}generalRules/${generalRulesId}`, data, 
                    { headers }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      dispatch({ type: "setLoading", payload: false });
                      window.location.reload();
                    }
                  })
                  .catch((error) => {
                    if (error.response.status === 401) {
                        window.location.href = '/'
                    }
                    if (error.response.status === 500) {
                      dispatch({
                        type: "messageError",
                        payload: ["Error al modificar regla general"],
                      });
                    }
                    dispatch({
                      type: "messageError",
                      payload: error.response.data?.message,
                    });
                    dispatch({ type: "setLoading", payload: false });
                  });
          }
      }
    const getProfessionals = async () => {

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
    
        dispatch({ type: "setLoading", payload: true });
    
        await axios
          .get(
            `${process.env.REACT_APP_BACKOFFICE_API}professionals?offset=0&limit=50`,
            { headers }
          )
          .then((response) => {
            dispatch({ type: "getProfessionals", payload: response.data });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            dispatch({ type: "setLoading", payload: false });
          });
      };

    const professionalsNextPage = async () => {

        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        const offsetValue = state.offset + 50
        state.offset = offsetValue

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}professionals?offset=${offsetValue}&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'professionalsNextPage', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

      const getProfessionalStatus = async () => {

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
    
        dispatch({ type: "setLoading", payload: true });
    
        await axios
          .get(
            `${process.env.REACT_APP_BACKOFFICE_API}professionalsStatus`,
            { headers }
          )
          .then((response) => {
            dispatch({ type: "professionalStatusData", payload: response.data });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            dispatch({ type: "setLoading", payload: false });
          });
      };
      const getProfessionalsFilter = async () => {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
    
        dispatch({ type: "setLoading", payload: true });
    
        let params = []
        if(state.professionalStateId){
          params.push(`status=${state.professionalStateId}`)
        }
        if(state.lastnameProfessionalFilter){
          params.push(`&lastname=${state.lastnameProfessionalFilter}`)
        }
        if(state.licenseProfessionalFilter){
          params.push(`&license=${state.licenseProfessionalFilter}`)
        }
        if(state.cuilProffesionalFilter){
            params.push(`&cuil=${state.cuilProffesionalFilter}`)
          }
    
        let paramsFormat = params[0]
        if(params.length === 2){
          paramsFormat = params[0] + params[1]
        } else if(params.length === 3){
          paramsFormat = params[0] + params[1] + params[2]
        }if(params.length === 4){
        paramsFormat = params[0] + params[1] + params[2] + params[3]
        }
    
        await axios
          .get(
            `${process.env.REACT_APP_BACKOFFICE_API}professionals?${paramsFormat}`,
            { headers }
          )
          .then((response) => {
            dispatch({ type: "getProfessionals", payload: response.data });
            dispatch({ type: "professionalStatusFiltered", payload: true });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            dispatch({ type: "setLoading", payload: false });
          });
      };

      const clearProfessionalStatusFilter = () => {
        dispatch({ type: "clearProfessionalStatusFilter" });
        if (state.professionalStatusFiltered) {
          getProfessionals()
          dispatch({ type: "professionalStatusFiltered", payload: false });
        }
      };

    const getInsuranceCheckedIndex = (insuranceId: number) => {
        let index = state.generalRuleInsurancesDetail.findIndex(i => i.insurancesId === insuranceId);
        return index;
    }

      const handleToggle = (insuranceId: number, status: boolean) => {
        const currentIndex = getInsuranceCheckedIndex(insuranceId);
        const newChecked = [...state.generalRuleInsurancesDetail];
        
        if(currentIndex !== -1){
          newChecked[currentIndex] = {
            insurancesId: insuranceId,
            status
          }
          
        }else{
          newChecked.push({
            insurancesId: insuranceId,
            status
          })
          
        }
        dispatch({type: 'handleToggle', payload: newChecked})
      };

    return(
        <EmpadronamientoContext.Provider value={{
            state,
            getEnrollment,
            enrollmentNextPage,
            getEnrollmentStatus,
            getAllEnrollments,
            getAllEnrollmentDetail,
            getAllEnrollmentsFilter,
            modifyEnrollmentStatus,
            getCircles,
            handleChangeSelectCircle,
            handleChangeSelect,
            handlePrevStep,
            handleNextStep,
            getInsurances,
            getInsuranceType,
            handleChangeFile,
            handleChangeFileSpeciality,
            clearFilter,
            clearEnrollmentFilter,
            handleClickCoverage,
            handleChangeFilter,
            getMainSpeciality,
            handleChangeSecureCheck,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            handleChangeUbicacion,
            handleChangeSelectFilter,
            handleChangeInput,
            handleChangeInputConsultorio,
            handleChangeDatePicker,
            handleChangeYearPicker,
            handleOpenConfirm,
            handleCloseConfirm,
            handleChangeView,
            closeSummaryModal,
            getGeneralRules,
            getGeneralRulesById,
            saveGeneralRules,
            editGeneralRule,
            deleteGeneralRule,
            getParticularRulesByProfessionalId,
            saveParticularRuleByProfessionalId,
            closeAlert,
            clearValues,
            getProfessionals,
            professionalsNextPage,
            getProfessionalStatus,
            getProfessionalsFilter,
            clearProfessionalStatusFilter,
            handleToggle
        }}>
            {children}
        </EmpadronamientoContext.Provider>
    )
}

export default EmpadronamientoProvider