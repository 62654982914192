import { Permisos, PermisosState, Roles, GrupoPermisos } from '../../../interfaces/Permisos';

type Action = {type: 'getRoles', payload: Roles[]}
    | {type: 'getPermisos', payload: GrupoPermisos[]}
    | {type: 'handleChangeSelect', payload: {value: string, name: string}}
    | {type: 'openAlert', payload: boolean}
    | {type: 'clearValues', payload: {data: GrupoPermisos[], items: Permisos, roles: Roles[], rolesValue: Roles, openAlert: boolean}}
    | {type: 'loading', payload: boolean}

export default function RolesReducer (state: PermisosState, action: Action): PermisosState {
    switch (action.type) {
        case 'getRoles':
            return { 
                ...state, 
                roles: action.payload
            }
        case 'getPermisos':
            return { 
                ...state, 
                data: action.payload
            }
        case 'handleChangeSelect':
            state.rolesValue[action.payload.name] = action.payload.value
            return {
                ...state,
                rolesValue: state.rolesValue
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                data: action.payload.data,
                roles: action.payload.roles,
                rolesValue: action.payload.rolesValue,
                items: action.payload.items,
                openAlert: action.payload.openAlert
            }
        case 'loading':
            return { 
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}