import Button from '../Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { useContext, useEffect } from "react"
import ContactContext from "../context/Contact/ContactContext"
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import 'moment/locale/es';
import Loader from '../Loader';
import Alert from '../Alert';
import SearchBox from './SearchBox';

const Index = () => {

    const { 
        getContact,
        handleChangeInput,
        handleChangeSearchAddressValue,
        editContact,
        closeAlert,
        setLatAndLng,
        setAddress,
        state 
    } = useContext(ContactContext)

    useEffect(() => {
        getContact()       
    }, [])

    if(state.loading){
        return <Loader />
    }

    return (
        <Box component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='phone'
                        variant="outlined"
                        fullWidth
                        value={state.items.phone ? state.items.phone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='cellPhone'
                        variant="outlined"
                        fullWidth
                        value={state.items.cellPhone ? state.items.cellPhone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        margin="dense"
                        label="Correo Electrónico"
                        name='email'
                        variant="outlined"
                        fullWidth
                        value={state.items.email}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        margin="dense"
                        label="Disponibilidad"
                        name='availability'
                        variant="outlined"
                        fullWidth
                        value={state.items.availability}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4 style={{marginTop: 40}}>Ubicación</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Piso"
                        name='floor'
                        variant="outlined"
                        fullWidth
                        inputProps={{maxLength: "2"}}
                        value={state.items.floor && state.items.floor !== "0" ? state.items.floor : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Depto"
                        name='apartment'
                        variant="outlined"
                        fullWidth
                        value={state.items.apartment ? state.items.apartment : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Código postal"
                        name='postalCode'
                        variant="outlined"
                        fullWidth
                        value={state.items.postalCode ? state.items.postalCode : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <SearchBox 
                        latAndLng={state.items.placeId}
                        street={state.items.street}
                        setLatAndLng={setLatAndLng}
                        handleChangeSearchAddressValue={handleChangeSearchAddressValue}
                        setAddress={setAddress}
                    />
                </Grid>
            </Grid>
            <h4>Redes Sociales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 3}} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="YouTube"
                        name='youtube'
                        variant="outlined"
                        fullWidth
                        value={state.items.youtube}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Facebook"
                        name='facebook'
                        variant="outlined"
                        fullWidth
                        value={state.items.facebook}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Twitter"
                        name='twitter'
                        variant="outlined"
                        fullWidth
                        value={state.items.twitter}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Instagram"
                        name='instagram'
                        variant="outlined"
                        fullWidth
                        value={state.items.instagram}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Linkedin"
                        name='linkedin'
                        variant="outlined"
                        fullWidth
                        value={state.items.linkedin}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            {/* BUTTONS */}
            <Grid container justifyContent='end' sx={{mt: 2}}>
                <Grid item md={2}>
                    <Button child='Guardar' handleClick={editContact} variant='contained' disabled={state.buttonDisabled} color='secondary' />
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Datos de contacto editado correctamente'} />
        </Box>
    )
}

export default Index