export const CuitSinGuiones = (cuit: string) => {
    
    const cuitSinGuiones = cuit.replaceAll('-', '')

    return cuitSinGuiones
}

export const CuitConGuiones = (cuit: string) => {
    
    const cuitLength = cuit.length
    const cuitPartOne = cuit.slice(0,2)
    const documentNumber = cuit.slice(2, cuitLength - 1)
    const cuitPartTwo = cuit.slice(cuitLength - 1,cuitLength)
    const cuitConcat = cuitPartOne.concat('-', documentNumber).concat('-', cuitPartTwo)

    return cuitConcat
}

export const CuitFormat = (cuitValue: string) => {
    
    // separo cuit en partes
    const cuitPartOne = cuitValue.slice(0,2)
    const documentNumber = cuitValue.slice(2, 10)
    const cuitPartOnePlusDocumentNumber = cuitValue.slice(0, 11)
    const cuitPartTwo = cuitValue.slice(11, 12)

    let cuit = cuitValue
    // si se colocaron mas de dos numeros y la cadena no contiene ningun guion se lo agrego
    if(cuitValue.length > 2 && !cuitValue.includes('-', 2)){
        cuit = cuitPartOne.concat('-', documentNumber)
    } else if(cuitValue.length > 11 && !cuitValue.includes('-', 11)){
        cuit = cuitPartOnePlusDocumentNumber.concat('-', cuitPartTwo)
    }

    return cuit
}