import { Box, Divider, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Alert from '../Alert';

//icon
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Index = () => {

  //creo una interfaz para poder acceder a la data que viene
  interface as400Data {
    as400TableId: number
    code: string
    description: string
  }

  //creo un estado para setear la data que me viene
  const [data, setData] = useState<as400Data[]>([])
  const [selectedCode, setSelectedCode] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeSelect = (event: SelectChangeEvent<string> ) => {
    setSelectedCode(event.target.value);
  };

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const getAs400Tables = async () => {

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}as400Tables`, {headers})
      .then((response) => {
      //seteo la data que me viene en el estado data
        setData(response.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const getArchiveAs400 = async (code: string) => {

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }

    setIsLoading(true)

    //seteo el nombre del archivo
    const archiveName = data.find(item => item.code === selectedCode)?.description

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}pfge/${code}`, {
      headers,
      responseType: 'text',
      timeout: 240000
      })
      .then((response) =>{

        setIsLoading(false)

        const blob = new Blob([response.data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', `${archiveName} - ${new Date().toISOString().slice(0,10)}.csv`) //seteo nombre de archivo

        document.body.appendChild(link)
        link.click() //simulo un click en el link al llamar a la funcion

        window.URL.revokeObjectURL(url); //elimino enlace y remuevo url
        document.body.removeChild(link);
      })
      .catch(()=> {
        setIsLoading(false)
        setOpenAlert(true)
      })
  }
    
  useEffect(() => {
    getAs400Tables();
  }, [])

    return (
      <Box sx={{margin: '20px 15px 0px 15px'}}>
        <h4>Exportación a As400</h4>
        <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8} md={10} >
            <FormControl fullWidth variant='outlined' margin='dense'>
            <InputLabel>Seleccione un Archivo a Descargar</InputLabel>
            <Select
              label="Seleccione un Archivo a Descargar"
              fullWidth
              variant='outlined'
              value={selectedCode}
              onChange={handleChangeSelect}   
            >
              {data.map((d) => (
                <MenuItem key={d.as400TableId} value={d.code}>{d.code} - {d.description}</MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>  
            <LoadingButton startIcon={<FileDownloadIcon />} loadingIndicator="Descargando.." loading={isLoading} variant="contained" disabled={selectedCode ? false : true} onClick={() => getArchiveAs400(selectedCode)}>
              Descargar
            </LoadingButton>
          </Grid>
        </Grid>
        <Alert openAlert={openAlert} handleClose={closeAlert} severity='warning' text={'No se pudo descargar el archivo .csv, intente en otro momento.'} />
      </Box>
    )
}

export default Index