import { Grid, TextField, Box, Typography, Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Pagination from '../Pagination';
import UserContext from '../context/Users/UserContext';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CsvBuilder } from 'filefy';
import Loader from '../Loader';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(Button)(() => ({
    backgroundColor: '#64AAB2',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const {
        state, 
        getProfessionals,
        profesionalsNextPage,
        handleChangeFilter,
        getProfessionalsFilter,
        getProfessionalStatus,
        clearProfessionalStatusFilter,
    } = useContext(UserContext)

    useEffect(() => {
        getProfessionals()
        getProfessionalStatus()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading){
        return <Loader />
    }

    const FilterDisabled = state.licenseProfessionalFilter || state.lastnameProfessionalFilter || state.professionalStateId || state.cuilProfessionalFilter

    const dataToExport = state.professionalsData.map(item => {
        return [item.fullName.name, item.fullName.lastName, item.provincialEnrollment, item.cuit]
    })

    const exportTable = () => {
        new CsvBuilder("listado_estados_prestador.csv")
        .setColumns(["Nombre", "Apellido", "Nº Matrícula Provincial", "Cuit / Cuil"])
        .setDelimeter(";")
        .addRows(dataToExport)
        .exportFile();
    }

    const onClickProfessionalStatusDetail = (id: number) => {
        window.location.href = `/detalle-de-prestador/${id}`
    }

    return (
        <Box sx={{ margin: '20px 15px 0px 15px' }}> 
            <h4>Prestadores</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container justifyContent='space-between' spacing={1} alignItems='center' sx={{ margin: '25px 0px 25px 0px' }}>
                <Grid xs={6} sm={4} md={2.4} item>
                    <TextField 
                        select 
                        fullWidth 
                        value={state.professionalStateId ? state.professionalStateId : ''} 
                        name='professionalStateId' 
                        label='Estado' 
                        onChange={(e) => handleChangeFilter(e)} 
                    >
                        {state.professionalStatusData.map(item => {
                            return <MenuItem key={item.professionalStateId} value={item.professionalStateId}>
                                {item.description}
                            </MenuItem>
                        })}
                    </TextField>
                </Grid>
                <Grid xs={6} sm={4} md={2.4} item>
                    <TextField 
                        fullWidth 
                        value={state.lastnameProfessionalFilter} 
                        name='lastnameProfessionalFilter' 
                        label='Apellido' 
                        onChange={(e) => handleChangeFilter(e)} 
                    />
                </Grid>
                <Grid xs={6} sm={4} md={2.4} item>
                    <TextField 
                        fullWidth 
                        value={state.licenseProfessionalFilter} 
                        name='licenseProfessionalFilter' 
                        label='Matrícula' 
                        onChange={(e) => handleChangeFilter(e)} 
                    />
                </Grid>
                <Grid xs={6} sm={6} md={2.4} item>
                    <TextField 
                        fullWidth 
                        value={state.cuilProfessionalFilter} 
                        name='cuilProfessionalFilter' 
                        label='Cuil / Cuit' 
                        onChange={(e) => handleChangeFilter(e)} 
                    />
                </Grid>
                <Grid xs={12} sm={6} md={2.4} item container justifyContent='end'>
                    <Grid xs={12} sm={12} md={12} item>
                        <Button variant="outlined" fullWidth size='small' startIcon={<FilterAltIcon />} onClick={() => {getProfessionalsFilter(); setPage(0)}} disabled={!FilterDisabled}>Filtrar</Button>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} item>
                        {FilterDisabled ?
                            <Button variant="outlined" fullWidth size='small' color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearProfessionalStatusFilter(); setPage(0)}}>Limpiar</Button>
                        : null}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent='end'>
                <ButtonCustom sx={{marginBottom: '10px', color: '#fff'}} onClick={exportTable} variant='outlined'>
                    Exportar <FileDownloadIcon sx={{marginLeft: '5px'}}/>
                </ButtonCustom>
            </Grid>
        
            {state.professionalsData.length ? 
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Apellido</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Nº Matrícula Provincial</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Cuil / Cuit</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 
                                ? state.professionalsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : state.professionalsData
                            ).map((row) => (
                                <StyledTableRow
                                    key={row.professionalsId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.fullName.name}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.fullName.lastName}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.provincialEnrollment}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.cuit}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">
                                        <RemoveRedEyeOutlinedIcon
                                            sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}} 
                                            onClick={() => onClickProfessionalStatusDetail(row.professionalsId)}
                                        />
                                </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={state.professionalsData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage='Filas por Página'
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={state.professionalsData.length} filtered={state.professionalStatusFiltered} noMoreResults={state.noMoreProfessionalResults} nextPage={profesionalsNextPage} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            : 
                <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2}}>
                    <Typography variant="body1" sx={{color: '#fff'}}>No se encontraron resultados para los filtros aplicados</Typography>
                </Box>
            }
        </Box>
    )
}

export default Index