import { useReducer } from 'react'
import axios from 'axios';
import RolesReducer from './RolesReducer';
import RolesContext from './RolesContext';
import { RolesState } from '../../../interfaces/Roles';
import { SelectChangeEvent } from '@mui/material';

const INITIAL_STATE: RolesState = {
    data: [],
    items: {
        rolesId: 0,
        description: '',
        profileId: 0
    },
    openConfirm: false,
    idSelected: 0,
    openAlert: false,
    loading: false,
    messageError: [],
    profiles: [],
    profileIdName: '',
    params: [],
    description: '',
    descriptionEdit: ''
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const RolesProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(RolesReducer, INITIAL_STATE)

    const getRoles = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'offset': 0,
            'limit': 20
        };

        dispatch({type: 'loading', payload: true})

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}roles`, {headers})
        .then(response => {
            dispatch({type: 'getRoles', payload: response.data})
            dispatch({type: 'loading', payload: false})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loading', payload: false})
        })
    }

    const editRoles = async (id: string | undefined) => {

        const headers = { 

            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const idFormat =  Number(id)

        const data = {
            rolesId: idFormat,
            description: state.items.description === "" ? state.descriptionEdit : state.items.description,
            profileId: state.items.profileId === 0 ? state.profileIdName : state.items.profileId
        }

        await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}roles/${idFormat}`, data, {headers})
        .then(response => {
            if(response.status === 200) {
                openAlert()
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const deleteRol = async () => {
        
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        await axios.delete(`${process.env.REACT_APP_BACKOFFICE_API}roles/${state.idSelected}`, {headers})
        getRoles()
        dispatch({type: 'openConfirm', payload: false})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }
    
    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const setIdSelected = (id: number) => {
        dispatch({type: 'setIdSelected', payload: id})
    }

    // AGREGAR

    const addRol = async () => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'offset': 0,
            'limit': 20
        };

        const data = {
            description : state.items.description,
            profileId : state.items.profileId
        }
        if(state.items.description && state.items.profileId){
        await axios.post(`${process.env.REACT_APP_BACKOFFICE_API}roles`, data, {headers})
        .then(response => {
            if(response.status === 201){
                openAlert()
                clearField()
                dispatch({type: 'messageError', payload: []});
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            if(error.response.status === 500){
                dispatch({type: 'messageError', payload: ['Error interno del servidor al agregar rol']});
            }
            dispatch({type: 'messageError', payload: error.response.data?.errors});
        }) 
        }else{
            dispatch({type: 'messageError', payload: ["Debe ingresar todos los campos obligatorios"]});
        }
    }

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value}})
    };

    const clearField = () => {
        dispatch({type: 'clearValues', payload: {rolesId: 0, description: '', profileId: 0}})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const getProfiles = async () => {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
    
        const response = await axios.get(
          `${process.env.REACT_APP_BACKOFFICE_API}profiles/`,
          { headers }
        );
        dispatch({ type: "getProfiles", payload: response.data });
      };
      const handleChangeSelect = (event: SelectChangeEvent<number | string>) => {
        dispatch({type: 'handleChangeSelect', payload: {name: event.target.name, value: event.target.value}})
    };

    return(
        <RolesContext.Provider value={{
            state,
            getRoles,
            deleteRol,
            handleOpenConfirm,
            handleCloseConfirm,
            setIdSelected,
            addRol,
            handleChangeInput,
            editRoles,
            closeAlert,
            clearField,
            getProfiles,
            handleChangeSelect
        }}>
            {children}
        </RolesContext.Provider>
    )
}

export default RolesProvider