import { useReducer } from 'react'
import { ContactState } from '../../../interfaces/Contact';
import ContactContext from './ContactContext';
import ContactReducer from './ContactReducer';
import { SelectChangeEvent } from '@mui/material';
import axios from 'axios';

const INITIAL_STATE: ContactState = {
    cargando: false,
    openConfirm: false,
    loading: false,
    openAlert: false,
    buttonDisabled: false,
    contactEdited: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    calles: [],
    items: {
        contactsId: 0,
        street: '',
        height: 0,
        floor: '',
        apartment: '',
        postalCode: '',
        town: '',
        department: '',
        state: '',
        country: '',
        placeId: '',
        phone: '',
        cellPhone: '',
        email: '',
        availability: '',
        youtube: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: ''
    }
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const ContactProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(ContactReducer, INITIAL_STATE)

    const getContact = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}fopbaContacts`, {headers})
        .then(response => {
            dispatch({type: 'getContact', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const editContact = async () => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "street": state.items.street,
            "height": state.items.height,
            "floor": state.items.floor,
            "apartment": state.items.apartment,
            "postalCode": state.items.postalCode,
            "town": state.items.town,
            "department": state.items.department,
            "state": state.items.state,
            "country": 'Argentina',
            "placeId": state.items.placeId,
            "phone": state.items.phone,
            "cellPhone": state.items.cellPhone,
            "email": state.items.email,
            "availability": state.items.availability,
            "youtube": state.items.youtube,
            "facebook": state.items.facebook,
            "twitter": state.items.twitter,
            "instagram": state.items.instagram,
            "linkedin": state.items.linkedin
        }

        dispatch({type: 'setLoading', payload: true});
        await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}fopbaContacts`, data, {headers})
        .then(() => {
            openAlert()
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getProvincias = async () => {
        const urlProvincias = 'https://apis.datos.gob.ar/georef/api/provincias'
        const res = await axios.get(urlProvincias)
        dispatch({type: 'getProvincias', payload: res.data.provincias});
    }

    const getDepartamentos = async () => {
        const urlDepartamentos = `https://apis.datos.gob.ar/georef/api/departamentos?provincia=${state.items.state}&max=200`
        const res = await axios.get(urlDepartamentos)
        dispatch({type: 'getDepartamentos', payload: res.data.departamentos});
    }

    const getLocalidades = async () => {
        const urlLocalidades = `https://apis.datos.gob.ar/georef/api/localidades?departamento=${state.items.department}&max=300`
        const res = await axios.get(urlLocalidades)
        dispatch({type: 'getLocalidades', payload: res.data.localidades});
    }

    const getCalles = async () => {
        const urlCalles = `https://apis.datos.gob.ar/georef/api/calles?departamento=${state.items.department}&localidad_censal=${state.items.town}&max=1000`
        const res = await axios.get(urlCalles)
        dispatch({type: 'getCalles', payload: res.data.calles});
    }

    const clearValues = () => {
        dispatch({type: 'clearValues', payload: {
            contactsId: 0,
            street: '',
            height: 0,
            floor: '',
            apartment: '',
            postalCode: '',
            town: '',
            department: '',
            state: '',
            country: '',
            placeId: '',
            phone: '',
            cellPhone: '',
            email: '',
            availability: '',
            youtube: '',
            facebook: '',
            twitter: '',
            instagram: '',
            linkedin: '',
        }})
    }

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeSearchAddressValue = (name: string, value: string) => {
        dispatch({type: 'handleChangeSearchAddressValue', payload: {value: value, name: name}})
    };

    const handleChangeSelect = (event: SelectChangeEvent<number>) => {
        dispatch({type: 'handleChangeSelect', payload: {name: event.target.name, value: event.target.value}})
    };

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const setLatAndLng = (value: string) => {
        dispatch({type: 'setLatAndLng', payload: value})
    };

    const setAddress = (value: string) => {
        dispatch({type: 'setAddress', payload: value})
    };

    return(
        <ContactContext.Provider value={{
            state,
            getContact,
            editContact,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getCalles,
            handleChangeUbicacion,
            handleChangeInput,
            handleChangeSearchAddressValue,
            handleOpenConfirm,
            handleCloseConfirm,
            clearValues,
            closeAlert,
            handleChangeSelect,
            setLatAndLng,
            setAddress
        }}>
            {children}
        </ContactContext.Provider>
    )
}

export default ContactProvider