import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Typography, Grid, DialogContentText, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    openModal: boolean,
    id: number,
    status: number,
    handleCloseModal: () => void
    modifyEnrollmentStatus: (registrationsId: number, status: number) => void
}

export default function ModalAprobado({ openModal, id, status, modifyEnrollmentStatus }:Props) {

    const closeModal = () => {
        modifyEnrollmentStatus(id, status)
    }

    return (
        <Dialog
            open={openModal}
            onClose={closeModal}
        >
            <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '25px'}} mt={6}>
                <Typography variant='h5'>¿Desea aprobar el empadronamiento?</Typography>
                <DialogContent>
                <DialogContentText>
                    Se dara de alta al profesional con el estado <Typography variant="inherit" component="span" fontWeight="700">"Activo"</Typography>
                </DialogContentText>
            </DialogContent>
            </Box>
            <Grid container spacing={1} justifyContent='center'>
            <Button 
                    onClick={closeModal} 
                    variant='contained' 
                    sx={{margin: '25px', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={() => modifyEnrollmentStatus(id, status ? 4 : 4)} 
                    variant='contained' 
                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                >
                    Aprobar
                </Button>
            </Grid>
        </Dialog>
    );
}