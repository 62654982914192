import { AffiliateItems, AffiliateState, Insurance, DocumentType, SexType, IvaType, TypeOfLoad } from '../../../interfaces/Affiliate';
import { InsurancePlanItems } from '../../../interfaces/Insurances';
import { CellPhoneConGuiones } from '../../../utils/CelPhoneFormat';

type Action = {type: 'getInsurances', payload: Insurance[]}
    | {type: 'getAffiliate', payload: AffiliateItems[]}
    | {type: 'getAffiliateById', payload: AffiliateItems}
    | {type: 'getInsurancePlanById', payload: {data: InsurancePlanItems[]}}
    | {type: 'messageError', payload: []}
    | {type: 'clearFilter'}
    | {type: 'getDocumentTypes', payload: DocumentType[]}
    | {type: 'getSexTypes', payload: SexType[]}
    | {type: 'getIvaTypes', payload: IvaType[]}
    | {type: 'getTypeOfLoad', payload: TypeOfLoad[]}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'filtered', payload: boolean}

export default function AffiliateReducer (state: AffiliateState, action: Action): AffiliateState {
    switch (action.type) {
        case 'getInsurances':
            return { 
                ...state, 
                dataInsurance: action.payload,
                loading: false
            }
        case 'getAffiliate':
            return { 
                ...state, 
                data: action.payload,
                loading: false
            }
        case 'getAffiliateById':
            state.items = action.payload
            state.items.cellPhone = state.items.cellPhone && state.items.cellPhone !== "0" ? CellPhoneConGuiones(action.payload.cellPhone) : ''
            state.items.phone = state.items.phone && state.items.phone !== "0" ? CellPhoneConGuiones(action.payload.phone) : ''
            return { 
                ...state, 
                items: state.items,
                loading: false
            }
        case 'getInsurancePlanById':
            return { 
                ...state,
                insurancesPlan: action.payload.data
            }
        case 'messageError':
            return { 
                ...state,
                messageError: action.payload
            }
        case 'clearFilter':
            return { 
                ...state,
                name: '',
                lastname: ''
            }
        case 'getDocumentTypes':
            return { 
                ...state,
                documentTypeList: action.payload
            }
        case 'getSexTypes':
            return { 
                ...state,
                sexTypeList: action.payload
            }
        case 'getIvaTypes':
            return { 
                ...state,
                ivaTypeList: action.payload
            }
        case 'getTypeOfLoad':
            return { 
                ...state,
                typeOfLoadList: action.payload
            }
        case 'handleChangeSelect':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'filtered':
            return {
                ...state,
                filtered: action.payload
            }
        default:
            return state;
    }
}