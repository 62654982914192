import Button from '../Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useContext, useEffect } from "react"
import ProfileContext from "../context/Profile/ProfileContext"
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import 'moment/locale/es';
import moment from 'moment';
import Loader from '../Loader';
import Alert from '../Alert';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { tokenDecode } from '../../interfaces/Login';
import jwt_decode from "jwt-decode";

const Index = () => {

    const { 
        getProfile,
        getProvincias, 
        getDepartamentos, 
        getLocalidades,
        handleChangeUbicacion, 
        handleChangeInput,
        handleChangeDatePicker,
        editProfile,
        getSecurityQuestions,
        // handleChangeAnswerField,
        handleChangeSelect,
        getDocumentTypes,
        closeAlert,
        getIvaTypes,
        getProfiles,
        getRoles,
        getCirculos,
        handleToggle,
        handleClickCheck,
        handleChangeDocumentType,
        handleChangeSelectCirculos,
        state 
    } = useContext(ProfileContext)

    useEffect(() => {
        if(!state.items.usersId){
            getProfile()       
            getSecurityQuestions()
            getDocumentTypes()
            getIvaTypes()
            getProfiles()
            getRoles()
            getCirculos()
            getProvincias();
        } else if(state.items.usersId && !state.loadingDatosDemograficos){
            getDepartamentos();
            getLocalidades();
        }
    }, [state.items.usersId, state.items.state, state.items.department])

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    // let questionAndAnswers: any[] = [];
    // state.listQuestions.map((questions) => {
    //     return state.items.answers.map((answers) => {
    //         if(answers.securityQuestionsId === questions.securityQuestionsId){
    //             questionAndAnswers.push({questionId: questions.securityQuestionsId, question: questions.question, answer: answers.answer})
    //         }
    //     })
    // })

    if (state.loading && !state.loadingDatosDemograficos) {
        return <Loader />
    }

    // armo array con preguntas que no estan seleccionadas para que no se repitan y se conteste la misma dos veces
    // const firstQuestionArray = state.listQuestions.filter(question => question.securityQuestionsId !== state.answerAndQuestions.secondQuestion && question.securityQuestionsId !== state.answerAndQuestions.thirdQuestion)
    // const secondQuestionArray = state.listQuestions.filter(question => question.securityQuestionsId !== state.answerAndQuestions.firstQuestion && question.securityQuestionsId !== state.answerAndQuestions.thirdQuestion)
    // const thirdQuestionArray = state.listQuestions.filter(question => question.securityQuestionsId !== state.answerAndQuestions.firstQuestion && question.securityQuestionsId !== state.answerAndQuestions.secondQuestion)

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.items.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.items.department))
    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    const profileCircle = decoded.profileId === 4

    const circlesWithStatusTrue = state.items.circlesChecked.filter(item => item.status === true)
    const circuloSelected = state.circles.filter(item => item.circlesId === state.items.circlesId[0])
    // formateo valor para select de circulo que se muestra cuando el perfil es circulo
    const formatValueCircle = {
        circlesId: circuloSelected.length ? circuloSelected[0].circlesId : 0,
        name: circuloSelected.length ? circuloSelected[0].name : '',
        status: true,
        idFopbaInsider: circuloSelected.length ? circuloSelected[0].idFopbaInsider : ''
    }

    return (
        <Box component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Datos de Acceso</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={4} md={state.items.profileId === 3 ? 6 : 4} alignItems="center">
                    <FormControl
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        required
                        error={!state.items.profileId && state.userEdited}
                    >
                        <InputLabel>Perfil</InputLabel>
                        <Select
                            label="Perfil"
                            name="profileId"
                            value={`${state.items.profileId ? state.items.profileId : ""}`}
                            disabled={profileCircle}
                            onChange={handleChangeSelect}
                        >
                            {state.profiles.map((profile) => (
                                <MenuItem key={profile.profilesId} value={profile.profilesId}>
                                    {profile.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={state.items.profileId === 3 ? 6 : 4}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        required
                        error={!state.items.rolId && state.userEdited}
                    >
                        <InputLabel>Rol</InputLabel>
                        <Select
                            label="Rol"
                            name="rolId"
                            value={`${state.items.rolId ? state.items.rolId : ""}`}
                            disabled={profileCircle}
                            onChange={handleChangeSelect}
                            >
                            {state.roles.map((r) => (
                                <MenuItem key={r.rolesId} value={r.rolesId}>
                                    {r.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {/* Perfil General */}
                {state.items.profileId === 2 && (
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            error={!circlesWithStatusTrue.length && state.userEdited}
                            margin="dense"
                        >
                            <Autocomplete
                                multiple
                                fullWidth
                                className="widthField"
                                disabled={profileCircle}
                                value={circlesWithStatusTrue}
                                getOptionLabel={(option) => {
                                    const idFopbaInsider = state.circles.find(circle => circle.name === option.name)?.idFopbaInsider
                                    return option.name ? idFopbaInsider + ' - ' + option.name : '';
                                }}
                                options={state.circles}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            disabled={state.items.circles.find(item => item.circleId === option.circlesId)?.using}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            onChange={(e) => handleToggle(Number(option.circlesId), e.target.checked, option.name)} 
                                            style={{ marginRight: 8 }}
                                            checked={circlesWithStatusTrue.findIndex(item => item.circlesId === option.circlesId && item.status) !== -1}
                                        />
                                        {option.idFopbaInsider + ' - ' + option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!state.items.state && state.userEdited}
                                        key={params.id}
                                        required
                                        name="circlesId"
                                        label="Círculos"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
                {/* Perfil Circulo */}
                {state.items.profileId === 4 && (
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            error={!circlesWithStatusTrue.length && state.userEdited}
                            margin="dense"
                        >
                            <Autocomplete
                                fullWidth
                                className="widthField"
                                value={formatValueCircle}
                                onChange={(event, newValue) =>
                                    handleChangeSelectCirculos(newValue ? [newValue.circlesId] : [], "circlesId")
                                }
                                getOptionLabel={(option) => {
                                    return option.name ? option.idFopbaInsider + ' - ' + option.name : option.name;
                                }}
                                options={state.circles}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!state.items.state && state.userEdited}
                                        key={params.id}
                                        required
                                        name="circlesId"
                                        label="Círculos"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <h4>Datos Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Nombre"
                        name='name'
                        required
                        variant="outlined"
                        fullWidth
                        error={!state.items.name && state.userEdited} 
                        value={state.items.name}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Apellido"
                        name='lastname'
                        required
                        variant="outlined"
                        fullWidth
                        error={!state.items.lastname && state.userEdited} 
                        value={state.items.lastname}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Cuil /CUIT"
                        name='cuil'
                        required
                        inputProps={{ maxLength: 13 }}
                        variant="outlined"
                        fullWidth
                        error={!state.items.cuil && state.userEdited}
                        value={state.items.cuil ? state.items.cuil : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 2 : 3}>
                    <FormControl fullWidth variant='outlined' required margin='dense' error={!state.documentTypeId && state.userEdited}>
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                            label="Tipo de documento"
                            name='documentTypeId'
                            value={state.documentTypeId}
                            onChange={handleChangeDocumentType}
                        >

                            {state.documentTypeList.map((d) => (
                                <MenuItem key={d.documentTypeId} value={d.documentTypeId}>{d.code}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 2 : 3}>
                    <TextField
                        margin="dense"
                        label="Número de Documento"
                        name='documentValue'
                        required
                        variant="outlined"
                        fullWidth
                        error={!state.items.documentValue && state.userEdited}
                        value={state.items.documentValue ? state.items.documentValue : ''}
                        onChange={handleChangeInput}
                        inputProps={state.documentTypeId === 1 ? { maxLength: 8 } : {}}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 4 : 6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha de Nacimiento"
                            value={state.items.bornDate ? state.items.bornDate : null}
                            onChange={(newValue) => handleChangeDatePicker(moment(newValue).format('YYYY-MM-DD'), 'bornDate')}
                            renderInput={(params) => <TextField {...params} error={!state.items.bornDate && state.userEdited} required variant='outlined' fullWidth margin="dense" />}
                        />
                    </LocalizationProvider>
                </Grid>
                {state.items.profileId === 2 ? 
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            required
                            margin="dense"
                            error={!state.items.ivaTypesId && state.userEdited}
                        >
                            <InputLabel>Condición Fiscal</InputLabel>
                            <Select
                                label="Condición Fiscal"
                                name="ivaTypesId"
                                value={`${state.items.ivaTypesId ? state.items.ivaTypesId : ""}`}
                                onChange={handleChangeSelect}
                            >
                                {state.ivaTypeList.map(item => (
                                    <MenuItem key={item.ivaTypeId} value={item.ivaTypeId}>{item.description}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                : null}
            </Grid>
            {state.items.profileId === 2 ?
                <>
                    <h4 style={{marginTop: 40}}>Datos Demográficos</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                        name: provinciaSelected?.name ? provinciaSelected.name : ''
                                    }}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={state.provincias}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!state.items.state && state.userEdited}
                                        key={params.id}
                                        required
                                        label="Provincia"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                        name: departamentSelected?.name ? departamentSelected.name : ''
                                    }}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={state.departamentos}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!state.items.department && state.userEdited}
                                        key={params.id}
                                        required
                                        label="Partido"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                        name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                                    }}
                                    onOpen={!state.localidades.length ? getLocalidades : () => {}}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={localidadesFormat}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!state.items.town && state.userEdited}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="dense"
                                label="Calle"
                                name="street"
                                fullWidth
                                required
                                variant="outlined"
                                value={state.items.street}
                                error={!state.items.street && state.userEdited}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="dense"
                                label="Entre calle"
                                name="betweenStreet"
                                variant="outlined"
                                fullWidth
                                value={state.items.betweenStreet}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="dense"
                                label="Observación"
                                name="observations"
                                variant="outlined"
                                fullWidth
                                value={state.items.observations}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="dense"
                                label="Número"
                                name='streetNumber'
                                required
                                variant="outlined"
                                fullWidth
                                error={!state.items.streetNumber && state.userEdited} 
                                value={state.items.streetNumber ? state.items.streetNumber : ''}
                                onChange={handleChangeInput}
                            />
                            <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.streetNumber === 'S/N'} />} label="Sin Número" />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="dense"
                                label="Piso"
                                name='floor'
                                variant="outlined"
                                fullWidth
                                value={state.items.floor ? state.items.floor : ''}
                                inputProps={{maxLength: "2"}}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="dense"
                                label="Depto"
                                name='apartment'
                                variant="outlined"
                                fullWidth
                                value={state.items.apartment ? state.items.apartment : ''}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="dense"
                                label="Código Postal"
                                name='postalCode'
                                required
                                variant="outlined"
                                fullWidth
                                error={!state.items.postalCode && state.userEdited} 
                                value={state.items.postalCode ? state.items.postalCode : ''}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                    </Grid>
                </>
            : null}
            <h4>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 3}} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='phone'
                        required
                        variant="outlined"
                        fullWidth
                        error={!state.items.phone && state.userEdited} 
                        value={state.items.phone ? state.items.phone : ''}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 18 }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='cellPhone'
                        variant="outlined"
                        fullWidth
                        value={state.items.cellPhone ? state.items.cellPhone : ''}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 18 }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        label="Email"
                        name='email'
                        required
                        variant="outlined"
                        fullWidth
                        value={state.items.email ? state.items.email : ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        required
                        margin="dense"
                        error={state.items.isProfessional === "" && state.userEdited}
                    >
                        <InputLabel>Es Profesional</InputLabel>
                        <Select
                            label="Es Profesional"
                            name="isProfessional"
                            value={state.items.isProfessional}
                            disabled={profileCircle}
                            onChange={handleChangeSelect}
                        >
                            <MenuItem value="true">Si</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <h4>Seguridad</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 3}} />
            <Grid container spacing={3}>
                {/* <Grid item xs={4} sm={4} md={4}>
                    <FormControl fullWidth >
                        <InputLabel>Pregunta 1</InputLabel>
                        <Select
                            value={state.answerAndQuestions.firstQuestion}
                            label='Pregunta 1'
                            name='firstQuestion'
                            onChange={handleChangeSelect}
                        >
                            {firstQuestionArray.map((question) => {
                                return <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <FormControl fullWidth >
                        <InputLabel>Pregunta 2</InputLabel>
                        <Select
                            value={state.answerAndQuestions.secondQuestion}
                            label='Pregunta 2'
                            name='secondQuestion'
                            onChange={handleChangeSelect}
                        >
                            {secondQuestionArray.map((question) => (
                                <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <FormControl fullWidth >
                        <InputLabel>Pregunta 3</InputLabel>
                        <Select
                            value={state.answerAndQuestions.thirdQuestion}
                            label='Pregunta 3'
                            name='thirdQuestion'
                            onChange={handleChangeSelect}
                        >
                            {thirdQuestionArray.map((question) => (
                                <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* <Grid item xs={4} sm={4} md={4}>
                    <TextField 
                        label='Respuesta 1'
                        variant="outlined"
                        name='firstAnswer'
                        value={state.answerAndQuestions.firstAnswer}
                        onChange={(e) => handleChangeAnswerField(e)}
                        margin="dense"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <TextField 
                        label='Respuesta 2'
                        variant="outlined"
                        name='secondAnswer'
                        value={state.answerAndQuestions.secondAnswer}
                        onChange={(e) => handleChangeAnswerField(e)}
                        margin="dense"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <TextField 
                        label='Respuesta 3'
                        variant="outlined"
                        name='thirdAnswer'
                        value={state.answerAndQuestions.thirdAnswer}
                        onChange={(e) => handleChangeAnswerField(e)}
                        margin="dense"
                        fullWidth
                    />
                </Grid> */}
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Contraseña"
                        name='password'
                        type='password'
                        variant="outlined"
                        fullWidth
                        error={state.passwordsDontMatch} 
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Repetir Contraseña"
                        name='repeatPassword'
                        type='password'
                        variant="outlined"
                        fullWidth
                        helperText={state.passwordsDontMatch ? 'Las contraseñas no coinciden' : ''}
                        error={state.passwordsDontMatch} 
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ? 
                    state.messageError.map((message, index) => (
                        <Typography color='error' variant="body1" key={index}>
                            {message}
                        </Typography>
                    ))
                : null}
            </Grid>
            {/* BUTTONS */}
            <Grid container justifyContent='end' sx={{mt: 2}} spacing={2}>
                <Grid item md={2}>
                    <Button child='Cancelar' handleClick={getProfile} variant='outlined' color='primary' />
                </Grid>
                <Grid item md={2}>
                    <Button child='Guardar' handleClick={editProfile} variant='contained' disabled={state.buttonDisabled} color='secondary' />
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Perfil editado correctamente'} />
        </Box>
    )
}

export default Index