import { createContext } from 'react'
import { LoginState } from '../../../interfaces/Login';

export interface ContextProps {
    state: LoginState
    doLogin: (user: string, contraseña: string) => void
    handleChangeValues: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const LoginContext = createContext<ContextProps>({} as ContextProps);

export default LoginContext;