import { useReducer } from 'react'
import { AffiliateState } from '../../../interfaces/Affiliate';
import AffiliateContext from './AffiliateContext';
import AffiliateReducer from './AffiliateReducer';
import { SelectChangeEvent } from '@mui/material';
import axios from 'axios';

const INITIAL_STATE: AffiliateState = {
    data: [],
    dataInsurance: [],
    messageError: [],
    openConfirm: false,
    openAddModal: false,
    loading: true,
    insuranceSelected: 0,
    name: '',
    lastname: '',
    documentTypeList: [],
    sexTypeList: [],
    ivaTypeList: [],
    typeOfLoadList: [],
    insurancesPlan: [],
    filtered: false,
    items: {
        affiliateId: 0,
        planId: 0,
        name: '',
        lastname: '',
        uploadType: 0,
        documentType: {documentTypeId: 0, name: ''},
        documentNumber: '',
        sex: 0,
        ivaCondition: 0,
        addRegister: '',
        email: '',
        bornDate: '',
        phone: '',
        cellPhone: '',
        street: '',
        streetNumber: 0,
        floor: 0,
        apartment: '',
        postalCode: '',
        town: '',
        department: '',
        state: '',
        country: 'Argentina'
    }
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const AffiliateProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(AffiliateReducer, INITIAL_STATE)

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances?offset=0&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getAffiliate = async (insuranceId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}affiliates/${insuranceId}/insurances`, {headers})
        .then(response => {
            dispatch({type: 'getAffiliate', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getAffiliateFilter = async (insuranceId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
                
        dispatch({type: 'setLoading', payload: true});
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${insuranceId}/affiliates?name=${state.name}&lastname=${state.lastname}`, {headers})
        .then(response => {
            dispatch({type: 'getAffiliate', payload: response.data})
            dispatch({type: 'filtered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getAffiliateById = async (insuranceId: number, id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}affiliates/${id}`, {headers})
        .then(response => {
            dispatch({type: 'getAffiliateById', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsurancePlanById = async (insuranceId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${Number(insuranceId)}/plans`, {headers})
        .then(response => {
            dispatch({type: 'getInsurancePlanById', payload: {data: response.data}})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de usuarios para traer todos los resultado nuevamente
        if(state.filtered){
            getAffiliate(Number(state.insuranceSelected))
            dispatch({type: 'filtered', payload: false})
        }
    }

    const getDocumentTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}documentTypes/`, {headers})
        dispatch({type: 'getDocumentTypes', payload: response.data})
    }

    const getSexTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}sexType/`, {headers})
        .then(response => {
            dispatch({type: 'getSexTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getIvaTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}ivaType/`, {headers})
        .then(response => {
            dispatch({type: 'getIvaTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getTypeOfLoad = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}affiliated/uploadType/`, {headers})
        .then(response => {
            dispatch({type: 'getTypeOfLoad', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeSelect = (event: SelectChangeEvent<number>) => {

        dispatch({type: 'handleChangeSelect', payload: {name: event.target.name, value: event.target.value}})
    };

    return(
        <AffiliateContext.Provider value={{
            state,
            getInsurances,
            getAffiliate,
            getAffiliateFilter,
            getAffiliateById,
            getInsurancePlanById,
            clearFilter,
            getDocumentTypes,
            getSexTypes,
            getIvaTypes,
            getTypeOfLoad,
            handleChangeFilter,
            handleChangeSelect
        }}>
            {children}
        </AffiliateContext.Provider>
    )
}

export default AffiliateProvider