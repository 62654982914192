import { createContext } from 'react'
import { UserState, HTMLInputEvent } from '../../../interfaces/Users';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: UserState,
    getUsers: () => void
    usersNextPage: () => void
    getUserById: (id: number) => void
    getProfiles: () => void
    getCirculos: () => void
    getDocumentTypes: (edit: boolean) => void
    getRoles: () => void
    editUser: (id: string | undefined) => void
    deleteUser: (id: string | undefined) => void
    getFilterUsers: () => void
    getIvaTypes: () => void
    getUserStates: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    clearFilter: () => void
    getAccess: () => void
    addUsers: () => void
    addMultipleUsers: () => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getCalles: () => void
    getProfessionalStatus: () => void
    getProfessionals: () => void
    profesionalsNextPage: () => void
    getProfessionalsById: (id: number) => void
    modifyProfessionalStatus: (professionalsId: number,  circleGroupingCode: number, insuranceGroupingCode: number, startDate: string, endDate: string, status: number, observation: string) => void
    getProfessionalsFilter: () => void
    clearProfessionalStatusFilter: () => void
    setValuesDefaultForAdminCircle: () => void
    setValuesDefaultForProfileDistinctToGeneral: () => void
    clearValuesDefaultForProfileDistinctToGeneral: () => void
    handleChangeUbicacion: (value: number, name: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleChangeSelect: (event:SelectChangeEvent<string | number[]>) => void
    handleToggle: (circleId: number, status: boolean, name?: string) => void
    handleChangeDocumentType: (event:SelectChangeEvent<number>) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleChangeFile: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> & HTMLInputEvent) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleChangeSelectCirculos: (value: string | number[], name: string) => void
    handleSubmitAddUser: () => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    closeAlert: () => void
    clearValues: () => void
    handleChangeFileUser: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const UserContext = createContext<ContextProps>({} as ContextProps);

export default UserContext;