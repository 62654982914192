import { useContext, useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom'
import { Box, Divider, Grid, Typography, Paper, Tooltip} from '@mui/material'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import Loader from "../Loader";
import Button from "../Button";
import Pagination from "../Pagination";
import Checkbox from '@mui/material/Checkbox';
import { tokenDecode } from '../../interfaces/Login';
import jwt_decode from "jwt-decode";
import ModalRechazo from "./ModalRechazo";
import ModalAprobado from "./ModalAprobado";
import { CellPhoneConGuiones } from "../../utils/CelPhoneFormat";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Button as MuiButton} from '@mui/material';

//icons
import InfoIcon from '@mui/icons-material/Info';

const TypographyStyled = styled(Typography)(() => ({
    color: '#64AAB2',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const StyledInfoIcon = styled(InfoIcon)(() => ({
    color: '#64AAB2'
}));

const Detail = () => {

    const { state, getAllEnrollmentDetail, modifyEnrollmentStatus, getMainSpeciality, getInsuranceType, getCircles } = useContext(EmpadronamientoContext)

    const {registrationsId: id} = useParams();

    useEffect(() => {
        getAllEnrollmentDetail(Number(id))
        getMainSpeciality()
        getInsuranceType()
        getCircles()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openModalRechazo, setOpenModalRechazo] = useState(false);
    const [openModalAprobado, setOpenModalAprobado] = useState(false);
    const [openFirstToolTip, setOpenFirstToolTip] = useState(false);
    const [openSecondToolTip, setOpenSecondToolTip] = useState(false);
    const [openThreeToolTip, setOpenThreeToolTip] = useState(false);
    const [openEmailToolTip, setOpenEmailToolTip] = useState(false);
    const [openSecondEmailToolTip, setOpenSecondEmailToolTip] = useState(false);

    //Abre o cierra modal de empadronamiento rechazado
    const handleCloseModalRechazo = () => {
        setOpenModalRechazo(false)
    }
    const handleOpenModalRechazo = () => {
        setOpenModalRechazo(true)
    }
    //Abre o cierra modal de empadronamiento aprobado
    const handleCloseModalAprobado = () => {
        setOpenModalAprobado(false)
    }
    const handleOpenModalAprobado = () => {
        setOpenModalAprobado(true)
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleFirstTooltipClose = () => {
        setOpenFirstToolTip(false);
      };
    
      const handleFirstTooltipOpen = () => {
        setOpenFirstToolTip(true);
      };

      const handleSecondTooltipClose = () => {
        setOpenSecondToolTip(false);
      };
    
      const handleSecondTooltipOpen = () => {
        setOpenSecondToolTip(true);
      };

      const handleThreeTooltipClose = () => {
        setOpenThreeToolTip(false);
      };
    
      const handleThreeTooltipOpen = () => {
        setOpenThreeToolTip(true);
      };

      const handleEmailTooltipClose = () => {
        setOpenEmailToolTip(false);
      };
    
      const handleEmailTooltipOpen = () => {
        setOpenEmailToolTip(true);
      };
      const handleSecondEmailTooltipClose = () => {
        setOpenSecondEmailToolTip(false);
      };
    
      const handleSecondEmailTooltipOpen = () => {
        setOpenSecondEmailToolTip(true);
      };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (base64data: string, fileName: string) => {
        const byteCharacters = atob(base64data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
      };

    //Almaceno el nombre del circulo al que pertenece el profesional
    const circleSelected = state.dataCircles.find(item => item.circlesId === state.allEnrollmentsDetail.circleId)
    
    //Almaceno el nombre del seguro al que pertenece el profesional
    const secureSelected = state.insuranceTypeData.find(insurance => insurance.insuranceTypeId === state.allEnrollmentsDetail.secureId)

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    if(state.loadingAllEnrollments || state.loading){
        return <Loader />
    }
    return (
                <Box sx={{margin: '20px 15px 0px 15px'}}>
                    {/*Muestro detalles generales*/}
                    <h4>Detalles</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Grid container spacing={3} justifyContent='space-between' mb={5}>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Nombre: </TypographyStyled>{state.allEnrollmentsDetail.name}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Apellido: </TypographyStyled>{state.allEnrollmentsDetail.lastName}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Estado: </TypographyStyled>{state.allEnrollmentsDetail.registrationState.description}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Círculo: </TypographyStyled>{circleSelected?.idFopbaInsider} - {circleSelected?.name}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Matrícula Provincial: </TypographyStyled>{state.allEnrollmentsDetail.professional.provincialEnrollment}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Matrícula Nacional: </TypographyStyled>{state.allEnrollmentsDetail.professional.nationalEnrollment ? state.allEnrollmentsDetail.professional.nationalEnrollment : ' - '}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Ingreso en la Actividad: </TypographyStyled>{state.allEnrollmentsDetail.professional.startActivity}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Ingreso en FOPBA: </TypographyStyled>{state.allEnrollmentsDetail.professional.fopbaRegistration ? state.allEnrollmentsDetail.professional.fopbaRegistration : ' - '}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Seguro: </TypographyStyled>{secureSelected?.description}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Inicio de Seguro: </TypographyStyled>{state.allEnrollmentsDetail.secureStartDate ? state.allEnrollmentsDetail.secureStartDate : ' - '}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Finalización de Seguro: </TypographyStyled>{state.allEnrollmentsDetail.secureFinishDate ? state.allEnrollmentsDetail.secureFinishDate : ' - '}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <MuiButton variant="contained" disabled={!state.allEnrollmentsDetail.collectiveFile} onClick={() => handleDownload(state.allEnrollmentsDetail.collectiveFile, 'Póliza de Seguro')}>
                                Descargar Archivo de Seguro
                            </MuiButton>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6} mt={-1}>
                            <MuiButton variant="contained" disabled={!state.allEnrollmentsDetail.userFile} onClick={() => handleDownload(state.allEnrollmentsDetail.userFile, 'Archivo de Alta de Usuario')}>
                                Descargar Archivo de Alta de Usuario
                            </MuiButton>
                        </Grid>
                   </Grid>
                   {/*Muestro detalles de obra sociales*/}
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <h4>Obra/s Social/es</h4>
                    {state.allEnrollmentsDetail.insurances.length ?
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Obra Social</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Odontología General</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Prótesis</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Ortodoncia</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.allEnrollmentsDetail.insurances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.allEnrollmentsDetail.insurances
                                ).map((row) => {
                                    return <StyledTableRow
                                        key={row.insurancesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                        {row.generalCoverages.map((coverage, index) => {
                                            return <StyledTableCell key={index} sx={{padding: '12px'}} align="center"><Checkbox checked={coverage.status ? coverage.status : false} disabled={!coverage.status} onChange={() => {}} /></StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.allEnrollmentsDetail.insurances.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.allEnrollmentsDetail.insurances.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    :
                    <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ borderColor: "#64AAB2", mt: 2 }} />
                    <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No existen obras sociales para este profesional</Typography>
                    </Box>
                    </Grid>
                    }
                    {/*Muestro detalles especialidades*/}
                    <Divider sx={{borderColor: '#64AAB2', mb: 2, marginTop: "30px"}} />
                    <h4>Especialidad/es</h4>
                    {state.allEnrollmentsDetail.specialitiesResponse.length ? 
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Especialidad Principal</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Institución</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Año de Finalización</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Especialidad Exclusiva</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.allEnrollmentsDetail.specialitiesResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.allEnrollmentsDetail.specialitiesResponse
                                ).map((row) => {
                                    return <StyledTableRow
                                        key={row.specialityId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.description}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.awardedBy}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.awardedYear}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">{row.isExclusive ? 'Si': 'No'}</StyledTableCell>    
                                    </StyledTableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ borderColor: "#64AAB2", mt: 2 }} />
                    <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No existen especialidades para este profesional</Typography>
                    </Box>
                    </Grid>
                    }
                    {/*Muestro detalles consultorios*/}
                    <Divider sx={{borderColor: '#64AAB2', mb: 2, marginTop: "30px"}} />
                    <h4>Consultorio/s</h4>
                    {state.allEnrollmentsDetail.officesResponse.length ?
                    <TableContainer component={Paper} sx={{marginBottom: '30px'}}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Calle</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Numero</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Entre Calles</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Teléfono / Celular</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Teléfono / Celular</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Email</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Email Opcional</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Fecha de Habilitación</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Rayos X</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Horario de Atención</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Observaciones</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                            </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.allEnrollmentsDetail.officesResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.allEnrollmentsDetail.officesResponse
                                ).map((row) => {
                                    return <StyledTableRow
                                        key={row.officeId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.street}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.height}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">
                                        <ClickAwayListener onClickAway={handleFirstTooltipClose}>
                                            <Tooltip sx={{cursor: 'pointer'}} title={row.betweenStreet ? row.betweenStreet : 'Sin Información'} placement="top" disableFocusListener disableTouchListener arrow onClose={handleFirstTooltipClose} open={openFirstToolTip}>
                                                <StyledInfoIcon onClick={handleFirstTooltipOpen}/>
                                            </Tooltip>
                                        </ClickAwayListener>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.phone}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.cellPhone ? row.cellPhone : '-'}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">
                                            <ClickAwayListener onClickAway={handleEmailTooltipClose}>
                                                <Tooltip sx={{cursor: 'pointer'}} title={row.email ? row.email : 'Sin Información'} placement="top" disableFocusListener disableTouchListener arrow onClose={handleEmailTooltipClose} open={openEmailToolTip}>
                                                    <StyledInfoIcon onClick={handleEmailTooltipOpen}/>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">
                                            <ClickAwayListener onClickAway={handleSecondEmailTooltipClose}>
                                                <Tooltip sx={{cursor: 'pointer'}} title={row.secondEmail ? row.secondEmail : 'Sin Información'} placement="top" disableFocusListener disableTouchListener arrow onClose={handleEmailTooltipClose} open={openSecondEmailToolTip}>
                                                    <StyledInfoIcon onClick={handleSecondEmailTooltipOpen}/>
                                                </Tooltip>
                                            </ClickAwayListener>  
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.availabilityDate}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">{row.hasXRay ? 'Si': 'No'}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">
                                        <ClickAwayListener onClickAway={handleSecondTooltipClose}>
                                            <Tooltip sx={{cursor: 'pointer'}} title={row.attentionHours ? row.attentionHours : 'Sin Información'} placement="top" disableFocusListener disableTouchListener arrow onClose={handleSecondTooltipClose} open={openSecondToolTip}>
                                                <StyledInfoIcon onClick={handleSecondTooltipOpen}/>
                                            </Tooltip>
                                        </ClickAwayListener>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">
                                        <ClickAwayListener onClickAway={handleThreeTooltipClose}>
                                            <Tooltip sx={{cursor: 'pointer'}} title={row.observations ? row.observations : 'Sin Información'} placement="top" disableFocusListener disableTouchListener arrow onClose={handleThreeTooltipClose} open={openThreeToolTip}>
                                                <StyledInfoIcon onClick={handleThreeTooltipOpen}/>
                                            </Tooltip>
                                        </ClickAwayListener>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.officeStateId ===  3 ? 'Inactivo' : 'Activo'}</StyledTableCell>
                                    </StyledTableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Grid item xs={12} sm={12} md={12}>
                    <Divider sx={{ borderColor: "#64AAB2", mt: 2 }} />
                    <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No existen consultorios para este profesional</Typography>
                    </Box>
                    </Grid>
                    }
                    {/*Muestro los botones aprobar o rechazar solo si el estado es 'Pendiente FOPBA'*/}
                    {decoded.profileId === 3 && state.allEnrollmentsDetail.registrationState.description === 'Pendiente FOPBA' && (
                    <Grid container spacing={3} sx={{mt: 2, mb: 5}} alignItems='center' justifyContent='center'> 
                            <Grid container item justifyContent='end' spacing={3} xs={8} sm={6} md={6} alignItems='center'>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button child='Rechazar' handleClick={handleOpenModalRechazo} variant='outlined' color="error" />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button child='Aprobar' handleClick={handleOpenModalAprobado} variant='contained' color="secondary" />
                                </Grid>
                            </Grid>
                    </Grid>
                    )}
                    <ModalRechazo openModal={openModalRechazo} handleCloseModal={handleCloseModalRechazo} modifyEnrollmentStatus={modifyEnrollmentStatus} id={Number(id)} status={state.allEnrollmentsDetail.registrationState.registrationStatesId}/>
                    <ModalAprobado openModal={openModalAprobado} handleCloseModal={handleCloseModalAprobado} modifyEnrollmentStatus={modifyEnrollmentStatus} id={Number(id)} status={state.allEnrollmentsDetail.registrationState.registrationStatesId}/>
                    <Grid container>
                        <Grid item xs={3} md={3} pb={3}>
                            <Link to={'/empadronamiento'}>
                                <Button child='volver' variant="outlined"/>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
    )
}
export default Detail