import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Checkbox, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useContext, useEffect } from "react";
import UserContext from "../context/Users/UserContext";
import Divider from "@mui/material/Divider";
import "moment/locale/es";
import moment from "moment";
import "./style.css";
import Loader from "../Loader";
import Alert from "../Alert";
import jwt_decode from "jwt-decode";
import FormControlLabel from '@mui/material/FormControlLabel';
import { tokenDecode } from '../../interfaces/Login';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import styled from "@emotion/styled";

const BoxStyled = styled(Box)(() => ({
    border: '1px dashed #42C5B7',
    padding: '20px 10px', 
    display: 'flex', 
    justifyContent: 'center', 
    position: 'relative', 
    borderRadius: '5px',
    marginTop: '10px'
}));

const Add = () => {
    const {
        handleChangeFileUser,
        getProvincias,
        getDepartamentos,
        getLocalidades,
        handleChangeUbicacion,
        handleChangeInput,
        handleChangeSelect,
        handleChangeDatePicker,
        handleSubmitAddUser,
        getProfiles,
        getCirculos,
        getDocumentTypes,
        getRoles,
        closeAlert,
        clearValues,
        handleChangeDocumentType,
        setValuesDefaultForAdminCircle,
        setValuesDefaultForProfileDistinctToGeneral,
        clearValuesDefaultForProfileDistinctToGeneral,
        getIvaTypes,
        handleClickCheck,
        handleChangeSelectCirculos,
        handleToggle,
        state,
    } = useContext(UserContext);

    useEffect(() => {
            getRoles()
            getCirculos();
            getProfiles();
            getDocumentTypes(false);
            clearValues();
            getProvincias();
            getIvaTypes();
            if(profileCircle){
                setValuesDefaultForAdminCircle()
            }
    }, []);

    useEffect(() => {
        if(state.items.profileId && state.items.profileId !== 2){
            // aca se setean valores por defecto de datos demograficos y condicion fiscal para el perfil fopba y circulo
            // ya que esos campos se ocultan para esos perfiles y se les setea un valor para que no rompa la validación 
            setValuesDefaultForProfileDistinctToGeneral()
        } else {
            clearValuesDefaultForProfileDistinctToGeneral()
        }
    }, [state.items.profileId]);

    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    useEffect(() => {
        if(Number(localidadSelected?.postalCode) !== 0){
            state.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
        }
    }, [localidadSelected])
    
    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    if (state.loading) {
        return <Loader />;
    }

    const file = state.items.userFile ? state.items.userFile[0] : ''

    let extensionError = false;

    if(file){
        const extension = file.name.split('.').pop()?.toLowerCase()
        if(extension !== 'pdf'){
            extensionError = true
        }else{
            extensionError = false
        }
    }

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    const profileCircle = decoded.profileId === 4
    const rolDisabled = state.items.profileId === 0

    const circlesWithStatusTrue = state.items.circlesChecked.filter(item => item.status === true)
    const circuloSelected = state.circles.filter(item => item.circlesId === state.items.circlesId[0])
    // formateo valor para select de circulo que se muestra cuando el perfil es circulo
    const formatValueCircle = {
        circlesId: circuloSelected.length ? circuloSelected[0].circlesId : 0,
        name: circuloSelected.length ? circuloSelected[0].name : '',
        status: true,
    }

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.items.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.items.department))
    
    

    return (
        <Box
            className="container"
            component="form"
            sx={{ margin: "20px 15px 30px 15px" }}
        >
            <h4>Datos de Acceso</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        required
                        margin="normal"
                        error={!state.items.profileId && state.userAdded}
                    >
                        <InputLabel>Perfil</InputLabel>
                            <Select
                                label="Perfil"
                                name="profileId"
                                disabled={profileCircle}
                                value={`${state.items.profileId ? state.items.profileId : ""}`}
                                onChange={handleChangeSelect}
                            >
                            {state.profiles.map((profile) => (
                                <MenuItem key={profile.profilesId} value={profile.profilesId}>
                                {profile.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        required
                        margin="normal"
                        error={!state.items.rolId && state.userAdded}
                    >
                        <InputLabel>Rol</InputLabel>
                        <Select
                            label="Rol"
                            name="rolId"
                            disabled={rolDisabled || profileCircle}
                            value={`${state.items.rolId ? state.items.rolId : ""}`}
                            onChange={handleChangeSelect}
                        >
                            {state.roles.map((r) => (
                                <MenuItem key={r.rolesId} value={r.rolesId}>
                                {r.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Contraseña"
                        name="password"
                        fullWidth
                        required
                        type="password"
                        variant="standard"
                        error={!state.items.password && state.userAdded}
                        value={state.items.password}
                        onChange={handleChangeInput}
                    />
                </Grid>
                {/* Perfil General */}
                {(state.items.profileId === 2 || profileCircle) && (
                    <Grid item xs={6} sm={6} md={12}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            error={!state.items.circlesId.length && state.userAdded}
                            margin="normal"
                        >
                            <Autocomplete
                                multiple
                                fullWidth
                                disabled={profileCircle}
                                className="widthField"
                                onChange={(event, newValue) =>
                                    {
                                        const values = newValue.map((item) => {
                                            return item.circlesId
                                        })
                                        handleChangeSelectCirculos(values, "circlesId")
                                    }
                                }
                                getOptionLabel={(option) => {
                                    const idFopbaInsider = state.circles.find(c => c.name === option.name)?.idFopbaInsider
                                    return option.name ? idFopbaInsider + ' - ' + option.name : ''
                                }}
                                value={profileCircle ? [formatValueCircle] : circlesWithStatusTrue}
                                options={state.circles}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            onChange={(e) => handleToggle(Number(option.circlesId), e.target.checked, option.name)} 
                                            style={{ marginRight: 8 }}
                                            checked={circlesWithStatusTrue.findIndex(item => item.circlesId === option.circlesId && item.status) !== -1}
                                        />
                                        {option.idFopbaInsider + ' - ' + option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!state.items.circlesId.length && state.userAdded}
                                        key={params.id}
                                        required
                                        name="circlesId"
                                        label="Círculos"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
                {/* Perfil Circulo */}
                {state.items.profileId === 4 && (
                    <Grid item xs={6} sm={6} md={12}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            error={!state.items.circlesId.length && state.userAdded}
                            margin="normal"
                        >
                            <Autocomplete
                                fullWidth
                                className="widthField"
                                onChange={(event, newValue) =>
                                    handleChangeSelectCirculos(newValue ? [newValue.circlesId] : [], "circlesId")
                                }
                                getOptionLabel={(option) => {
                                    return option.idFopbaInsider + ' - ' + option.name;
                                }}
                                options={state.circles}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!state.items.circlesId.length && state.userAdded}
                                        key={params.id}
                                        required
                                        name="circlesId"
                                        label="Círculos"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <h4>Datos Generales</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre"
                        name="name"
                        required
                        variant="standard"
                        fullWidth
                        error={!state.items.name && state.userAdded}
                        value={state.items.name}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Apellido"
                        name="lastname"
                        required
                        variant="standard"
                        fullWidth
                        error={!state.items.lastname && state.userAdded}
                        value={state.items.lastname}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Cuil / Cuit"
                        fullWidth
                        name="cuil"
                        required
                        inputProps={{ maxLength: 13 }}
                        variant="standard"
                        error={!state.items.cuil && state.userAdded}
                        value={state.items.cuil ? state.items.cuil : ""}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 2 : 3}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        required
                        margin="normal"
                        error={!state.documentTypeId && state.userAdded}
                    >
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                            label="Tipo de documento"
                            name="documentTypeId"
                            value={state.documentTypeId ? state.documentTypeId : ""}
                            onChange={handleChangeDocumentType}
                        >
                            {state.documentType.map((d) => (
                                <MenuItem key={d.documentTypeId} value={d.documentTypeId}>
                                    {d.code}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 2 : 3}>
                    <TextField
                        margin="normal"
                        label="Número de Documento"
                        name="documentValue"
                        required
                        inputProps={state.documentTypeId === 1 ? { maxLength: 8 } : {}}
                        variant="standard"
                        fullWidth
                        error={!state.items.documentValue && state.userAdded}
                        value={state.items.documentValue ? state.items.documentValue : ""}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 4 : 6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha de Nacimiento"
                            value={state.items.bornDate ? state.items.bornDate : null}
                            onChange={(newValue) =>
                                handleChangeDatePicker(
                                moment(newValue).format("YYYY-MM-DD"),
                                "bornDate"
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!state.items.bornDate && state.userAdded}
                                    variant="standard"
                                    required
                                    fullWidth
                                    margin="normal"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                {state.items.profileId === 2 ? 
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            margin="normal"
                            error={!state.items.ivaTypesId && state.userAdded}
                        >
                            <InputLabel>Condición Fiscal</InputLabel>
                            <Select
                            label="Condición Fiscal"
                            name="ivaTypesId"
                            value={`${state.items.ivaTypesId ? state.items.ivaTypesId : ""}`}
                            onChange={handleChangeSelect}
                            >
                            {state.ivaTypeList.map(item => (
                                <MenuItem key={item.ivaTypeId} value={item.ivaTypeId}>{item.description}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                : null}
            </Grid>
            {state.items.profileId === 2 ? 
                <>
                    <h4>Datos Demográficos</h4>
                    <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    fullWidth
                                    value={{
                                        statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                        name: provinciaSelected?.name ? provinciaSelected.name : ''
                                    }}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={state.provincias}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!state.items.state && state.userAdded}
                                            key={params.id}
                                            required
                                            label="Provincia"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                        name: departamentSelected?.name ? departamentSelected.name : ''
                                    }}
                                    onOpen={getDepartamentos}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={state.departamentos}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!state.items.department && state.userAdded}
                                            key={params.id}
                                            required
                                            label="Partido"
                                        />
                                    )}
                                    fullWidth
                                    disabled={!state.items.state}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                        name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                                    }}
                                    onOpen={getLocalidades}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name
                                    }}
                                    options={localidadesFormat}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!state.items.town && state.userAdded}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                        />
                                    )}
                                    fullWidth
                                    disabled={!state.items.department}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="normal"
                                label="Calle"
                                name="street"
                                required
                                variant="standard"
                                fullWidth
                                error={!state.items.street && state.userAdded}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="normal"
                                label="Entre calle"
                                name="betweenStreet"
                                variant="standard"
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="normal"
                                label="Observación"
                                name="observations"
                                variant="standard"
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Número"
                                name="streetNumber"
                                required
                                variant="standard"
                                fullWidth
                                error={!state.items.streetNumber && state.userAdded}
                                value={state.items.streetNumber ? state.items.streetNumber : ""}
                                onChange={handleChangeInput}
                            />
                            <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.streetNumber === 'S/N'} />} label="Sin Número" />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Piso"
                                name="floor"
                                variant="standard"
                                inputProps={{maxLength: "2"}}
                                fullWidth
                                value={state.items.floor ? state.items.floor : ""}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Depto"
                                name="apartment"
                                variant="standard"
                                fullWidth
                                value={state.items.apartment ? state.items.apartment : ""}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Código postal"
                                name="postalCode"
                                required
                                variant="standard"
                                fullWidth
                                error={!state.items.postalCode && state.userAdded}
                                value={state.items.postalCode ? state.items.postalCode : ""}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                    </Grid>
                </>
            : null}
            <h4>Datos de Contacto</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name="phone"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.phone && state.userAdded}
                        value={state.items.phone ? state.items.phone : ""}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 18 }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name="cellPhone"
                        variant="standard"
                        className="widthField"
                        value={state.items.cellPhone ? state.items.cellPhone : ""}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 18 }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="normal"
                        label="Email"
                        name="email"
                        required
                        type="email"
                        variant="standard"
                        className="widthField"
                        error={!state.items.email && state.userAdded}
                        value={state.items.email}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        required
                        margin="normal"
                        error={!state.items.isProfessional && state.userAdded}
                    >
                        <InputLabel>Es Profesional</InputLabel>
                        <Select
                            label="Es Profesional"
                            name="isProfessional"
                            disabled={profileCircle}
                            value={state.items.isProfessional}
                            onChange={handleChangeSelect}
                        >
                            <MenuItem value="true">Si</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ? 
                    state.messageError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>
                            {message}
                        </Typography>
                    ))
                : null}
            </Grid> 
            {profileCircle ? 
            <Grid item xs={12} md={12}>
                <BoxStyled>
                    {file ? 
                    <Typography sx={{color: 'grey', fontWeight: '700'}}>
                        {file ? 'Archivo cargado correctamente: ' + file.name : 'Archivo cargado correctamente, nombre no disponible.'}
                    </Typography>
                    : 
                    <Typography sx={{color: 'grey', fontWeight: '700'}}>
                        Presione este area o arrastre el archivo de alta de usuario para agregarlo.
                        (El Documento debe ser PDF)
                    </Typography>
                    }
                    <input
                        type="file" 
                        name='userFile' 
                        onChange={(event) => handleChangeFileUser(event)}
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer'
                        }}
                    />
                </BoxStyled>
            </Grid>
            : null}
            {extensionError ? 
                <Typography sx={{mt: '20px', fontWeight: '600', color: 'red'}}>
                    Error, el archivo adjunto debe ser PDF.
                </Typography>
            : null}
            <Grid container justifyContent="flex-end" sx={{ mt: 6 }} spacing={1}>
                <Grid item md={2}>
                    <Button fullWidth type="button" variant="outlined">
                        <Link
                            to="/usuarios"
                            style={{
                                color: "#4e4e4e",
                                textDecoration: "none",
                                width: "100%",
                            }}
                        >
                            Volver
                        </Link>
                    </Button>
                </Grid>
                <Grid item md={2}>
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        disabled={extensionError}
                        sx={{ background: "#42C5B7", "&:hover": { background: "#389b90" } }}
                        onClick={handleSubmitAddUser}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
            <Alert
                openAlert={state.openAlert}
                handleClose={closeAlert}
                severity="success"
                time={6000}
                text={"Usuario agregado correctamente, si no encuentras el email en tu bandeja de entrada recuerda revisar la casilla de spam"}
            />
        </Box>
    );
};

export default Add;
