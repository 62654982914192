import { CirculoItems } from '../../../interfaces/Circulos';
import { DentalRecordsState, DentalRecords, DentalRecordsItems, DentalDetail, DentalRecordStates, SexType, IvaTypes, Localidades, ProviderState } from '../../../interfaces/DentalRecords';
import { InsuranceData, StatusList } from '../../../interfaces/Insurances';
import { CellPhoneConGuiones } from '../../../utils/CelPhoneFormat';
import { CuitFormat } from '../../../utils/CuitFormat';

type Action = {type: 'getDentalRecords', payload: DentalRecords[]}
    | {type: 'dentalRecordsNextPage', payload: DentalRecords[]}
    | {type: 'getDentalRecordsById', payload: DentalRecordsItems}
    | {type: 'getDentalDetail', payload: DentalDetail}
    | {type: 'getDentalRecordStates', payload: DentalRecordStates[]}
    | {type: 'getInsurances', payload: InsuranceData[]}
    | {type: 'getInsuranceStatus', payload: StatusList[]}
    | {type: 'getCirles', payload: CirculoItems[]}
    | {type: 'getSexTypes', payload: SexType[]}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'clearValues', payload: DentalRecordsItems}
    | {type: 'clearFilter'}
    | {type: 'filtered', payload: boolean}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeSelect', payload: {name: string, value: string}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'openAlert', payload: boolean}
    | {type: 'loadingInsurance', payload: boolean}
    | {type: 'getIvaTypes', payload: IvaTypes[]}
    | {type: 'getLocalidades', payload: Localidades[]} 
    | {type: 'setProviders', payload: ProviderState[]}



export default function DentalRecordsReducer (state: DentalRecordsState, action: Action): DentalRecordsState {
    switch (action.type) {
        case 'getDentalRecords':
            return { 
                ...state, 
                data: action.payload
            }
        case 'dentalRecordsNextPage':
            if(action.payload.length !== 50){
                state.noMoreResults = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.data.push(item)
            })
            return { 
                ...state
            }
        case 'getDentalRecordsById':
            state.items = action.payload
            state.items.patientDetail.phone = state.items.patientDetail.phone && state.items.patientDetail.phone !== "0" ? CellPhoneConGuiones(action.payload.patientDetail.phone) : '';
            state.providerState= []
            return { 
                ...state
            }
        case 'getDentalDetail':
            return { 
                ...state,
                dentalDetail: action.payload
            }
        case 'getDentalRecordStates':
            return { 
                ...state,
                dentalRecordStates: action.payload
            }
        case 'getInsurances':
            return {
                ...state,
                dataInsurance: action.payload,
                loadingInsurance: false
            }
        case 'getInsuranceStatus':
            return {
                ...state,
                statusList: action.payload
            }
        case 'getCirles':
            return {
                ...state,
                circleList: action.payload
            }
        case 'getSexTypes':
            return {
                ...state,
                sexTypesList: action.payload
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'clearFilter':
            return { 
                ...state,
                insuranceId: 0,
                professionalEnrollment: '',
                status: 0,
                affiliateFilter: ''
            }
        case 'filtered':
            return { 
                ...state,
                filtered: action.payload
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value

            if(action.payload.name === 'cuil'){
                state.items.cuil = CuitFormat(action.payload.value)
            }
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSelect':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'changeDatePicker':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'loadingInsurance':
            return { 
                ...state,
                loadingInsurance: action.payload
            }
        case 'getIvaTypes':
            return {
                ...state,
                ivaTypesData: action.payload
            }
        case 'getLocalidades':
            return {
                ...state,
                localidadesData: action.payload
            }

        case 'setProviders':
            return { 
                ...state, 
                providerState: action.payload
            }
        default:
            return state;
    }
}