import { EmpadronamientoState, ProfessionalItems, CircleItems, InsuranceItems, InsuranceTypeItems, MainSpecialityItems, ConsultorioItems, SpecialityItems, EnrollmentItems, EnrollmentStatusItems, EnrollmentDetailItems, ParticularRules, GeneralRules, ProfessionalsData, ProfessionalStatus, GeneralRulesDetailsInsurances} from '../../../interfaces/Empadronamiento';


type Action = {type: 'getEnrollment', payload: EnrollmentItems[]}
    |{type: 'getEnrollmentStatus', payload: EnrollmentStatusItems[]}
    |{type: 'getAllEnrollments', payload: EnrollmentItems[]}
    |{type: 'enrollmentNextPage', payload: EnrollmentItems[]}
    |{type: 'getAllEnrollmentDetail', payload: EnrollmentDetailItems}
    |{type: 'getAllEnrollmentsFilter', payload: EnrollmentItems[]}
    | {type: 'getCircles', payload: CircleItems[]}
    | {type: 'getInsurances', payload: InsuranceItems[]}
    | {type: 'getInsuranceFiltered', payload: InsuranceItems[]}
    | {type: 'getInsuranceType', payload: InsuranceTypeItems[]}
    | {type: 'handleChangeFile', payload: {valueBase64: string, value: FileList | null, name: string }}
    | {type: 'handleChangeFileSpeciality', payload: {valueBase64: string, value: FileList | null, name: string }}
    | {type: 'handleStep', payload: number}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'clearFilter'}
    | {type: 'clearEnrollmentFilter'}
    | {type: 'handleClickCoverage', payload: {checked: boolean, insuranceId: number, coverageSelected: number}}
    | {type: 'filtered', payload: boolean}
    | {type: 'enrollmentFiltered', payload: boolean}
    | {type: 'setLoadingInsurance', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'getMainSpeciality', payload: MainSpecialityItems[]}
    | {type: 'handleChangeSecureCheck', payload: number}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeSelectFilter', payload: {name: string, value: string}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeInputConsultorio', payload: {name: string, value: string}}
    | {type: 'changeYearPicker', payload: {name: string, value: string}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'userAdded', payload: boolean}
    | {type: 'setLoading', payload: boolean}
    | {type: 'handleChangeAnswerField', payload: {value: string, name: string}}
    | {type: 'handleChangeSelectCircle', payload: {value: string}}
    | {type: 'handleChangeSpeciality', payload: {value: string | boolean, name: string}}
    | {type: 'viewToShow', payload: string}
    | {type: 'openSummaryModal', payload: boolean}
    | {type: 'setLoadingEnrollmentDetail', payload: boolean}
    | {type: 'setLoadingEnrollment', payload: boolean}
    | {type: 'setLoadingAllEnrollments', payload: boolean}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number | number[]}}
    | {type: 'openAlert', payload: boolean}
    | {type: 'getGeneralRules', payload: []}
    | {type: 'getGeneralRulesById', payload: GeneralRules}
    | {type: 'getParticularRulesByProfessionalId', payload: []}
    | {type: 'messageError', payload: string[]}
    | {type: 'clearValues', payload: {generalRuleId: string, toDate: string, description: string, fromDate: string, insurances: []}}
    | {type: 'getProfessionals', payload: ProfessionalsData[]}
    | {type: 'professionalsNextPage', payload: ProfessionalsData[]}
    | {type: 'professionalStatusData', payload: ProfessionalStatus[]}
    | {type: 'professionalStatusFiltered', payload: boolean}
    | {type: 'getProfessionalStatusFilter', payload: ProfessionalStatus[]}
    | {type: 'clearProfessionalStatusFilter'}
    | {type: 'handleToggle', payload: GeneralRulesDetailsInsurances[]}
    

export default function EmpadronamientoReducer (state: EmpadronamientoState, action: Action): EmpadronamientoState {
    switch (action.type) {
        case 'getEnrollment':
            return { 
                ...state, 
                enrollmentData: action.payload,
                loadingEnrollment: false
            }
        case 'getEnrollmentStatus':
            return { 
                ...state, 
                enrollmentStatus: action.payload,
                loading: false
            }
        case 'getAllEnrollments':
            return { 
                ...state, 
                allEnrollmentsData: action.payload,
                loadingAllEnrollments: false
            }
        case 'enrollmentNextPage':
            if(action.payload.length !== 50){
                state.noMoreResultsEnrollment = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.allEnrollmentsData.push(item)
            })
            return { 
                ...state
            }
        case 'getAllEnrollmentDetail':
            return { 
                ...state, 
                allEnrollmentsDetail: action.payload,
                loadingAllEnrollments: false
            }
        case 'getAllEnrollmentsFilter':
            if(action.payload === null){
                state.allEnrollmentsData = state.allEnrollmentsData
            }
            return { 
                ...state, 
                allEnrollmentsData: action.payload,
                loadingAllEnrollments: false
            }
        case 'getCircles':
            return { 
                ...state, 
                dataCircles: action.payload,
                loading: false
            }
        case 'handleChangeSelectCircle':
            return { 
                ...state,
                circleName: action.payload.value,
            }
        case 'handleStep':
            switch (action.payload) {
                case 0:
                    state.viewToShow = 'step1'
                    break;
                case 1:
                    state.viewToShow = 'step2'
                    break;
                case 2:
                    state.viewToShow = 'step3'
                    break;
                case 3:
                    state.viewToShow = 'step4'
                    break;
                case 4:
                    state.viewToShow = 'step5'
                    break;
                default:
                    state.viewToShow = 'step1'
            }
            return { 
                ...state, 
                activeStep: action.payload
            }
        case 'getInsurances':
            return { 
                ...state, 
                insuranceData: action.payload,
                loadingInsurance: false
            }
        case 'getInsuranceFiltered':
            const dataFiltrada: any = []
            action.payload.map(item => {
                const filter = state.insuranceData.filter(insurance => insurance.insurancesId === item.insurancesId)[0]
                if(filter){
                    dataFiltrada.push(filter)
                }
            })
   
            return { 
                ...state, 
                insuranceFiltered: dataFiltrada,
                loadingInsurance: false
            }
        case 'setLoadingInsurance':
            return {
                ...state,
                loadingInsurance: action.payload
            }
        case 'getInsuranceType':
            return { 
                ...state, 
                insuranceTypeData: action.payload,
                loading: false
            }
        case 'handleChangeFile':
            state.insuranceTypeItems[action.payload.name] = action.payload.value
            state.insuranceTypeItems.collectiveFileBase64 = action.payload.valueBase64
            return {
                ...state
            }
        case 'handleChangeFileSpeciality':
            state.specialityItems[action.payload.name] = action.payload.value
            state.specialityItems.certifyFileBase64 = action.payload.valueBase64
            return {
                ...state
            }
        case 'clearFilter':
            return { 
                ...state, 
                nameFilter: ''
            }
        case 'clearEnrollmentFilter':
            return { 
                ...state, 
                enrollmentNameFilter: '',
                enrollmentLastNameFilter: '',
                enrollmentRegistrationFilter: '',
                enrollmentStatusFilter: '',
                enrollmentCuilFilter: ''
                
            }
        case 'handleClickCoverage':
            state.insuranceData.forEach(insurance => {
                if(insurance.insurancesId === action.payload.insuranceId){
                    const coverageSelected = insurance.generalCoverages.filter(item => item.generalCoveragesId === action.payload.coverageSelected)[0]
                    // si se selecciona el check seteo el atributo checked = true y si se desmarca elimino el atributo checked
                    action.payload.checked ? coverageSelected.checked = action.payload.checked : delete coverageSelected.checked 
                }
            })

            return { 
                ...state,
                InsurancesSelectedItems: state.InsurancesSelectedItems,
                insurancesSelected: state.insurancesSelected
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload,
                insuranceFiltered: !action.payload ? [] : state.insuranceFiltered

            }
        case 'enrollmentFiltered':
            return { 
                ...state, 
                enrollmentFiltered: action.payload,
                allEnrollmentsData: state.allEnrollmentsData
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'getMainSpeciality':
            return { 
                ...state, 
                mainSpecialityData: action.payload,
                loading: false
            }
        case 'handleChangeSpeciality':
            state.specialityItems[action.payload.name] = action.payload.value

            return { 
                ...state
            }
        case 'handleChangeSecureCheck':
            return { 
                ...state,
                secureId: action.payload
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload
            }
        case 'changeUbicacion':
            state.consultorioItems[action.payload.name] = action.payload.value
            return { 
                ...state
            }
        case 'handleChangeSelectFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'handleChangeInput':
            state.professionalItems[action.payload.name] = action.payload.value
            if(action.payload.name === "description"){
                state.generalRuleName = action.payload.value
                state.generalRulesDetails.description = action.payload.value
            }
            return {
                ...state
            }
        case 'handleChangeInputConsultorio':
            state.consultorioItems[action.payload.name] = action.payload.value
            return {
                ...state
            }
        case 'changeDatePicker':
            state.professionalItems[action.payload.name] = action.payload.value
            state.particularRuletoDateSelect = action.payload.value
            if(action.payload.name === "fromDate"){
                state.generalRuleFromDate = action.payload.value
                state.generalRulesDetails.from = action.payload.value
            }
            if(action.payload.name === "toDate"){
                state.generalRuleToDate = action.payload.value
                state.generalRulesDetails.to = action.payload.value
            }
            if(action.payload.name === "to"){
                state.generalRuleToDate = action.payload.value
            }
            return {
                ...state
            }
        case 'changeYearPicker':
            state.specialityItems[action.payload.name] = action.payload.value
            return {
                ...state
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'viewToShow':
            return { 
                ...state,
                viewToShow: action.payload
            }
        case 'openSummaryModal':
            return {
                ...state,
                openSummaryModal: action.payload
            }
        case 'setLoadingEnrollmentDetail':
            return {
                ...state,
                loadingEnrollmentDetail: action.payload
            }
        case 'setLoadingEnrollment':
            return {
                ...state,
                loadingEnrollment: action.payload
            }
        case 'setLoadingAllEnrollments':
            return {
                ...state,
                loadingAllEnrollments: action.payload
            }
        case 'handleChangeSelect':
            if (action.payload.name === "generalRuleId") {
                const generalRuleDateSelect = state.generalRules.find(item => item.generalRulesId === action.payload.value)?.to
                const generalRuleDescriptionSelected = state.generalRules.find(item => item.generalRulesId === action.payload.value)?.description
                const generalRuleIdSelected = state.generalRules.find(item => item.generalRulesId === action.payload.value)?.generalRulesId
                state.generalRuleDateSelected = typeof generalRuleDateSelect === "string" ? generalRuleDateSelect : ''
                state.generalRuleDescriptionSelected = typeof generalRuleDescriptionSelected === "string" ? generalRuleDescriptionSelected : ''
                state.generalRuleIdSelected = typeof generalRuleIdSelected === "number" ? generalRuleIdSelected : 0
                state.particularRuletoDateSelect = state.generalRuleDateSelected
            }
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'openAlert':
            return { 
                ...state,
                openAlertErrorDate: action.payload,
            }
        case 'getGeneralRules':
            const generalRuleDescription = state.generalRules.find(item => item.description)
            state.generalRuleName = typeof generalRuleDescription === "string" ? generalRuleDescription : ''
            return {
                ...state,
                generalRules: action.payload,
                loading: false
            }
        case 'getParticularRulesByProfessionalId':
            return {
                ...state,
                particularRules: action.payload
            }
        case 'getGeneralRulesById':
            state.generalRulesDetails = action.payload
            state.generalRulesInsurancesInitial = state.generalRulesDetails.insurances.map((item) => item.insurancesId)
            state.generalRuleInsurancesDetail = state.generalRulesDetails.insurances.map((item) => {return {insurancesId: item.insurancesId, status: true}})
            return {
                ...state,
                generalRulesDetails: action.payload
            }
        case 'messageError':
            return { 
                ...state, 
                messageError: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                generalRuleDescriptionSelected: action.payload.generalRuleId,
                generalRuleDateSelected: action.payload.toDate,
                particularRuletoDateSelect: action.payload.toDate,
                generalRuleName: action.payload.description,
                generalRuleFromDate: action.payload.fromDate,
                generalRuleToDate: action.payload.toDate,
                insurancesChecked: action.payload.insurances
            }
        case 'getProfessionals':
            return {
                ...state,
                professionalsData: action.payload,
                loading: false
            }
        case 'professionalsNextPage':
            if(action.payload.length !== 50){
                state.noMoreResults = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.professionalsData.push(item)
            })
            return { 
                ...state
            }
        case 'professionalStatusData':
            return { 
                ...state, 
                professionalStatusData: action.payload,
                loading: false
            }
        case 'professionalStatusFiltered':
            return { 
                ...state, 
                professionalStatusFiltered: action.payload
            }
        case 'clearProfessionalStatusFilter':
            return { 
                ...state,
                professionalStateId: 0,
                lastnameProfessionalFilter: '',
                licenseProfessionalFilter: '',
                cuilProffesionalFilter: ''  
            }
        case 'getProfessionalStatusFilter':
            return { 
                ...state, 
                professionalStatusData: action.payload,
                loading: false
            }
        case 'handleToggle':
            state.generalRuleInsurancesDetail = action.payload
            return { 
                ...state,
                buttonDisabled: false
            }
        default:
            return state;
    }
}