import Button from '../Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useContext, useEffect } from "react"
import AffiliateContext from "../context/Affiliate/AffiliateContext"
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import 'moment/locale/es';
import Loader from '../Loader';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const TypographyStyled = styled(Typography)(() => ({
    color: '#64AAB2',
    fontWeight: 'bolder',
    marginRight: 8
}));

const Detail = () => {

    const { 
        getAffiliateById,
        getInsurancePlanById,
        state,
        getTypeOfLoad,
        getSexTypes,
        getIvaTypes
    } = useContext(AffiliateContext)

    const {idInsurance, idAffiliate} = useParams();

    useEffect(() => {
        getSexTypes()
        getTypeOfLoad()
        getIvaTypes()
        getInsurancePlanById(Number(idInsurance))
        getAffiliateById(Number(idInsurance), Number(idAffiliate))
    }, [])

    
    const typeOfLoadDescription = state.typeOfLoadList.map((u) => {
        if (state.items.uploadType === u.uploadTypeId) {
            return u.description
        }
    })

    const sexDescription = state.sexTypeList.map((s) => {
        if (state.items.sexTypeId === s.sexTypeId) {
            return s.description
        }
    })

    const ivaDescription = state.ivaTypeList.map((i) => {
        if (state.items.ivaTypeId === i.ivaTypeId) {
            return i.description
        }
    })

    if(!state.items){
        return <Loader />
    }

    const data = state.insurancesPlan.filter(data => data.plansId === Number(state.items.planId))
    const planDescription = data.length ? data[0].description : Number(state.items.planId)

    return (
        <Box component="form" sx={{ margin: '20px 15px 30px 15px' }}>

            {/* DATOS PERSONALES */}
            <h4>Datos Personales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3}>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Nombre:</TypographyStyled>{state.items.name}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Apellido:</TypographyStyled>{state.items.lastname}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Sexo:</TypographyStyled>{sexDescription}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Tipo de Documento:</TypographyStyled>{state.items.documentType.name}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Número de Documento:</TypographyStyled>{state.items.documentValue}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Fecha de Nacimiento:</TypographyStyled>{state.items.bornDate}
                </Grid>
                {state.items.planId ?
                    <Grid item container xs={6} sm={6} md={4}>
                        <TypographyStyled>Plan:</TypographyStyled>{planDescription}
                    </Grid>
                : null}
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Fecha de Alta:</TypographyStyled>{state.items.registerDate}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Condición IVA:</TypographyStyled>{ivaDescription}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Tipo de Carga:</TypographyStyled>{typeOfLoadDescription}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Provincia:</TypographyStyled>{state.items.state}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Partido:</TypographyStyled>{state.items.department}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Localidad:</TypographyStyled>{state.items.town}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Calle:</TypographyStyled>{state.items.street}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Número:</TypographyStyled>{state.items.streetNumber}
                </Grid>
                {state.items.floor ? 
                    <Grid item container xs={6} sm={6} md={4}>
                        <TypographyStyled>Piso:</TypographyStyled>{state.items.floor}
                    </Grid>
                : null}
                {state.items.apartment ?
                    <Grid item container xs={6} sm={6} md={4}>
                        <TypographyStyled>Depto:</TypographyStyled>{state.items.apartment}
                    </Grid>
                : null}
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Código Postal:</TypographyStyled>{state.items.postalCode}
                </Grid>
            </Grid>

            {/* DATOS DE CONTACTO */}

            <h4 style={{marginTop: 40}}>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3}>
                {state.items.phone ?
                    <Grid item container xs={6} sm={6} md={4}>
                        <TypographyStyled>Teléfono:</TypographyStyled>{state.items.phone}
                    </Grid>
                : null}
                {state.items.cellPhone ?
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Celular:</TypographyStyled>{state.items.cellPhone}
                </Grid>
                : null}
                {state.items.email ?
                    <Grid item container xs={6} sm={6} md={4}>
                        <TypographyStyled>Correo Electrónico:</TypographyStyled>{state.items.email}
                    </Grid>
                : null}
            </Grid>

            <Grid container justifyContent='end' sx={{mt: 2}} spacing={2}>
                <Grid item md={2}>
                    <Button 
                        child={
                            <Link to='/afiliados' style={{textDecoration: 'none', color: '#4e4e4e'}}>Volver</Link>
                        }
                        color='primary'
                        variant='outlined'
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Detail