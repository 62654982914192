import { createContext } from 'react'
import { DentalRecordsState, ProviderState } from '../../../interfaces/DentalRecords';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    dentalRecordState: DentalRecordsState,
    getDentalRecords: () => void
    dentalRecordsNextPage: () => void
    getDentalRecordsFilter: () => void
    getDentalRecordsById: (id: number) => void
    getDentalDetail: () => void
    getDentalRecordStates: () => void
    modifyDentalRecordStatus: (dentalRecordId: number, status: number, comment?: string) => void
    getInsurances: () => void
    getInsuranceStatus: () => void
    getCirles: () => void
    getSexTypes: () => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeSelect: (event:SelectChangeEvent<string>) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    clearFilter: () => void
    closeAlert: () => void
    getIvaTypes: () => void
    getLocalidades: () => void
    setProviders: (value: ProviderState[]) => void
}

const DentalRecordsContext = createContext<ContextProps>({} as ContextProps);

export default DentalRecordsContext;