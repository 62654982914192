const ListPermissions = () => {
    
    const permissionsStorage = localStorage.getItem('permissions')
    const permissions: [{description: string, policiesId: number, status: boolean}] = JSON.parse(permissionsStorage ? permissionsStorage : '');

    
    const listarUsuario = permissions.find(p => p.description === 'backoffice-listar-usuarios')
    const crearUsuario = permissions.find(p => p.description === 'backoffice-crear-usuarios')
    const editarUsuario = permissions.find(p => p.description === 'backoffice-editar-usuario')
    const eliminarUsuario = permissions.find(p => p.description === 'backoffice-eliminar-usuario')
    const listarPerfiles = permissions.find(p => p.description === 'listar-perfiles')
    const listarCirculos = permissions.find(p => p.description === 'listar-circulos')
    const editarCirculo = permissions.find(p => p.description === 'editar-circulo')
    const crearCirculo = permissions.find(p => p.description === 'crear-circulo')
    const eliminarCirculo = permissions.find(p => p.description === 'eliminar-circulo')
    const listarObrasSociales = permissions.find(p => p.description === 'Listar-obras-sociales')
    const crearObrasSociales = permissions.find(p => p.description === 'Crear-obras-sociales')
    const editarObrasSociales = permissions.find(p => p.description === 'Modificar-obras-sociales')
    const eliminarObrasSociales = permissions.find(p => p.description === 'Eliminar-obras-sociales')
    const manejoRoles = permissions.find(p => p.description === 'Manejo-roles')
    const manejoPermisos = permissions.find(p => p.description === 'Manejo-permisos')
    const contactos = permissions.find(p => p.description === 'Contactos')
    const sitioDeUsuarios = permissions.find(p => p.description === 'Sitio-de-usuarios')
    const backoffice = permissions.find(p => p.description === 'Backoffice')
    const listarEmpadronamientos = permissions.find(p => p.description === 'manejo-de-empadronamientos')
    const listarFichas = permissions.find(p => p.description === 'listar-fichas')
    const modificarFichas = permissions.find(p => p.description === 'modificar-fichas')
    
    return (
        {
            listarUsuario,
            crearUsuario,
            editarUsuario,
            eliminarUsuario,
            listarPerfiles,
            listarCirculos,
            editarCirculo,
            crearCirculo,
            eliminarCirculo,
            listarObrasSociales,
            crearObrasSociales,
            editarObrasSociales,
            eliminarObrasSociales,
            manejoRoles,
            manejoPermisos,
            contactos,
            sitioDeUsuarios,
            backoffice,
            listarEmpadronamientos,
            listarFichas,
            modificarFichas
        }
    )
}

export default ListPermissions