import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField, Typography, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useState } from 'react';

interface Props {
    openModal: boolean,
    id: number,
    status: number,
    handleCloseModal: () => void
    modifyEnrollmentStatus: (registrationsId: number, status: number, comment?: string) => void
}

export default function ModalRechazo({ openModal, id, status, modifyEnrollmentStatus }:Props) {

    const [comment, setComment] = useState('')
    

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value)
    }

    const closeModal = () => {
        modifyEnrollmentStatus(id, status, comment)
    }

    return (
        <Dialog
            open={openModal}
            onClose={closeModal}
        >
            <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} mt={6}>
                <ThumbDownIcon color='error' sx={{fontSize: '70px'}}/>
                <Typography variant='h5'>Empadronamiento rechazado</Typography>
                <DialogContent>
                <DialogContentText>
                    Te pido que nos dejes un comentario explicando el motivo del rechazo.
                </DialogContentText>
            </DialogContent>
            </Box>
            <DialogActions sx={{flexDirection: 'column'}}>
                <TextField 
                    label="Comentario"
                    name='comment'
                    variant="outlined"
                    value={comment}
                    multiline
                    sx={{width: '80%'}}
                    rows={3}
                    onChange={(e) => handleChange(e)}
                />
                <Grid container spacing={1} justifyContent='center'>
                <Button 
                    onClick={closeModal} 
                    variant='contained' 
                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={() => modifyEnrollmentStatus(id, status ? 7 : 7, comment)} 
                    variant='contained' 
                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                >
                    Rechazar
                </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}