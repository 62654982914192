import { useReducer } from "react";
import { UserState, HTMLInputEvent } from "../../../interfaces/Users";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import { SelectChangeEvent } from "@mui/material";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { CuitSinGuiones } from "../../../utils/CuitFormat";
import { tokenDecode } from "../../../interfaces/Login";
import { CellPhoneSinGuiones } from "../../../utils/CelPhoneFormat";

const INITIAL_STATE: UserState = {
  data: [],
  cargando: false,
  openConfirm: false,
  search: "",
  openAddModal: false,
  id: 0,
  edit: false,
  provincias: [],
  departamentos: [],
  localidades: [],
  calles: [],
  lastname: "",
  valueProfileFilter: 0,
  valueProvincialEnrollmentFilter: '',
  valueUserStateFilter: 0,
  valueCuitFilter: '',
  valueEmailFilter: '',
  userAdded: false,
  userEdited: false,
  passwordsDontMatch: false,
  loading: false,
  profiles: [],
  circles: [],
  documentType: [],
  roles: [],
  openAlert: false,
  buttonDisabled: true,
  usersFile: null,
  multipleUsers: { usersCreated: [], usersError: [] },
  multipleUsersLoading: false,
  documentTypeId: 0,
  filtered: false,
  professionalStatusFiltered: false,
  messageError: [],
  access: [],
  loadingDatosDemograficos: false,
  items: {
    usersId: 0,
    name: "",
    lastname: "",
    bornDate: "",
    isProfessional: "",
    profileId: 0,
    rolId: 0,
    email: "",
    password: "",
    repeatPassword: "",
    documentValue: "",
    cuil: "",
    phone: "",
    cellPhone: "",
    street: "",
    streetNumber: "",
    floor: "",
    betweenStreet: "",
    observations: "",
    apartment: "",
    postalCode: "",
    town: 0,
    department: 0,
    state: 0,
    country: "",
    circles: [],
    circlesId: [],
    circlesChecked: [],
    circlesInitial: [],
    block: false,
    ivaTypesId: 0,
    userState: {
      userStatesId: 0,
      description: ''
    },
    userFile: [],
    userFileBase64: ''
  },
  professionalsData: [],
  professionalStatusData: [],
  professionalStateId: 0,
  licenseProfessionalFilter: '',
  lastnameProfessionalFilter: '',
  cuilProfessionalFilter: '',
  ivaTypeList: [],
  userStates: [],
  sessionUserId: '',
  sessionRolId: '',
  sessionProfileId: '',
  usersOffset: 0,
  professionalsOffset: 0,
  noMoreResults: false,
  noMoreProfessionalResults: false,
  professionalDetail: {
    nationalEnrollment: '',
    professionalsId: '',
    professionalStatus: [],
    provincialEnrollment: '',
    fopbaRegistration: '',
    startActivity: '',
    professionalName: '',
    professionalLastname: '',
    cuil: '',
    circles: [{
      circlesId: 0,
      name: '',
      idFopbaInsider: ''
    }],
  },
  originalEmail: ''
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

const UserProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE);

  const getUsers = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    dispatch({ type: "setLoading", payload: true });

    await axios
      .get(`${process.env.REACT_APP_BACKOFFICE_API}users?offset=0&limit=50`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: "getUsers", payload: response.data });
        dispatch({ type: "setLoading", payload: false });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };

  const usersNextPage = async () => {

    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    const offsetValue = state.usersOffset + 50
    state.usersOffset = offsetValue

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}users?offset=${offsetValue}&limit=50`, {headers})
    .then(response => {
      dispatch({type: 'usersNextPage', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
    })
  }

  const getUserById = async (id: number) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BACKOFFICE_API}users/${id}`,
      { headers }
    );
    dispatch({ type: "getUserById", payload: response.data });
  };

  const getProfiles = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BACKOFFICE_API}profiles/`,
      { headers }
    );
    dispatch({ type: "getProfiles", payload: response.data });
  };

  const getCirculos = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .get(`${process.env.REACT_APP_BACKOFFICE_API}circles?offset=0&limit=200`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: "getCirculos", payload: response.data });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const getDocumentTypes = async (edit: boolean) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BACKOFFICE_API}documentTypes/`,
      { headers }
    );
    dispatch({
      type: "getDocumentTypes",
      payload: { data: response.data, edit: edit },
    });
  };

  const getRoles = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      offset: 0,
      limit: 20,
    };

    await axios
      .get(`${process.env.REACT_APP_BACKOFFICE_API}roles`, { headers })
      .then((response) => {
        dispatch({ type: "getRoles", payload: response.data });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const editUser = async (id: string | undefined) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const idFormat = Number(id);

    const arrayObject = state.items.circlesInitial.map((item, index) => {
      // si tiene mas de un elemento es por que se paso de perfil general a circulo asi que por lo tanto 
      // el ultimo elemento es el que se selecciono para el circulo asi que mando ese elem en true y los demas en false
      const lastElementSelected = index === state.items.circlesInitial.length - 1
      return {
        circlesId: item,
        status: lastElementSelected ? true : false
      }
    })

    //elimino del array los circlesId que no se modificaron
    const circlesChecked = state.items.circlesChecked.filter(item => !(state.items.circlesInitial.includes(item.circlesId) && item.status));
    circlesChecked.forEach(item => delete item.name)

    const data = {
      name: state.items.name,
      lastname: state.items.lastname,
      bornDate: state.items.bornDate,
      isProfessional: state.items.isProfessional === "true" ? true : false,
      profileId: state.items.profileId,
      rolId: Math.floor(state.items.rolId),
      documentTypeId: state.documentTypeId,
      documentValue: state.items.documentValue,
      cuil: CuitSinGuiones(state.items.cuil),
      phone: CellPhoneSinGuiones(state.items.phone),
      cellPhone: CellPhoneSinGuiones(state.items.cellPhone),
      email: state.items.email,
      street : (state.items.profileId !== 2 && !state.items.street) ? '-' : state.items.street.toLowerCase(),
      streetNumber : (state.items.profileId !== 2 && !state.items.streetNumber) ? 'S/N' : state.items.streetNumber,
      floor : (state.items.profileId !== 2 && !state.items.floor) ? '-' : state.items.floor,
      apartment : (state.items.profileId !== 2 && !state.items.apartment) ? '-' : state.items.apartment,
      postalCode : (state.items.profileId !== 2 && !state.items.postalCode) ? '1234' : state.items.postalCode,
      town : (state.items.profileId !== 2 && !state.items.town) ? 799 : state.items.town,
      department : (state.items.profileId !== 2 && !state.items.department) ? 2 : state.items.department,
      state : (state.items.profileId !== 2 && !state.items.state) ? 1 : state.items.state,
      country: "Argentina",
      ivaTypesId: (state.items.profileId !== 2 && !state.items.ivaTypesId) ? 2 : Number(state.items.ivaTypesId),
      // profile 4 = circulo
      circles: state.items.profileId === 4 ? arrayObject : circlesChecked,
      betweenStreet: state.items.betweenStreet ? state.items.betweenStreet : '',
      observations: state.items.observations ? state.items.observations : ''
    };

    const dataWithPassword = { ...data, password: state.items.password };
    const dataWithIsBlocked = { ...data, block: state.items.block };
    const dataWithPasswordAndIsBlocked = {
      ...data,
      password: state.items.password,
      block: state.items.block,
    };

    let dataPut = data;
    if (state.items.password) {
      dataPut = dataWithPassword;
    } else if (!state.items.block) {
      dataPut = dataWithIsBlocked;
    } else if (state.items.password && !state.items.block) {
      dataPut = dataWithPasswordAndIsBlocked;
    }

    // solo envio el email si se modifico
    if(state.originalEmail === state.items.email){
      delete dataPut.email
    }

    dispatch({ type: "setLoading", payload: true });

    // verifico que las contraseñas sean iguales, sino muestro mensaje de error
    if (state.items.password === state.items.repeatPassword) {
      dispatch({ type: "passwordsDontMatch", payload: false });
      // verifico que todos los campos obligatorios esten seteados
      if (
        state.items.name &&
        state.items.lastname &&
        state.items.bornDate &&
        state.items.isProfessional !== "" &&
        state.items.profileId &&
        state.items.rolId &&
        state.documentTypeId &&
        state.items.documentValue &&
        state.items.cuil &&
        state.items.phone &&
        (state.items.profileId === 2 ? state.items.street : true) &&
        (state.items.profileId === 2 ? state.items.streetNumber : true) &&
        (state.items.profileId === 2 ? state.items.postalCode : true) &&
        (state.items.profileId === 2 ? state.items.town : true) &&
        (state.items.profileId === 2 ? state.items.state : true) &&
        (state.items.profileId === 2 ? state.items.department : true) &&
        (state.items.profileId === 2 ? state.items.ivaTypesId : true) &&
        (state.items.profileId === 4 ? (state.items.circlesId.length && state.items.circlesId.length === 1)  : true) &&
        (state.items.profileId === 2 ? (circlesChecked.length || state.items.circlesId.length) : true)
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_BACKOFFICE_API}users/${idFormat}`,
            dataPut,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch({ type: "setLoading", payload: false });
              dispatch({ type: "messageError", payload: [] });
              openAlert();
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            if (error.response.status === 500) {
              dispatch({type: "messageError", payload: ["Error interno del servidor al modificar usuario"]});
              dispatch({type: 'setLoading', payload: false});
            }
            dispatch({type: "messageError",payload: error.response.data?.errors});
            dispatch({ type: "setLoading", payload: false });
          });
        dispatch({ type: "userAdded", payload: false });
      } else {
        dispatch({ type: "userEdited", payload: true });
        dispatch({ type: "setLoading", payload: false });
      }
    } else {
      dispatch({ type: "passwordsDontMatch", payload: true });
      dispatch({ type: "setLoading", payload: false });
    }
  };

  const deleteUser = async (id: string | undefined) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const idFormat = Number(id);

    const response = await axios.delete(
      `${process.env.REACT_APP_BACKOFFICE_API}users/${idFormat}`,
      { headers }
    );
    if (response.status === 200) {
      window.location.href = "/usuarios";
    }
    dispatch({ type: "openConfirm", payload: false });
  };

  const getFilterUsers = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    dispatch({ type: "setLoading", payload: true });

    const filters = `&lastname=${state.lastname}&profileId=${state.valueProfileFilter}&provincialEnrollment=${state.valueProvincialEnrollmentFilter}&status=${state.valueUserStateFilter}&cuit=${state.valueCuitFilter}&email=${state.valueEmailFilter}`

    await axios
      .get(
        `${process.env.REACT_APP_BACKOFFICE_API}users?offset=0&limit=100000${filters}`,
        { headers }
      )
      .then((response) => {
        dispatch({ type: "getFilterUsers", payload: response.data });
        dispatch({ type: "filtered", payload: true });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };

  const clearFilter = () => {
    dispatch({ type: "clearFilter" });
    // si se filtro llamo al get de usuarios para traer todos los resultado nuevamente
    if (state.filtered) {
      getUsers();
      dispatch({ type: "filtered", payload: false });
    }
  };

  const setValuesDefaultForAdminCircle = () => {
    dispatch({ type: "setValuesDefaultForAdminCircle" });
  };

  const setValuesDefaultForProfileDistinctToGeneral = () => {
    dispatch({ type: "setValuesDefaultForProfileDistinctToGeneral" });
  };

  const clearValuesDefaultForProfileDistinctToGeneral = () => {
    dispatch({ type: "clearValuesDefaultForProfileDistinctToGeneral" });
  };

  const handleChangeFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: "handleChangeFilter",
      payload: { value: event.target.value, name: event.target.name },
    });
  };

  const getAccess = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    await axios
      .get(`${process.env.REACT_APP_BACKOFFICE_API}access`, { headers })
      .then((response) => {
        dispatch({ type: "getAccess", payload: response.data });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const addUsers = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const circlesChecked = state.items.circlesChecked.filter(item => item.status === true);
    const multiplesCirclesChecked = circlesChecked.map(item => {
      delete item.name;
      return item.circlesId
    })

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    const profileCircle = decoded.profileId === 4

    let circleValue = state.items.circlesId
    if(!profileCircle && state.items.profileId === 2){
      circleValue = multiplesCirclesChecked
    }

    const data = {
      name: state.items.name,
      lastname: state.items.lastname,
      bornDate: state.items.bornDate,
      isProfessional: state.items.isProfessional === "true" ? true : false,
      profileId: state.items.profileId,
      rolId: Math.floor(state.items.rolId),
      email: state.items.email,
      password: state.items.password,
      documentTypeId: state.documentTypeId,
      documentValue: state.items.documentValue,
      cuil: CuitSinGuiones(state.items.cuil),
      phone: CellPhoneSinGuiones(state.items.phone),
      cellPhone: CellPhoneSinGuiones(state.items.cellPhone),
      street: state.items.street
        ? state.items.street.toLowerCase()
        : state.items.street,
      streetNumber: state.items.streetNumber,
      floor: state.items.floor,
      apartment: state.items.apartment,
      postalCode: state.items.postalCode,
      town: state.items.town,
      department: state.items.department,
      state: state.items.state,
      country: "Argentina",
      accessId: 3,
      circlesId: circleValue,
      ivaTypesId: Number(state.items.ivaTypesId),
      betweenStreet: state.items.betweenStreet,
      observations: state.items.observations,
      ...(state.items.userFileBase64 && {userFile: state.items.userFileBase64})
    };
    
    dispatch({ type: "setLoading", payload: true });

    if (
      state.items.name &&
      state.items.lastname &&
      state.items.bornDate &&
      state.items.isProfessional &&
      state.items.profileId &&
      state.items.rolId &&
      state.items.email &&
      state.items.password &&
      state.documentTypeId &&
      state.items.documentValue &&
      state.items.cuil &&
      state.items.phone &&
      state.items.street &&
      state.items.streetNumber &&
      state.items.postalCode &&
      state.items.town &&
      state.items.state &&
      state.items.department &&
      state.items.ivaTypesId &&
      (state.items.profileId === 4 ? state.items.circlesId : true) &&
      (state.items.profileId === 2 ? state.items.circlesId : true)
    ) {
      await axios
        .post(`${process.env.REACT_APP_BACKOFFICE_API}users`, data, { headers })
        .then((response) => {
          console.log(response.data)
          if (response.status === 201) {
            openAlert();
            dispatch({ type: "setLoading", payload: false });
            dispatch({ type: "messageError", payload: [] });
            clearValues();
            
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            window.location.href = "/";
          }
          if (error.response.status === 500) {
            dispatch({type: "messageError", payload: ["Error interno del servidor al agregar usuario"]});
            dispatch({type: 'setLoading', payload: false});
          }
          dispatch({type: "messageError", payload: error.response.data?.errors});
          dispatch({ type: "setLoading", payload: false });
        });
      dispatch({ type: "userAdded", payload: false });
    } else {
      dispatch({ type: "userAdded", payload: true });
      dispatch({ type: "setLoading", payload: false });
    }
  };

  const addMultipleUsers = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    };

    const file = state.usersFile ? state.usersFile[0] : "";

    const formDataUser = new FormData();
    formDataUser.append("register", file);

    dispatch({ type: "multipleUsersLoading", payload: true });

    await axios
      .post(`${process.env.REACT_APP_BACKOFFICE_API}users/bulk`, formDataUser, {
        headers,
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch({ type: "multipleUsers", payload: response.data });
          dispatch({ type: "multipleUsersLoading", payload: false });
        }
      })
      .catch((error) => {
        dispatch({ type: "multipleUsersLoading", payload: false });
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const getProvincias = async () => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}states`, {headers})
    .then(response => {
      dispatch({type: 'getProvincias', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
    })
  }

  const getDepartamentos = async () => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}departments?stateId=${state.items.state}&offset=0&limit=200`, {headers})
    .then(response => {
      dispatch({type: 'getDepartamentos', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
    })
  }

  const getLocalidades = async () => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    dispatch({ type: "loadingDatosDemograficos", payload: true });

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}towns?stateId=${state.items.state}&departmentId=${state.items.department}&offset=0&limit=200`, {headers})
    .then(response => {
      dispatch({type: 'getLocalidades', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
      dispatch({ type: "loadingDatosDemograficos", payload: false });
    })
  }

  const getCalles = async () => {
    const urlCalles = `https://apis.datos.gob.ar/georef/api/calles?departamento=${state.items.department}&localidad_censal=${state.items.town}&max=1000`;
    const res = await axios.get(urlCalles);
    dispatch({ type: "getCalles", payload: res.data.calles });
  };

  const getProfessionals = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    dispatch({ type: "setLoading", payload: true });

    await axios
      .get(
        `${process.env.REACT_APP_BACKOFFICE_API}professionals?offset=0&limit=50`,
        { headers }
      )
      .then((response) => {
        dispatch({ type: "getProfessionals", payload: response.data });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };

  const profesionalsNextPage = async () => {

    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    const offsetValue = state.professionalsOffset + 50
    state.professionalsOffset = offsetValue

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}professionals?offset=${offsetValue}&limit=50`, {headers})
    .then(response => {
      dispatch({type: 'profesionalsNextPage', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
    })
  }

  const getProfessionalsById = async (id: number) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    dispatch({ type: "setLoading", payload: true });

    await axios
      .get(
        `${process.env.REACT_APP_BACKOFFICE_API}professionals/${id}/status`,
        { headers }
      )
      .then((response) => {
        dispatch({ type: "getProfessionalsById", payload: response.data });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };

  const modifyProfessionalStatus = async (professionalsId: number, circleGroupingCode: number, insuranceGroupingCode: number,  startDate: string, endDate: string, status: number, observation: string) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const data = {
      "startDate": startDate,
      "endDate": endDate,
      "circleGroupingCode": `${circleGroupingCode}`,
      "insuranceGroupingCode": `${insuranceGroupingCode}`,
      "observation": `${observation}`
    }

    dispatch({ type: "setLoading", payload: true });

    await axios
      .put(
        `${process.env.REACT_APP_BACKOFFICE_API}professionals/${professionalsId}/status/${status}`,
        data,
        { headers }
      )
      .then((response) => {
        window.location.reload()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };

  const getProfessionalStatus = async () => {

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    dispatch({ type: "setLoading", payload: true });

    await axios
      .get(
        `${process.env.REACT_APP_BACKOFFICE_API}professionalsStatus`,
        { headers }
      )
      .then((response) => {
        dispatch({ type: "professionalStatusData", payload: response.data });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };
  
  const getProfessionalsFilter = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    dispatch({ type: "setLoading", payload: true });

    let params = []
    if(state.professionalStateId){
      params.push(`&status=${state.professionalStateId}`)
    }
    if(state.lastnameProfessionalFilter){
      params.push(`&lastname=${state.lastnameProfessionalFilter}`)
    }
    if(state.licenseProfessionalFilter){
      params.push(`&license=${state.licenseProfessionalFilter}`)
    }
    if(state.cuilProfessionalFilter){
      params.push(`&cuil=${state.cuilProfessionalFilter}`)
    }

    let paramsFormat = params[0]
    if(params.length === 2){
      paramsFormat = params[0] + params[1]
    } else if(params.length === 3){
      paramsFormat = params[0] + params[1] + params[2]
    }

    await axios
      .get(
        `${process.env.REACT_APP_BACKOFFICE_API}professionals?offset=0&limit=100000${paramsFormat}`,
        { headers }
      )
      .then((response) => {
        dispatch({ type: "getProfessionals", payload: response.data });
        dispatch({ type: "professionalStatusFiltered", payload: true });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        dispatch({ type: "setLoading", payload: false });
      });
  };

  const getIvaTypes = async () => {

    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}ivaType/`, {headers})
    .then(response => {
      dispatch({type: 'getIvaTypes', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
    })
  }

  const getUserStates = async () => {

    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}userStates`, {headers})
    .then(response => {
      dispatch({type: 'getUserStates', payload: response.data})
    })
    .catch(error => {
      if(error.response.status === 401){
        window.location.href = '/'
      }
    })
  }

  const clearProfessionalStatusFilter = () => {
    dispatch({ type: "clearProfessionalStatusFilter" });
    if (state.professionalStatusFiltered) {
      getProfessionals()
      dispatch({ type: "professionalStatusFiltered", payload: false });
    }
  };

  const clearValues = () => {
    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    const profileCircle = decoded.profileId === 4

    dispatch({
      type: "clearValues",
      payload: {
        usersId: 0,
        name: "",
        lastname: "",
        bornDate: "",
        isProfessional: profileCircle ? "true" : "",
        profileId: profileCircle ? 2 : 0,
        rolId: profileCircle ? 3 : 0,
        email: "",
        password: "",
        repeatPassword: "",
        documentValue: "",
        cuil: "",
        phone: "",
        cellPhone: "",
        street: "",
        streetNumber: "",
        floor: "",
        betweenStreet: "",
        observations: "",
        apartment: "",
        postalCode: "",
        town: 0,
        department: 0,
        state: 0,
        country: "",
        circles: [],
        circlesId: profileCircle ? state.items.circlesId : [],
        circlesChecked: [],
        circlesInitial: [],
        block: false,
        ivaTypesId: 0,
        userState: {
          userStatesId: 0,
          description: ''
        },
        userFile: [],
        userFileBase64: ''
      },
    });
  };

  const handleChangeUbicacion = (value: number, name: string) => {
    dispatch({
      type: "changeUbicacion",
      payload: { value: value, name: name },
    });
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: "handleChangeInput",
      payload: { value: event.target.value, name: event.target.name },
    });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "handleChangeCheckbox",
      payload: { value: event.target.checked, name: event.target.name },
    });
  };

  const handleChangeSelect = (event: SelectChangeEvent<string | number[]> ) => {
    let {value} = event.target;
    if (Number.isInteger(value) && event.target.name === 'circlesId') value = [Number(value)]
    dispatch({
      type: "handleChangeSelect",
      payload: { value, name: event.target.name },
    });
  };

  const handleChangeSelectCirculos = (value: string | number[], name: string) => {
    if (Number.isInteger(value) && name === 'circlesId') value = [Number(value)]
    dispatch({
      type: "handleChangeSelectCirculos",
      payload: { value, name: name },
    });
  };

  const getGeneralCoveragesIndex = (circleId: number) => {
    let index = state.items.circlesChecked.findIndex(i => i.circlesId === circleId);
    return index;
}

  const handleToggle = (circleId: number, status: boolean, name?: string) => {
    const currentIndex = getGeneralCoveragesIndex(circleId);
    const newChecked = [...state.items.circlesChecked];

    if(currentIndex !== -1){
      newChecked[currentIndex] = {
        circlesId: circleId,
        name,
        status
      }
    }else{
      newChecked.push({
        circlesId: circleId,
        name,
        status
      })
    }
    dispatch({type: 'handleToggle', payload: newChecked})
  };

  const handleChangeDocumentType = (event: SelectChangeEvent<number>) => {
    dispatch({
      type: "handleChangeDocumentType",
      payload: typeof event.target.value === "number" ? event.target.value : 1,
    });
  };

  const handleChangeDatePicker = (value: string, name: string) => {
    dispatch({
      type: "changeDatePicker",
      payload: { value: value, name: name },
    });
  };

  const handleChangeFile = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> &
      HTMLInputEvent
  ) => {
    dispatch({
      type: "handleChangeFile",
      payload: { value: event.target.files },
    });
  };

  const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: 'handleClickCheck', payload: event.target.checked})
  }

  const handleSubmitAddUser = () => {
    addUsers();
  };

  const handleOpenConfirm = () => {
    dispatch({ type: "openConfirm", payload: true });
  };

  const handleCloseConfirm = () => {
    dispatch({ type: "openConfirm", payload: false });
  };

  const openAlert = () => {
    dispatch({ type: "openAlert", payload: true });
  };

  const closeAlert = () => {
    dispatch({ type: "openAlert", payload: false });
  };

  const convertFileToBase64 = (file: FileList | null, name: string, typeDispatch: 'handleChangeFileUser' ) => {
    const fileValue = file ? file : []
    if(fileValue.length){
        Array.from(fileValue).forEach((archivo: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo)
            reader.onload = () => {
                const base64 = reader.result
                const base64String = typeof base64 === 'string' ? base64 : ''
                // hago split para quitar todo lo que esta antes de la coma ya que eso no se necesita
                let base64Split = []
                base64Split = base64String.split(',')
                dispatch({type: typeDispatch, payload: {valueBase64: base64Split[1], value: file, name: name}})
            }
        })
    }
}

const handleChangeFileUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    convertFileToBase64(event.target.files, event.target.name, 'handleChangeFileUser')
}

  return (
    <UserContext.Provider
      value={{
        state,
        getUsers,
        usersNextPage,
        getUserById,
        getProfiles,
        getCirculos,
        getDocumentTypes,
        getRoles,
        editUser,
        deleteUser,
        getFilterUsers,
        getAccess,
        handleChangeFilter,
        clearFilter,
        addUsers,
        addMultipleUsers,
        getProvincias,
        getDepartamentos,
        getLocalidades,
        getCalles,
        getProfessionalStatus,
        getProfessionals,
        profesionalsNextPage,
        getProfessionalsById,
        modifyProfessionalStatus,
        getProfessionalsFilter,
        getIvaTypes,
        getUserStates,
        clearProfessionalStatusFilter,
        setValuesDefaultForAdminCircle,
        setValuesDefaultForProfileDistinctToGeneral,
        clearValuesDefaultForProfileDistinctToGeneral,
        handleChangeUbicacion,
        handleChangeInput,
        handleChangeCheckbox,
        handleChangeSelect,
        handleToggle,
        handleChangeDocumentType,
        handleChangeDatePicker,
        handleChangeFile,
        handleClickCheck,
        handleChangeSelectCirculos,
        handleSubmitAddUser,
        handleOpenConfirm,
        handleCloseConfirm,
        clearValues,
        closeAlert,
        handleChangeFileUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
