import { useContext, useEffect, useState } from "react"
import { Box, Divider, Grid, Paper, TextField, MenuItem } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import DentalRecordsContext from "../context/DentalRecords/DentalRecordsContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CsvBuilder } from 'filefy';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    }
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    }
}));

const ButtonCustom = styled(MuiButton)(() => ({
    backgroundColor: '#64AAB2',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const { dentalRecordState, getDentalRecords, dentalRecordsNextPage, getDentalRecordStates, getDentalRecordsFilter, getInsurances, getInsuranceStatus, clearFilter, handleChangeFilter } = useContext(DentalRecordsContext)

    useEffect(() => {
        getDentalRecords()
        getInsurances()
        getInsuranceStatus()
        getDentalRecordStates()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(dentalRecordState.loading){
        return <Loader />
    }

    const FilterDiabled = dentalRecordState.insuranceId || dentalRecordState.professionalEnrollment || dentalRecordState.status || dentalRecordState.affiliateFilter

    const dataToExport = dentalRecordState.data.map(item => {
        const insuranceDescription = dentalRecordState.dataInsurance.find(insurance => insurance.insurancesId === item.insuranceId)?.name
        const stateDescription = dentalRecordState.dentalRecordStates.find(drState => drState.dentalRecordStatesId === item.status)?.description
        return [item.date, insuranceDescription ? insuranceDescription : '', `${item.planId}`, `${item.affiliateId}`, item.professionalFullName.name, item.professionalFullName.lastname, stateDescription ? stateDescription : '']
    })

    const exportTable = () => {
        new CsvBuilder("listado_de_fichas.csv")
        .setColumns(["Fecha", "Obra Social", "Plan", "Nº de Afiliado", "Nombre", "Apellido", "Estado"])
        .setDelimeter(";")
        .addRows(dataToExport)
        .exportFile();
    }

    return (
        <Box sx={{ margin: '20px 15px 0px 15px' }}>
            <h4>Fichas</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

            <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                <Grid xs={6} sm={2} md={2.5} item>
                    <TextField 
                        select 
                        fullWidth 
                        value={dentalRecordState.insuranceId ? dentalRecordState.insuranceId : ''} 
                        name='insuranceId' 
                        label='Obra Social' 
                        onChange={(e) => handleChangeFilter(e)}
                    >
                        {dentalRecordState.dataInsurance.map((insurance) => (
                            <MenuItem key={insurance.insurancesId} value={insurance.insurancesId}>
                                {insurance.idFopbaInsider} - {insurance.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid xs={6} sm={2} md={2.5} item>
                    <TextField fullWidth value={dentalRecordState.professionalEnrollment} name='professionalEnrollment' label='Matrícula' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={6} sm={2} md={2.5} item>
                    <TextField 
                        select 
                        fullWidth 
                        value={dentalRecordState.status ? dentalRecordState.status : ''} 
                        name='status' 
                        label='Estado' 
                        onChange={(e) => handleChangeFilter(e)}
                    >
                        {dentalRecordState.dentalRecordStates.map((item) => (
                            <MenuItem key={item.dentalRecordStatesId} value={item.dentalRecordStatesId}>
                                {item.description}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid xs={6} sm={2} md={2.5} item>
                    <TextField fullWidth value={dentalRecordState.affiliateFilter} name='affiliateFilter' label='Nº de Afiliado' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={4} sm={3} md={1.5} item>
                    <MuiButton variant="outlined" size={FilterDiabled ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={() => {getDentalRecordsFilter(); setPage(0)}} disabled={!FilterDiabled}>Filtrar</MuiButton>
                    {FilterDiabled ? 
                        <MuiButton variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearFilter(); setPage(0)}}>Limpiar</MuiButton>
                    : null}
                </Grid>
            </Grid>

            <Grid container justifyContent='end'>
                <ButtonCustom sx={{marginBottom: '10px', color: '#fff'}} onClick={exportTable} variant='outlined'>
                    Exportar <FileDownloadIcon sx={{marginLeft: '5px'}}/>
                </ButtonCustom>
            </Grid>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Fecha</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Obra Social</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Plan</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">N° de Afiliado</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Apellido</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 
                            ? dentalRecordState.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : dentalRecordState.data
                        ).map((row) => (
                            <StyledTableRow
                                key={row.dentalRecordsId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell sx={{padding: '12px'}} component="th" scope="row" align="left">
                                    {row.createDate ? row.createDate : row.date}
                                </StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{dentalRecordState.dataInsurance.find(item => item.insurancesId === row.insuranceId)?.idFopbaInsider} - {dentalRecordState.dataInsurance.find(item => item.insurancesId === row.insuranceId)?.name}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.planId}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.code}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.professionalFullName.name}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.professionalFullName.lastname}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{dentalRecordState.dentalRecordStates.find(item => item.dentalRecordStatesId === row.status)?.description}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">
                                    <Link to={`/fichas/${row.dentalRecordsId}/${row.status}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                        <SearchIcon color="primary" sx={{cursor: 'pointer'}} />
                                    </Link>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                colSpan={3}
                                count={dentalRecordState.data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Filas por pagina',
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage='Filas por página'
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={() => <Pagination count={dentalRecordState.data.length} filtered={dentalRecordState.filtered} noMoreResults={dentalRecordState.noMoreResults} nextPage={dentalRecordsNextPage} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Index