import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext, useEffect } from "react"
import InsuranceContext from "../context/Insurances/InsuranceContext"
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import 'moment/locale/es';
import Loader from '../Loader';
import Alert from '../Alert';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../interfaces/Login';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditorHtml from './EditorHtml';

const ListItemStyled = styled(ListItem)(() => ({
    backgroundColor: 'aliceblue',
    ':hover': {
        backgroundColor: '#e2eef9',
    }
}));


const Add = () => {

    const { 
        getCoverage,
        getProvincias, 
        getDepartamentos, 
        getLocalidades,
        handleToggle,
        handleChangeUbicacion, 
        handleChangeInput, 
        addObraSocial,
        closeAlert,
        clearValues,
        handleChangeParticularRules,
        handleClickCheck,
        state 
    } = useContext(InsuranceContext)

    useEffect(() => {
        getCoverage()
        clearValues()
        getProvincias()
    }, [])

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    //deshabilito los campos de Datos prestacionales para el admin circulo
    const fieldDisabled = decoded.profileId === 4;

    // recorro array de calles y me fijo si hay algun nombre repetido y lo saco del array
    let arrayCalles: any[] = [];
    if(state.calles.length){
        state.calles.map(c => {
            if(!arrayCalles.includes(c.nombre)){
                arrayCalles.push(c.nombre)
            }
        })
    }

    if(state.loading){
        return <Loader />
    }

    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    if(Number(localidadSelected?.postalCode) !== 0){
        state.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
    }

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Datos Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Nombre"
                        name='name'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.items.name && state.insuranceAdd} 
                        value={state.items.name}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Código"
                        name='idFopbaInsider'
                        variant="standard"
                        fullWidth
                        className='widthField'
                        required
                        error={!state.items.idFopbaInsider && state.insuranceAdd} 
                        value={state.items.idFopbaInsider ? state.items.idFopbaInsider : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Cuit"
                        name='cuit'
                        inputProps={{ maxLength: 13 }}
                        variant="standard"
                        fullWidth
                        required
                        error={!state.items.cuit && state.insuranceAdd} 
                        value={state.items.cuit ? state.items.cuit : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Email"
                        name='email'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.items.email && state.insuranceAdd} 
                        value={state.items.email}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Edad de Adulto"
                        name='adultAge'
                        variant="standard"
                        fullWidth
                        required
                        type='number'
                        error={!state.items.adultAge && state.insuranceAdd} 
                        value={state.items.adultAge ? state.items.adultAge : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos Demográficos</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
            <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            className="widthField"
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.provincias}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.state && state.insuranceAdd}
                                key={params.id}
                                required
                                label="Provincia"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            onOpen={getDepartamentos}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.departamentos}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.department && state.insuranceAdd}
                                key={params.id}
                                required
                                label="Partido"
                                />
                            )}
                            className="widthField"
                            disabled={!state.items.state}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            onOpen={getLocalidades}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                            }
                            getOptionLabel={(option) => {
                                return option.name
                            }}
                            options={localidadesFormat}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.town && state.insuranceAdd}
                                key={params.id}
                                required
                                label="Localidad"
                                />
                            )}
                            className="widthField"
                            disabled={!state.items.department}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Calle"
                        name="street"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.street && state.insuranceAdd}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Número"
                        name='height'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.items.height && state.insuranceAdd} 
                        value={state.items.height ? state.items.height : ''}
                        onChange={handleChangeInput}
                    />
                    <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.height === 'S/N'} />} label="Sin Número" />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Piso"
                        name='floor'
                        variant="standard"
                        fullWidth
                        inputProps={{maxLength: "2"}}
                        value={state.items.floor && state.items.floor !== "0" ? state.items.floor : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Depto"
                        name='apartment'
                        variant="standard"
                        fullWidth
                        value={state.items.apartment ? state.items.apartment : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Código Postal"
                        name='postalCode'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.items.postalCode && state.insuranceAdd} 
                        value={state.items.postalCode ? state.items.postalCode : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name='phone'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.items.phone && state.insuranceAdd} 
                        value={state.items.phone ? state.items.phone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name='cellPhone'
                        variant="standard"
                        fullWidth
                        value={state.items.cellPhone ? state.items.cellPhone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos Prestacionales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography>Normas Particulares</Typography>
                    <EditorHtml 
                        value={state.items.particularRegulations} 
                        handleChangeParticularRules={handleChangeParticularRules} 
                        name={'particularRegulations'} 
                        fieldDisabled={fieldDisabled} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography>Nomenclador y Arancel</Typography>
                    <EditorHtml 
                        value={state.items.arancelNomenclature} 
                        handleChangeParticularRules={handleChangeParticularRules} 
                        name={'arancelNomenclature'} 
                        fieldDisabled={fieldDisabled} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography>Información General</Typography>
                    <EditorHtml 
                        value={state.items.generalInformation} 
                        handleChangeParticularRules={handleChangeParticularRules} 
                        name={'generalInformation'} 
                        fieldDisabled={fieldDisabled} 
                    />
                </Grid>
            </Grid>
            <h4>Coberturas Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                {state.coverage.length ?
                        state.coverage.map((c) => {
                            return <ListItemStyled
                                key={c.generalCoveragesId}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={(e) => handleToggle(c.generalCoveragesId, e.target.checked)}
                                        checked={state.items.generalCoverages.findIndex(item => item.generalCoveragesId === c.generalCoveragesId && item.status) !== -1}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText primary={c.description} />
                                </ListItemButton>
                            </ListItemStyled>
                        })
                    : null}
                </Grid>
                {!state.items.generalCoverages.length && state.insuranceAdd && <Typography className="errorMessage" variant="body1">Tienes que seleccionar al menos 1 cobertura general</Typography>}
            </Grid>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ?
                    state.messageError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                    ))
                : null}
            </Grid>
            <Grid container justifyContent='flex-end' sx={{mt: 6}}>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='outlined'>
                        <Link to='/obras-sociales' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                    </Button>
                </Grid>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} onClick={addObraSocial}>Guardar</Button>
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Obra social agregada correctamente'} />
        </Box>
    )
}

export default Add