import { Box, Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Grid, TextField } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import Pagination from '../Pagination';
import InsuranceContext from '../context/Insurances/InsuranceContext';
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider';
import Loader from '../Loader';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ListPermissions from '../../utils/ListPermissions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CsvBuilder } from 'filefy';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(Button)(() => ({
    backgroundColor: '#64AAB2',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const {
        state, 
        getInsurances,
        closeAlert,
        getInsuranceState,
        insurancesNextPage,
        handleChangeFilter,
        getInsuranceFilter,
        clearFilter
    } = useContext(InsuranceContext)

    useEffect(() => {
        getInsurances()
        getInsuranceState()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if((!state.data.length && !state.filtered) || state.loading){
        return <Loader />
    }

    const onClickInsuranceDetail = (id: number) => {
        window.location.href = `/detalle-de-obra-social/${id}`
    }

    const dataToExport = state.data.map(item => {
        const statusDescription = state.statusList.find((status) => status.insuranceStateId === item.insuranceStateId)?.description
        return [item.idFopbaInsider, item.name, statusDescription ? statusDescription : '']
    })

    const exportTable = () => {
        new CsvBuilder("listado_de_obras_sociales.csv")
        .setColumns(["Código", "Nombre", "Estado"])
        .setDelimeter(";")
        .addRows(dataToExport)
        .exportFile();
    }

    const FilterDiabled = state.nameFilter.length >= 3 || state.codeFilter

    return (
        <Box sx={{ margin: '30px 15px 0px 15px' }}>
            <h4>Obras Sociales</h4>
            <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                <Grid xs={6} sm={4} md={4} item>
                    <TextField fullWidth value={state.codeFilter} name='codeFilter' label='Código' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={6} sm={4} md={4} item>
                    <TextField fullWidth value={state.nameFilter} name='nameFilter' label='Nombre' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={12} sm={4} md={4} item>
                    <Button variant="outlined" size={state.nameFilter || state.codeFilter ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={() => {getInsuranceFilter(); setPage(0)}} disabled={!FilterDiabled}>Filtrar</Button>
                    {state.nameFilter || state.codeFilter ? 
                        <Button variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearFilter(); setPage(0)}}>Limpiar</Button>
                    : null}
                </Grid>
            </Grid>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container justifyContent={ListPermissions().crearObrasSociales?.status ? 'space-between' : 'end'}>
                {ListPermissions().crearObrasSociales?.status &&
                    <ButtonCustom variant='outlined' onClick={closeAlert}>
                        <Link to='/agregar-obra-social' style={{color: '#fff', textDecoration: 'none', width: '100%'}}>Agregar Obra Social</Link>
                    </ButtonCustom>
                }
                <ButtonCustom sx={{marginBottom: '10px', color: '#fff'}} onClick={exportTable} variant='outlined'>
                    Exportar <FileDownloadIcon sx={{marginLeft: '5px'}}/>
                </ButtonCustom>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="center">Estado</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 
                            ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : state.data
                        ).map((row) => {

                            const statusDescription = state.statusList.map((status) => {
                                if(row.insuranceStateId === status.insuranceStateId){
                                    return status.description
                                }
                            })

                            return <StyledTableRow
                                key={row.insurancesId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} align="center">{statusDescription}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} align="center">
                                    <RemoveRedEyeOutlinedIcon sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}} onClick={() => {onClickInsuranceDetail(row.insurancesId); closeAlert()}}/>
                                </StyledTableCell>
                            </StyledTableRow>
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                colSpan={3}
                                count={state.data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Filas por pagina',
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage='Filas por página'
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={() => <Pagination count={state.data.length} noMoreResults={state.noMoreResults} nextPage={insurancesNextPage} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Index