import { ContactItems, ContactState } from '../../../interfaces/Contact';
import { CellPhoneFormat } from '../../../utils/CelPhoneFormat';

type Action = {type: 'getContact', payload: ContactItems}
    | {type: 'contactEdited', payload: boolean}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getCalles', payload: []}
    | {type: 'clearValues', payload: ContactItems}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeSearchAddressValue', payload: {name: string, value: string}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'openAlert', payload: boolean}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number}}
    | {type: 'setLatAndLng', payload: string}
    | {type: 'setAddress', payload: string}

export default function InsuranceReducer (state: ContactState, action: Action): ContactState {
    switch (action.type) {
        case 'getContact':
            return { 
                ...state, 
                items: action.payload,
                loading: false
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'contactEdited':
            return {
                ...state,
                contactEdited: action.payload
            }
  
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload
            }
        case 'getCalles':
            return { 
                ...state, 
                calles: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'changeUbicacion':
            state.items[action.payload.name] = action.payload.value
            return { 
                ...state, 
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value

            if(action.payload.name === 'cellPhone'){
                state.items.cellPhone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'phone'){
                state.items.phone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'postalCode'){
                state.items.postalCode = action.payload.value.replaceAll('-', '')
            }
            
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSearchAddressValue':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSelect':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'setLatAndLng':
            state.items.placeId = action.payload
            return { 
                ...state,
                items: state.items
            }
        case 'setAddress':
            state.items.street = action.payload
            return { 
                ...state,
                items: state.items
            }
        default:
            return state;
    }
}