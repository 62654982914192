import { createContext } from 'react'
import { EmpadronamientoState } from '../../../interfaces/Empadronamiento';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: EmpadronamientoState,
    getEnrollment: () => void
    enrollmentNextPage: () => void
    getEnrollmentStatus: () => void
    getAllEnrollments: () => void
    getAllEnrollmentDetail: (registrationsId: number) => void
    getAllEnrollmentsFilter: () => void
    modifyEnrollmentStatus: (registrationsId: number, status: number, comment?: string) => void
    getCircles: () => void
    handleChangeSelectCircle: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handlePrevStep: () => void
    handleNextStep: () => void
    getInsurances: () => void
    getInsuranceType: () => void
    handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleChangeFileSpeciality: (event: React.ChangeEvent<HTMLInputElement>) => void
    clearFilter: () => void
    clearEnrollmentFilter: () => void
    handleClickCoverage: (event: React.ChangeEvent<HTMLInputElement>, insuranceId: number, coverageSelected: number) => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any) => void
    getMainSpeciality: () => void
    handleChangeSecureCheck: (secureId: number) => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    handleChangeUbicacion: (value: any, name: string) => void
    handleChangeSelectFilter: (event: SelectChangeEvent<string>) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeInputConsultorio: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleChangeYearPicker: (value: string, name: string) => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    handleChangeView: (view: string) => void
    closeSummaryModal: () => void
    handleChangeSelect: (event:SelectChangeEvent<string | number[]>) => void
    getGeneralRules: () => void
    getGeneralRulesById: (generalRulesId: number) => void
    editGeneralRule: (generalRulesId: number) => void
    saveGeneralRules: () => void
    getParticularRulesByProfessionalId: (professionalId: number) => void
    saveParticularRuleByProfessionalId: (professionalId: number) => void
    closeAlert: () => void
    clearValues: () => void
    deleteGeneralRule: (id: string | undefined) => void
    getProfessionals: () => void
    professionalsNextPage: () => void
    getProfessionalStatus: () => void
    getProfessionalsFilter: () => void
    clearProfessionalStatusFilter: () => void
    handleToggle: (insuranceId: number, status: boolean) => void
}

const EmpadronamientoContext = createContext<ContextProps>({} as ContextProps);

export default EmpadronamientoContext;