import { useContext, useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom'
import { Box, Divider, Grid, Typography, Paper } from '@mui/material'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, TextField, Button } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import UserContext from "../context/Users/UserContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment'

const TypographyStyled = styled(Typography)(() => ({
    color: '#64AAB2',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Detail = () => {

    const { state, getProfessionalsById, getProfessionalStatus, modifyProfessionalStatus } = useContext(UserContext)

    const {id} = useParams();

    useEffect(() => {
        getProfessionalsById(Number(id))
        getProfessionalStatus()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState(0);
    const [circleGroupingCode, setCircleGroupingCode] = useState(0);
    const [insuranceGroupingCode, setInsuranceGroupingCode] = useState(0);
    const [observation, setObservation] = useState('');

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault()
        modifyProfessionalStatus(Number(id), circleGroupingCode, insuranceGroupingCode, startDate, endDate, status, observation)
    }

    const circlesGroupingCodes = [
        901,
        902,
        903,
        904,
        905,
        906,
        907,
        908,
        909,
        910,
        911,
        912,
        913,
        914,
        915,
        916,
        917,
        918,
        919,
        920,
        921,
        922,
        923,
        924,
        925,
        926,
        927,
        928,
        930,
        936
    ]

    const circulos = (idFopbaInsider: string, name: number | string, index: number) => {
        const coma = index + 1 !== state.professionalDetail.circles.length
        return `${idFopbaInsider ? idFopbaInsider : ''} - ${name ? name : ''} ${!idFopbaInsider && !name ? 'Sin Circulos' : ''}${coma ? ', ' : ''}`
    }

    if(state.loading || !state.professionalDetail.professionalsId){
        return <Loader />
    }


    return (
        <Box sx={{margin: '20px 15px 0px 15px'}}>
            {/*Muestro detalles genearales*/}
            <h4>Detalles</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Box component='form' onSubmit={handleSubmit}>
                <Grid container spacing={3} justifyContent='space-between' mb={5}>
                    <Grid item container xs={12} sm={6} md={6}>
                        <TypographyStyled>Nombre: </TypographyStyled>{state.professionalDetail.professionalName}
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6}>
                        <TypographyStyled>Apellido: </TypographyStyled>{state.professionalDetail.professionalLastname}
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6}>
                        <TypographyStyled>Cuil / Cuit: </TypographyStyled>{state.professionalDetail.cuil}
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6}>
                        <TypographyStyled>Fecha de Alta en FOPBA: </TypographyStyled>{state.professionalDetail.fopbaRegistration}
                    </Grid>
                    {state.professionalDetail.circles.length ?
                    <Grid item container xs={12} sm={6} md={6}>
                            {state.professionalDetail.circles.length > 1 ? <TypographyStyled>Círculos:</TypographyStyled> : null}
                            {state.professionalDetail.circles.length > 1 ?
                                state.professionalDetail.circles.map((circles, index) => (
                                    circulos(circles.idFopbaInsider, circles.name, index)
                                ))
                            :
                                <>
                                    <TypographyStyled>Circulo:</TypographyStyled>
                                    {!state.professionalDetail.circles[0].idFopbaInsider && !state.professionalDetail.circles[0].name ? 'Sin Círculo' : state.professionalDetail.circles[0].idFopbaInsider + ' - ' + state.professionalDetail.circles[0].name}
                                </>
                            }
                        </Grid>
                        : null}
                    <Grid item container xs={12} sm={6} md={6}>
                        <TypographyStyled>Inicio de Actividad: </TypographyStyled>{state.professionalDetail.startActivity}
                    </Grid>
                    {state.professionalDetail.nationalEnrollment ? 
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Matrícula Nacional: </TypographyStyled>{state.professionalDetail.nationalEnrollment}
                        </Grid>
                    : null}
                    {state.professionalDetail.provincialEnrollment ? 
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Matrícula Provincial: </TypographyStyled>{state.professionalDetail.provincialEnrollment}
                        </Grid>
                    : null}
                </Grid>
                <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} sm={4} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Inicio"
                                value={startDate ? startDate : null}
                                onChange={(newValue) =>
                                    setStartDate(moment(newValue).format("YYYY-MM-DD"))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha Fin"
                                value={endDate ? endDate : null}
                                onChange={(newValue) =>
                                    setEndDate(moment(newValue).format("YYYY-MM-DD"))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField 
                            select 
                            fullWidth 
                            required
                            value={status ? status : ''} 
                            label='Estado' 
                            onChange={(e) => setStatus(Number(e.target.value))} 
                        >
                            {state.professionalStatusData.map(item => {
                                return <MenuItem key={item.professionalStateId} value={item.professionalStateId}>
                                    {item.description}
                                </MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField 
                            select 
                            fullWidth
                            required
                            value={circleGroupingCode ? circleGroupingCode : ''} 
                            label='Código de Grupo de Círculos' 
                            onChange={(e) => setCircleGroupingCode(Number(e.target.value))} 
                        >
                             {circlesGroupingCodes.map(item => {
                                return <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField 
                            select 
                            fullWidth
                            required
                            value={insuranceGroupingCode ? insuranceGroupingCode : ''} 
                            label='Código de Grupo de Obras Sociales' 
                            onChange={(e) => setInsuranceGroupingCode(Number(e.target.value))} 
                        >
                            <MenuItem value={200}>200</MenuItem>
                            <MenuItem value={999}>999</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            label="Observación"
                            fullWidth
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='end' mb={3}>
                    <Grid item xs={6} sm={4} md={4}>
                        <Button fullWidth variant="contained" size="large" type="submit">Guardar</Button>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <h4>Historial</h4>
            {state.professionalDetail.professionalStatus.length ?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Grupo de Círculos</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Grupo de Obras Sociales</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Fecha Inicio</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Fecha Fin</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 
                                ? state.professionalDetail.professionalStatus.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : state.professionalDetail.professionalStatus
                            ).map((row) => {
                                return <StyledTableRow
                                    key={row.professionalStatusesId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.circleGroupingCode}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.insuranceGroupingCode}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.startDate}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.endDate}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.professionalStates.description}</StyledTableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={state.professionalDetail.professionalStatus.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage='Filas por Página'
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={state.professionalDetail.professionalStatus.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            : 
                <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2}}>
                    <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No hay historial de cambio de estado para el prestador seleccionado</Typography>
                </Box> 
            }
            <Grid container justifyContent='end' mt={2} mb={2}>
                <Grid item xs={6} sm={4} md={4}>
                    <Button fullWidth type='button' variant='outlined'>
                        <Link to='/estado-prestador' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
export default Detail