import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
    openConfirm: boolean,
    title: string,
    description?: string,
    handleCloseConfirm: () => void
    handleConfirm: () => void
    handleConfirmId: (id: string | undefined) => void
    idAEliminar?: string | undefined
}

export default function Confirm({ openConfirm, title, description, idAEliminar, handleConfirmId, handleCloseConfirm, handleConfirm }:Props) {

    return (
        <div>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {description && description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} variant='contained' color='inherit'>Cancelar</Button>
                    <Button onClick={idAEliminar ? () => handleConfirmId(idAEliminar) : handleConfirm} variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}