import './Odontogram.css';
import Teeth from './Teeth';
import OdontoGramImg from '../../../img/ReferenciaOdontograma.png'
import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import DentalRecordsContext from '../../context/DentalRecords/DentalRecordsContext';
function Odontogram({pushProviders, providers,...rest}) {

    const disabled= rest?.disabled
    const mobile = window.innerWidth <= 800

    let content = ''

    let odontogramState = {};

    const handleToothUpdate = (id, toothState) => {
        odontogramState[id] = toothState;
    };
    const { 
        getDentalDetail
    } = useContext(DentalRecordsContext)
    useEffect(() => {
        getDentalDetail();
    }, [])
    if(mobile){
        content = <>
            <>
                <div className="teeth">
                    <Teeth start={18} end={11} x={0} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/>
                </div>
                <div className="teeth">
                    <Teeth start={21} end={28} x={210} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
                <div className="teeth">
                    <Teeth start={48} end={41} x={0} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
                <div className="teeth">
                    <Teeth start={31} end={38} x={210} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
              </>
            <>
                <div className="teeth">
                    <Teeth start={55} end={51} x={75} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
                <div className="teeth">
                    <Teeth start={61} end={65} x={210} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
                <div className="teeth">
                    <Teeth start={85} end={81} x={75} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
                <div className="teeth">
                    <Teeth start={71} end={75} x={210} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled} />
                </div>
            </>
        </>
    } else {
        content = <>
            <>
                <div className="teethContainerDesktop">
                    <div>
                        <Teeth start={18} end={11} x={0} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/>
                    </div>
                    <div>
                        <Teeth start={21} end={28} x={210} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                </div>
                <div className="teethContainerDesktop">
                    <div>
                        <Teeth start={48} end={41} x={0} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                    <div>
                        <Teeth start={31} end={38} x={210} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                </div>
            </>
            <>
                <div className="teethContainerDesktop">
                    <div>
                        <Teeth start={55} end={51} x={75} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                    <div>
                        <Teeth start={61} end={65} x={210} y={20} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                </div>
                <div className="teethContainerDesktop">
                    <div>
                        <Teeth start={85} end={81} x={75} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                    <div>
                        <Teeth start={71} end={75} x={210} y={70} handleChange={handleToothUpdate} pushProviders={pushProviders} providers={providers} disabled={disabled}/> 
                    </div>
                </div>
            </>
        </>
    }

    return (
        <>


        <div className="Odontogram">{content}</div>
   
        <Box style={{ display:"flex",justifyContent:"center", marginTop:"16px"}}>
            <img alt='Referencia Odontograma' src={OdontoGramImg}  style={{ width:"100%", maxWidth:1000}}/>
        </Box>
         </>
    );
}

export default Odontogram;