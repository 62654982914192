import { useReducer } from 'react'
import { ProfileState } from '../../../interfaces/Profile';
import ProfileContext from './ProfileContext';
import ProfileReducer from './ProfileReducer';
import { SelectChangeEvent } from '@mui/material';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../../interfaces/Login';
import axios from 'axios';
import { CuitSinGuiones } from '../../../utils/CuitFormat';

const INITIAL_STATE: ProfileState = {
    openConfirm: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    calles: [],
    userEdited: false,
    passwordsDontMatch: false,
    loading: false,
    openAlert: false,
    buttonDisabled: true,
    documentTypeList: [],
    listQuestions: [{securityQuestionsId: 0, question: ''}],
    answerAndQuestions: {
        firstQuestion: 0,
        firstAnswer: '',
        secondQuestion: 0,
        secondAnswer: '',
        thirdQuestion: 0,
        thirdAnswer: ''
    },
    loadingDatosDemograficos: false,
    items: {
        usersId: 0,
        name: '',
        lastname: '',
        bornDate: '',
        email: '',
        password: '',
        repeatPassword: '',
        documentType: {documentTypeId: 0, name: '', code: ''},
        documentValue: '',
        cuil: '',
        phone: '',
        cellPhone: '',
        street: '',
        streetNumber: '',
        floor: '',
        betweenStreet: '',
        observations: '',
        apartment: '',
        postalCode: '',
        town: 0,
        department: 0,
        state: 0,
        country: '',
        isProfessional: '',
        answers: [{
            answerId: 0,
            userId: 0,
            securityQuestionsId: 0,
            answer: ''
        }],
        circlesId: [],
        circles: [],
        circlesChecked: [],
        circlesInitial: []
    },
    ivaTypeList: [],
    profiles: [],
    circles: [],
    roles: [],
    documentTypeId: 0,
    messageError: []
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const UserProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(ProfileReducer, INITIAL_STATE)

    const getProfile = async () => {

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');
        const userId = decoded.sub

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}users/${userId}`, {headers})
        .then(response => {
            dispatch({type: 'getProfile', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getSecurityQuestions =  async () => {

        // const headers = {
        //     'Authorization': `Bearer ${localStorage.getItem('token')}`,
        // };

        // await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}securityQuestions`, {headers})
        // .then(response => {
        //     dispatch({type: 'getSecurityQuestions', payload: response.data})
        // })
        // .catch(error => {
        //     if(error.response.status === 401){
        //         window.location.href = '/'
        //     }
        // })
    };

    const changePassword = async (newPassword: string) => {

        // const token = localStorage.getItem('token')
        // const decoded: tokenDecode = jwt_decode(token ? token : '');
        // const userId = decoded.sub

        // const headers = {
        //     'Authorization': `Bearer ${localStorage.getItem('token')}`,
        // };

        // const data = {
        //     "password" : newPassword
        // }

        // await axios.patch(`${process.env.REACT_APP_BACKOFFICE_API}users/${userId}/newPassword`, data, { headers })
        // .then(() => {
        //     return true
        // })
        // .catch(() => {
        //     return false
        // })
    }

    const editProfile = async () => {

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');
        const userId = decoded.sub

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const regexCuil = /(^(2[03-7]{1})+([0-9]{8})+([0-9]{1})$)|(^((50)|(3[0-46]{1})|(2[03-7]{1}))+([0-9]{8})+([0-9]{1})$)/;
        const cuilValid = regexCuil.test(CuitSinGuiones(state.items.cuil))
        if(!cuilValid){
            dispatch({type: "messageError", payload: ['Cuil/Cuit invalido']});
        }

        const arrayObject = state.items.circlesInitial.map((item, index) => {
            // si tiene mas de un elemento es por que se paso de perfil general a circulo asi que por lo tanto 
            // el ultimo elemento es el que se selecciono para el circulo asi que mando ese elem en true y los demas en false
            const lastElementSelected = index === state.items.circlesInitial.length - 1
            return {
              circlesId: item,
              status: lastElementSelected ? true : false
            }
        })
    
        //elimino del array los circlesId que no se modificaron
        const circlesChecked = state.items.circlesChecked.filter(item => !(state.items.circlesInitial.includes(item.circlesId) && item.status));

        const data = {
            name : state.items.name,
            lastname : state.items.lastname,
            bornDate : state.items.bornDate,
            documentTypeId: state.documentTypeId,
            documentValue : state.items.documentValue,
            cuil: CuitSinGuiones(state.items.cuil),
            phone : state.items.phone,
            cellPhone : state.items.cellPhone,
            street : (state.items.profileId !== 2 && !state.items.street) ? '-' : state.items.street.toLowerCase(),
            streetNumber : (state.items.profileId !== 2 && !state.items.streetNumber) ? 'S/N' : state.items.streetNumber,
            floor : (state.items.profileId !== 2 && !state.items.floor) ? '-' : state.items.floor,
            apartment : (state.items.profileId !== 2 && !state.items.apartment) ? '-' : state.items.apartment,
            postalCode : (state.items.profileId !== 2 && !state.items.postalCode) ? '1234' : state.items.postalCode,
            town : (state.items.profileId !== 2 && !state.items.town) ? 799 : state.items.town,
            department : (state.items.profileId !== 2 && !state.items.department) ? 2 : state.items.department,
            state : (state.items.profileId !== 2 && !state.items.state) ? 1 : state.items.state,
            country : 'Argentina',
            ivaTypesId: (state.items.profileId !== 2 && !state.items.ivaTypesId) ? 2 : Number(state.items.ivaTypesId),
            circles: state.items.profileId === 4 ? arrayObject : circlesChecked,
            profileId: state.items.profileId,
            rolId: Number(state.items.rolId),
            isProfessional: state.items.isProfessional === "true" ? true : false,
            betweenStreet: state.items.betweenStreet ? state.items.betweenStreet : '',
            observations: state.items.observations ? state.items.observations : ''
        }

        const dataWithPassword = {...data, password: state.items.password}
        const dataPut = state.items.password ? dataWithPassword : data

        dispatch({type: 'setLoading', payload: true});

        // verifico que las contraseñas sean iguales, sino muestro mensaje de error
        if(state.items.password === state.items.repeatPassword){
            dispatch({type: 'passwordsDontMatch', payload: false});
            // verifico que todos los campos obligatorios esten seteados
            if(state.items.name && state.items.lastname && state.items.bornDate && state.items.rolId && state.items.documentValue 
                && state.items.cuil && state.items.phone && (state.items.profileId === 2 ? state.items.street : true) && (state.items.profileId === 2 ? state.items.streetNumber : true) 
                && (state.items.profileId === 2 ? state.items.postalCode : true) && (state.items.profileId === 2 ? state.items.town : true) && (state.items.profileId === 2 ? state.items.state : true)
                && (state.items.profileId === 2 ? state.items.department : true) && state.items.isProfessional !== "" && cuilValid
                && state.items.profileId && state.items.rolId && (state.items.profileId === 2 ? state.items.ivaTypesId : true)
            ){
                await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}users/profile/${userId}`, dataPut, {headers})
                .then(response => {
                    if(response.status === 200) {
                        dispatch({type: 'setLoading', payload: false});
                        dispatch({type: "messageError", payload: []});
                        openAlert()
                    }
                })
                .catch(error => {
                    if(error.response.status === 401){
                        window.location.href = '/'
                    }
                })
                dispatch({type: 'userEdited', payload: false});
            } else {
                dispatch({type: 'userEdited', payload: true});
                dispatch({type: 'setLoading', payload: false});
            }
        } else {
            dispatch({type: 'passwordsDontMatch', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const getDocumentTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}documentTypes/`, {headers})
        dispatch({type: 'getDocumentTypes', payload: response.data})
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}states`, {headers})
        .then(response => {
             dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}departments?stateId=${state.items.state}&offset=0&limit=200`, {headers})
        .then(response => {
          dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}towns?stateId=${state.items.state}&departmentId=${state.items.department}&offset=0&limit=200`, {headers})
        .then(response => {
          dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getCalles = async () => {
        const urlCalles = `https://apis.datos.gob.ar/georef/api/calles?departamento=${state.items.department}&localidad_censal=${state.items.town}&max=1000`
        const res = await axios.get(urlCalles)
        dispatch({type: 'getCalles', payload: res.data.calles});
    }

    const getIvaTypes = async () => {

        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}ivaType/`, {headers})
        .then(response => {
          dispatch({type: 'getIvaTypes', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }

    const getProfiles = async () => {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
    
        const response = await axios.get(
          `${process.env.REACT_APP_BACKOFFICE_API}profiles/`,
          { headers }
        );
        dispatch({ type: "getProfiles", payload: response.data });
    };

    const getCirculos = async () => {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}circles?offset=1&limit=50`, {headers})
        .then((response) => {
            dispatch({ type: "getCirculos", payload: response.data });
        })
        .catch((error) => {
            if (error.response.status === 401) {
                window.location.href = "/";
            }
        });
    };

    const getRoles = async () => {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          offset: 0,
          limit: 20,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}roles`, { headers })
        .then((response) => {
            dispatch({ type: "getRoles", payload: response.data });
        })
        .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
        });
    };


    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: "handleChangeCheckbox", payload: { value: event.target.checked, name: event.target.name }});
    };

    const getGeneralCoveragesIndex = (circleId: number) => {
        let index = state.items.circlesChecked.findIndex(i => i.circlesId === circleId);
        return index;
    }
    
    const handleToggle = (circleId: number, status: boolean, name?: string) => {
        const currentIndex = getGeneralCoveragesIndex(circleId);
        const newChecked = [...state.items.circlesChecked];
    
        if(currentIndex !== -1){
          newChecked[currentIndex] = {
            circlesId: circleId,
            name,
            status
          }
        }else{
          newChecked.push({
            circlesId: circleId,
            name,
            status
          })
        }
        dispatch({type: 'handleToggle', payload: newChecked})
    };

    const clearValues = () => {
        dispatch({type: 'clearValues', payload: {
            usersId: 0,
            name: '',
            lastname: '',
            bornDate: '',
            email: '',
            password: '',
            repeatPassword: '',
            documentType: {documentTypeId: 0, name: '', code: ''},
            documentValue: '',
            cuil: '',
            phone: '',
            cellPhone: '',
            street: '',
            streetNumber: '',
            floor: '',
            betweenStreet: '',
            observations: '',
            apartment: '',
            postalCode: '',
            town: 0,
            department: 0,
            state: 0,
            country: '',
            isProfessional: '',
            answers: [{
                answerId: 0,
                userId: 0,
                securityQuestionsId: 0,
                answer: ''
            }],
            circlesId: [],
            circles: [],
            circlesChecked: [],
            circlesInitial: []
        }})
    }

    const handleChangeAnswerField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeAnswerField', payload: {name: e.target.name, value: e.target.value}})
    }

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleUserChangeSelect = (event: SelectChangeEvent<string>) => {
        dispatch({type: 'handleUserChangeSelect', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeSelect = (event: SelectChangeEvent<string | number[]> ) => {
        let {value} = event.target;
        if (Number.isInteger(value) && event.target.name === 'circlesId') value = [Number(value)]
        dispatch({
          type: "handleChangeSelect",
          payload: { value, name: event.target.name },
        });
    };

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({type: 'changeDatePicker', payload: {value: value, name: name}})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    const handleChangeDocumentType = (event: SelectChangeEvent<number>) => {
        dispatch({
          type: "handleChangeDocumentType",
          payload: typeof event.target.value === "number" ? event.target.value : 1,
        });
    };

    const handleChangeSelectCirculos = (value: string | number[], name: string) => {
        if (Number.isInteger(value) && name === 'circlesId') value = [Number(value)]
        dispatch({
          type: "handleChangeSelectCirculos",
          payload: { value, name: name },
        });
    };

    return(
        <ProfileContext.Provider value={{
            state,
            getProfile,
            getSecurityQuestions,
            editProfile,
            getDocumentTypes,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getCalles,
            handleChangeAnswerField,
            handleChangeSelect,
            handleChangeUbicacion,
            handleChangeInput,
            handleUserChangeSelect,
            handleChangeDatePicker,
            handleOpenConfirm,
            handleCloseConfirm,
            clearValues,
            closeAlert,
            getIvaTypes,
            getProfiles,
            getCirculos,
            getRoles,
            handleChangeCheckbox,
            handleToggle,
            handleClickCheck,
            handleChangeDocumentType,
            handleChangeSelectCirculos
        }}>
            {children}
        </ProfileContext.Provider>
    )
}

export default UserProvider