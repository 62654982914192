// import { securityQuestions } from '../../../interfaces/Login';
import { ProfileState, ProfileItems, DocumentType, IvaType, Profiles, Roles, CirclesChecked, DataCirculos } from '../../../interfaces/Profile';
import { CellPhoneConGuiones, CellPhoneFormat } from '../../../utils/CelPhoneFormat';
import { CuitConGuiones, CuitFormat } from '../../../utils/CuitFormat';

type Action = {type: 'getProfile', payload: ProfileItems}
    | {type: 'getDocumentTypes', payload: DocumentType[]}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'getFilterUsers', payload: ProfileItems[]}
    | {type: 'clearFilter', payload: {name: string, lastname: string}}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getCalles', payload: []}
    | {type: 'clearValues', payload: ProfileItems}
    | {type: 'clearFilter', payload: {name: string, lastname: string}}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'handleUserChangeSelect', payload: {name: string, value: string}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'userEdited', payload: boolean}
    | {type: 'passwordsDontMatch', payload: boolean}
    | {type: 'setLoading', payload: boolean}
    | {type: 'openAlert', payload: boolean}
    // | {type: 'getSecurityQuestions', payload: securityQuestions[]}
    | {type: 'handleChangeAnswerField', payload: {value: string, name: string}}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number[]}}
    | {type: 'getIvaTypes', payload: IvaType[]}
    | {type: 'getProfiles', payload: Profiles[]}
    | {type: 'getCirculos', payload: DataCirculos[]}
    | {type: 'getRoles', payload: Roles[]}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'handleChangeCheckbox', payload: {name: string, value: boolean}}
    | {type: 'handleToggle', payload: CirclesChecked[]}
    | {type: 'handleClickCheck', payload: boolean}
    | {type: 'handleChangeDocumentType', payload: number}
    | {type: 'handleChangeSelectCirculos', payload: {name: string, value: string | number[]}}
    | {type: 'messageError', payload: string[]}

export default function UserReducer (state: ProfileState, action: Action): ProfileState {
    switch (action.type) {
        case 'getProfile':
            // set answers
            // state.answerAndQuestions.firstAnswer = action.payload.answers[0].answer
            // state.answerAndQuestions.secondAnswer = action.payload.answers[1].answer
            // state.answerAndQuestions.thirdAnswer = action.payload.answers[2].answer
            // set questions
            // state.answerAndQuestions.firstQuestion = action.payload.answers[0].securityQuestionsId
            // state.answerAndQuestions.secondQuestion = action.payload.answers[1].securityQuestionsId
            // state.answerAndQuestions.thirdQuestion = action.payload.answers[2].securityQuestionsId
            state.items = action.payload
            state.items.cuil = CuitConGuiones(action.payload.cuil)
            state.items.isProfessional = action.payload.isProfessional ? 'true' : 'false'
            state.items.circlesId = state.items.circles.map((item) => item.circleId)
            state.items.circlesInitial = state.items.circles.map((item) => item.circleId)
            state.items.circlesChecked = state.items.circles.map((item) => {return {circlesId: item.circleId, idFopbaInsider: item.idFopbaInsider, name: item.name, status: true}})
            return { 
                ...state, 
                items: action.payload,
                loading: false
            }
        case 'getDocumentTypes':
            const documentType = action.payload.find(item => item.documentTypeId === state.items.documentType.documentTypeId)?.documentTypeId
            return { 
                ...state, 
                documentTypeList: action.payload,
                documentTypeId: documentType ? documentType : 1
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getCalles':
            return { 
                ...state, 
                calles: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'changeUbicacion':
            state.items[action.payload.name] = action.payload.value
            return { 
                ...state, 
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value
            if(action.payload.name === 'cuil'){
                state.items.cuil = CuitFormat(action.payload.value)
                const cuitLength = state.items.cuil.length
                if(cuitLength === 13){
                    const documentNumber = state.items.cuil.slice(3, cuitLength - 2)
                    state.items.documentValue = documentNumber
                }
            }
            if(action.payload.name === 'cellPhone'){
                state.items.cellPhone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'phone'){
                state.items.phone = CellPhoneFormat(action.payload.value)
            }
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleUserChangeSelect':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'changeDatePicker':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'userEdited':
            return {
                ...state,
                userEdited: action.payload
            }
        case 'passwordsDontMatch':
            return {
                ...state,
                passwordsDontMatch: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'handleChangeAnswerField':
            state.answerAndQuestions[action.payload.name] = action.payload.value
            let indexAnswer = 0
            if(action.payload.name === 'firstAnswer'){
                indexAnswer = 0
            } else if(action.payload.name === 'secondAnswer'){
                indexAnswer = 1
            } else {
                indexAnswer = 2
            }
            // seteo dentro del array de answer el nuevo valor que se cambio en el input de respuestas 
            state.items.answers[indexAnswer].answer = action.payload.value 
            return { 
                ...state,
                answerAndQuestions: state.answerAndQuestions,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSelect':
            state.items[action.payload.name] = action.payload.value
            if(action.payload.name === 'circlesId'){
                // pusheo el circleId seleccionado dentro del array de circleInitial, esto se hace por si se cambia de perfil general a circulo y tenia seteado mas de un circuloId
                state.items.circlesInitial.push(typeof action.payload.value !== 'string' ? action.payload.value[0] : 1)
            }
            if(action.payload.name === 'profileId'){
                const rolesByProfile = state.profiles.find(r => r.profilesId === (typeof action.payload.value === 'number' ? action.payload.value : 0))
                state.roles = rolesByProfile?.roles.length ? rolesByProfile.roles : []
            }
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'getIvaTypes':
            return { 
                ...state,
                ivaTypeList: action.payload
            }
        case 'getProfiles':
            return { 
                ...state, 
                profiles: action.payload
            }
        case 'getCirculos':
            return { 
                ...state, 
                circles: action.payload
            }
        case 'getRoles':
            return { 
                ...state, 
                roles: action.payload
            }
        case 'handleChangeCheckbox':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleToggle':
            state.items.circlesChecked = action.payload
            return { 
                ...state,
                buttonDisabled: false
            }
        case 'loadingDatosDemograficos':
            return {
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'handleClickCheck':
            state.items.streetNumber = action.payload ? 'S/N' : ''
            return {
                ...state,
                buttonDisabled: false
            }
        case 'handleChangeDocumentType':
            return {
                ...state,
                documentTypeId: action.payload,
                buttonDisabled: false
            }
        case 'handleChangeSelectCirculos':
            state.items[action.payload.name] = action.payload.value
            if(action.payload.name === 'circlesId'){
                // pusheo el circleId seleccionado dentro del array de circleInitial, esto se hace por si se cambia de perfil general a circulo y tenia seteado mas de un circuloId
                state.items.circlesInitial.push(typeof action.payload.value !== 'string' ? action.payload.value[0] : 1)
            }
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'messageError':
            return { 
                ...state, 
                messageError: action.payload
            }
        // case 'getSecurityQuestions':
        //     return {
        //         ...state,
        //         listQuestions: action.payload
        //     }
        default:
            return state;
    }
}