import { useState, useContext, useEffect } from 'react';
import './Tooth.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, MenuItem, Typography, FormControl, Select, InputLabel, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import DentalRecordsContext from '../../context/DentalRecords/DentalRecordsContext';

const StyledDialog = styled(Dialog)(() => ({
    '.MuiDialog-paper': {
      width: '700px',
      maxWidth: '700px'
    }
}));
const facesPositions = {top:"top" , bottom:"bottom",left:"left" , right:"right" , center:"center"};
const keys = ["extraccion","piezaAusente","protesisFija","protesisRemovible","corona","piezaNoErupcionada","endodoncia","opturacion","implante"]

function Tooth({ number, providers, pushProviders, pieceId, faces, disabled= false}) {
    const { 
      dentalRecordState: state
    } = useContext(DentalRecordsContext)
const providerState = state?.providerState?.flat() ?? [];
 const details = state?.providerDetail?.find((piece) => piece?.pieceId == pieceId)?.faces ?? providerState.find((piece) => piece?.pieceId == pieceId)?.faces ;
 const [selectedSide, setSelectedSide] = useState({});
 useEffect(() => {
if (Object.keys( selectedSide).length == 0){
        if(Array.isArray(details)){
        let objSide= {};
        details.forEach(side => {
            const sideData= faces?.find(({facesId}) => facesId == side?.facesId );
            objSide[sideData?.facesId] = {...sideData, active:true};
        });
        setSelectedSide(objSide)
    } else {setSelectedSide(details ?? {})}}
 },[details])
    // formateo providerState para que me vengan todos los elementos en un mismo aray
    const pieceSelected = providerState.find(item => item.pieceId === pieceId);
    function optionProviders(providerTypesId, value) {
        const clearValues = () => {
            pieceSelected.extraccion = 0
            pieceSelected.piezaAusente = 0
            pieceSelected.protesisFija = 0
            pieceSelected.protesisRemovible = 0
            pieceSelected.corona = 0
            pieceSelected.piezaNoErupcionada = 0
            pieceSelected.implante = 0
            pieceSelected.endodoncia = 0
            pieceSelected.opturacion = 0
            setSelectedSide({})
        }
        switch (providerTypesId) {
            case 1:
                clearValues()
                pieceSelected.piezaNoErupcionada = 2
                pieceSelected.prestacionRequeridaSelected = 1
                pieceSelected.prestacionExistenteSelected = ''
                break;
            case 2:
                clearValues()
                pieceSelected.extraccion = value
                pieceSelected.prestacionRequeridaSelected = 2
                pieceSelected.prestacionExistenteSelected = ''
                break;
            case 3:
                clearValues()
                pieceSelected.piezaAusente = 1
                pieceSelected.prestacionExistenteSelected = 3
                pieceSelected.prestacionRequeridaSelected = ''
                break;
            case 4:
                clearValues()
                pieceSelected.protesisFija = value
                if(value === 1){
                    pieceSelected.prestacionExistenteSelected = 4
                    pieceSelected.prestacionRequeridaSelected = ''
                } else {
                    pieceSelected.prestacionRequeridaSelected = 4
                    pieceSelected.prestacionExistenteSelected = ''
                }
                break;
            case 5:
                clearValues()
                pieceSelected.protesisRemovible = value
                if(value === 1){
                    pieceSelected.prestacionExistenteSelected = 5
                    pieceSelected.prestacionRequeridaSelected = ''
                } else {
                    pieceSelected.prestacionRequeridaSelected = 5
                    pieceSelected.prestacionExistenteSelected = ''
                }
                break;
            case 6:
                clearValues()
                pieceSelected.corona = value
                if(value === 1){
                    pieceSelected.prestacionExistenteSelected = 6
                    pieceSelected.prestacionRequeridaSelected = ''
                } else {
                    pieceSelected.prestacionRequeridaSelected = 6
                    pieceSelected.prestacionExistenteSelected = ''
                }
                break;

            case 7:
                clearValues()
                pieceSelected.endodoncia = value
                if(value === 1){
                    pieceSelected.prestacionExistenteSelected = 7
                    pieceSelected.prestacionRequeridaSelected = ''
                } else {
                    pieceSelected.prestacionRequeridaSelected = 7
                    pieceSelected.prestacionExistenteSelected = ''
                }
                break;

            case 8:
                clearValues()
                pieceSelected.opturacion = value
                if(value === 1){
                    pieceSelected.prestacionExistenteSelected = 8
                    pieceSelected.prestacionRequeridaSelected = ''
                } else {
                    pieceSelected.prestacionRequeridaSelected = 8
                    pieceSelected.prestacionExistenteSelected = ''
                }
                break;
                
            case 9:
                clearValues()
                pieceSelected.implante = value
                if(value === 1){
                    pieceSelected.prestacionExistenteSelected = 9
                    pieceSelected.prestacionRequeridaSelected = ''
                } else {
                    pieceSelected.prestacionRequeridaSelected = 9
                    pieceSelected.prestacionExistenteSelected = ''
                }
                break;    
            case 'limpiar':
                if(pieceSelected){
                    pieceSelected.extraccion = 0
                    pieceSelected.piezaAusente = 0
                    pieceSelected.protesisFija = 0
                    pieceSelected.protesisRemovible = 0
                    pieceSelected.corona = 0
                    pieceSelected.piezaNoErupcionada = 0 
                    pieceSelected.endodoncia = 0 
                    pieceSelected.opturacion = 0 
                    pieceSelected.implante = 0 
                    setSelectedSide({})
                }
                break;
            default:
                throw new Error();
        }
    }

    const prestacionesExistentes = providers.filter(provider => [3, 4, 5, 6,7,8,9].includes(provider.providerTypesId))
    const prestacionesRequeridas = providers.filter(provider => [1, 2, 4, 5, 6,7,8,9].includes(provider.providerTypesId))

    const [openModal, setOpenModal] = useState(false)
    const [treatmentSelected, setTreatmentSelected] = useState({prestacion: 0, value: 0})

    const handleOpenModal = () => {
        if(!disabled)setOpenModal(!openModal)
    }

    const handleSelectChange = (event) => {
        if(event.target.name === 'prestacionExistenteSelected'){
            setTreatmentSelected({...treatmentSelected, prestacion: event.target.value, value: 2})
            // value 1 existentes, value 2 requeridas
            optionProviders(event.target.value, 1)
        } else {
            setTreatmentSelected({...treatmentSelected, prestacion: event.target.value, value: 1})
            optionProviders(event.target.value, 2)
        }
    }

    const descriptionProvider = providers.find(provider => provider.providerTypesId === treatmentSelected.prestacion)?.description

    const saveProvider = () => {
        //Asigno pieceId al guardar la ficha
        let required;
        keys.forEach((key) => {
            if(pieceSelected[key] == 1){
                required= false;
            }
            if(pieceSelected[key] == 2){
                required= true;
            }
        })
        pushProviders(pieceId, treatmentSelected.prestacion, descriptionProvider, selectedSide, required)
        pieceSelected.saveTreatment = true
    }

    let getClassNamesByZone = (zone) => {
        if(pieceSelected){
            if (pieceSelected.carie) {
                if (pieceSelected.carie[zone] === 1) {
                    return 'to-do';
                } else if (pieceSelected.carie[zone] === 2) {
                    return 'done';
                }
            }
        }

        return '';
    }
    const handleOnclickSide =(side)=>{
        let newSelectSide=  selectedSide;
   
        if(newSelectSide[side?.facesId]?.active){
        delete newSelectSide[side?.facesId];

        setSelectedSide({...newSelectSide})
        }else{
            setSelectedSide((previous)=> ({...previous, [side?.facesId] :{...side, active: !previous[side?.facesId]?.active}   }) )
        }
    }
    const opturacion = pieceSelected?.opturacion === 1;
    const mobile = window.innerWidth <= 600;
    const top = faces?.find(({tooltipPosition})=> tooltipPosition == facesPositions.top);
    const bottom= faces?.find(({tooltipPosition})=> tooltipPosition == facesPositions.bottom); 
    const right=faces?.find(({tooltipPosition})=> tooltipPosition == facesPositions.right);
    const left=faces?.find(({tooltipPosition})=> tooltipPosition == facesPositions.left);
    const center=faces?.find(({tooltipPosition})=> tooltipPosition == facesPositions.center);
    const pieceModal = <svg className="tooth" style={{height: 70, width: '30%', marginLeft: mobile ? '40%' : '44%'}}>
        <g transform="scale(3)">
            {pieceSelected?.opturacion > 0 ? (<>
            <Tooltip title={top?.name ?? ''} >  
                <polygon
                    points="0,0 20,0 15,5 5,5"
                    onClick={() => handleOnclickSide(top)}
                    className={ !selectedSide[top?.facesId]?.active ?  "tooth-polygon" :`${opturacion ?"tooth-polygon-selected-red" : "tooth-polygon-selected" }` }
                />
            </Tooltip>
            <Tooltip title={bottom?.name ?? ''}>
                <polygon
                    points="5,15 15,15 20,20 0,20"
                    onClick={() => handleOnclickSide(bottom)}
                    className={ !selectedSide[bottom?.facesId]?.active ? "tooth-polygon" : `${opturacion ?"tooth-polygon-selected-red" : "tooth-polygon-selected" }` }
                />
            </Tooltip>
            <Tooltip title={right?.name ?? ''}>
                <polygon
                    points="15,5 20,0 20,20 15,15"
                    onClick={() => handleOnclickSide(right)}
                    className={ !selectedSide[right?.facesId]?.active ? "tooth-polygon" : `${opturacion ?"tooth-polygon-selected-red" : "tooth-polygon-selected" }` }
                />
            </Tooltip>
            <Tooltip title={left?.name ?? ''}>
                <polygon
                    onClick={() => handleOnclickSide(left)}
                    className={ !selectedSide[left?.facesId]?.active ? "tooth-polygon" : `${opturacion ?"tooth-polygon-selected-red" : "tooth-polygon-selected" }` }
                    points="0,0 5,5 5,15 0,20"
                />
            </Tooltip>
            <Tooltip title={center?.name ?? ''}>
                <polygon
                    onClick={() => handleOnclickSide(center)}
                    className={ !selectedSide[center?.facesId]?.active ? "tooth-polygon" : `${opturacion ?"tooth-polygon-selected-red" : "tooth-polygon-selected" }` }
                    points="5,5 15,5 15,15 5,15"
                />
            </Tooltip>
            </>) : (
            <>
            <polygon
                points="0,0 20,0 15,5 5,5"
            />
            <polygon
                points="5,15 15,15 20,20 0,20"
            />
            <polygon
                points="15,5 20,0 20,20 15,15"
            />
            <polygon
                points="0,0 5,5 5,15 0,20"
            />
            <polygon
                points="5,5 15,5 15,15 5,15"
            />
            </>
            )}
            {drawToothActions()}
        </g>
    </svg>
    return (
        <>
            <svg className="tooth"  onClick={handleOpenModal}>
                <g transform={mobile ? "scale(1.5)" : "scale(2)"}>
                    {pieceSelected?.opturacion > 0  && pieceSelected.saveTreatment ? (<>   
                    <polygon
                        points="0,0 20,0 15,5 5,5"
                        onClick={() => {handleOpenModal()}}
                         className={`${getClassNamesByZone('top')} ${!selectedSide[top?.facesId]?.active ? "tooth-polygon-sin-hover" : `${opturacion ?"tooth-polygon-selected-red-sin-hover" : "tooth-polygon-selected" }`}`}
                    />
                    <polygon
                        points="5,15 15,15 20,20 0,20"
                        className={`${getClassNamesByZone('bottom')} ${!selectedSide[bottom?.facesId]?.active ? "tooth-polygon-sin-hover" :  `${opturacion ?"tooth-polygon-selected-red-sin-hover" : "tooth-polygon-selected" }`}`}
                    />
                    <polygon
                        points="15,5 20,0 20,20 15,15"
                        onClick={() => {handleOpenModal()}}
                        className={`${getClassNamesByZone('left')} ${!selectedSide[right?.facesId]?.active ? "tooth-polygon-sin-hover" :  `${opturacion ?"tooth-polygon-selected-red-sin-hover" : "tooth-polygon-selected" }`}`}
                    />
                    <polygon
                        points="0,0 5,5 5,15 0,20"
                        onClick={() => {handleOpenModal()}}
                        className={`${getClassNamesByZone('right')} ${!selectedSide[left?.facesId]?.active ? "tooth-polygon-sin-hover" :  `${opturacion ?"tooth-polygon-selected-red-sin-hover" : "tooth-polygon-selected" }`}`}
                    />
                    <polygon
                        points="5,5 15,5 15,15 5,15"
                        onClick={() => {handleOpenModal()}}
                        className={`${getClassNamesByZone('center')} ${!selectedSide[center?.facesId]?.active ? "tooth-polygon-sin-hover" :  `${opturacion ?"tooth-polygon-selected-red-sin-hover" : "tooth-polygon-selected" }`}`}
                    /></>) : (<>
                
                    <polygon
                        points="0,0 20,0 15,5 5,5"
                        onClick={() => {handleOpenModal()}}
                        className={getClassNamesByZone('top')}
                    />
                    <polygon
                        points="5,15 15,15 20,20 0,20"
                        onClick={() => {handleOpenModal()}}
                        className={getClassNamesByZone('bottom')}
                    />
                    <polygon
                        points="15,5 20,0 20,20 15,15"
                        onClick={() => {handleOpenModal()}}
                        className={getClassNamesByZone('left')}
                    />
                    <polygon
                        points="0,0 5,5 5,15 0,20"
                        onClick={() => {handleOpenModal()}}
                        className={getClassNamesByZone('right')}
                    />
                    <polygon
                        points="5,5 15,5 15,15 5,15"
                        onClick={() => {handleOpenModal()}}
                        className={getClassNamesByZone('center')}
                    />
                    </>)}
                    {pieceSelected && pieceSelected.saveTreatment ? drawToothActions() : null}

                    <text
                        x="6"
                        y="30"
                        stroke="navy"
                        fill="navy"
                        strokeWidth="0.1"
                        className="tooth">
                        {number}
                    </text>
                </g>
            </svg>

            <StyledDialog
                open={openModal}
                onClose={handleOpenModal}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={handleOpenModal}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} mt={6}>
                    <DialogContent>
                        <Typography>
                            Seleccioná el tratamiento a realizar en la pieza dental <strong>{number}</strong>
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{flexDirection: 'column'}}>
                    <Box sx={{width: '90%'}}>
                        {pieceModal}
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth variant='outlined' required>
                                    <InputLabel>Prestaciones existentes</InputLabel>
                                    <Select
                                        label="Prestaciones existentes"
                                        value={pieceSelected && pieceSelected.prestacionExistenteSelected ? pieceSelected.prestacionExistenteSelected : ''}
                                        name='prestacionExistenteSelected' 
                                        onChange={(e) => handleSelectChange(e)}
                                        sx={{mb: 3}}
                                    >
                                        {prestacionesExistentes.map((p) => {
                                            return <MenuItem key={p.providerTypesId} value={p.providerTypesId}>
                                                {p.description}
                                            </MenuItem>
                                        })}
                                        <MenuItem value='limpiar'>
                                            Limpiar
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth variant='outlined' required>
                                    <InputLabel>Prestaciones requeridas</InputLabel>
                                    <Select
                                        label="Prestaciones requeridas"
                                        value={pieceSelected && pieceSelected.prestacionRequeridaSelected ? pieceSelected.prestacionRequeridaSelected : ''}
                                        name='prestacionRequeridaSelected' 
                                        onChange={(e) => handleSelectChange(e)}
                                        sx={{mb: 3}}
                                    >
                                        {prestacionesRequeridas.map((p) => {
                                            return <MenuItem key={p.providerTypesId} value={p.providerTypesId}>
                                                {p.description}
                                            </MenuItem>
                                        })}
                                        <MenuItem value='limpiar'>
                                            Limpiar
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justifyContent='center' mt={1}>
                            <Button 
                                type='button'
                                disabled={pieceSelected?.opturacion > 0 &&  Object.keys(selectedSide).length == 0 }
                                onClick={() => {saveProvider(); handleOpenModal()}}
                                variant='contained' 
                                sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                            >
                                Guardar
                            </Button>
                           
                        </Grid>
                    </Box>
                </DialogActions>
            </StyledDialog>
        </>
    )

    function drawToothActions() {
        let otherFigures = null;

        if(pieceSelected){
            if (pieceSelected.piezaAusente > 0) {
                otherFigures = <g stroke={pieceSelected.piezaAusente === 1 ? "#b92f2f" : "#3b72ff"}>
                    <line x1="0" y1="0" x2="20" y2="20" strokeWidth="2" />
                    <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
                </g>
            }

            if (pieceSelected.piezaNoErupcionada > 0) {
                otherFigures = <g stroke={pieceSelected.piezaNoErupcionada === 1 ? "#b92f2f" : "#3b72ff"}>
                    <line x1="0" y1="0" x2="20" y2="20" strokeWidth="2" />
                    <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
                </g>
            }

            if (pieceSelected.protesisFija > 0) {
                otherFigures = <g stroke={pieceSelected.protesisFija === 1 ? "#b92f2f" : "#3b72ff"}>
                    <rect x="0" y="8" width="20" height="4.5" fill={pieceSelected.protesisFija === 1 ? "#b92f2f" : "#3b72ff"}/>
                </g>
            }

            if (pieceSelected.protesisRemovible > 0) {
                otherFigures = <g >
                   <rect x="1.8" y="1.5" width="16" height="2" fill={pieceSelected.protesisRemovible === 1 ? "#b92f2f" : "#3b72ff"}/>
                   <rect x="1.8" y="1.5" width="2" height="12" fill={pieceSelected.protesisRemovible === 1 ? "#b92f2f" : "#3b72ff"}/>
                   <rect x="16.2" y="1.5" width="2" height="12" fill={pieceSelected.protesisRemovible === 1 ? "#b92f2f" : "#3b72ff"}/>
                </g>
            }

            if (pieceSelected.extraccion > 0) {
                otherFigures = <g stroke={pieceSelected.extraccion === 1 ? "#b92f2f" : "#3b72ff"}>
                    {/* lena en diagonal */}
                    {/* <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" /> */}
                    <line x1="0" y1="8" x2="20" y2="8" strokeWidth="2" />
                    <line x1="0" y1="12" x2="20" y2="12" strokeWidth="2" />
                </g>
            }

            if (pieceSelected.corona > 0) {
                otherFigures = <circle
                    cx="10"
                    cy="10"
                    r="7"
                    fill="none"
                    stroke={pieceSelected.corona === 1 ? "#b92f2f" : "#3b72ff"}
                    strokeWidth="2"
                />;
            }

            if (pieceSelected.endodoncia > 0) {
                otherFigures =  <text x="3" y="17" fontFamily="Arial" fontWeight="900" fontSize="20" fill={pieceSelected.endodoncia === 1 ? "#b92f2f" : "#3b72ff"}>E</text>;
            }

            if (pieceSelected.implante > 0) {
            otherFigures =  <><line x1="4" y1="3" x2="16" y2="3" fontWeight="900" stroke={pieceSelected.implante === 1 ? "#b92f2f" : "#3b72ff"} strokeWidth="4" />
             <rect x="8" y="3" width="4" height="15"  fontWeight="900" fill={pieceSelected.implante === 1 ? "#b92f2f" : "#3b72ff"} />
             <line x1="4" y1="17" x2="16" y2="17"  fontWeight="900" stroke={pieceSelected.implante === 1 ? "#b92f2f" : "#3b72ff"} strokeWidth="4" />
             </>
            }
              
        }

        return otherFigures;
    }
}

export default Tooth;