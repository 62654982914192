import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditorHtml = ({value, handleChangeParticularRules, name, fieldDisabled}) => {
    return (
        <CKEditor
            editor={ ClassicEditor }
            data={value ? value : ''}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                handleChangeParticularRules(data, name)
            } }
            disabled={fieldDisabled}
        />
    )
}

export default EditorHtml