import { createContext } from 'react'
import { ContactState } from '../../../interfaces/Contact';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: ContactState,
    getContact: () => void
    editContact: () => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getCalles: () => void
    setLatAndLng: (value: string) => void
    setAddress: (value: string) => void
    handleChangeUbicacion: (value: any, name: string) => void
    handleChangeSearchAddressValue: (name: string, value: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    closeAlert: () => void
    clearValues: () => void
    handleChangeSelect: (event:SelectChangeEvent<number>) => void
}

const ContactContext = createContext<ContextProps>({} as ContextProps);

export default ContactContext;