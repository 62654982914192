import { useReducer } from 'react'
import { InsurancePlanCodesItems, InsuranceState } from '../../../interfaces/Insurances';
import InsuranceContext from './InsuranceContext';
import InsuranceReducer from './InsuranceReducer';
import { SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import { CuitSinGuiones } from '../../../utils/CuitFormat';

const INITIAL_STATE: InsuranceState = {
    data: [],
    cargando: false,
    openConfirm: false,
    openConfirmDeleteCodePlans: false,
    openModalAddCodes: false,
    codeId: 0,
    loading: false,
    add: false,
    planError: false,
    planErrorEdit: false,
    openAlert: false,
    buttonDisabled: false,
    insuranceAdd: false,
    insuranceEdited: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    calles: [],
    statusList: [],
    chapterList: [],
    planStatusList: [],
    fieldsModify: {},
    coverage: [],
    messageError: [],
    messagePlansError: [],
    items: {
        insurancesId: 0,
        idFopbaInsider: '',
        name: '',
        insuranceStateId: 0,
        phone: '',
        cellPhone: '',
        email: '',
        cuit: '',
        street: '',
        height: '',
        floor: '',
        apartment: '',
        postalCode: '',
        town: 0,
        department: 0,
        state: 0,
        country: '',
        generalCoverages: [],
        generalCoveragesResponse: [],
        adultAge: 0,
        particularRegulations: '',
        arancelNomenclature: '',
        generalInformation: ''
    },
    insuranceSelected: 0,
    dataInsurancePlan: [],
    idPlanCodeDelete: 0,
    planItems: {
        plansId: 0,
        code: '',
        description: '',
        planStateId: 0,
        codes: [],
        itemsCodesPlan: {
            codesId: 0,
            code: '',
            description: '',
            chapterId: 0,
            customsDuty: 0,
            member: 0,
            codeStateId: 0
        }
    },
    loadingDatosDemograficos: false,
    offset: 0,
    noMoreResults: false,
    nameFilter: '',
    codeFilter: '',
    filtered: false
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const InsuranceProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(InsuranceReducer, INITIAL_STATE)

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances?offset=0&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const insurancesNextPage = async () => {

        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        const offsetValue = state.offset + 50
        state.offset = offsetValue

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances?offset=${offsetValue}&limit=50`, {headers})
        .then(response => {
          dispatch({type: 'insurancesNextPage', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }

    const getInsuranceFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        let params = ''
        if(state.nameFilter && state.codeFilter){
            params = `name=${state.nameFilter}&idFopbaInsider=${state.codeFilter}`
        } else if(state.nameFilter){
            params = `name=${state.nameFilter}`
        } else if(state.codeFilter){
            params = `idFopbaInsider=${state.codeFilter}`
        }

        const url = `${process.env.REACT_APP_BACKOFFICE_API}insurances?${params}`

        await axios.get(url, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
            dispatch({type: 'filtered', payload: true})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de obras sociales para traer todos los resultado nuevamente
        if(state.filtered){
            getInsurances()
            dispatch({type: 'filtered', payload: false})
        }
    }

    const getInsuranceById = async (id: string | undefined) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${Number(id)}`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceById', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsuranceState = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insuranceStates`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceState', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const editObraSocial = async (id: string | undefined) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        const idFormat =  Number(id)
        
    
        const fieldsToModify = {...state.fieldsModify, insurancesId: Number(id)}

        dispatch({type: 'setLoading', payload: true});

        if(state.items.name && state.items.phone && state.items.insuranceStateId && state.items.idFopbaInsider && state.items.street && state.items.cuit && state.items.adultAge &&
            state.items.height && state.items.postalCode && state.items.town && state.items.state && state.items.department && state.items.generalCoverages.length
        ){
            await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${idFormat}`, fieldsToModify, {headers})
            .then(() => {
                openAlert()
                dispatch({type: 'setLoading', payload: false});
                dispatch({type: 'messageError', payload: []});
            })
            .catch(error => {
                if(error.response.status === 401){
                    window.location.href = '/'
                }
                if(error.response.status === 500){
                    dispatch({type: 'messageError', payload: ['Error interno del servidor al modificar obra social']});
                    dispatch({type: 'setLoading', payload: false});
                }
                dispatch({type: 'messageError', payload: error.response.data?.errors});
                dispatch({type: 'setLoading', payload: false});
            })
            dispatch({type: 'insuranceEdited', payload: false});
        } else {
            dispatch({type: 'insuranceEdited', payload: true});
            dispatch({type: 'setLoading', payload: false});
        } 
        
    }

    const deleteObraSocial = async (id: string | undefined) => {
        
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        await axios.delete(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${Number(id)}`, {headers})
        .then(() => {
            window.location.href = '/obras-sociales'
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
        dispatch({type: 'openConfirm', payload: false})
    }


    const addObraSocial = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const generalCoveragesChecked = state.items.generalCoverages.filter(item => item.status === true)
        const checkCoverages = generalCoveragesChecked.map(item => {
            return item.generalCoveragesId
        })

        const data = {
            "name" : state.items.name,
            "idFopbaInsider" : state.items.idFopbaInsider,
            "email" : state.items.email,
            "cuit" : CuitSinGuiones(state.items.cuit),
            "phone" : (state.items.phone),
            "cellPhone" : (state.items.cellPhone),
            "street" : state.items.street ? state.items.street.toLowerCase() : state.items.street,
            "height" : state.items.height,
            "floor" : state.items.floor,
            "apartment" : state.items.apartment,
            "postalCode" : (state.items.postalCode),
            "town" : state.items.town,
            "department" : state.items.department,
            "state" : state.items.state,
            "country" : 'Argentina',
            "generalCoveragesIds" : checkCoverages,
            "adultAge" : Number(state.items.adultAge),
            "particularRegulations": state.items.particularRegulations,
            "arancelNomenclature": state.items.arancelNomenclature,
            "generalInformation": state.items.generalInformation
        }

        dispatch({type: 'setLoading', payload: true});

        if(state.items.name && state.items.phone && state.items.idFopbaInsider && state.items.street && state.items.cuit && state.items.email && state.items.adultAge &&
            state.items.height && state.items.postalCode && state.items.town && state.items.state && state.items.department && checkCoverages.length
        ){
            await axios.post(`${process.env.REACT_APP_BACKOFFICE_API}insurances`, data, { headers })
            .then(response => {
                if(response.status === 201){
                    openAlert()
                    dispatch({type: 'setLoading', payload: false});
                    dispatch({type: 'messageError', payload: []});
                    clearValues()
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    window.location.href = '/'
                }
                if(error.response.status === 500){
                    dispatch({type: 'messageError', payload: ['Error interno del servidor al agregar obra social']});
                    dispatch({type: 'setLoading', payload: false});
                }
                dispatch({type: 'messageError', payload: error.response.data?.errors});
                dispatch({type: 'setLoading', payload: false});
            })
            dispatch({type: 'insuranceAdd', payload: false});
        } else {
            dispatch({type: 'insuranceAdd', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const getCoverage = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}generalCoverages`, {headers})
        .then(response => {
            dispatch({type: 'getCoverage', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}states`, {headers})
        .then(response => {
          dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}departments?stateId=${state.items.state}&offset=0&limit=200`, {headers})
        .then(response => {
          dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}towns?stateId=${state.items.state}&departmentId=${state.items.department}&offset=0&limit=200`, {headers})
        .then(response => {
          dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getCalles = async () => {
        const urlCalles = `https://apis.datos.gob.ar/georef/api/calles?departamento=${state.items.department}&localidad_censal=${state.items.town}&max=1000`
        const res = await axios.get(urlCalles)
        dispatch({type: 'getCalles', payload: res.data.calles});
    }

    const clearValues = () => {
        dispatch({type: 'clearValues', payload: {
            insurancesId: 0,
            idFopbaInsider: '',
            name: '',
            insuranceStateId: 0,
            phone: '',
            cellPhone: '',
            email: '',
            cuit: '',
            street: '',
            height: '',
            floor: '',
            apartment: '',
            postalCode: '',
            town: 0,
            department: 0,
            state: 0,
            country: '',
            generalCoverages: [],
            generalCoveragesResponse: [],
            adultAge: 0,
            particularRegulations: '',
            arancelNomenclature: '',
            generalInformation: ''
        }})
    }

    const getGeneralCoveragesIndex = (coverageId: number) => {
        let index = state.items.generalCoverages.findIndex(i => i.generalCoveragesId === coverageId);
        return index;
    }

    const handleToggle = (coverageId: number, status: boolean) => {
        const currentIndex = getGeneralCoveragesIndex(coverageId);
        const newChecked = [...state.items.generalCoverages];

        if(currentIndex !== -1){
            newChecked[currentIndex] = {
                generalCoveragesId: coverageId,
                status
            }
        }else{
            newChecked.push({
                generalCoveragesId: coverageId,
                status
            })
        }

        dispatch({type: 'handleToggle', payload: newChecked})
    };

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeParticularRules = (value: string, name: string) => {
        dispatch({type: 'handleChangeParticularRules', payload: {value: value, name: name}})
    };

    const handleChangeStatusList = (event: SelectChangeEvent<number>) => {
        dispatch({type: 'handleChangeStatusList', payload: typeof event.target.value === 'number' ? event.target.value : 1})
    };

    const handleChangeSelect = (event: SelectChangeEvent<number>) => {
        dispatch({type: 'handleChangeSelect', payload: {name: event.target.name, value: event.target.value}})
    };

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }
    
    const handleOpenConfirmDeleteCodePlans = (id: number) => {
        dispatch({type: 'openConfirmDeleteCodePlans', payload: true})
        dispatch({type: 'idPlanCodeDelete', payload: id})
    }

    const handleCloseConfirmDeleteCodePlans = () => {
        dispatch({type: 'openConfirmDeleteCodePlans', payload: false})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const clearValuesItemsPlan = () => {
        dispatch({type: 'clearValuesItemsPlan', payload: {
            plansId: 0,
            code: '',
            description: '',
            planStateId: 0,
            codes: [],
            itemsCodesPlan: {
                codesId: 0,
                code: '',
                description: '',
                chapterId: 0,
                customsDuty: 0,
                member: 0,
                codeStateId: 0
            }
        }})
    }

    const clearValuesItemsCodePlan = () => {
        dispatch({type: 'clearValuesItemsCodePlan', payload: {
            codesId: 0,
            code: '',
            description: '',
            chapterId: 0,
            customsDuty: 0,
            member: 0,
            codeStateId: 0
        }})
    }

    const getPlanStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurance/codes/status`, {headers})
        .then(response => {
            dispatch({type: 'getPlanStatus', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getChapters = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}chapters`, {headers})
        .then(response => {
            dispatch({type: 'getChapters', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getInsurancePlanById = async (insuranceId: number, insurancePlanId: string | undefined) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${Number(insuranceId)}/plans`, {headers})
        .then(response => {
            dispatch({type: 'getInsurancePlanById', payload: {data: response.data, insurancePlanId: insurancePlanId}})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsurancePlan = async (id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${Number(id)}/plans`, {headers})
        .then(response => {
            dispatch({type: 'getInsurancePlan', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const addInsurancePlans = async (id: string | undefined) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "code": state.planItems.code,
            "description": state.planItems.description,
            // lo mando activo por defecto
            "planStateId": 1,
            "codes": state.planItems.codes
        }

        dispatch({type: 'setLoading', payload: true});

        if(state.planItems.code && state.planItems.description){
            await axios.post(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${Number(id)}/plans`, data, { headers })
            .then(() => {
                openAlert()
                clearValuesItemsCodePlan()
                dispatch({type: 'setLoading', payload: false});
                dispatch({type: 'messagePlansError', payload: []});
            })
            .catch(error => {
                if(error.response.status === 401){
                    window.location.href = '/'
                }
                if(error.response.status === 500){
                    dispatch({type: 'messagePlansError', payload: ['Error interno del servidor al agregar plan']});
                    dispatch({type: 'setLoading', payload: false});
                }
                dispatch({type: 'messagePlansError', payload: error.response.data?.errors});
                dispatch({type: 'setLoading', payload: false});
            })
            dispatch({type: 'planError', payload: false});
        } else {
            dispatch({type: 'planError', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const addCodes = () => {
        dispatch({type: 'addCodes'})
    };

    const editCode = (data: InsurancePlanCodesItems) => {
        dispatch({type: 'editCode', payload: data})
    };

    const deleteCode = (id: number | undefined) => {
        dispatch({type: 'deleteCode', payload: id})
    };

    const editPlans = async (insurancesId: number, plansId: number) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "plansId": plansId,
            "code": state.planItems.code,
            "description": state.planItems.description,
            "planStateId": Number(state.planItems.planStateId),
        }

        dispatch({type: 'setLoading', payload: true});

        if(state.planItems.code && state.planItems.description){
            await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${insurancesId}/plans/${plansId}`, data, {headers})
            .then(() => {
                openAlert()
                dispatch({type: 'setLoading', payload: false});
            })
            .catch(error => {
                if(error.response.status === 401){
                    window.location.href = '/'
                }
                dispatch({type: 'setLoading', payload: false});
            })
            dispatch({type: 'planErrorEdit', payload: false});
        } else {
            dispatch({type: 'planErrorEdit', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const editPlansCodes = async (insurancesId: number, plansId: number) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const codes: any = []
        state.planItems.codes.map((code) => {
            if(code.add){
                // si se agrega un codigo no se agrega el codesId y si es editar si
                codes.push({
                    code: code.code,
                    chapterId: code.chapterId,
                    codeStateId: code.codeStateId,
                    customsDuty: code.customsDuty,
                    description: code.description,
                    member: code.member
                })
            } else {
                codes.push({
                    codesId: code.codesId,
                    code: code.code,
                    chapterId: code.chapterId,
                    codeStateId: code.codeStateId,
                    customsDuty: code.customsDuty,
                    description: code.description,
                    member: code.member
                })
            }
        })
        
        dispatch({type: 'setLoading', payload: true});

        await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${insurancesId}/plans/${plansId}/codes`, codes, {headers})
        .then(() => {
            openAlert()
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const deletePlans = async (insurancesId: number, plansId: number) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.delete(`${process.env.REACT_APP_BACKOFFICE_API}insurances/${insurancesId}/plans/${plansId}/`, {headers})
        .then(() => {
            window.location.href = '/planes'
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const handleChangePlanItems = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangePlanItems', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangePlanCodes = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangePlanCodes', payload: {value: event.target.value, name: event.target.name}})
    };
    
    const handleChangePlanStatus = (event: SelectChangeEvent<number>) => {
        dispatch({type: 'handleChangePlanStatus', payload: typeof event.target.value === 'number' ? event.target.value : 1})
    };

    const handleChangeChapter = (event: SelectChangeEvent<number>) => {
        dispatch({type: 'handleChangeChapter', payload: typeof event.target.value === 'number' ? event.target.value : 1})
    };

    const openModalAddCodes = (add: boolean) => {
        dispatch({type: 'openModalAddCodes', payload: {open: true, add: add}})
        if(add){
            clearValuesItemsCodePlan()
        }
    };

    const closeModalAddCodes = () => {
        dispatch({type: 'openModalAddCodes', payload: {open: false, add: false}})
    };

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    return(
        <InsuranceContext.Provider value={{
            state,
            getInsurances,
            insurancesNextPage,
            clearFilter,
            getInsuranceById,
            getCoverage,
            getInsuranceState,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getCalles,
            handleToggle,
            handleChangeUbicacion,
            handleChangeInput,
            handleChangeParticularRules,
            handleChangeStatusList,
            handleOpenConfirm,
            handleCloseConfirm,
            addObraSocial,
            clearValues,
            closeAlert,
            deleteObraSocial,
            editObraSocial,
            handleChangeSelect,
            handleClickCheck,
            // PLANS
            getPlanStatus,
            getChapters,
            getInsurancePlanById,
            getInsurancePlan,
            addInsurancePlans,
            handleChangePlanItems,
            handleChangePlanCodes,
            addCodes,
            editCode,
            deleteCode,
            editPlans,
            editPlansCodes,
            deletePlans,
            handleChangePlanStatus,
            handleChangeChapter,
            openModalAddCodes,
            closeModalAddCodes,
            clearValuesItemsPlan,
            handleOpenConfirmDeleteCodePlans,
            handleCloseConfirmDeleteCodePlans,
            getInsuranceFilter,
            handleChangeFilter
        }}>
            {children}
        </InsuranceContext.Provider>
    )
}

export default InsuranceProvider