import { 
    Box, 
    Button, 
    Paper, 
    TableContainer, 
    Table, 
    TableBody, 
    TableHead, 
    TableRow, 
    TableFooter, 
    TablePagination, 
    Grid,
    FormControl,
    Divider,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import Pagination from '../Pagination';
import AffiliateContext from '../context/Affiliate/AffiliateContext';
import { Link } from 'react-router-dom'
import Loader from '../Loader';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CsvBuilder } from 'filefy';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(Button)(() => ({
    backgroundColor: '#64AAB2',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const {
        state, 
        getInsurances,
        getAffiliate,
        getAffiliateFilter,
        getInsurancePlanById,
        clearFilter,
        handleChangeFilter,
        handleChangeSelect,
        getTypeOfLoad,
    } = useContext(AffiliateContext)

    useEffect(() => {
        getTypeOfLoad()
        getInsurances()
        if(state.insuranceSelected){
            getInsurancePlanById(Number(state.insuranceSelected))
            getAffiliate(Number(state.insuranceSelected))
        }
    }, [state.insuranceSelected])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading){
        return <Loader />
    }

    // habilito el boton de filtrar cuando se haya escrito al menos 3 caracteres de nombre o apellido
    const FilterDiabled = state.name.length >= 3 || state.lastname.length >= 3

    const dataToExport = state.data.map(item => {
        const insurancePlanDescription = state.insurancesPlan.find(plan => plan.plansId === item.plansId)?.description
        const uploadType = state.typeOfLoadList.find(type => type.uploadTypeId === item.uploadType)?.description
        return [item.name, item.lastname, insurancePlanDescription ? insurancePlanDescription : '', uploadType ? uploadType : '']
    })

    const exportTable = () => {
        new CsvBuilder("listado_de_afiliados.csv")
        .setColumns(["Nombre", "Apellido", "Plan", "Tipo de Carga"])
        .setDelimeter(";")
        .addRows(dataToExport)
        .exportFile();
    }

    return (
        <Box sx={{ margin: '30px 15px 0px 15px' }}>
            <h4>Afiliados</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container alignItems='center' mb={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth variant='standard' margin='dense'>
                        <InputLabel>Obra Social</InputLabel>
                        <Select
                            fullWidth
                            label="Obra social"
                            name='insuranceSelected'
                            value={state.insuranceSelected ? state.insuranceSelected : ''}
                            onChange={handleChangeSelect}
                        >
                            {state.dataInsurance.map((d) => (
                                <MenuItem key={d.insurancesId} value={d.insurancesId}>{d.idFopbaInsider} - {d.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {state.data.length ?             
                <>
                    <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                        <Grid xs={6} sm={4} md={5} item>
                            <TextField fullWidth value={state.name} name='name' label='Nombre' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={6} sm={4} md={5} item>
                            <TextField fullWidth value={state.lastname} name='lastname' label='Apellido' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={4} sm={3} md={2} item>
                            <Button variant="outlined" fullWidth startIcon={<FilterAltIcon />} onClick={() => getAffiliateFilter(Number(state.insuranceSelected))} disabled={!FilterDiabled}>Filtrar</Button>
                            {(state.name || state.lastname) && <Button variant="outlined" fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={clearFilter}>Limpiar</Button>}
                        </Grid>
                    </Grid>

                    <Grid container justifyContent='end'>
                        <ButtonCustom sx={{marginBottom: '10px', color: '#fff'}} onClick={exportTable} variant='outlined'>
                            Exportar <FileDownloadIcon sx={{marginLeft: '5px'}}/>
                        </ButtonCustom>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{ padding: '12px' }} align="left">Nombre</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '12px' }} align="left">Apellido</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '12px' }} align="left">Plan</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '12px' }} align="left">Tipo de Carga</StyledTableCell>
                                    <StyledTableCell sx={{ padding: '12px' }} align="center">Detalle</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.data
                                ).map((row) => {
                                    const typeOfLoadDescription = state.typeOfLoadList.map((u) => {
                                        if (row.uploadType === u.uploadTypeId) {
                                            return u.description;
                                        }
                                    });
                                    // Filtro los planes por el id del plan del afiliado
                                    const data = state.insurancesPlan.filter(data => data.plansId === row.plansId);
                                    const planDescription = data.length ? data[0].description : row.plansId;

                                    return <StyledTableRow
                                        key={row.affiliateId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{ padding: '12px' }} align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px' }} align="left">{row.lastname}</StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px' }} align="left">{planDescription}</StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px' }} align="left">{typeOfLoadDescription}</StyledTableCell>
                                        <StyledTableCell sx={{ padding: '12px' }} align="center">
                                            <Link to={`/detalle-de-afiliado/${state.insuranceSelected}/${row.affiliateId}`} style={{ color: '#3C78F8', textDecoration: 'none', width: '100%' }}>
                                                <RemoveRedEyeOutlinedIcon />
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>;
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />} />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer></>
                :
                <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2, mb: 2}}>
                    <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No existen afiliados para esta obra social</Typography>
                </Box>
                }
        </Box>
    )
}

export default Index