import { UserState, UsersData, UserItems, Profiles, DocumentType, Roles, MultipleUsers, Access, CirclesChecked, ProfessionalStatus, ProfessionalsData, IvaType, DataCirculos, DataUserStates, ProfessionalDetail } from '../../../interfaces/Users';
import { CuitConGuiones, CuitFormat } from '../../../utils/CuitFormat';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../../interfaces/Login';
import { CellPhoneConGuiones, CellPhoneFormat } from '../../../utils/CelPhoneFormat';

type Action = {type: 'getUsers', payload: UsersData[]}
    | {type: 'usersNextPage', payload: UsersData[]}
    | {type: 'getUserById', payload: UserItems}
    | {type: 'getProfiles', payload: Profiles[]}
    | {type: 'getCirculos', payload: DataCirculos[]}
    | {type: 'getDocumentTypes', payload: {data: DocumentType[], edit: boolean}}
    | {type: 'getRoles', payload: Roles[]}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'getAccess', payload: Access[]}
    | {type: 'getFilterUsers', payload: UsersData[]}
    | {type: 'clearFilter'}
    | {type: 'clearProfessionalStatusFilter'}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getCalles', payload: []}
    | {type: 'professionalStatusData', payload: ProfessionalStatus[]}
    | {type: 'getProfessionals', payload: ProfessionalsData[]}
    | {type: 'profesionalsNextPage', payload: ProfessionalsData[]}
    | {type: 'getProfessionalsById', payload: ProfessionalDetail}
    | {type: 'getProfessionalStatusFilter', payload: ProfessionalStatus[]}
    | {type: 'clearValues', payload: UserItems}
    | {type: 'changeUbicacion', payload: {name: string, value: number}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeCheckbox', payload: {name: string, value: boolean}}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number[]}}
    | {type: 'handleToggle', payload: CirclesChecked[]}
    | {type: 'handleChangeSelectMultiple', payload: {name: string, value: string | number[]}}
    | {type: 'handleChangeDocumentType', payload: number}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'handleChangeFile', payload: {value: FileList | null }}
    | {type: 'userAdded', payload: boolean}
    | {type: 'userEdited', payload: boolean}
    | {type: 'passwordsDontMatch', payload: boolean}
    | {type: 'setLoading', payload: boolean}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'openAlert', payload: boolean}
    | {type: 'multipleUsers', payload: MultipleUsers}
    | {type: 'multipleUsersLoading', payload: boolean}
    | {type: 'filtered', payload: boolean}
    | {type: 'professionalStatusFiltered', payload: boolean}
    | {type: 'messageError', payload: string[]}
    | {type: 'setValuesDefaultForAdminCircle'}
    | {type: 'setValuesDefaultForProfileDistinctToGeneral'}
    | {type: 'clearValuesDefaultForProfileDistinctToGeneral'}
    | {type: 'getIvaTypes', payload: IvaType[]}
    | {type: 'getUserStates', payload: DataUserStates[]}
    | {type: 'handleClickCheck', payload: boolean}
    | {type: 'handleChangeSelectCirculos', payload: {name: string, value: string | number[]}}
    | {type: 'handleChangeFileUser', payload: {valueBase64: string, value: FileList | null, name: string }}


export default function UserReducer (state: UserState, action: Action): UserState {
    switch (action.type) {
        case 'getUsers':
            return { 
                ...state, 
                data: action.payload
            }
        case 'usersNextPage':
            if(action.payload.length !== 50){
                state.noMoreResults = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.data.push(item)
            })
            return { 
                ...state
            }
        case 'getProfiles':
            return { 
                ...state, 
                profiles: action.payload
            }
        case 'getCirculos':
            return { 
                ...state, 
                circles: action.payload
            }
        case 'getDocumentTypes':
            return { 
                ...state, 
                documentType: action.payload.data,
                documentTypeId: action.payload.edit ? action.payload.data[0].documentTypeId : 0
            }
        case 'getRoles':
            return { 
                ...state, 
                roles: action.payload
            }
        case 'getAccess':
            return { 
                ...state, 
                access: action.payload
            }
        case 'getUserById':
            state.items = action.payload
            state.originalEmail = action.payload.email
            state.items.cuil = CuitConGuiones(action.payload.cuil)
            state.items.phone = state.items.phone && state.items.phone !== "0" ? CellPhoneConGuiones(action.payload.phone) : ''
            state.items.cellPhone = state.items.cellPhone && state.items.cellPhone !== "0" ? CellPhoneConGuiones(action.payload.cellPhone) : ''
            state.items.isProfessional = action.payload.isProfessional ? 'true' : 'false'
            state.items.circlesId = state.items.circles.map((item) => item.circleId)
            state.items.circlesInitial = state.items.circles.map((item) => item.circleId)
            state.items.circlesChecked = state.items.circles.map((item) => {return {circlesId: item.circleId, idFopbaInsider: item.idFopbaInsider, name: item.name, status: true}})
            return { 
                ...state, 
                items: state.items
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'getFilterUsers':
            return { 
                ...state, 
                data: action.payload,
                loading: false
            }
        case 'clearFilter':
            return { 
                ...state,
                lastname: '',
                valueProfileFilter: 0,
                valueProvincialEnrollmentFilter: '',
                valueUserStateFilter: 0,
                valueCuitFilter: '',
                valueEmailFilter: ''
            }
        case 'clearProfessionalStatusFilter':
            return { 
                ...state,
                professionalStateId: 0,
                lastnameProfessionalFilter: '',
                licenseProfessionalFilter: '',
                cuilProfessionalFilter: ''
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getCalles':
            return { 
                ...state, 
                calles: action.payload
            }
        case 'professionalStatusData':
            return { 
                ...state, 
                professionalStatusData: action.payload,
                loading: false
            }
        case 'getProfessionals':
            return { 
                ...state, 
                professionalsData: action.payload,
                loading: false
            }
        case 'profesionalsNextPage':
            if(action.payload.length !== 50){
                state.noMoreProfessionalResults = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.professionalsData.push(item)
            })
            return { 
                ...state
            }
        case 'getProfessionalsById':
            return { 
                ...state, 
                professionalDetail: action.payload,
                loading: false
            }
        case 'getProfessionalStatusFilter':
            return { 
                ...state, 
                professionalStatusData: action.payload,
                loading: false
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'changeUbicacion':
            state.items[action.payload.name] = action.payload.value
            return { 
                ...state, 
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value

            if(action.payload.name === 'cuil'){
                state.items.cuil = CuitFormat(action.payload.value)
                const cuitLength = state.items.cuil.length
                if(cuitLength === 13){
                    const documentNumber = state.items.cuil.slice(3, cuitLength - 2)
                    state.items.documentValue = documentNumber
                }
            }
            if(action.payload.name === 'cellPhone'){
                state.items.cellPhone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'phone'){
                state.items.phone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'postalCode'){
                state.items.postalCode = action.payload.value
            }
            if(action.payload.name === 'streetNumber'){
                state.items.streetNumber = action.payload.value.replaceAll('-', '')
            }

            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeCheckbox':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSelect':
            state.items[action.payload.name] = action.payload.value
            if(action.payload.name === 'profileId'){
                const rolesByProfile = state.profiles.find(r => r.profilesId === (typeof action.payload.value === 'number' ? action.payload.value : 0))
                state.roles = rolesByProfile?.roles.length ? rolesByProfile.roles : []
            }
            if(action.payload.name === 'circlesId'){
                // pusheo el circleId seleccionado dentro del array de circleInitial, esto se hace por si se cambia de perfil general a circulo y tenia seteado mas de un circuloId
                state.items.circlesInitial.push(typeof action.payload.value !== 'string' ? action.payload.value[0] : 1)
            }
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSelectCirculos':
            state.items[action.payload.name] = action.payload.value
            if(action.payload.name === 'circlesId'){
                // pusheo el circleId seleccionado dentro del array de circleInitial, esto se hace por si se cambia de perfil general a circulo y tenia seteado mas de un circuloId
                state.items.circlesInitial.push(typeof action.payload.value !== 'string' ? action.payload.value[0] : 1)
            }
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleToggle':
            state.items.circlesChecked = action.payload
            return { 
                ...state,
                buttonDisabled: false
            }
        case 'handleChangeDocumentType':
            return {
                ...state,
                documentTypeId: action.payload,
                buttonDisabled: false
            }
        case 'changeDatePicker':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeFile':
            return {
                ...state,
                usersFile: action.payload.value
            }
        case 'userAdded':
            return {
                ...state,
                userAdded: action.payload
            }
        case 'userEdited':
            return {
                ...state,
                userEdited: action.payload
            }
        case 'passwordsDontMatch':
            return {
                ...state,
                passwordsDontMatch: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'loadingDatosDemograficos':
            return {
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'multipleUsers':
            return { 
                ...state,
                multipleUsers: action.payload
            }
        case 'multipleUsersLoading':
            return { 
                ...state,
                multipleUsersLoading: action.payload
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload
            }
        case 'professionalStatusFiltered':
            return { 
                ...state, 
                professionalStatusFiltered: action.payload
            }
        case 'messageError':
            return { 
                ...state, 
                messageError: action.payload
            }
        case 'setValuesDefaultForAdminCircle':
            state.items.rolId = 3
            state.items.profileId = 2
            state.items.isProfessional = 'true'

            const token = localStorage.getItem('token')
            const decoded: tokenDecode = jwt_decode(token ? token : '');
            state.items.circlesId = [decoded.circleId]
            return { 
                ...state
            }
        case 'setValuesDefaultForProfileDistinctToGeneral':
            state.items.state = 1
            state.items.department = 2
            state.items.town = 799
            state.items.ivaTypesId = 2
            state.items.street = '-'
            state.items.betweenStreet = '-'
            state.items.observations = '-'
            state.items.streetNumber = 'S/N'
            state.items.floor = '-'
            state.items.apartment = '-'
            state.items.postalCode = '1234'

            return { 
                ...state
            }
        case 'clearValuesDefaultForProfileDistinctToGeneral':
            state.items.state = 0
            state.items.department = 0
            state.items.town = 0
            state.items.ivaTypesId = 0
            state.items.street = ''
            state.items.betweenStreet = ''
            state.items.observations = ''
            state.items.streetNumber = ''
            state.items.floor = ''
            state.items.apartment = ''
            state.items.postalCode = ''

            return { 
                ...state
            }
        case 'getIvaTypes':
            return { 
                ...state,
                ivaTypeList: action.payload
            }
        case 'getUserStates':
            return { 
                ...state,
                userStates: action.payload
            }
        case 'handleClickCheck':
            state.items.streetNumber = action.payload ? 'S/N' : ''
            return {
                ...state,
                buttonDisabled: false
            }
        case 'handleChangeFileUser':
            state.items.userFileBase64 = action.payload.valueBase64
            state.items[action.payload.name] = action.payload.value
            return {
                ...state
            }
        default:
            return state;
    }
}