import { useReducer } from 'react'
import axios from 'axios';
import PermisosReducer from './PermisosReducer';
import PermisosContext from './PermisosContext';
import { PermisosState } from '../../../interfaces/Permisos';
import { SelectChangeEvent } from '@mui/material';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../../interfaces/Login';

const INITIAL_STATE: PermisosState = {
    data: [],
    roles: [],
    items: {
        policiesId: 0,
        description: '',
        status: false
    },
    rolesValue: {
        rolesId: 0,
        description: ''
    },
    openAlert: false,
    loading: false
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const PermisosProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(PermisosReducer, INITIAL_STATE)

    const getRoles = async () => {
        dispatch({type: 'loading', payload: true})
        
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'offset': 0,
            'limit': 20
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}roles`, {headers})
        .then(response => {
            dispatch({type: 'getRoles', payload: response.data})
            dispatch({type: 'loading', payload: false})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loading', payload: false})
        })
    }

    const getPermisos = async (id: number) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'offset': 0,
            'limit': 20
        };

        dispatch({type: 'loading', payload: true})

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}roles/${id}/policies`, {headers})
        .then(response => {
            dispatch({type: 'getPermisos', payload: response.data})
            dispatch({type: 'loading', payload: false})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loading', payload: false})
        })
    }

    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
        dispatch({type: 'handleChangeSelect', payload: {value: event.target.value, name: event.target.name}})
    };

    const addPermisos = async (checkModificados: number[]) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        
        const rolId = state.rolesValue.rolesId

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');

        let arrayPermisos: any[] = [];
        let permisosModificados: any[] = [];
        state.data.map(d => {
            if(d.policies.length){
                d.policies.map(p => {
                    checkModificados.map(check => {
                        // saco en un array solo los valores de permisos que se modificaron
                        if(check === p.policiesId){
                            permisosModificados.push(p)
                        }
                    })
                    // array completo de permisos
                    arrayPermisos.push(p)
                })
            }
        })

        dispatch({type: 'loading', payload: true})

        await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}roles/${rolId}/policies`, permisosModificados, {headers})
        .then(response => {
            dispatch({type: 'loading', payload: false})
            if(response.status === 200){
                // verifico que el rol que se este modificando sea el mismo que el del usuario que esta en la sesion
                // para asi actualizarle los permisos del localstorage
                if(rolId === decoded.rolesId){
                    localStorage.setItem('permissions', JSON.stringify(arrayPermisos));
                }
                openAlert()
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loading', payload: false})
        })
    }

    const clearValues = () => {
        dispatch({type: 'clearValues', payload: {data: [],
            roles: [],
            items: {
                policiesId: 0,
                description: '',
                status: false
            },
            rolesValue: {
                rolesId: 0,
                description: ''
            },
            openAlert: false 
        }})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    return(
        <PermisosContext.Provider value={{
            state,
            getRoles,
            getPermisos,
            handleChangeSelect,
            addPermisos,
            closeAlert,
            clearValues
        }}>
            {children}
        </PermisosContext.Provider>
    )
}

export default PermisosProvider