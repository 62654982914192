import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Link, useParams } from 'react-router-dom'
import { Grid, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext, useEffect } from "react"
import InsuranceContext from "../context/Insurances/InsuranceContext"
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import 'moment/locale/es';
import Loader from '../Loader';
import Confirm from '../Confirm'
import Alert from '../Alert';
import ListPermissions from '../../utils/ListPermissions';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../interfaces/Login';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditorHtml from './EditorHtml';

const ListItemStyled = styled(ListItem)(() => ({
    backgroundColor: 'aliceblue',
    ':hover': {
        backgroundColor: '#e2eef9',
    }
}));

const Detail = () => {

    const { 
        getCoverage,
        getInsuranceById,
        getProvincias, 
        getDepartamentos, 
        getLocalidades,
        handleToggle,
        handleChangeUbicacion, 
        handleChangeInput, 
        editObraSocial,
        deleteObraSocial,
        handleOpenConfirm,
        handleCloseConfirm,
        closeAlert,
        handleChangeStatusList,
        getInsuranceState,
        handleChangeParticularRules,
        handleClickCheck,
        state 
    } = useContext(InsuranceContext)

    const {id} = useParams();

    useEffect(() => {
        if(!state.items.insurancesId){
            getCoverage()   
            getInsuranceById(id)        
            getInsuranceState() 
            getProvincias();
        } else if(state.items.insurancesId && !state.loadingDatosDemograficos){
            getDepartamentos();
            getLocalidades();
        }
    }, [state.items.insurancesId, state.items.state, state.items.department])

    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    if (!state.items.insurancesId && !state.loading && !state.loadingDatosDemograficos) {
        return <Loader />
    }

    const fieldsDisabled = !ListPermissions().editarObrasSociales?.status

    //deshabilito los campos de Datos prestacionales para el admin circulo
    const fieldDisabled = decoded.profileId === 4;

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.items.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.items.department))

    if(Number(localidadSelected?.postalCode) !== 0){
        state.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
    }

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Datos Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre"
                        name='name'
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        required
                        error={!state.items.name && state.insuranceEdited} 
                        value={state.items.name}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Código"
                        name='idFopbaInsider'
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        required
                        error={!state.items.idFopbaInsider && state.insuranceEdited} 
                        value={state.items.idFopbaInsider}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth variant='standard' disabled={fieldsDisabled} margin='normal' error={!state.items.insuranceStateId && state.insuranceEdited}>
                        <InputLabel>Estado</InputLabel>
                        <Select
                            label="Estado"
                            name='status'
                            value={state.items.insuranceStateId ? state.items.insuranceStateId : ''}
                            onChange={handleChangeStatusList}
                        >
                            {state.statusList.map((s) => (
                                <MenuItem key={s.insuranceStateId} value={s.insuranceStateId}>{s.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Cuit"
                        name='cuit'
                        inputProps={{ maxLength: 13 }}
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        required
                        error={!state.items.cuit && state.insuranceEdited} 
                        value={state.items.cuit ? state.items.cuit : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Email"
                        name='email'
                        variant="standard"
                        fullWidth
                        required
                        disabled={fieldsDisabled}
                        error={!state.items.email && state.insuranceEdited} 
                        value={state.items.email}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Edad de Adulto"
                        name='adultAge'
                        variant="standard"
                        fullWidth
                        required
                        type='number'
                        disabled={fieldsDisabled}
                        error={!state.items.adultAge && state.insuranceEdited} 
                        value={state.items.adultAge ? state.items.adultAge : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos Demográficos</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
            <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            className="widthField"
                            value={{
                                statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                name: provinciaSelected?.name ? provinciaSelected.name : ''
                            }}
                            disabled={fieldsDisabled}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.provincias}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.state && state.insuranceEdited}
                                key={params.id}
                                required
                                label="Provincia"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            value={{
                                departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                name: departamentSelected?.name ? departamentSelected.name : ''
                            }}
                            disabled={fieldsDisabled}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.departamentos}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.department && state.insuranceEdited}
                                key={params.id}
                                required
                                label="Partido"
                                />
                            )}
                            className="widthField"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            value={{
                                townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                            }}
                            disabled={fieldsDisabled}
                            onOpen={!state.localidades.length ? getLocalidades : () => {}}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={localidadesFormat}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.town && state.insuranceEdited}
                                key={params.id}
                                required
                                label="Localidad"
                                />
                            )}
                            className="widthField"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Calle"
                        name="street"
                        required
                        variant="standard"
                        className="widthField"
                        value={state.items.street}
                        error={!state.items.street && state.insuranceEdited}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Número"
                        name='height'
                        variant="standard"
                        fullWidth
                        required
                        disabled={fieldsDisabled}
                        error={!state.items.height && state.insuranceEdited} 
                        value={state.items.height && state.items.height !== "0" ? state.items.height : ''}
                        onChange={handleChangeInput}
                    />
                    <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.height === 'S/N'} />} label="Sin Número" />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Piso"
                        name='floor'
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        inputProps={{maxLength: "2"}}
                        value={state.items.floor && state.items.floor !== "0" ? state.items.floor : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Depto"
                        name='apartment'
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        value={state.items.apartment ? state.items.apartment : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="normal"
                        label="Código Postal"
                        name='postalCode'
                        variant="standard"
                        fullWidth
                        required
                        disabled={fieldsDisabled}
                        error={!state.items.postalCode && state.insuranceEdited} 
                        value={state.items.postalCode ? state.items.postalCode : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name='phone'
                        required
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        error={!state.items.phone && state.insuranceEdited} 
                        value={state.items.phone && state.items.phone !== "0" ? state.items.phone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name='cellPhone'
                        variant="standard"
                        fullWidth
                        disabled={fieldsDisabled}
                        error={!state.items.cellPhone && state.insuranceEdited} 
                        value={state.items.cellPhone && state.items.cellPhone !== "0" ? state.items.cellPhone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos Prestacionales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography>Normas Particulares</Typography>
                    <EditorHtml 
                        value={state.items.particularRegulations} 
                        handleChangeParticularRules={handleChangeParticularRules} 
                        name={'particularRegulations'} 
                        fieldDisabled={fieldDisabled} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography>Nomenclador y Arancel</Typography>
                    <EditorHtml 
                        value={state.items.arancelNomenclature} 
                        handleChangeParticularRules={handleChangeParticularRules} 
                        name={'arancelNomenclature'} 
                        fieldDisabled={fieldDisabled} 
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography>Información General</Typography>
                    <EditorHtml 
                        value={state.items.generalInformation} 
                        handleChangeParticularRules={handleChangeParticularRules} 
                        name={'generalInformation'} 
                        fieldDisabled={fieldDisabled} 
                    />
                </Grid>
            </Grid>
            <h4>Coberturas Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                {state.coverage.length ?
                        state.coverage.map((c) => {
                            return <ListItemStyled
                                key={c.generalCoveragesId}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={(e) => handleToggle(c.generalCoveragesId, e.target.checked)}
                                        checked={state.items.generalCoverages.findIndex(item => item.generalCoveragesId === c.generalCoveragesId && item.status) !== -1}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText primary={c.description} />
                                </ListItemButton>
                            </ListItemStyled>
                        })
                    : null}
                </Grid>
                {!state.items.generalCoverages.length && state.insuranceEdited && <Typography className="errorMessage" variant="body1">Tienes que seleccionar al menos 1 cobertura general</Typography>}
            </Grid>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ?
                    state.messageError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                    ))
                : null}
            </Grid>
            <Grid container justifyContent='space-between' sx={{mt: 6}}>
                {/* muestro boton eliminar si esta habilitado en los permisos */}
                {ListPermissions().eliminarObrasSociales?.status &&
                    <Grid item md={2}>
                        <Button fullWidth type='button' variant='contained' color='error' onClick={handleOpenConfirm}>Eliminar</Button>
                    </Grid>
                }
                <Grid container item md={ListPermissions().eliminarObrasSociales?.status ? 6 : 12} justifyContent='end'>
                    <Grid item md={ListPermissions().eliminarObrasSociales?.status ? 6 : 3}>
                        <Button fullWidth type='button' variant='outlined'>
                            <Link to='/obras-sociales' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                        </Button>
                    </Grid>
                    {ListPermissions().editarObrasSociales?.status &&
                        <Grid item md={ListPermissions().eliminarObrasSociales?.status ? 6 : 3}>
                            <Button fullWidth type='button' disabled={state.buttonDisabled} variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} onClick={() => editObraSocial(id)}>Guardar</Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Confirm
                openConfirm={state.openConfirm}
                handleCloseConfirm={handleCloseConfirm}
                handleConfirmId={deleteObraSocial}
                handleConfirm={() => {}}
                idAEliminar={id}
                title='¿Estas seguro?'
                description='Si eliminas esta obra social no podrás volver a recuperarla luego'
            />
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Obra social editada correctamente'} />
        </Box>
    )
}

export default Detail