import { Typography, TextField, Button, Box } from "@mui/material"
import LoginContext from "../context/Login/LoginContext"
import { Link } from "react-router-dom"
import { useContext } from "react"
import Logo from '../../img/logo.png'
import './style.css'
import Loader from "../Loader"

const Login = () => {

    const { state, doLogin, handleChangeValues } = useContext(LoginContext)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault()
        doLogin(state.email, state.contraseña)
    }

    if(state.cargando){
        return <Loader />
    }

    return (
        <Box className="container">
            <Box className="textAlign">
                <img src={Logo} width='130px' alt='Logo' />
            </Box>
            <Typography variant="h4" className="title textAlign">Te damos la bienvenida</Typography>
            <Box 
                component='form' 
                onSubmit={handleSubmit}
                className='formContainer'
            >
                <TextField 
                    label="Email"
                    variant="standard" 
                    name="email"
                    error={!state.email && state.error}
                    value={state.email}
                    onChange={(e) => handleChangeValues(e)}
                    margin="dense"
                    className="inputWidth"
                />
                <TextField 
                    label="Contraseña" 
                    variant="standard"
                    name="contraseña"
                    type='password'
                    error={!state.contraseña && state.error}
                    value={state.contraseña}
                    onChange={(e) => handleChangeValues(e)}
                    margin="dense"
                    className="inputWidth"
                />
                {state.wrongUser && <Typography className="errorMessage" variant="body1">Usuario o contraseña incorrectos</Typography>}
                {state.userBlocked && <Typography className="errorMessage" variant="body1">Usuario bloqueado - Contactase con FOPBA para desbloqueo</Typography>}
                {state.userNotEnabled && <Typography className="errorMessage" variant="body1">Usuario no habilitado para este sitio</Typography>}
                <Button type='submit' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}, margin: 6}}>Ingresar</Button> 
            </Box>
        </Box>
    )
}

export default Login