import { Routes, Route } from "react-router-dom";
import Users from "./components/Users/Index";
import AddUser from "./components/Users/Add";
import Roles from "./components/Roles/Index";
import AddRol from "./components/Roles/Add";
import UserDetail from "./components/Users/UserDetail";
import MultipleUsers from "./components/MultipleUsers/Index";
import Login from "./components/Login/Index";
import Permisos from "./components/Permisos/Index";
import Circulos from "./components/Circulos/Index";
import AddCirculo from "./components/Circulos/Add";
import CirculoDetail from "./components/Circulos/Detail";
import Insurances from "./components/Insurances/Index";
import AddInsurance from "./components/Insurances/Add";
import InsuranceDetail from "./components/Insurances/Detail";
import PlansInsurances from "./components/PlansInsurances/Index";
import AddPlansInsurances from "./components/PlansInsurances/Add";
import PlansInsurancesDetail from "./components/PlansInsurances/Detail";
import Profile from "./components/Profile/Index";
import Contact from "./components/Contact/Index";
import Affiliate from "./components/Affiliate/Index";
import AffiliateDetail from "./components/Affiliate/Detail";
import Header from "./components/Header";
import Index from "./components/Empadronamientos/Index";
import Detail from "./components/Empadronamientos/Detail";
import ReRegistrationParticular from "./components/Reempadronamiento/ReRegistrationParticular";
import ReRegistration from "./components/Reempadronamiento/ReRegistration";
import ReRegistrationDetails from "./components/Reempadronamiento/ReRegistrationDetails";
import ReRegistrationPrivate from "./components/Reempadronamiento/ReRegistrationPrivate";
import ProfessionalStatus from "./components/ProfessionalStatus/Index";
import ProfessionalStatusDetail from "./components/ProfessionalStatus/Detail";
import DentalRecords from "./components/DentalRecords/Index";
import DentalRecordsDetail from "./components/DentalRecords/Detail";
import Home from "./components/Home";
import Exportacion from "./components/Exportacion/Index";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/inicio" element={<Header content={<Home />} />} />
      <Route path="/usuarios" element={<Header content={<Users/>} />} />
      <Route path="/agregar-usuario" element={<Header content={<AddUser/>} />} />
      <Route path="/detalle-de-usuario/:id" element={<Header content={<UserDetail />} />} />
      <Route path="/roles" element={<Header content={<Roles />} />} />
      <Route path="/agregar-rol" element={<Header content={<AddRol/>} />} />
      <Route path="/editar-rol/:id" element={<Header content={<AddRol/>} />} />
      <Route path="/multiple-usuarios" element={<Header content={<MultipleUsers/>} />} />
      <Route path="/permisos" element={<Header content={<Permisos />} />} />
      <Route path="/circulos" element={<Header content={<Circulos />} />} />
      <Route path="/agregar-circulo" element={<Header content={<AddCirculo />} />} />
      <Route path="/detalle-de-circulo/:id" element={<Header content={<CirculoDetail />} />} />
      <Route path="/obras-sociales" element={<Header content={<Insurances />} />} />
      <Route path="/agregar-obra-social" element={<Header content={<AddInsurance />} />} />
      <Route path="/detalle-de-obra-social/:id" element={<Header content={<InsuranceDetail />} />} />
      <Route path="/planes" element={<Header content={<PlansInsurances />} />} />
      <Route path="/agregar-plan/:id" element={<Header content={<AddPlansInsurances />} />} />
      <Route path="/detalle-de-plan/:idInsurance/:idPlan" element={<Header content={<PlansInsurancesDetail />} />} />
      <Route path="/perfil" element={<Header content={<Profile />} />} />
      <Route path="/empadronamiento" element={<Header content={<Index/>} />} />
      <Route path="/empadronamiento-detalle/:registrationsId" element={<Header content={<Detail/>} />} />
      <Route path="/reempadronamiento" element={<Header content={<ReRegistration/>} />} />
      <Route path="/reempadronamiento-detalle/:generalRulesId" element={<Header content={<ReRegistrationDetails/>} />} />
      <Route path="/reempadronamiento-particular" element={<Header content={<ReRegistrationPrivate/>} />} />
      <Route path="/reempadronamiento-particular/:professionalId" element={<Header content={<ReRegistrationParticular/>} />} />
      <Route path="/contacto" element={<Header content={<Contact />} />} />
      <Route path="/afiliados" element={<Header content={<Affiliate />} />} />
      <Route path="/detalle-de-afiliado/:idInsurance/:idAffiliate" element={<Header content={<AffiliateDetail />} />} />
      <Route path="/estado-prestador/" element={<Header content={<ProfessionalStatus />} />} />
      <Route path="/detalle-de-prestador/:id" element={<Header content={<ProfessionalStatusDetail />} />} />
      <Route path="/fichas" element={<Header content={<DentalRecords />} />} />
      <Route path="/fichas/:id/:status" element={<Header content={<DentalRecordsDetail />} />} />
      <Route path="/exportacion" element={<Header content={<Exportacion />} />} />
    </Routes>
  );
}

export default App;
