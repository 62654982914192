import { InsuranceItems, InsuranceState, StatusList, PlanStatusList, ChapterList, InsurancePlanItems, InsurancePlanCodesItems, CoverageList, GeneralCoverage } from '../../../interfaces/Insurances';
import { CellPhoneConGuiones, CellPhoneFormat } from '../../../utils/CelPhoneFormat';
import { CuitConGuiones, CuitFormat, CuitSinGuiones } from '../../../utils/CuitFormat';

type Action = {type: 'getInsurances', payload: InsuranceItems[]}
    | {type: 'insurancesNextPage', payload: InsuranceItems[]}
    | {type: 'getInsuranceById', payload: InsuranceItems}
    | {type: 'insuranceAdd', payload: boolean}
    | {type: 'insuranceEdited', payload: boolean}
    | {type: 'getCoverage', payload: CoverageList[]}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'getInsuranceState', payload: StatusList[]}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getCalles', payload: []}
    | {type: 'clearValues', payload: InsuranceItems}
    | {type: 'changeUbicacion', payload: {name: string, value: number}}
    | {type: 'handleToggle', payload: GeneralCoverage[]}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeParticularRules', payload: {value: string, name: string}}
    | {type: 'handleChangeStatusList', payload: number}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'openAlert', payload: boolean}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number}}
    | {type: 'messageError', payload: string[]}
    | {type: 'messagePlansError', payload: string[]}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'clearFilter'}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'filtered', payload: boolean}
    | {type: 'handleClickCheck', payload: boolean}
    // PLANS
    | {type: 'planError', payload: boolean}
    | {type: 'planErrorEdit', payload: boolean}
    | {type: 'getPlanStatus', payload: PlanStatusList[]}
    | {type: 'getChapters', payload: ChapterList[]}
    | {type: 'clearValuesItemsPlan', payload: InsurancePlanItems}
    | {type: 'clearValuesItemsCodePlan', payload: InsurancePlanCodesItems}
    | {type: 'getInsurancePlanById', payload: {data: InsurancePlanItems[], insurancePlanId: string | undefined}}
    | {type: 'getInsurancePlan', payload: InsurancePlanItems[]}
    | {type: 'addCodes'}
    | {type: 'editCode', payload: InsurancePlanCodesItems}
    | {type: 'deleteCode', payload: number | undefined}
    | {type: 'handleChangePlanItems', payload: {name: string, value: string}}
    | {type: 'handleChangePlanCodes', payload: {name: string, value: string}}
    | {type: 'handleChangePlanStatus', payload: number}
    | {type: 'handleChangeChapter', payload: number}
    | {type: 'openModalAddCodes', payload: {open: boolean, add: boolean}}
    | {type: 'openConfirmDeleteCodePlans', payload: boolean}
    | {type: 'idPlanCodeDelete', payload: number}

export default function InsuranceReducer (state: InsuranceState, action: Action): InsuranceState {
    switch (action.type) {
        case 'getInsurances':
            return { 
                ...state, 
                data: action.payload
            }
        case 'insurancesNextPage':
            if(action.payload.length !== 50){
                state.noMoreResults = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.data.push(item)
            })
            return { 
                ...state
            }
        case 'getInsuranceById':
            state.items = action.payload
            state.items.cuit = CuitConGuiones(action.payload.cuit)
            state.items.generalCoverages = [...action.payload.generalCoveragesResponse]
            return { 
                ...state, 
                items: state.items
            }
        case 'getCoverage':
            return { 
                ...state, 
                coverage: action.payload,
                loading: false
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'insuranceAdd':
            return {
                ...state,
                insuranceAdd: action.payload
            }
        case 'insuranceEdited':
            return {
                ...state,
                insuranceEdited: action.payload
            }
        case 'getInsuranceState':
            return { 
                ...state, 
                statusList: action.payload
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getCalles':
            return { 
                ...state, 
                calles: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'changeUbicacion':
            state.items[action.payload.name] = action.payload.value
            const fieldsChange = {...state.fieldsModify, [action.payload.name]: `${action.payload.value}`}

            return { 
                ...state, 
                items: state.items,
                fieldsModify: fieldsChange,
                buttonDisabled: false
            }
        case 'clearFilter':
            return { 
                ...state, 
                nameFilter: '',
                codeFilter: ''
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload
            }
        case 'handleToggle':
            const fieldCoverage = {...state.fieldsModify, generalCoverages: action.payload}
            state.items.generalCoverages = action.payload
            return { 
                ...state, 
                fieldsModify: fieldCoverage,
                buttonDisabled: false
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value
            let fields = {...state.fieldsModify}
            
            if(action.payload.name === 'cuit'){
                state.items.cuit = CuitFormat(action.payload.value)
                fields = {...state.fieldsModify, cuit: CuitSinGuiones(action.payload.value)}
            } else if(action.payload.name === 'cellPhone') {
                state.items.cellPhone = CellPhoneFormat(action.payload.value)
                fields = {...state.fieldsModify, cellPhone: CellPhoneFormat(action.payload.value)}
            } else if(action.payload.name === 'phone') {
                state.items.phone = CellPhoneFormat(action.payload.value)
                fields = {...state.fieldsModify, phone: CellPhoneFormat(action.payload.value)}
            }else {
                fields = {...state.fieldsModify, [action.payload.name]: action.payload.value}
            }
            if(action.payload.name === 'postalCode'){
                state.items.postalCode = action.payload.value.replaceAll('-', '')
                fields = {...state.fieldsModify, postalCode: action.payload.value.replaceAll('-', '')}
            }
            if(action.payload.name === 'height'){
                state.items.height = action.payload.value.replaceAll('-', '')
                fields = {...state.fieldsModify, height: action.payload.value.replaceAll('-', '')}
            }

            return {
                ...state,
                items: state.items,
                fieldsModify: fields,
                buttonDisabled: false
            }
        case 'handleChangeParticularRules':
            state.items[action.payload.name] = action.payload.value
            const fieldsModify = {...state.fieldsModify, [action.payload.name]: action.payload.value}

            return {
                ...state,
                fieldsModify: fieldsModify,
                buttonDisabled: false
            }
        case 'handleChangeStatusList':
            state.items.insuranceStateId = action.payload
            const fieldStatus = {...state.fieldsModify, insuranceStateId: action.payload}

            return {
                ...state,
                items: state.items,
                fieldsModify: fieldStatus,
                buttonDisabled: false
            }
        case 'handleChangeSelect':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case 'messageError':
            return { 
                ...state, 
                messageError: action.payload
            }
        case 'messagePlansError':
            return { 
                ...state, 
                messagePlansError: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'planError':
            return { 
                ...state, 
                planError: action.payload
            }
        case 'planErrorEdit':
            return { 
                ...state, 
                planErrorEdit: action.payload
            }
        case 'getPlanStatus':
            return { 
                ...state, 
                planStatusList: action.payload
            }
        case 'getChapters':
            return { 
                ...state, 
                chapterList: action.payload
            }
        case 'clearValuesItemsPlan':
            state.planItems = action.payload
            return { 
                ...state,
                planItems: state.planItems,
            }
        case 'clearValuesItemsCodePlan':
            state.planItems.itemsCodesPlan = action.payload
            return { 
                ...state,
                planItems: state.planItems,
            }
        case 'getInsurancePlanById':
            const planId = Number(action.payload.insurancePlanId)
            // Filtro la data total de planes con el id del plan seleccionado
            const data = action.payload.data.filter(data => data.plansId === planId)

            // Seteo los valores filtrados a los items de planes
            state.planItems.code = data[0].code
            state.planItems.codes = data[0].codes
            state.planItems.description = data[0].description
            state.planItems.planStateId = data[0].planStateId
            state.planItems.plansId = planId

            const largeCodes = data[0].codes.length
            // Verifico si tiene algun codigo seteado ya que se puede dar el caso de que el plan no tenga codigos cargados
            let codesId: number | undefined = 1
            if(largeCodes){
                codesId = data[0].codes[largeCodes - 1].codesId
            }
            return { 
                ...state,
                dataInsurancePlan: data,
                loading: false,
                codeId: codesId ? codesId : 1
            }
        case 'getInsurancePlan':
            return { 
                ...state, 
                dataInsurancePlan: action.payload,
                loading: false
            }
        case 'addCodes':
            // Agrego id para usarlo de key en la tabla de codigos
            state.planItems.itemsCodesPlan.codesId = state.codeId + 1
            // Al agregar un codigo le seteo el codeStateId en 1 (el estado 1 es el Activo)
            state.planItems.itemsCodesPlan.codeStateId = 1
            state.planItems.codes.push(state.planItems.itemsCodesPlan)
            state.planItems.itemsCodesPlan.add = true
            return {
                ...state,
                openModalAddCodes: false,
                buttonDisabled: false,
                codeId: state.codeId + 1
            }
        case 'editCode':
            state.planItems.itemsCodesPlan = action.payload
            return {
                ...state
            }
        case 'deleteCode':
            const planCodeToDelete = state.planItems.codes.find((code) => code.codesId === action.payload)
            if(planCodeToDelete){
                // seteo el estado 3 (eliminado)
                planCodeToDelete.codeStateId = 3
            }
            // const codesToShow = state.planItems.codes.filter((code) => code.codesId !== action.payload)
            // state.planItems.codes = codesToShow
            return {
                ...state,
                openConfirmDeleteCodePlans: false
            }
        case 'handleChangePlanItems':
            state.planItems[action.payload.name] = action.payload.value
            return {
                ...state,
                planItems: state.planItems,
            }
        case 'handleChangePlanCodes':
            if(action.payload.name === 'description' || action.payload.name === 'code'){
                state.planItems.itemsCodesPlan[action.payload.name] = action.payload.value
            } else {
                state.planItems.itemsCodesPlan[action.payload.name] = Number(action.payload.value)
            }

            return {
                ...state,
                planItems: state.planItems,
            }
        case 'handleChangePlanStatus':
            state.planItems.planStateId = action.payload
            return {
                ...state,
                planItems: state.planItems,
                buttonDisabled: false
            }
        case 'handleChangeChapter':
            state.planItems.itemsCodesPlan.chapterId = action.payload
            return {
                ...state,
                planItems: state.planItems,
                buttonDisabled: false
            }
        case 'openModalAddCodes':
            return { 
                ...state, 
                openModalAddCodes: action.payload.open,
                add: action.payload.add
            }
        case 'openConfirmDeleteCodePlans':
            return { 
                ...state,
                openConfirmDeleteCodePlans: action.payload
            }
        case 'idPlanCodeDelete':
            return { 
                ...state, 
                idPlanCodeDelete: action.payload,
            }
        case 'loadingDatosDemograficos':
            return {
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'handleClickCheck':
            state.items.height = action.payload ? 'S/N' : ''
            return {
                ...state,
                buttonDisabled: false
            }
        default:
            return state;
    }
}