import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react"
import PermisosContext from "../context/Permisos/PermisosContext"
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import 'moment/locale/es';
import Loader from '../Loader';
import Alert from '../Alert';

const ListButton = styled(ListItemButton)(() => ({
    background: '#F7F9FF',
    marginTop: 8,
    '&:hover': {
        background: '#F7F9FF',
    },
}));

const GroupButton = styled(ListItemButton)(() => ({
    background: '#64AAB2',
    color: '#fff',
    '&:hover': {
        background: '#64AAB2',
    },
}));

const Index = () => {

    const {
        getPermisos,
        getRoles,
        handleChangeSelect,
        addPermisos,
        closeAlert,
        clearValues,
        state 
    } = useContext(PermisosContext)

    useEffect(() => {
        if(!state.rolesValue.rolesId){
            getRoles()
        }
        if(state.rolesValue.rolesId){
            getPermisos(state.rolesValue.rolesId)
        }
    }, [state.rolesValue.rolesId])

    const [checked, setChecked] = useState<number[]>([]);
    const [open, setOpen] = useState(true);
    const [groupName, setGroupName] = useState({
        usuarios: true,
        circulos: true,
        accesos: true,
        obrasSociales: true,
        sitioDeUsuarios: true,
        contactos: true,
        otros: true,
    });

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickOpenPermisos = (name: string) => {
        switch (name) {
            case 'Usuarios':
                setGroupName({...groupName, usuarios: !groupName.usuarios})
                break;
            case 'Circulos':
                setGroupName({...groupName, circulos: !groupName.circulos})
                break;
            case 'Accesos':
                setGroupName({...groupName, accesos: !groupName.accesos})
                break;
            case 'Obras sociales':
                setGroupName({...groupName, obrasSociales: !groupName.obrasSociales})
                break;
            case 'Sitio de usuarios':
                setGroupName({...groupName, sitioDeUsuarios: !groupName.sitioDeUsuarios})
                break;
            case 'Contactos':
                setGroupName({...groupName, contactos: !groupName.contactos})
                break;
            case 'Otros':
                setGroupName({...groupName, otros: !groupName.otros})
                break;
        }
    };

    const handleToggle = (idPermiso: number, idGroup: number) => () => {
        const currentIndex = checked.indexOf(idPermiso);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(idPermiso);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        state.data.map(d => {
            // verifico que el grupo tenga elementos y que el id corresponda al que selecciono en el check
            if(d.policies.length && idGroup === d.groupPoliciesId){
                return d.policies.map(p => {
                    if(idPermiso === p.policiesId){
                        // cambio el estado del permiso seleccionado en el check
                        p.status = !p.status
                    }
                })
            }
        })

        setChecked(newChecked);
    };

    if(state.loading){
        return <Loader />
    }

    const listPermisos = () => {
        return <Collapse in={open} timeout="auto" unmountOnExit>
            <List dense sx={{ width: '100%' }}>
                {
                    state.data.map((permisos) => {
                        // verifico uno por uno los permisos disponibles para saber si se tienen que mostrar en el collapse o no
                        const openCollapse = (permisos.name === 'Usuarios' && groupName.usuarios) 
                            || (permisos.name === 'Circulos' && groupName.circulos)
                            || (permisos.name === 'Accesos' && groupName.accesos)
                            || (permisos.name === 'Obras sociales' && groupName.obrasSociales)
                            || (permisos.name === 'Sitio de usuarios' && groupName.sitioDeUsuarios)
                            || (permisos.name === 'Contactos' && groupName.contactos)
                            || (permisos.name === 'Otros' && groupName.otros)
                        return (
                            <div key={permisos.groupPoliciesId}>
                                {
                                    permisos.policies.length ?
                                        <GroupButton sx={{background: '#64AAB2',  color: '#fff', fontWeight: 'bolder'}} onClick={() => handleClickOpenPermisos(permisos.name)}>
                                            <ListItemText primary={permisos.name} />
                                            {openCollapse ? <ExpandLess /> : <ExpandMore />}
                                        </GroupButton>
                                    : null
                                }
                                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                                    {
                                        permisos.policies.map((p) => {
                                            return <ListItem
                                                key={p.policiesId}
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleToggle(p.policiesId, permisos.groupPoliciesId)}
                                                        // checked={checked.indexOf(p.policiesId) !== -1}
                                                        checked={p.status}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemText primary={p.description} />
                                                </ListItemButton>
                                            </ListItem>
                                        })
                                    }
                                </Collapse>
                            </div>
                        );
                    })
                }
            </List>
        </Collapse> 
    }

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Permisos</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                    <FormControl fullWidth variant='standard' margin='dense'>
                        <InputLabel>Rol</InputLabel>
                        <Select
                            className='widthField'
                            label="Rol"
                            name='rolesId'
                            value={`${state.rolesValue.rolesId ? state.rolesValue.rolesId : ''}`}
                            onChange={handleChangeSelect}
                        >
                            {state.roles.map((r) => (
                                <MenuItem key={r.rolesId} value={r.rolesId}>{r.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <ListButton onClick={state.data.length ? () => handleClick() : () => {}}>
                        <ListItemText primary="Permisos" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListButton>
                    {listPermisos()}
                </Grid>
            </Grid>
            <Grid container justifyContent='flex-end' sx={{mt: 6}}>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='outlined' onClick={clearValues}>Cancelar</Button>
                </Grid>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} onClick={() => addPermisos(checked)} disabled={!checked.length}>Guardar</Button>
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Permiso modificado correctamente!'} />
        </Box>
    )
}

export default Index