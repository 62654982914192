import { createContext } from 'react'
import { CirculoState } from '../../../interfaces/Circulos';

export interface ContextProps {
    state: CirculoState,
    getCirculos: () => void
    circlesNextPage: () => void
    getCirculoById: (id: string | undefined) => void
    editCirculo: (id: string | undefined) => void
    deleteCirculo: (id: string | undefined) => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getCalles: () => void
    handleChangeUbicacion: (value: number, name: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    addCirculo: () => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    closeAlert: () => void
    clearValues: () => void
    getFilterCircles: () => void
    clearFilter: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CirculoContext = createContext<ContextProps>({} as ContextProps);

export default CirculoContext;