import { RolesState, RolesGet, Profiles } from '../../../interfaces/Roles';

type Action = {type: 'getRoles', payload: RolesGet[]}
    | {type: 'getProfiles', payload: Profiles[]}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'setIdSelected', payload: number}
    | {type: 'handleChangeInput', payload: {value: string}}
    | {type: 'clearValues', payload: RolesGet}
    | {type: 'openAlert', payload: boolean}
    | {type: 'loading', payload: boolean}
    | {type: 'messageError', payload: string[]}
    | {type: 'handleChangeSelect', payload: {name: string, value: number | string}}

export default function RolesReducer (state: RolesState, action: Action): RolesState {
    switch (action.type) {
        case 'getRoles':
            return { 
                ...state, 
                data: action.payload
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'setIdSelected':
            return { 
                ...state,
                idSelected: action.payload
            }
        case 'handleChangeInput':
            state.items.description = action.payload.value
            return { 
                ...state,
                items: state.items
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'loading':
            return { 
                ...state,
                loading: action.payload
            }
        case 'messageError':
            return { 
                ...state,
                messageError: action.payload
            }
        case 'getProfiles':
            return { 
                ...state, 
                profiles: action.payload
            }
        case 'handleChangeSelect':
            state.items.profileId = typeof action.payload.value === 'number' ? action.payload.value : 0
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        default:
            return state;
    }
}