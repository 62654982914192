import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Link, useParams } from 'react-router-dom'
import { Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext, useEffect } from "react"
import CirculoContext from "../context/Circulos/CirculoContext"
import Divider from '@mui/material/Divider';
import 'moment/locale/es';
import Loader from '../Loader';
import Confirm from '../../components/Confirm'
import Alert from '../Alert';
import ListPermissions from '../../utils/ListPermissions';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../interfaces/Login';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Detail = () => {

    const { 
        getCirculoById,
        getProvincias, 
        getDepartamentos, 
        getLocalidades,
        handleChangeUbicacion, 
        handleChangeInput, 
        editCirculo,
        deleteCirculo,
        handleOpenConfirm,
        handleCloseConfirm,
        closeAlert,
        handleClickCheck,
        state 
    } = useContext(CirculoContext)

    const {id} = useParams();

    useEffect(() => {
        if(!state.items.idFopbaInsider){
            getCirculoById(id)        
            getProvincias();
        } else if(state.items.idFopbaInsider && !state.loadingDatosDemograficos){
            getDepartamentos();
            getLocalidades();
        }
    }, [state.items.idFopbaInsider, state.items.state, state.items.department])

    
    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    // recorro array de calles y me fijo si hay algun nombre repetido y lo saco del array
    let arrayCalles: any[] = [];
    if(state.calles.length){
        state.calles.map(c => {
            if(!arrayCalles.includes(c.nombre)){
                arrayCalles.push(c.nombre)
            }
        })
    }

    if(state.loading){
        return <Loader />
    }

    const fieldsDisabled = !ListPermissions().editarCirculo?.status;

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.items.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.items.department))
    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    if(Number(localidadSelected?.postalCode) !== 0){
        state.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
    }

    //deshabilito la edición de los campos de autoridad para el perfil admin-circulo
    const fieldDisabledAuthorities = decoded.profileId === 4

    const buttonsDisabled = state.items.circleState?.description !== 'Activo'

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Datos Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Nombre"
                        name='name'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.name && state.inputRequiredEmpty} 
                        value={state.items.name}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Código"
                        name='idFopbaInsider'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.idFopbaInsider && state.inputRequiredEmpty} 
                        value={state.items.idFopbaInsider}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Cuit"
                        name='cuit'
                        required
                        inputProps={{ maxLength: 13 }}
                        variant="standard"
                        className='widthField'
                        error={!state.items.cuit && state.inputRequiredEmpty} 
                        value={state.items.cuit}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos Demográficos</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            className="widthField"
                            value={{
                                statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                name: provinciaSelected?.name ? provinciaSelected.name : ''
                            }}
                            disabled={fieldsDisabled}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.provincias}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.state && state.inputRequiredEmpty}
                                key={params.id}
                                required
                                label="Provincia"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            value={{
                                departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                name: departamentSelected?.name ? departamentSelected.name : ''
                            }}
                            disabled={fieldsDisabled}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.departamentos}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.department && state.inputRequiredEmpty}
                                key={params.id}
                                required
                                label="Partido"
                                />
                            )}
                            className="widthField"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            value={{
                                townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                            }}
                            disabled={fieldsDisabled}
                            onOpen={!state.localidades.length ? getLocalidades : () => {}}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={localidadesFormat}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.town && state.inputRequiredEmpty}
                                key={params.id}
                                required
                                label="Localidad"
                                />
                            )}
                            className="widthField"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Calle"
                        name="street"
                        required
                        variant="standard"
                        className="widthField"
                        value={state.items.street}
                        error={!state.items.street && state.inputRequiredEmpty}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Número"
                        name='height'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.height && state.inputRequiredEmpty} 
                        value={state.items.height && state.items.height !== "0" ? state.items.height : ''}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                    <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.height === 'S/N'} />} label="Sin Número" />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Piso"
                        name='floor'
                        variant="standard"
                        className='widthField'
                        inputProps={{maxLength: "2"}}
                        value={state.items.floor && state.items.floor !== "0" ? state.items.floor : ''}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Depto"
                        name='apartment'
                        variant="standard"
                        className='widthField'
                        value={state.items.apartment ? state.items.apartment : ''}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Código postal"
                        name='postalCode'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.postalCode && state.inputRequiredEmpty} 
                        value={state.items.postalCode ? state.items.postalCode : ''}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='phone'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.phone && state.inputRequiredEmpty} 
                        value={state.items.phone && state.items.phone !== "0" ? state.items.phone : ''}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='cellPhone'
                        variant="standard"
                        className='widthField'
                        value={state.items.cellPhone && state.items.cellPhone !== "0" ? state.items.cellPhone : ''}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Email"
                        name='email'
                        required
                        type='email'
                        variant="standard"
                        className='widthField'
                        error={!state.items.email && state.inputRequiredEmpty} 
                        value={state.items.email}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Autoridades</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre de Autoridad: "
                        name='authorityName'
                        variant="standard"
                        className='widthField'
                        inputProps={{maxLength: "50"}}
                        disabled={fieldDisabledAuthorities}
                        value={state.items.authorityName ? state.items.authorityName : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre de Secretaria/o: "
                        name='secretaryName'
                        variant="standard"
                        className='widthField'
                        inputProps={{maxLength: "50"}}
                        disabled={fieldDisabledAuthorities}
                        value={state.items.secretaryName ? state.items.secretaryName : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                <TextField
                        margin="normal"
                        label="Observaciones: "
                        name='observation'
                        variant="outlined"
                        className='widthField'
                        disabled={fieldDisabledAuthorities}
                        multiline
                        rows={3}
                        inputProps={{maxLength: "500"}}
                        value={state.items.observation ? state.items.observation : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <Grid mt={4}>
                {state.messageError.map((message, index) => (
                    <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                ))}
            </Grid>
            <Grid container justifyContent='space-between' sx={{mt: 6}}>
                {/* muestro boton eliminar si esta habilitado en los permisos */}
                {ListPermissions().eliminarCirculo?.status &&
                    <Grid item md={2}>
                        <Button fullWidth type='button' variant='contained' color='error' onClick={handleOpenConfirm} disabled={buttonsDisabled}>Eliminar</Button>
                    </Grid>
                }
                <Grid container item md={ListPermissions().eliminarCirculo?.status ? 6 : 12} justifyContent='end'>
                    <Grid item md={ListPermissions().eliminarCirculo?.status ? 6 : 3}>
                        <Button fullWidth type='button' variant='outlined'>
                            <Link to='/circulos' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                        </Button>
                    </Grid>
                    {ListPermissions().editarCirculo?.status &&
                        <Grid item md={ListPermissions().eliminarCirculo?.status ? 6 : 3}>
                            <Button fullWidth type='button' disabled={state.buttonDisabled || buttonsDisabled} variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} onClick={() => editCirculo(id)}>Guardar</Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Confirm
                openConfirm={state.openConfirm}
                handleCloseConfirm={handleCloseConfirm}
                handleConfirmId={deleteCirculo}
                handleConfirm={() => {}}
                idAEliminar={id}
                title='¿Estas seguro?'
                description='Si eliminas este Circulo no podras volver a recuperarlo luego'
            />
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Circulo editado correctamente'} />
        </Box>
    )
}

export default Detail