import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '../Pagination';
import { useContext, useEffect } from "react"
import InsuranceContext from "../context/Insurances/InsuranceContext"
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom'
import 'moment/locale/es';
import Loader from '../Loader';
import Alert from '../Alert';
import ModalAddCodes from './ModalAddCodes';
import Confirm from '../Confirm';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Detail = () => {

    const { 
        closeAlert,
        getInsurancePlanById,
        getChapters,
        getPlanStatus,
        handleChangePlanItems,
        handleChangePlanCodes,
        handleCloseConfirm,
        handleOpenConfirm,
        addCodes,
        editCode,
        deleteCode,
        editPlans,
        editPlansCodes,
        deletePlans,
        openModalAddCodes,
        closeModalAddCodes,
        handleChangePlanStatus,
        handleChangeChapter,
        handleOpenConfirmDeleteCodePlans,
        handleCloseConfirmDeleteCodePlans,
        state 
    } = useContext(InsuranceContext)

    const {idInsurance, idPlan} = useParams();

    useEffect(() => {
        getInsurancePlanById(Number(idInsurance), idPlan)
        getChapters()
        getPlanStatus()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading){
        return <Loader />
    }

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Modificar Plan</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Button variant='contained' sx={{background: '#64AAB2', '&:hover': {background: '#73c9d3'}}} onClick={() => openModalAddCodes(true)}>Agregar Nomenclador</Button>
            <Grid container mt={3} mb={3} spacing={4}>
                <Grid item xs={6} sm={4} md={4}>
                    <TextField
                        margin="dense"
                        label="Código de Plan"
                        name='code'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.planItems.code && state.planErrorEdit} 
                        value={state.planItems.code ? state.planItems.code : ''}
                        onChange={handleChangePlanItems}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <FormControl fullWidth variant='standard' margin='dense' required error={!state.planItems.planStateId && state.planErrorEdit}>
                        <InputLabel>Estado</InputLabel>
                        <Select
                            label="Estado"
                            name='planStateId'
                            value={state.planItems.planStateId ? state.planItems.planStateId : ''}
                            onChange={handleChangePlanStatus}
                        >
                            {state.planStatusList.map((s) => (
                                <MenuItem key={s.codeStatesId} value={s.codeStatesId}>{s.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <TextField
                        margin="dense"
                        label="Descripción"
                        name='description'
                        variant="standard"
                        fullWidth
                        required
                        error={!state.planItems.description && state.planErrorEdit} 
                        value={state.planItems.description}
                        onChange={handleChangePlanItems}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='flex-end' mb={3}>
                <Grid item>
                    <Button fullWidth type='button' variant='contained' sx={{background: '#64AAB2', '&:hover': {background: '#73c9d3'}}} onClick={() => {editPlans(Number(idInsurance), Number(idPlan))}}>Guardar Plan</Button>
                </Grid>
            </Grid>
            {state.planItems.codes.length > 0 &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Nomenclador</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Descripción</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Capítulo</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Arancel</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Porcentaje Afiliado</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Opciones</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 
                                ? state.planItems.codes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : state.planItems.codes
                            ).map((row) => {
                                const chapterDescription = state.chapterList.map((chapter) => {
                                    if(row.chapterId === chapter.chaptersId){
                                        return chapter.description
                                    }
                                })
                                const statusDescription = state.planStatusList.map((status) => {
                                    if(row.codeStateId === status.codeStatesId){
                                        return status.description
                                    }
                                })
                                return <StyledTableRow
                                    key={row.codesId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell sx={{padding: '12px'}} component="th" scope="row" align="left">
                                        {row.code}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.description}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{chapterDescription}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.customsDuty}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.member}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{statusDescription}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <EditIcon sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}} onClick={() => {editCode(row); openModalAddCodes(false)}}/>
                                        <DeleteIcon sx={{cursor: 'pointer', color: '#B92F2F'}} onClick={() => handleOpenConfirmDeleteCodePlans(Number(row.codesId))} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={state.data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage='Filas por Página'
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
            <Grid container justifyContent='space-between' sx={{mt: 6}}>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='contained' color='error' onClick={handleOpenConfirm}>Eliminar Plan</Button>
                </Grid>
                {state.planItems.codes.length ?
                    <Grid container item justifyContent='end' md={6}>
                        <Grid item md={4}>
                            <Button fullWidth type='button' variant='outlined'>
                                <Link to='/planes' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button fullWidth type='button' variant='contained' sx={{background: '#64AAB2', '&:hover': {background: '#73c9d3'}}} onClick={() => {editPlansCodes(Number(idInsurance), Number(idPlan))}}>Guardar Nomenclador</Button>
                        </Grid>
                    </Grid>
                : null}
            </Grid>
            <Confirm
                openConfirm={state.openConfirm}
                handleCloseConfirm={handleCloseConfirm}
                handleConfirmId={() => {}}
                handleConfirm={() => deletePlans(Number(idInsurance), Number(idPlan))}
                title='¿Estas seguro?'
                description='Si eliminas este plan no podrás volver a recuperarlo luego'
            />
            <Confirm
                openConfirm={state.openConfirmDeleteCodePlans}
                handleCloseConfirm={handleCloseConfirmDeleteCodePlans}
                handleConfirmId={() => {}}
                handleConfirm={() => deleteCode(state.idPlanCodeDelete)}
                title='¿Estas seguro?'
                description='Si eliminas este nomenclador no podrás volver a recuperarlo luego'
            />
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={state.add ? 'Plan agregado correctamente' : 'Plan editado correctamente'} />
            <ModalAddCodes
                chapterList={state.chapterList}
                openModal={state.openModalAddCodes} 
                handleCloseModal={closeModalAddCodes} 
                handleChangePlanCodes={handleChangePlanCodes} 
                handleChangeChapter={handleChangeChapter} 
                planItems={state.planItems}
                addCodes={addCodes}
                add={state.add}
            />
        </Box>
    )
}

export default Detail