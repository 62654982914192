import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

interface Props {
    openAlert: boolean
    handleClose: () => void
    severity: AlertColor
    text: string
    time?: number
}

const AlertComponent = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alert = ({handleClose, openAlert, severity, text, time}: Props) => {

    return (
        <div>
            <Snackbar open={openAlert} autoHideDuration={time ? time : 3000} onClose={handleClose}>
                <AlertComponent onClose={handleClose} severity={severity} sx={{ width: '100%', ml: 7 }}>
                    {text}
                </AlertComponent>
            </Snackbar>
        </div>
    )
}

export default Alert