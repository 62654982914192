import { createContext } from 'react'
import { RolesState } from '../../../interfaces/Roles';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: RolesState
    getRoles: () => void
    deleteRol: () => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    setIdSelected: (id: number) => void
    addRol: () => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    editRoles: (id: string | undefined) => void
    closeAlert: () => void
    clearField: () => void
    getProfiles: () => void
    handleChangeSelect: (event:SelectChangeEvent<number | string>) => void
}

const LoginContext = createContext<ContextProps>({} as ContextProps);

export default LoginContext;