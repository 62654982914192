import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext, useEffect } from "react"
import CirculoContext from "../context/Circulos/CirculoContext"
import Divider from '@mui/material/Divider';
import 'moment/locale/es';
import Loader from '../Loader';
import Alert from '../Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Add = () => {

    const { 
        getProvincias, 
        getDepartamentos, 
        getLocalidades,
        handleChangeUbicacion, 
        handleChangeInput, 
        addCirculo,
        closeAlert,
        clearValues,
        handleClickCheck,
        state 
    } = useContext(CirculoContext)

    useEffect(() => {
        clearValues()
        getProvincias()
    }, [])

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    if(state.loading){
        return <Loader />
    }

    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    if(Number(localidadSelected?.postalCode) !== 0){
        state.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
    }

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Datos Generales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Nombre"
                        required
                        name='name'
                        variant="standard"
                        className='widthField'
                        error={!state.items.name && state.circuloAgregado} 
                        value={state.items.name}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Código"
                        required
                        name='idFopbaInsider'
                        variant="standard"
                        className='widthField'
                        error={!state.items.idFopbaInsider && state.circuloAgregado} 
                        value={state.items.idFopbaInsider ? state.items.idFopbaInsider : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Cuit"
                        name='cuit'
                        required
                        inputProps={{ maxLength: 13 }}
                        variant="standard"
                        className='widthField'
                        error={!state.items.cuit && state.circuloAgregado} 
                        value={state.items.cuit ? state.items.cuit : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos Demográficos</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            className="widthField"
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.provincias}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.state && state.circuloAgregado}
                                key={params.id}
                                required
                                label="Provincia"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            onOpen={getDepartamentos}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                            }
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            options={state.departamentos}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.department && state.circuloAgregado}
                                key={params.id}
                                required
                                label="Partido"
                                />
                            )}
                            className="widthField"
                            disabled={!state.items.state}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            onOpen={getLocalidades}
                            onChange={(event, newValue) =>
                                handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                            }
                            getOptionLabel={(option) => {
                                return option.name
                            }}
                            options={localidadesFormat}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!state.items.town && state.circuloAgregado}
                                key={params.id}
                                required
                                label="Localidad"
                                />
                            )}
                            className="widthField"
                            disabled={!state.items.department}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Calle"
                        name="street"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.street && state.circuloAgregado}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Número"
                        name='height'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.height && state.circuloAgregado} 
                        value={state.items.height ? state.items.height : ''}
                        onChange={handleChangeInput}
                    />
                    <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.height === 'S/N'} />} label="Sin Número" />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Piso"
                        name='floor'
                        variant="standard"
                        className='widthField'
                        inputProps={{maxLength: "2"}}
                        value={state.items.floor && state.items.floor !== "0" ? state.items.floor : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Depto"
                        name='apartment'
                        variant="standard"
                        className='widthField'
                        value={state.items.apartment ? state.items.apartment : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                    <TextField
                        margin="dense"
                        label="Código Postal"
                        name='postalCode'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.postalCode && state.circuloAgregado} 
                        value={state.items.postalCode ? state.items.postalCode : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Datos de Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='phone'
                        required
                        variant="standard"
                        className='widthField'
                        error={!state.items.phone && state.circuloAgregado} 
                        value={state.items.phone ? state.items.phone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Teléfono / Celular"
                        name='cellPhone'
                        variant="standard"
                        className='widthField'
                        value={state.items.cellPhone ? state.items.cellPhone : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="dense"
                        label="Email"
                        name='email'
                        required
                        variant="standard"
                        className='widthField'
                        type='email'
                        error={!state.items.email && state.circuloAgregado} 
                        value={state.items.email}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <h4>Autoridades</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre de Autoridad: "
                        name='authorityName'
                        variant="standard"
                        className='widthField'
                        inputProps={{maxLength: "50"}}
                        value={state.items.authorityName ? state.items.authorityName : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre de Secretaria/o: "
                        name='secretaryName'
                        variant="standard"
                        className='widthField'
                        inputProps={{maxLength: "50"}}
                        value={state.items.secretaryName ? state.items.secretaryName : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                <TextField
                        margin="normal"
                        label="Observaciones: "
                        name='observation'
                        variant="outlined"
                        className='widthField'
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{maxLength: "500"}}
                        value={state.items.observation ? state.items.observation : ''}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ? 
                    state.messageError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                    ))
                : null}
            </Grid> 
            <Grid container justifyContent='flex-end' sx={{mt: 6}}>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='outlined'>
                        <Link to='/circulos' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                    </Button>
                </Grid>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} onClick={addCirculo}>Guardar</Button>
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Circulo agregado correctamente'} />
        </Box>
    )
}

export default Add