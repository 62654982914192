import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Link } from 'react-router-dom'
import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom";
import RolesContext from "../context/Roles/RolesContext"
import Alert from '../Alert';
import { Select } from '@mui/material';

const Add = () => {

    const { 
        state,
        addRol,
        handleChangeInput,
        editRoles,
        closeAlert,
        clearField,
        getProfiles,
        handleChangeSelect
    } = useContext(RolesContext)

    useEffect(() => {
        getProfiles()
        if(!id){
            clearField()
        }
    }, [])
    const { id } = useParams();

    if(id){
        state.params = window.location.search.split('&') 
        state.description = state.params[0].substring(13) 
        state.profileIdName = state.params[1].substring(10) 
    }
    // si el rol llega a tener algun espacio le saco el %20 que trae desde la url
    state.descriptionEdit = state.description.replace("%20", " ")

    return (
        <Box component="form" sx={{ display: "flex", flexDirection:'column', margin: '30px 15px 0px 15px' }}>
            <h4>{id ? 'Editar Rol' : 'Agregar Rol'}</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 3}} />
            <TextField
                margin="dense"
                label="Descripción"
                name='description'
                variant="outlined"
                fullWidth
                value={ id && !state.items.description ? state.descriptionEdit : state.items.description}
                onChange={(e) => handleChangeInput(e)}
            />
            <Box>
                <h4>Agregar Rol a Perfil</h4>
                <FormControl
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        required
                    >
                        <InputLabel>Perfil</InputLabel>
                        <Select
                            className="widthField"
                            label="Perfil"
                            name="profileId"
                            value={(id && !state.items.profileId) ? state.profileIdName : (state.items.profileId ? String(state.items.profileId) : undefined)}
                            onChange={(e) => handleChangeSelect(e)}
                            required
                            fullWidth
                        >
                            {state.profiles.map((profile) => (
                                <MenuItem key={profile.profilesId} value={profile.profilesId}>
                                    {profile.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ? 
                    state.messageError.map((message, index) => (
                        <Typography color='error' variant="body1" key={index}>{message}</Typography>
                    ))
                : null}
            </Grid>
            <Divider sx={{borderColor: '#64AAB2', mt: 1.5}} /> 
            <Grid container justifyContent='flex-end' sx={{mt: 2}}>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='outlined'>
                        <Link to='/roles' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                    </Button>
                </Grid>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} onClick={id ? () => editRoles(id) : () => addRol()}>Guardar</Button>
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={id ? 'Rol editado correctamente' : 'Rol agregado correctamente'} />
        </Box>
    )
}

export default Add