import { CirculoItems, CirculoState } from '../../../interfaces/Circulos';
import { CellPhoneConGuiones, CellPhoneFormat } from '../../../utils/CelPhoneFormat';
import { CuitConGuiones, CuitFormat, CuitSinGuiones } from '../../../utils/CuitFormat';

type Action = {type: 'getCirculos', payload: CirculoItems[]}
    | {type: 'circlesNextPage', payload: CirculoItems[]}
    | {type: 'getCirculoById', payload: CirculoItems}
    | {type: 'messageError', payload: string[]}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'circuloAgregado', payload: boolean}
    | {type: 'inputRequiredEmpty', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getCalles', payload: []}
    | {type: 'clearValues', payload: CirculoItems}
    | {type: 'changeUbicacion', payload: {name: string, value: number}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeSelect', payload: {name: string, value: string}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'openAlert', payload: boolean}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'clearFilter'}
    | {type: 'getFilterCircles', payload: CirculoItems[]}
    | {type: 'filtered', payload: boolean}
    | {type: 'handleClickCheck', payload: boolean}

export default function CirculoReducer (state: CirculoState, action: Action): CirculoState {
    switch (action.type) {
        case 'getCirculos':
            return { 
                ...state, 
                data: action.payload
            }
        case 'circlesNextPage':
            if(action.payload.length !== 50){
                state.noMoreResults = true
            }
            // pusheo los resultados de la nueva pagina al state de data
            action.payload.map((item) => {
                return state.data.push(item)
            })
            return { 
                ...state
            }
        case 'getCirculoById':
            state.items = action.payload
            state.items.cuit = CuitConGuiones(action.payload.cuit)
            return { 
                ...state, 
                items: state.items
            }
        case 'messageError':
            return { 
                ...state,
                messageError: action.payload
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getCalles':
            return { 
                ...state, 
                calles: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'changeUbicacion':
            state.items[action.payload.name] = action.payload.value
            const fieldsChange = {...state.fieldsModify, [action.payload.name]: action.payload.value}

            return { 
                ...state, 
                items: state.items,
                buttonDisabled: false,
                fieldsModify: fieldsChange
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value
            let fields = {...state.fieldsModify}
        
            if(action.payload.name === 'cuit'){
                state.items.cuit = CuitFormat(action.payload.value)
                fields = {...state.fieldsModify, cuit: CuitSinGuiones(action.payload.value)}
            }else if(action.payload.name === 'cellPhone') {
                state.items.cellPhone = CellPhoneFormat(action.payload.value)
                fields = {...state.fieldsModify, cellPhone: CellPhoneFormat(action.payload.value)}
            }else if(action.payload.name === 'phone'){
                state.items.phone = CellPhoneFormat(action.payload.value)
                fields = {...state.fieldsModify, phone: CellPhoneFormat(action.payload.value)}
            } else {
                fields = {...state.fieldsModify, [action.payload.name]: action.payload.value}
            }
            if(action.payload.name === 'postalCode'){
                state.items.postalCode = action.payload.value.replaceAll('-', '')
                fields = {...state.fieldsModify, postalCode: action.payload.value.replaceAll('-', '')}
            }
            if(action.payload.name === 'height'){
                state.items.height = action.payload.value.replaceAll('-', '')
                fields = {...state.fieldsModify, height: action.payload.value.replaceAll('-', '')}
            }

            return {
                ...state,
                items: state.items,
                buttonDisabled: false,
                fieldsModify: fields
            }
        case 'handleChangeSelect':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'circuloAgregado':
            return {
                ...state,
                circuloAgregado: action.payload
            }
        case 'inputRequiredEmpty':
            return {
                ...state,
                inputRequiredEmpty: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'loadingDatosDemograficos':
            return {
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'getFilterCircles':
            return { 
                ...state, 
                data: action.payload,
                loading: false
            }
        case 'clearFilter':
            return { 
                ...state,
                circleNameFilter: '',
                circleIdFilter: ''
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'handleClickCheck':
            state.items.height = action.payload ? 'S/N' : ''
            return {
                ...state,
                buttonDisabled: false
            }
        default:
            return state;
    }
}