import { Link, useParams } from "react-router-dom";
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext";
import { useContext, useEffect } from "react";
import {Checkbox, Divider, Grid, TextField, ListItemText, Box, Button, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Confirm from "../Confirm";
import Loader from "../Loader";

const ReRegistrationDetails = () => {
  const { state, handleChangeDatePicker, clearValues, handleChangeInput, getGeneralRulesById, deleteGeneralRule, getInsurances, handleOpenConfirm, handleCloseConfirm, editGeneralRule, handleToggle} = useContext(EmpadronamientoContext);

  const { generalRulesId: generalRulesId } = useParams();

  useEffect(() => {
    getGeneralRulesById(Number(generalRulesId));
    getInsurances();
    clearValues();
  }, []);
  
  //
  const insuranceCheckedTrue = state.generalRuleInsurancesDetail.filter(item => item.status === true);

  if (!state.generalRulesDetails || state.loading) {
    return <Loader />;
  }

  return (
    <Box sx={{ margin: "20px 15px 0px 15px" }}>
      <h4>
        Detalles de Regla: <i>{state.generalRulesDetails.description}</i>
      </h4>
      <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
      <Grid container justifyContent={"center"} columnGap={13} rowGap={5}>
        <Grid item xs={12} sm={12} md={2}>
          <h5>Nombre: </h5>
          <TextField
            name="description"
            label="Nombre"
            variant="standard"
            fullWidth
            value={state.generalRulesDetails.description}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <h5>Obra/s Social/es</h5>
          <FormControl
            fullWidth
            variant="standard"
            margin="normal"
            required
            sx={{ padding: "0", margin: "0" }}
          >
            <InputLabel>Obras Sociales</InputLabel>
            <Select
              className="widthField"
              label="Obras Sociales"
              name="generalRuleInsurancesDetail"
              multiple
              renderValue={(selected) =>
                selected
                  .map(
                    (e) =>
                      state.insuranceData.find((i) => i.insurancesId === e)
                        ?.name
                  )
                  .join(", ")
              }
              value={insuranceCheckedTrue.map(item => item.insurancesId)}
              fullWidth
              sx={{ padding: "0", margin: "0" }}
            >
               {/* Seleccionar todas las obras sociales
              <MenuItem>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Seleccionar todas"
                  />
                </FormGroup>
              </MenuItem>  */}
              {state.insuranceData.map((i) => (
                <MenuItem key={i.insurancesId} value={i.insurancesId}>
                  <Checkbox
                    onChange={(e) => handleToggle(Number(i.insurancesId), e.target.checked)} 
                    checked={insuranceCheckedTrue.findIndex(item => item.insurancesId === i.insurancesId && item.status) > -1}
                  />
                  <ListItemText primary={`(${i.idFopbaInsider}) - ${i.name}`}/>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <h5>Fecha "desde": </h5>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Desde"
              value={state.generalRulesDetails.from}
              onChange={(newValue) =>
                handleChangeDatePicker(
                  moment(newValue).format("YYYY-MM-DD"),
                  "fromDate"
                )
              }
              renderInput={(params) => (
                <TextField
                  sx={{ padding: "0", margin: "0" }}
                  {...params}
                  name="fromDate"
                  variant="standard"
                  className="widthField"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <h5>Fecha "hasta": </h5>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Hasta"
              value={state.generalRulesDetails.to}
              onChange={(newValue) =>
                handleChangeDatePicker(
                  moment(newValue).format("YYYY-MM-DD"),
                  "toDate"
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ padding: "0", margin: "0" }}
                  name="toDate"
                  variant="standard"
                  className="widthField"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={() => editGeneralRule(Number(generalRulesId))}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: "#64AAB2", mt: 3 }} />
      <Box display={"flex"} flexDirection={"column"} mt={4} width={"30%"}>
        <Button
          variant="contained"
          color="error"
          sx={{ mb: 2 }}
          fullWidth
          onClick={handleOpenConfirm}
        >
          Eliminar
        </Button>
        <Link to={`/reempadronamiento`}>
          <Button variant="outlined" fullWidth>
            Volver
          </Button>
        </Link>
      </Box>
      <Confirm
        openConfirm={state.openConfirm}
        handleCloseConfirm={handleCloseConfirm}
        handleConfirmId={deleteGeneralRule}
        handleConfirm={() => {}}
        idAEliminar={generalRulesId}
        title="¿Estás seguro?"
        description="Si eliminas esta regla no podrás volver a recuperarla luego"
      />
    </Box>
  );
};
export default ReRegistrationDetails;
