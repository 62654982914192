export const CellPhoneSinGuiones = (cellPhone: string) => {
    
    const cellPhoneSinGuiones = cellPhone.replaceAll('-', '')

    return cellPhoneSinGuiones
}

export const CellPhoneConGuiones = (cellPhone: string) => {
    
    const cellPhoneLength = cellPhone.length
    const cellPhonePartOne = cellPhone.slice(0, 4)
    const cellPhonePartTwo = cellPhone.slice(4, cellPhoneLength - 1)
    const cellPhoneConcat = cellPhonePartOne.concat('-', cellPhonePartTwo)

    return cellPhoneConcat
}

export const CellPhoneFormat = (cellPhoneValue: string) => {
    
    const cellPhonePartOne = cellPhoneValue.slice(0, 4)
    const cellPhonePartTwo = cellPhoneValue.slice(4, 18)
    
    let cellPhone = cellPhoneValue
    if(cellPhoneValue.length > 4 && !cellPhoneValue.includes('-', 4)){
        cellPhone = cellPhonePartOne.concat('-', cellPhonePartTwo)
    }

    return cellPhone
}