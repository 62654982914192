import { useReducer } from 'react'
import { DentalRecordsState, ProviderState } from '../../../interfaces/DentalRecords';
import DentalRecordsContext from './DentalRecordsContext';
import DentalRecordsReducer from './DentalRecordsReducer';
import { SelectChangeEvent } from '@mui/material';
import axios from 'axios';

const INITIAL_STATE: DentalRecordsState = {
    data: [],
    loading: false,
    openConfirm: false,
    openAlert: false,
    buttonDisabled: true,
    insuranceId: 0,
    professionalEnrollment: '',
    status: 0,
    loadingInsurance: false,
    filtered: false,
    dataInsurance: [],
    statusList: [],
    dentalDetail: {
        pieces: [],
        faces: []
    },
    circleList: [],
    sexTypesList: [],
    dentalRecordStates: [],
    items: {
        affiliatedValidated: false,
        createdAt: '',
        patientDetail: {
            affiliateId: 0,
            insuranceId: 0,
            planId: 0,
            floor: '',
            apartment: '',
            town: '',
            documentTypeId: 0,
            document: 0,
            phone: '',
            name: '',
            lastname: '',
            postalCode: 0,
            sex: 0,
            street: '',
            streetNumber: 0,
            ivaTypeId: 0,
            code: ''
        },
        professionalDetail: {
            professionalsId: 0,
            isNationalEnrollment: false,
            nationalEnrollment: '',
            professionalName: '',
            professionalLastname: '',
            circleId: 0,
            provincialEnrollment: '',
        },
        treatments: [],
        providers: [],
        histories: [],
        observers: '',
        bond: '',
        token: '',
        createDate: '',
        isNationalEnrollment: false,
        professionalEnrollment: '',
        dentalRecordsFiles: [{
            dentalRecordFileId: 0,
            dentalFile: '',
            dentalRecordsId: 0
        }],
        amountPieces:0 ,
        healthyMouth:false

    },
    providerState: [],
    offset: 0,
    noMoreResults: false,
    affiliateFilter: '',
    ivaTypesData: [],
    localidadesData: []
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const DentalRecordsProvider = ({children}: Props) => {

    const [dentalRecordState, dispatch] = useReducer(DentalRecordsReducer, INITIAL_STATE)

    const getDentalRecords = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}dentalRecords?offset=0&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecords', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const dentalRecordsNextPage = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const offsetValue = dentalRecordState.offset + 50
        dentalRecordState.offset = offsetValue

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}dentalRecords?offset=${offsetValue}&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'dentalRecordsNextPage', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getDentalRecordsFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        let params = []
        if(dentalRecordState.insuranceId){
            params.push(`&insuranceId=${dentalRecordState.insuranceId}`)
        }
        if(dentalRecordState.professionalEnrollment){
            params.push(`&professionalEnrollment=${dentalRecordState.professionalEnrollment}`)
        }
        if(dentalRecordState.status){
            params.push(`&status=${dentalRecordState.status}`)
        }
        if(dentalRecordState.affiliateFilter){
            params.push(`&affiliateCode=${dentalRecordState.affiliateFilter}`)
        }

        let paramsFormat = params[0]
        if(params.length === 2){
            paramsFormat = params[0] + params[1]
        } else if(params.length === 3){
            paramsFormat = params[0] + params[1] + params[2]
        } else if(params.length === 4){
            paramsFormat = params[0] + params[1] + params[2] + params[3]
        }

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}dentalRecords?offset=0&limit=100000${paramsFormat}`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecords', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
            dispatch({type: 'filtered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getDentalRecordsById = async (id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}dentalRecords/${id}`, {headers})
        .then(response => {
            console.log(response.data)
            dispatch({type: 'getDentalRecordsById', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getDentalDetail = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}dental/detail`, {headers})
        .then(response => {
            dispatch({type: 'getDentalDetail', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getDentalRecordStates = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}dentalRecordStates`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecordStates', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const modifyDentalRecordStatus = async (dentalRecordId: number, status: number, comment?: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "status" : status,
            "comment" : comment,
        }

        dispatch({type: 'setLoading', payload: true});

        await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}dentalRecords/${dentalRecordId}`, data, {headers})
        .then(() => {
            window.location.href = `/fichas/${dentalRecordId}/${status}`
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
        
    }

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'loadingInsurance', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insurances`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loadingInsurance', payload: false});
        })
    }

    const getInsuranceStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}insuranceStates`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceStatus', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getCirles = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}circles`, {headers})
        .then(response => {
            dispatch({type: 'getCirles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getSexTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}sexType/`, {headers})
        .then(response => {
            dispatch({type: 'getSexTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de fichas para traer todos los resultados nuevamente
        if(dentalRecordState.filtered){
            getDentalRecords()
            dispatch({type: 'filtered', payload: false})
        }
    }

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
        dispatch({type: 'handleChangeSelect', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({type: 'changeDatePicker', payload: {value: value, name: name}})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const getIvaTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}ivaType`, {headers})
        .then(response => {
            dispatch({type: 'getIvaTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const setProviders = (value: ProviderState[]) => {
        dispatch({type: 'setProviders', payload: value})
    }

    const getLocalidades = async () => {

        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}towns?offset=0&limit=950`, {headers})
        .then(response => {
          dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }

    return(
        <DentalRecordsContext.Provider value={{
            dentalRecordState,
            getDentalRecords,
            dentalRecordsNextPage,
            getDentalRecordsFilter,
            getDentalRecordsById,
            getDentalDetail,
            getDentalRecordStates,
            modifyDentalRecordStatus,
            getInsurances,
            getInsuranceStatus,
            getCirles,
            getSexTypes,
            handleChangeInput,
            handleChangeSelect,
            handleChangeDatePicker,
            handleOpenConfirm,
            handleCloseConfirm,
            handleChangeFilter,
            clearFilter,
            closeAlert,
            getIvaTypes,
            getLocalidades,
            setProviders
        }}>
            {children}
        </DentalRecordsContext.Provider>
    )
}

export default DentalRecordsProvider