  import {Checkbox, Divider, Grid, TextField, ListItemText, Box, Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, tableCellClasses, styled, FormControl, InputLabel, Select, MenuItem, Typography} from "@mui/material";
  import {useContext, useEffect, useState } from "react";
  import { Link} from "react-router-dom";
  import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext";
  import { DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
  import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
  import moment from "moment";
  import Alert from "../Alert";
  import Loader from "../Loader";
  //icons
  import AddIcon from '@mui/icons-material/Add';
  import EditIcon from '@mui/icons-material/Edit';

  //estilos para celdas de tablas
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
  }));
  
    //estilos para filas de tablas
  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ReRegistration = () => {

    const { state, getGeneralRules, handleChangeDatePicker, closeAlert, clearValues, handleChangeInput, saveGeneralRules, handleChangeSelect, getInsurances} = useContext(EmpadronamientoContext);

    useEffect(() => {
        getGeneralRules();
        clearValues()
        getInsurances();
      }, []);

      //paginacion de tabla
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    // openAddRuleG = openAddRuleGeneral
    const [openAddRuleG, setopenAddRuleG] = useState(false)

    // toggleMenuRuleG = toggleMenuRuleGeneral
    const toggleMenuRuleG = () => {
        setopenAddRuleG(!openAddRuleG)
        clearValues()
    }

    if (state.loading) {
        return <Loader />;
      }
    return (
        <Box sx={{ margin: "20px 15px 0px 15px" }}>
          <h4>Reempadronamiento Reglas Generales</h4>
          <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
          <Grid container>
            {!openAddRuleG &&
              <Grid item xs={6} sm={6} md={6}>
                <Button variant="contained" startIcon={<AddIcon/>} sx={{mt: 1, mb: 2, width: "50%"}} onClick={toggleMenuRuleG}>Agregar regla general</Button>
              </Grid> 
            }
            {openAddRuleG &&
              <Grid container justifyContent={"center"} columnGap={13} rowGap={5} >
                <Grid item xs={12} sm={12} md={2}>
                  <h5>Ingrese un nombre: </h5>
                  <TextField name="description" label="Nombre" variant="standard" fullWidth value={state.generalRuleName} onChange={handleChangeInput} inputProps={{maxLength: 40}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <h5>Seleccione la/s obras sociales </h5>
                  <FormControl
                fullWidth
                variant="standard"
                margin="normal"
                required
                sx={{padding: "0", margin: "0"}}
              >
                <InputLabel>Obras Sociales</InputLabel>
                <Select
                  className="widthField"
                  label="Obras Sociales"
                  name="insurancesChecked"
                  multiple
                  renderValue={(selected: string | number[]) => {
                    const selectedArray = Array.isArray(selected) ? selected : [selected];
                    return selectedArray.map(e => state.insuranceData.find(i => i.insurancesId === e)?.name).join(', ');
                  }}
                  value={state.insurancesChecked}
                  onChange={handleChangeSelect}
                  fullWidth
                  sx={{padding: "0", margin: "0"}}
                >
                  {/* Seleccionar todas las obras sociales
                  <MenuItem>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Seleccionar todas"
                      />
                    </FormGroup>
                  </MenuItem> */}
                  {state.insuranceData.map((i) => (
                    <MenuItem key={i.insurancesId} value={i.insurancesId}>
                      <Checkbox
                        checked={state.insurancesChecked.findIndex(item => item === i.insurancesId) != -1}
                      />
                      <ListItemText primary={`(${i.idFopbaInsider}) - ${i.name}`}/>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <h5>Ingrese una fecha "desde": </h5>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Desde"
                      value={state.generalRuleFromDate}
                      onChange={(newValue) => handleChangeDatePicker(moment(newValue).format("YYYY-MM-DD"), "fromDate")}
                      renderInput={(params) => (
                    <TextField
                      sx={{padding: "0", margin: "0"}}
                      {...params}
                      name="fromDate"
                      variant="standard"
                      className="widthField"
                      margin="normal"
                      fullWidth
                    />
                    )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <h5>Ingrese una fecha "hasta": </h5>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Hasta"
                      value={state.generalRuleToDate}
                      onChange={(newValue) => handleChangeDatePicker(moment(newValue).format("YYYY-MM-DD"), "to")}
                      renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{padding: "0", margin: "0"}}
                      name="to"
                      variant="standard"
                      className="widthField"
                      margin="normal"
                      fullWidth
                    />
                    )}
                    />
                  </LocalizationProvider>
                  <Grid mt={4}>
                    {state.messageError && state.messageError.length ? 
                      state.messageError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                      ))
                    : null}
                  </Grid>
                  <Box display={"flex"} mt={"30px"} mb={"15px"}>
                    <Button variant="contained" color="error" sx={{ marginRight: "20px"}} onClick={toggleMenuRuleG} fullWidth>Cancelar</Button>
                    <Button variant="contained" fullWidth onClick={saveGeneralRules}>Guardar</Button>
                  </Box>
                </Grid>
              </Grid>
                }
                <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{ borderColor: "#64AAB2", mt: 1}} />
                <h4>Historial Reglas Generales</h4>
                {state.generalRules.length ?
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell sx={{padding: '12px'}} align="left">Descripción </StyledTableCell>
                        <StyledTableCell sx={{padding: '12px'}} align="left">Desde </StyledTableCell>
                        <StyledTableCell sx={{padding: '12px'}} align="left">Hasta </StyledTableCell>
                        <StyledTableCell sx={{padding: '12px'}} align="center">Editar </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0 
                      ? state.generalRules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : state.generalRules
                      ).map((row) => {
                      return (
                        <StyledTableRow key={row.generalRulesId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCell sx={{padding: '12px'}} align="left">{row.description}</StyledTableCell>
                          <StyledTableCell sx={{padding: '12px'}} align="left">{row.from}</StyledTableCell>
                          <StyledTableCell sx={{padding: '12px'}} align="left">{row.to}</StyledTableCell>
                          <StyledTableCell sx={{padding: '12px'}} align="center">
                            <Link to={`/reempadronamiento-detalle/${row.generalRulesId}`}>
                              <EditIcon sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}}/>
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                            )})}
                    </TableBody>
                  </Table>
                </TableContainer>
                :
                <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2, mb: 2}}>
                        <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No existen reglas generales</Typography>
                </Box>
                }
                </Grid>
                <Alert
                  openAlert={state.openAlertErrorDate}
                  handleClose={closeAlert}
                  severity="warning"
                  text={"Verificar campos a rellenar"}
                />
          </Grid>
        </Box>
    )
}
export default ReRegistration;