import { createContext } from 'react'
import { PermisosState } from '../../../interfaces/Permisos';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: PermisosState
    getRoles: () => void
    getPermisos: (id: number) => void
    handleChangeSelect: (event:SelectChangeEvent<string>) => void
    addPermisos: (checkModificados: number[]) => void
    closeAlert: () => void
    clearValues: () => void
}

const PermisosContext = createContext<ContextProps>({} as ContextProps);

export default PermisosContext;