import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from '../img/logo.png'
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListPermissions from '../utils/ListPermissions'
// import Typography from '@mui/material/Typography';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../interfaces/Login';

// Icons
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FeedIcon from '@mui/icons-material/Feed';
import BadgeIcon from '@mui/icons-material/Badge';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ReplayIcon from '@mui/icons-material/Replay';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HomeIcon from '@mui/icons-material/Home';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open'})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiPaper-root': {
            backgroundColor: '#64AAB2',
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const routeUsers = (index: number) => {
    switch (index) {
        case 0:
            return '/usuarios'
        case 1:
            return '/multiple-usuarios'
        case 2:
            return '/roles'
        case 3:
            return '/permisos'
        case 4:
            return '/estado-prestador'
        default:
            return '/usuarios'
    }
}

const routeInsurance = (index: number) => {
    switch (index) {
        case 0:
            return '/obras-sociales'
        case 1:
            return '/planes'
        case 2:
            return '/afiliados'
        default:
            return '/obras-sociales'
    }
}

const routeEnrollments = (index: number) => {
    switch (index) {
        case 0:
            return '/empadronamiento'
        case 1:
            return '/reempadronamiento'
        case 2:
            return '/reempadronamiento-particular'
        default:
            return '/empadronamiento'
    }
}

interface Props {
    content: JSX.Element
}

export default function Header({content}: Props) {
    const [open, setOpen] = React.useState(false);
    const [openUsers, setOpenUsers] = React.useState(false);
    const [openInsurances, setOpenInsurances] = React.useState(false);
    const [openEnrollments, setOpenEnrollments] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClickOpenUsers = () => {
        setOpenUsers(!openUsers);
    };

    const handleClickOpenInsurances = () => {
        setOpenInsurances(!openInsurances);
    };
    const handleClickOpenEnrollments = () => {
        setOpenEnrollments(!openEnrollments);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logout = () => {
        localStorage.setItem('token', '');
        window.location.reload();
    }

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    
  const profileCircle = decoded.profileId === 4

    const submenuInsurances = ['Obras Sociales', 'Planes', 'Afiliados']
    const submenuUsers = ['Usuarios', 'Alta masiva', 'Roles', 'Permisos', 'Estado Prestador']
    const submenuEnrollments = ['Búsqueda', 'Global', 'Individual']

    submenuUsers[0] = ListPermissions().listarUsuario?.status ? 'Usuarios' : ''
    submenuUsers[1] = ListPermissions().crearUsuario?.status && !profileCircle ? 'Alta Masiva' : ''
    submenuUsers[2] = ListPermissions().manejoRoles?.status && !profileCircle ? 'Roles' : ''
    submenuUsers[3] = ListPermissions().manejoPermisos?.status ? 'Permisos' : ''
    submenuUsers[4] = !profileCircle ? 'Estado Prestador' : ''
    submenuEnrollments[0] = ListPermissions().listarEmpadronamientos?.status ? 'Búsqueda' : ''

    return (
        <Box sx={{ display: 'flex', marginTop: '70px'}}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{background: '#F7F9FF'}}>
                <Toolbar>
                    <Box sx={{ flexGrow: 0, position: 'absolute', right: 15, color: 'black'}}> 
                        <Tooltip title="Abrir menu">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {/* {window.innerWidth > 600 &&
                                    <Typography variant="body1" noWrap component="div" sx={{position: 'absolute', color: '#222', right: 30, zIndex: 0}}>
                                        {decoded.user}
                                    </Typography>
                                } */}
                                <AccountCircleIcon sx={{color: '#64AAB2', fontSize: 28}}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Link to={`/perfil`} style={{color: '#00071A', textDecoration: 'none', width: '100%'}}>
                                    Perfil
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
                <Box sx={{display: 'flex', color: 'black', position: 'absolute', left: 20, top: 10, alignItems: 'center'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                    edge="start"
                >
                    <MenuIcon sx={{color: '#64AAB2', fontSize: 28}} />
                </IconButton>
                </Box>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img src={Logo} alt='Logo' style={{marginRight: 50}}/>
                </DrawerHeader>
                <Divider />
                <List>
                     {/* ----- HOME ----- */}
                    <Link to='/inicio' style={{textDecoration: 'none', color: '#fff'}}>
                        <ListItemButton sx={{background: '#83c9d1'}}>
                            <ListItemIcon sx={{color: '#F7F9FF'}}>
                                <Tooltip title={!open && "Inicio"} placement="right">
                                    <HomeIcon />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText sx={{color: '#fff'}} primary="Inicio" />
                        </ListItemButton>
                    </Link>
                    {/* ----- USUARIOS ----- */}
                    <ListItemButton onClick={handleClickOpenUsers} sx={{background: '#83c9d1'}}>
                        <ListItemIcon sx={{color: '#F7F9FF'}}>
                            <Tooltip title={!open && "Usuarios / Accesos"} placement="right">
                                <PersonOutlineOutlinedIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText sx={{color: '#fff'}} primary="Usuarios/Accesos" />
                        {openUsers ? <ExpandLess sx={{color: '#fff'}} /> : <ExpandMore sx={{color: '#fff'}} />}
                    </ListItemButton>
                    <Collapse in={openUsers} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {submenuUsers.map((text, index) => {
                                // si el texto viene como string vacio significa que esa opcion no esta habilitada para el usuario
                                return text !== '' && (
                                    <Link key={index} to={routeUsers(index)} style={{textDecoration: 'none', color: '#fff'}}>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon sx={{color: '#F7F9FF'}}>
                                                {index === 0 && (
                                                    <Tooltip title={!open && "Usuarios"} placement="right">
                                                        <PersonOutlineOutlinedIcon />
                                                    </Tooltip>
                                                )}
                                                {index === 1 && (
                                                    <Tooltip title={!open && "Alta Masiva"} placement="right">
                                                        <SupervisorAccountOutlinedIcon />
                                                    </Tooltip>
                                                )}
                                                {index === 2 && (
                                                    <Tooltip title={!open && "Roles"} placement="right">
                                                        <AssignmentIndIcon />
                                                    </Tooltip>
                                                )}
                                                {index === 3 && (
                                                    <Tooltip title={!open && "Permisos"} placement="right">
                                                        <AdminPanelSettingsIcon />
                                                    </Tooltip>
                                                )}
                                                {index === 4 && (
                                                    <Tooltip title={!open && "Estado Prestador"} placement="right">
                                                        <AccessibilityIcon />
                                                    </Tooltip>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, textDecoration: 'none' }} />
                                        </ListItemButton>
                                    </Link>
                                )
                            })}
                        </List>
                    </Collapse>
                    {/* ----- OBRAS SOCIALES ----- */}
                    {ListPermissions().listarObrasSociales?.status && <>
                        <ListItemButton onClick={handleClickOpenInsurances} sx={{background: '#83c9d1'}}>
                            <ListItemIcon sx={{color: '#F7F9FF'}}>
                                <Tooltip title={!open && "Obras Sociales"} placement="right">
                                    <MedicalInformationIcon />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText sx={{color: '#fff'}} primary="Obras Sociales" />
                            {openInsurances ? <ExpandLess sx={{color: '#fff'}} /> : <ExpandMore sx={{color: '#fff'}} />}
                        </ListItemButton>
                        <Collapse in={openInsurances} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {submenuInsurances.map((text, index) => {
                                    // si el texto viene como string vacio significa que esa opcion no esta habilitada para el usuario
                                    return text !== '' && (
                                        <Link key={text} to={routeInsurance(index)} style={{textDecoration: 'none', color: '#fff'}}>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon sx={{color: '#F7F9FF'}}>
                                                    {index === 0 && (
                                                        <Tooltip title={!open && "Obras Sociales"} placement="right">
                                                            <MedicalInformationIcon />
                                                        </Tooltip>
                                                    )}
                                                    {index === 1 && (
                                                        <Tooltip title={!open && "Planes"} placement="right">
                                                            <FactCheckIcon />
                                                        </Tooltip>
                                                    )}
                                                    {index === 2 && (
                                                        <Tooltip title={!open && "Afiliados"} placement="right">
                                                            <BadgeIcon />
                                                        </Tooltip>
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, textDecoration: 'none' }} />
                                            </ListItemButton>
                                        </Link>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </>}
                    {/* ----- CIRCULOS ----- */}
                    {/* si tiene habilitado el permiso y no es circulo lo muestro, se hizo esto ya que para el perfil circulo
                    necesito tener el permiso habilitado para que me liste los circulos en el alta de usuario pero no quiero que se vea esta opcion en el menu */}
                    {(ListPermissions().listarCirculos?.status && !profileCircle) && <>
                        <Link to='/circulos' style={{textDecoration: 'none', color: '#fff'}}>
                            <ListItemButton sx={{background: '#83c9d1'}}>
                                <ListItemIcon sx={{color: '#F7F9FF'}}>
                                    <Tooltip title={!open && "Círculos"} placement="right">
                                        <RadioButtonUncheckedIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText sx={{color: '#fff'}} primary="Circulos" />
                            </ListItemButton>
                        </Link>
                    </>}
                    {/* ----- FICHAS ODONTOLÓGICAS ----- */}
                    {ListPermissions().listarFichas?.status && <>
                        <Link to='/fichas' style={{textDecoration: 'none', color: '#fff'}}>
                            <ListItemButton sx={{background: '#83c9d1'}}>
                                <ListItemIcon sx={{color: '#F7F9FF'}}>
                                    <Tooltip title={!open && "Fichas Odontológicas"} placement="right">
                                        <ReceiptLongIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText sx={{color: '#fff'}} primary="Fichas Odontológicas" />
                            </ListItemButton>
                        </Link>
                    </>}
                    {/* ----- EMPADRONAMIENTO -----*/}
                    {ListPermissions().listarEmpadronamientos?.status && <>
                    <ListItemButton onClick={handleClickOpenEnrollments} sx={{background: '#83c9d1'}}>
                            <ListItemIcon sx={{color: '#F7F9FF'}}>
                                <Tooltip title={!open && "Empadronamiento"} placement="right">
                                    <AppRegistrationIcon/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText sx={{color: '#fff'}} primary="Empadronamiento" />
                            {openEnrollments ? <ExpandLess sx={{color: '#fff'}} /> : <ExpandMore sx={{color: '#fff'}} />}
                        </ListItemButton>
                        <Collapse in={openEnrollments} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {submenuEnrollments.map((text, index) => {
                                    // si el texto viene como string vacio significa que esa opcion no esta habilitada para el usuario
                                    return text !== '' && (
                                        <Link key={text} to={routeEnrollments(index)} style={{textDecoration: 'none', color: '#fff'}}>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon sx={{color: '#F7F9FF'}}>
                                                    {index === 0 && (
                                                        <Tooltip title={!open && "Búsqueda"} placement="right">
                                                            <AppRegistrationIcon/>
                                                        </Tooltip>
                                                    )}
                                                    {index === 1 && (
                                                        <Tooltip title={!open && "Global"} placement="right">
                                                            <ReplayIcon sx={{rotate: "180deg"}}/>
                                                        </Tooltip>
                                                    )}
                                                    {index === 2 && (
                                                        <Tooltip title={!open && "Individual"} placement="right">
                                                            <GroupAddIcon/>
                                                        </Tooltip>
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, textDecoration: 'none' }} />
                                            </ListItemButton>
                                        </Link>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </>}
                    {/* ----- CONTACTO FOPBA ----- */}
                    {ListPermissions().contactos?.status &&
                        <Link to='/contacto' style={{textDecoration: 'none', color: '#fff'}}>
                            <ListItemButton sx={{background: '#83c9d1'}}>
                                <ListItemIcon sx={{color: '#F7F9FF'}}>
                                    <Tooltip title={!open && "Datos FOPBA"} placement="right">
                                        <FeedIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText sx={{color: '#fff'}} primary="Datos FOPBA" />
                            </ListItemButton>
                        </Link>
                    }
                    {/*----- EXPORTACIÓN FOPBA -----*/}
                        <Link to='/exportacion' style={{textDecoration: 'none', color: '#fff'}}>
                            <ListItemButton sx={{background: '#83c9d1'}}>
                                <ListItemIcon sx={{color: '#F7F9FF'}}>
                                    <Tooltip title={!open && "Exportación"} placement="right">
                                        <FileDownloadIcon/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText sx={{color: '#fff'}} primary="Exportación" />
                            </ListItemButton>
                        </Link>
                </List>
            </Drawer>
            <Box sx={{width: {xs: '86%', sm: '95%', md: '100%'}}}>
                {content}
            </Box>
        </Box>
    );
}