import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#6faab1',
            main: '#64AAB2',
            dark: '#56a7b1',
            contrastText: '#fff'
        },
        secondary: {
            light: '#52c3b7',
            main: '#42C5B7',
            dark: '#32c9b9',
            contrastText: '#fff'
        },
    },
});

export default theme