import { useContext, useEffect, useState } from "react"
import { Box, Divider, Grid, MenuItem, Paper, TextField, Select, FormControl, InputLabel} from '@mui/material'
import MuiButton from '@mui/material/Button'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link, useParams } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CsvBuilder } from 'filefy';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(MuiButton)(() => ({
  backgroundColor: '#64AAB2',
  marginBottom: 10,
  '&:hover': {
      backgroundColor: '#73c9d3'
  },
}));

const Index = () => {

    const { state, getAllEnrollments, getEnrollmentStatus, getCircles, clearEnrollmentFilter, handleChangeFilter, getAllEnrollmentsFilter, handleChangeSelectFilter, enrollmentNextPage } = useContext(EmpadronamientoContext)


    useEffect(() => {
        getAllEnrollments()
        getEnrollmentStatus()
        getCircles()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading || state.loadingAllEnrollments){
        return <Loader />
    }
    
    // habilito el boton de filtrar cuando se haya escrito al menos 3 caracteres de nombre o apellido
    const FilterDiabled = state.enrollmentNameFilter.length >= 3 || state.enrollmentLastNameFilter.toString().length >= 2 || state.enrollmentRegistrationFilter || state.enrollmentStatusFilter || state.enrollmentCuilFilter.length >= 2

    const dataToExport = state.allEnrollmentsData.map(item => {
      const circleDescription = state.dataCircles.find(circle => circle.circlesId === item.circleId)?.name
      const enrollmentStatusDescription = state.enrollmentStatus.find(status => status.registrationStatesId === item.status)?.description
      return [item.name, item.lastName, item.cuit , circleDescription ? circleDescription : '', item.registrationFinishDate, enrollmentStatusDescription ? enrollmentStatusDescription : '']
    })

    const exportTable = () => {
      new CsvBuilder("listado_de_empadronamientos.csv")
      .setColumns(["Nombre", "Apellido", "Cuit / Cuil", "Circulo", "Fecha de Empadronamiento", "Estado" ])
      .setDelimeter(";")
      .addRows(dataToExport)
      .exportFile();
    }

    return (
      <Box sx={{ margin: "20px 15px 0px 15px" }}>
        <h4>Empadronamiento Búsqueda</h4>
        <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
          mb={3}
          mt={3}
        >
          <Grid xs={6} sm={4} md={4} item>
            <TextField
              fullWidth
              value={state.enrollmentNameFilter}
              name="enrollmentNameFilter"
              label="Nombre"
              onChange={(e) => handleChangeFilter(e)}
            />
          </Grid>
          <Grid xs={6} sm={4} md={4} item>
            <TextField
              fullWidth
              value={
                state.enrollmentLastNameFilter
                  ? state.enrollmentLastNameFilter
                  : ""
              }
              name="enrollmentLastNameFilter"
              label="Apellido"
              onChange={(e) => handleChangeFilter(e)}
            />
          </Grid>
          <Grid xs={6} sm={4} md={4} item>
            <FormControl
              fullWidth
              variant="outlined"
              error={!state.enrollmentStatus}
            >
            <InputLabel>Estado</InputLabel>
            <Select
              label="Estado"
              name="enrollmentStatusFilter"
              fullWidth
              value={state.enrollmentStatusFilter}
              onChange={(e) => handleChangeSelectFilter(e)}
            >
              {state.enrollmentStatus.map((c) => (
                <MenuItem
                  key={c.registrationStatesId}
                  value={c.registrationStatesId}
                  dense
                >
                  {c.description}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={4} md={4} item>
            <TextField
              fullWidth
              value={state.enrollmentRegistrationFilter}
              name="enrollmentRegistrationFilter"
              label="Matrícula"
              onChange={(e) => handleChangeFilter(e)}
            />
          </Grid>
          <Grid xs={6} sm={4} md={4} item>
            <TextField
              fullWidth
              value={state.enrollmentCuilFilter}
              name="enrollmentCuilFilter"
              label="Cuil / Cuit"
              onChange={(e) => handleChangeFilter(e)}
            />
          </Grid>
          <Grid xs={6} sm={4} md={4} item>
            <MuiButton
              variant="outlined"
              size={
                state.enrollmentNameFilter ||
                state.enrollmentLastNameFilter ||
                state.enrollmentRegistrationFilter ||
                state.enrollmentStatusFilter  ||
                state.enrollmentCuilFilter 
                  ? "small"
                  : "large"
              }
              fullWidth
              startIcon={<FilterAltIcon />}
              onClick={() => {getAllEnrollmentsFilter(); setPage(0)}}
              disabled={!FilterDiabled}
            >
              Filtrar
            </MuiButton>
            {state.enrollmentNameFilter ||
            state.enrollmentLastNameFilter ||
            state.enrollmentRegistrationFilter ||
            state.enrollmentCuilFilter ||
            state.enrollmentStatusFilter ? (
              <MuiButton
                variant="outlined"
                size="small"
                fullWidth
                color="error"
                startIcon={<FilterAltOffIcon />}
                onClick={() => {clearEnrollmentFilter(); setPage(0)}}
              >
                Limpiar
              </MuiButton>
            ) : null}
          </Grid>
        </Grid>

        <Grid container justifyContent='end'>
          <ButtonCustom sx={{marginBottom: '10px', color: '#fff'}} onClick={exportTable} variant='outlined'>
              Exportar <FileDownloadIcon sx={{marginLeft: '5px'}}/>
          </ButtonCustom>
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell sx={{ padding: "12px" }} align="left">
                  Nombre
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "12px" }} align="left">
                  Apellido
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "12px" }} align="left">
                  Cuil / Cuit
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "12px" }} align="left">
                  Círculo
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "12px" }} align="left">
                  Fecha de Empadronamiento
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "12px" }} align="left">
                  Estado
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "12px" }} align="center">
                  Detalle
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? state.allEnrollmentsData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : state.allEnrollmentsData
              ).map((row) => {
                return (
                <StyledTableRow
                  key={row.registrationsId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell sx={{ padding: "12px" }} align="left">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: "12px" }} align="left">
                    {row.lastName}
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: "12px" }} align="left">
                    {row.cuit}
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: "12px" }} align="left">
                    {
                      state.dataCircles.find(
                        (item) => item.circlesId === row.circleId
                      )?.idFopbaInsider
                    } - 
                    {
                      state.dataCircles.find(
                        (item) => item.circlesId === row.circleId
                      )?.name
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: "12px" }} align="left">
                    {row.registrationFinishDate}
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: "12px" }} align="left">
                    {
                      state.enrollmentStatus.find(
                        (item) => item.registrationStatesId === row.status
                      )?.description
                    }
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: "12px" }} align="center">
                    <Link
                      to={`/empadronamiento-detalle/${row.registrationsId}`}
                      style={{
                        color: "#3C78F8",
                        textDecoration: "none",
                        width: "100%",
                      }}
                    >
                      <SearchIcon color="primary" sx={{ cursor: "pointer" }} />
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              )})}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "Completo", value: -1 },
                  ]}
                  colSpan={3}
                  count={state.allEnrollmentsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Filas por pagina",
                    },
                    native: true,
                  }}
                  labelRowsPerPage='Filas por Página'
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={() => (
                    <Pagination
                      count={state.allEnrollmentsData.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      filtered={state.enrollmentFiltered}
                      noMoreResults={state.noMoreResultsEnrollment}
                      nextPage={enrollmentNextPage}
                      onPageChange={handleChangePage}
                    />
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    );
}

export default Index