import { createContext } from 'react'
import { InsuranceState, InsurancePlanCodesItems } from '../../../interfaces/Insurances';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: InsuranceState,
    getInsurances: () => void
    insurancesNextPage: () => void
    getInsuranceFilter: () => void
    clearFilter: () => void
    getInsuranceById: (id: string | undefined) => void
    editObraSocial: (id: string | undefined) => void
    deleteObraSocial: (id: string | undefined) => void
    getCoverage: () => void
    getInsuranceState: () => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getCalles: () => void
    handleToggle: (coverageId: number, status: boolean) => void
    handleChangeUbicacion: (value: number, name: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeParticularRules: (value: string, name: string) => void
    handleChangeStatusList: (event:SelectChangeEvent<number>) => void
    addObraSocial: () => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    closeAlert: () => void
    clearValues: () => void
    handleChangeSelect: (event:SelectChangeEvent<number>) => void
    // PLANS
    getPlanStatus: () => void
    getChapters: () => void
    getInsurancePlanById: (insuranceId: number, insurancePlanId: string | undefined) => void
    getInsurancePlan: (id: number) => void
    addInsurancePlans: (id: string | undefined) => void
    addCodes: () => void
    editCode: (data: InsurancePlanCodesItems) => void
    deleteCode: (id: number | undefined) => void
    editPlans: (insurancesId: number, plansId: number) => void
    editPlansCodes: (insurancesId: number, plansId: number) => void
    deletePlans: (insurancesId: number, plansId: number) => void
    handleChangePlanItems: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangePlanCodes: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangePlanStatus: (event:SelectChangeEvent<number>) => void
    handleChangeChapter: (event:SelectChangeEvent<number>) => void
    openModalAddCodes: (add: boolean) => void
    closeModalAddCodes: () => void
    clearValuesItemsPlan: () => void
    handleOpenConfirmDeleteCodePlans: (id: number) => void
    handleCloseConfirmDeleteCodePlans: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const InsuranceContext = createContext<ContextProps>({} as ContextProps);

export default InsuranceContext;