import {
  Divider,
  Grid,
  TextField,
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  tableCellClasses,
  styled,
  Typography,
} from "@mui/material";
import {
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Alert from "../Alert";
import Loader from "../Loader";
//icons
import AddIcon from '@mui/icons-material/Add';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#64AAB2',
    color: 'white',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#eafaff',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

//detalles de reempadronamiento individual
const ReRegistrationParticular = () => {

  const { state, getGeneralRules, getParticularRulesByProfessionalId, handleChangeSelect, handleChangeDatePicker, saveParticularRuleByProfessionalId, closeAlert, clearValues } = useContext(EmpadronamientoContext);
  
  const {professionalId: professionalId} = useParams()

  useEffect(() => {
    getGeneralRules();
    getParticularRulesByProfessionalId(Number(professionalId));
    clearValues();
  }, []);

  const [openMenu, setOpenMenu] = useState(false)
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
    clearValues()
  }
  if (state.loading) {
    return <Loader />;
  }

  return (
    <Box sx={{ margin: "20px 15px 0px 15px" }}>
      <h4>Extensión Reempadronamiento Particular</h4>
      <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
      <Grid container>
        {!openMenu && (
        <Grid item xs={12} sm={12} md={4}>
          <Button variant="contained" startIcon={<AddIcon/>} onClick={toggleMenu}>Agregar extensión particular</Button>
        </Grid>
        )}
        {openMenu && (
          <Grid container gap={"100px"} justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={3}>
              <h5>Seleccione una Regla General: </h5>
              <FormControl
                fullWidth
                variant="standard"
                margin="normal"
                required
              >
                <InputLabel>Reglas Generales</InputLabel>
                <Select
                  className="widthField"
                  label="Reglas Generales"
                  name="generalRuleId"
                  onChange={handleChangeSelect}
                  fullWidth
                >
                  {state.generalRules.map((gr) => (
                    <MenuItem key={gr.generalRulesId} value={gr.generalRulesId}>
                      {gr.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <h5>Fecha "hasta" de: <b style={{textDecoration: "underline"}}>{state.generalRuleDescriptionSelected}</b> </h5>
              <TextField name="toDate" variant="standard" disabled className="widthField" fullWidth sx={{paddingTop: "30px"}} value={moment(state.generalRuleDateSelected).format('DD/MM/YYYY')}/>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <h5>Extensión Hasta: </h5>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Hasta"
                  value={state.particularRuletoDateSelect}
                  onChange={(newValue) => handleChangeDatePicker(moment(newValue).format("YYYY-MM-DD"), "toDate")}
                  disablePast
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="toDate"
                      variant="standard"
                      className="widthField"
                      margin="normal"
                      fullWidth
                    />
                    )}
                />
              </LocalizationProvider>
              <Grid mt={4}>
                {state.messageError && state.messageError.length ? 
                  state.messageError.map((message, index) => (
                    <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                  ))
                : null}
              </Grid>
              <Box display={"flex"} mt={"30px"} mb={"15px"}>
                <Button variant="contained" color="error" sx={{ marginRight: "20px"}} onClick={toggleMenu} fullWidth>Cancelar</Button>
                <Button variant="contained" fullWidth onClick={() => saveParticularRuleByProfessionalId(Number(professionalId))}>Guardar</Button>
              </Box>
            </Grid>
          </Grid>
        )}
        {/* si existen reglas particulares para este usuario muestro la tabla con sus reglas */}
        {state.particularRules.length ?  
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ marginTop: "20px" }}>
          <Divider sx={{ borderColor: "#64AAB2", mt: 2 }} />
            <h4>Historial de Extensiones Particulares</h4>
            <TableContainer component={Paper} sx={{marginTop: '20px'}}>
              <Table aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell sx={{padding: '12px'}} align="left">Regla Particular N°: </StyledTableCell>
                    <StyledTableCell sx={{padding: '12px'}} align="left">Extensión de Fecha Hasta: </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0 
                    ? state.particularRules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : state.particularRules
                    ).map((row) => {
                      return (
                      <StyledTableRow key={row.particularRulesId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.particularRulesId}</StyledTableCell>
                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.toDate}</StyledTableCell>
                      </StyledTableRow>
                    )})}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        :
        <Grid item xs={12} sm={12} md={12}>
          <Divider sx={{ borderColor: "#64AAB2", mt: 2 }} />
          <Box sx={{background: '#64AAB2', padding: 3, borderRadius: 2, mt: 2}}>
                    <Typography variant="body1" sx={{color: '#fff', textAlign: 'center'}}>No existen extensiónes particulares para este profesional</Typography>
                </Box>
          </Grid>
        }
        <Grid item xs={12} sm={12} md={3}> 
          <Button fullWidth type="button" variant="outlined" sx={{mt: "30px", mb: "30px"}}>
            <Link to="/reempadronamiento-particular" style={{color: "#4e4e4e", textDecoration: "none", width: "100%"}}>
              Volver
            </Link>
          </Button>
        </Grid>
        <Alert
          openAlert={state.openAlertErrorDate}
          handleClose={closeAlert}
          severity="warning"
          text={"Verificar fecha de extension y campos a llenar"}
        />
      </Grid>
    </Box>
  );
};
export default ReRegistrationParticular;
