import { createContext } from 'react'
import { AffiliateState } from '../../../interfaces/Affiliate';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
  state: AffiliateState,
  getInsurances: () => void
  getAffiliate: (insuranceId: number) => void
  getAffiliateFilter: (insuranceId: number) => void
  getAffiliateById: (insuranceId: number, id: number) => void
  getInsurancePlanById: (insuranceId: number) => void
  clearFilter: () => void
  getDocumentTypes: () => void
  getSexTypes: () => void
  getIvaTypes: () => void
  getTypeOfLoad: () => void
  handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChangeSelect: (event:SelectChangeEvent<number>) => void
}

const AffiliateContext = createContext<ContextProps>({} as ContextProps);

export default AffiliateContext;