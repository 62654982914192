import { Box, Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Grid, TextField } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import Pagination from '../Pagination';
import CirculoContext from '../context/Circulos/CirculoContext';
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider';
import Loader from '../Loader';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ListPermissions from '../../utils/ListPermissions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CsvBuilder } from 'filefy';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(Button)(() => ({
    backgroundColor: '#64AAB2',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const {
        state, 
        getCirculos,
        circlesNextPage,
        closeAlert,
        getFilterCircles,
        clearFilter,
        handleChangeFilter
        // deleteRol,
        // handleOpenConfirm,
        // handleCloseConfirm,
        // setIdSelected,
    } = useContext(CirculoContext)

    useEffect(() => {
        getCirculos()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if((!state.data.length && !state.filtered) || state.loading){
        return <Loader />
    }

    const onClickCirculoDetail = (id: number) => {
        window.location.href = `/detalle-de-circulo/${id}`
    }

    const dataToExport = state.data.map(item => {
        return [item.idFopbaInsider, item.name, item.authorityName, item.circleState.description]
    })

    const exportTable = () => {
        new CsvBuilder("listado_de_circulos.csv")
        .setColumns(["Codigo", "Nombre", "Autoridad", "Estado"])
        .setDelimeter(";")
        .addRows(dataToExport)
        .exportFile();
    }

    const FilterDisabled = state.circleNameFilter.length > 3 || state.circleIdFilter

    return (
        <Box sx={{ margin: '25px 15px 0px 15px' }}>
            <h4>Círculos</h4>
            <Grid container spacing={3} alignItems='center' mb={2} >
                <Grid xs={6} sm={6} md={4} item>
                    <TextField fullWidth value={state.circleIdFilter} name='circleIdFilter' label='Código' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={6} sm={6} md={4} item>
                    <TextField fullWidth value={state.circleNameFilter} name='circleNameFilter' label='Nombre' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                    <Button variant="outlined" fullWidth size='small' startIcon={<FilterAltIcon />} onClick={() => {getFilterCircles(); setPage(0)}} disabled={!FilterDisabled}>Filtrar</Button>
                    {(state.circleNameFilter || state.circleIdFilter) ? 
                        <Button variant="outlined" fullWidth size='small'  color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearFilter(); setPage(0)}}>Limpiar</Button>
                    : null}
                </Grid>
            </Grid>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container justifyContent={ListPermissions().crearCirculo?.status ? 'space-between' : 'end'}>
                {ListPermissions().crearCirculo?.status &&
                    <ButtonCustom variant='outlined' onClick={closeAlert}>
                        <Link to='/agregar-circulo' style={{color: '#fff', textDecoration: 'none', width: '100%'}}>Agregar circulo</Link>
                    </ButtonCustom>
                }
                <ButtonCustom sx={{marginBottom: '10px', color: '#fff'}} onClick={exportTable} variant='outlined'>
                    Exportar <FileDownloadIcon sx={{marginLeft: '5px'}}/>
                </ButtonCustom>
            </Grid>
             
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Autoridad</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                            <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 
                            ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : state.data
                        ).map((row) => (
                            <StyledTableRow
                                key={row.circlesId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.authorityName}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">{row.circleState.description}</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">
                                    <RemoveRedEyeOutlinedIcon sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}} onClick={() => {onClickCirculoDetail(row.circlesId); closeAlert()}}/>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                colSpan={3}
                                count={state.data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Filas por pagina',
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage='Filas por Página'
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={() => <Pagination count={state.data.length} nextPage={circlesNextPage} noMoreResults={state.noMoreResults} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Index