import { Box, Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import Pagination from '../Pagination';
import RolesContext from '../context/Roles/RolesContext';
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider';
import Confirm from '../Confirm';
import Loader from '../Loader';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(Button)(() => ({
    backgroundColor: '#64AAB2',
    marginBottom: 10,
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const {
        state, 
        getRoles,
        deleteRol,
        handleOpenConfirm,
        handleCloseConfirm,
        setIdSelected,
        closeAlert
    } = useContext(RolesContext)

    useEffect(() => {
        getRoles()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(!state.data.length || state.loading){
        return <Loader />
    }
    const onClickRolEdit = (id: number) => {
        const dataAEditar = state.data.filter(row => row.rolesId === id)[0]
        window.location.href = `/editar-rol/${id}?description=${dataAEditar.description}&profileId=${dataAEditar.profileId}`;
    }
    
    return (
        <Box sx={{ margin: '30px 15px 0px 15px' }}>
            <h4>Roles</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <ButtonCustom variant='outlined' onClick={closeAlert}>
                <Link to='/agregar-rol' style={{color: '#fff', textDecoration: 'none', width: '100%'}}>Agregar rol</Link>
            </ButtonCustom>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Descripción</StyledTableCell>
                            <StyledTableCell align="center">Opciones</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 
                            ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : state.data
                        ).map((row) => (
                            <StyledTableRow
                                key={row.rolesId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell align="left">{row.description}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <EditIcon sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}} onClick={() => onClickRolEdit(row.rolesId)}/>
                                    <DeleteIcon sx={{cursor: 'pointer', color: '#B92F2F'}} onClick={() => {handleOpenConfirm(); setIdSelected(row.rolesId)}} />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                colSpan={3}
                                count={state.data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Filas por página',
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage='Filas por Página'
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Confirm
                openConfirm={state.openConfirm}
                handleCloseConfirm={handleCloseConfirm}
                handleConfirm={deleteRol}
                handleConfirmId={() => {}}
                title='¿Estas seguro?'
                description='Si eliminas este Rol no podras volver a recuperarla luego'
            />
        </Box>
    )
}

export default Index