import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material';
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '../Pagination';
import { useContext, useEffect } from "react"
import InsuranceContext from "../context/Insurances/InsuranceContext"
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom'
import 'moment/locale/es';
import Loader from '../Loader';
import Alert from '../Alert';
import ModalAddCodes from './ModalAddCodes';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Add = () => {

    const { 
        closeAlert,
        getChapters,
        addInsurancePlans,
        clearValuesItemsPlan,
        handleChangePlanItems,
        handleChangePlanCodes,
        addCodes,
        editCode,
        deleteCode,
        openModalAddCodes,
        closeModalAddCodes,
        handleChangeChapter,
        state 
    } = useContext(InsuranceContext)

    const {id} = useParams();

    useEffect(() => {
        clearValuesItemsPlan()
        getChapters()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading){
        return <Loader />
    }

    return (
        <Box className='container' component="form" sx={{ margin: '20px 15px 30px 15px' }}>
            <h4>Agregar Plan</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Button variant='contained' sx={{background: '#64AAB2', '&:hover': {background: '#73c9d3'}}} onClick={() => openModalAddCodes(true)}>Agregar codigos de plan</Button>
            <Grid container mt={3} mb={3}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Código de Plan"
                        name='code'
                        variant="standard"
                        className='widthField'
                        required
                        error={!state.planItems.code && state.planError} 
                        value={state.planItems.code ? state.planItems.code : ''}
                        onChange={handleChangePlanItems}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="dense"
                        label="Descripción"
                        name='description'
                        variant="standard"
                        className='widthField'
                        required
                        error={!state.planItems.description && state.planError} 
                        value={state.planItems.description}
                        onChange={handleChangePlanItems}
                    />
                </Grid>
            </Grid>
            {state.planItems.codes.length > 0 &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Codigo</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Descripción</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Capitulo</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Arancel</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Porcentaje afiliado</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Opciones</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 
                                ? state.planItems.codes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : state.planItems.codes
                            ).map((row) => {
                                const chapterDescription = state.chapterList.map((chapter) => {
                                    if(row.chapterId === chapter.chaptersId){
                                        return chapter.description
                                    }
                                })
                                return <StyledTableRow
                                    key={row.codesId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell sx={{padding: '5px'}} component="th" scope="row" align="center">
                                        {row.code}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{row.description}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{chapterDescription}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{row.customsDuty}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{row.member}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <EditIcon sx={{cursor: 'pointer', color: '#3C78F8', marginRight: 1}} onClick={() => {editCode(row); openModalAddCodes(false)}}/>
                                        <DeleteIcon sx={{cursor: 'pointer', color: '#B92F2F'}} onClick={() => {deleteCode(row.codesId)}} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={state.data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
            <Grid mt={4}>
                {state.messagePlansError && state.messagePlansError.length ? 
                    state.messagePlansError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>{message}</Typography>
                    ))
                : null}
            </Grid>
            <Grid container justifyContent='flex-end' sx={{mt: 6}}>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='outlined'>
                        <Link to='/planes' style={{color: '#4e4e4e', textDecoration: 'none', width: '100%'}}>Volver</Link>
                    </Button>
                </Grid>
                <Grid item md={2}>
                    <Button fullWidth type='button' variant='contained' sx={{background: '#64AAB2', '&:hover': {background: '#73c9d3'}}} onClick={() => addInsurancePlans(id)}>Guardar</Button>
                </Grid>
            </Grid>
            <Alert openAlert={state.openAlert} handleClose={closeAlert} severity='success' text={'Plan agregado correctamente'} />
            <ModalAddCodes
                chapterList={state.chapterList}
                openModal={state.openModalAddCodes} 
                handleCloseModal={closeModalAddCodes} 
                handleChangePlanCodes={handleChangePlanCodes} 
                handleChangeChapter={handleChangeChapter}
                planItems={state.planItems}
                addCodes={addCodes}
                add={state.add}
            />
        </Box>
    )
}

export default Add