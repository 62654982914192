import { useReducer } from 'react'
import { CirculoState } from '../../../interfaces/Circulos';
import CirculoContext from './CirculoContext';
import CirculoReducer from './CirculoReducer';
import axios from 'axios';
import { CuitSinGuiones } from '../../../utils/CuitFormat';

const INITIAL_STATE: CirculoState = {
    data: [],
    cargando: false,
    messageError: [],
    openConfirm: false,
    openAddModal: false,
    id: 0,
    edit: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    calles: [],
    circuloAgregado: false,
    inputRequiredEmpty: false,
    loading: true,
    openAlert: false,
    buttonDisabled: true,
    fieldsModify: {},
    items: {
        circlesId: 0,
        idFopbaInsider: '',
        cuit: '',
        email: '',
        name: '',
        phone: '',
        cellPhone: '',
        street: '',
        height: '',
        floor: '',
        apartment: '',
        postalCode: '',
        town: 0,
        department: 0,
        state: 0,
        country: '',
        circleState: {
            circlesStatesId: 0,
            description: ''
        },
        authorityName: '',
        secretaryName: '',
        observation: ''
    },
    loadingDatosDemograficos: false,
    offset: 0,
    noMoreResults: false,
    circleNameFilter: '',
    circleIdFilter: '',
    filtered: false
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const CirculoProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(CirculoReducer, INITIAL_STATE)

    const getCirculos = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({ type: "setLoading", payload: true });

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}circles?offset=0&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'getCirculos', payload: response.data})
            dispatch({ type: "setLoading", payload: false });
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
                
            }
            dispatch({ type: "setLoading", payload: false });
        })
    }

    const circlesNextPage = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const offsetValue = state.offset + 50
        state.offset = offsetValue

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}circles?offset=${offsetValue}&limit=50`, {headers})
        .then(response => {
            dispatch({type: 'circlesNextPage', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getCirculoById = async (id: string | undefined) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}circles/${Number(id)}`, {headers})
        .then(response => {
            dispatch({type: 'getCirculoById', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const editCirculo = async (id: string | undefined) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});
        
        const fieldsToModify = {...state.fieldsModify, circleId: Number(id)}

        if(state.items.name && state.items.idFopbaInsider && state.items.email && state.items.cuit 
            && state.items.phone && state.items.street && state.items.height 
            && state.items.postalCode && state.items.town && state.items.state && state.items.department
        ){
            await axios.put(`${process.env.REACT_APP_BACKOFFICE_API}circles/${Number(id)}`, fieldsToModify, {headers})
            .then(() => {
                dispatch({type: 'setLoading', payload: false});
                dispatch({type: 'messageError', payload: []});
                openAlert()
            })
            .catch(error => {
                if(error.response.status === 401){
                    window.location.href = '/'
                }
                if(error.response.status === 500){
                    dispatch({type: 'messageError', payload: ['Error interno del servidor al modificar circulo']});
                    dispatch({type: 'setLoading', payload: false});
                }
                dispatch({type: 'messageError', payload: error.response.data?.errors});
                dispatch({type: 'setLoading', payload: false});
            })
            dispatch({type: 'inputRequiredEmpty', payload: false});
        } else {
            dispatch({type: 'inputRequiredEmpty', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const deleteCirculo = async (id: string | undefined) => {
        
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        const response = await axios.delete(`${process.env.REACT_APP_BACKOFFICE_API}circles/${Number(id)}`, {headers})
        if(response.status === 200){
            window.location.href = '/circulos'
        }
        dispatch({type: 'openConfirm', payload: false})
    }


    const addCirculo = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "name" : state.items.name,
            "idFopbaInsider" : state.items.idFopbaInsider,
            "email" : state.items.email,
            "cuit" : CuitSinGuiones(state.items.cuit),
            "phone" : (state.items.phone),
            "cellPhone" : (state.items.cellPhone),
            "street" : state.items.street ? state.items.street.toLowerCase() : state.items.street,
            "height" : state.items.height,
            "floor" : state.items.floor,
            "apartment" : state.items.apartment,
            "postalCode" : (state.items.postalCode),
            "town" : state.items.town,
            "department" : state.items.department,
            "state" : state.items.state,
            "country" : 'Argentina',
            "authorityName": state.items.authorityName,
            "secretaryName": state.items.secretaryName,
            "observation": state.items.observation
        }

        dispatch({type: 'setLoading', payload: true});

        if(state.items.name && state.items.idFopbaInsider && state.items.email && state.items.cuit 
            && state.items.phone && state.items.street && state.items.height 
            && state.items.postalCode && state.items.town && state.items.state && state.items.department
        ){
            await axios.post(`${process.env.REACT_APP_BACKOFFICE_API}circles`, data, { headers })
            .then(() => {
                openAlert()
                dispatch({type: 'setLoading', payload: false});
                dispatch({type: 'messageError', payload: []});
                clearValues()
            })
            .catch(error => {
                if(error.response.status === 401){
                    window.location.href = '/'
                }
                if(error.response.status === 500){
                    dispatch({type: 'messageError', payload: ['Error interno del servidor al agregar circulo']});
                    dispatch({type: 'setLoading', payload: false});
                }
                dispatch({type: 'messageError', payload: error.response.data?.errors});
                dispatch({type: 'setLoading', payload: false});
            })
            dispatch({type: 'circuloAgregado', payload: false});
        } else {
            dispatch({type: 'circuloAgregado', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}states`, {headers})
        .then(response => {
          dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}departments?stateId=${state.items.state}&offset=0&limit=200`, {headers})
        .then(response => {
          dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}towns?stateId=${state.items.state}&departmentId=${state.items.department}&offset=0&limit=200`, {headers})
        .then(response => {
          dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
          if(error.response.status === 401){
            window.location.href = '/'
          }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getCalles = async () => {
        const urlCalles = `https://apis.datos.gob.ar/georef/api/calles?departamento=${state.items.department}&localidad_censal=${state.items.town}&max=1000`
        const res = await axios.get(urlCalles)
        dispatch({type: 'getCalles', payload: res.data.calles});
    }

    const clearValues = () => {
        dispatch({type: 'clearValues', payload: {
            circlesId: 0,
            idFopbaInsider: '',
            cuit: '',
            email: '',
            name: '',
            phone: '',
            cellPhone: '',
            street: '',
            height: '',
            floor: '',
            apartment: '',
            postalCode: '',
            town: 0,
            department: 0,
            state: 0,
            country: '',
            circleState: {
                circlesStatesId: 0,
                description: ''
            },
            authorityName: '',
            secretaryName: '',
            observation: ''
        }})
        dispatch({type: 'setLoading', payload: false});
    }
    const getFilterCircles = async () => {

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        
        dispatch({ type: "setLoading", payload: true });

        let params = '';
        if(state.circleNameFilter && state.circleIdFilter){
            params = `&name=${state.circleNameFilter}&idFopbaInsider=${state.circleIdFilter}`
        }else if (state.circleNameFilter){
            params = `&name=${state.circleNameFilter}`
        }else if (state.circleIdFilter){
            params = `&idFopbaInsider=${state.circleIdFilter}`
        }

        await axios
          .get(
            `${process.env.REACT_APP_BACKOFFICE_API}circles?offset=0&limit=500${params}`,
            { headers }
          )
          .then((response) => {
            dispatch({ type: "getFilterCircles", payload: response.data });
            dispatch({ type: "filtered", payload: true });
            dispatch({ type: "setLoading", payload: false });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            dispatch({ type: "setLoading", payload: false });
          });
      };
    
    const clearFilter = () => {
        dispatch({ type: "clearFilter" });
        // si se filtro llamo al get de círculos para traer todos los resultado nuevamente
        if (state.filtered) {
          getCirculos();
          dispatch({ type: "filtered", payload: false });
        }
    };
    const handleChangeFilter = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        dispatch({
          type: "handleChangeFilter",
          payload: { value: event.target.value, name: event.target.name },
        });
    };

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    return(
        <CirculoContext.Provider value={{
            state,
            getCirculos,
            circlesNextPage,
            getCirculoById,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getCalles,
            handleChangeUbicacion,
            handleChangeInput,
            handleOpenConfirm,
            handleCloseConfirm,
            addCirculo,
            clearValues,
            closeAlert,
            deleteCirculo,
            editCirculo,
            getFilterCircles,
            clearFilter,
            handleChangeFilter,
            handleClickCheck
        }}>
            {children}
        </CirculoContext.Provider>
    )
}

export default CirculoProvider