import { LoginState } from '../../../interfaces/Login';

type Action = {type: 'handleChange', payload: {name: string, value: string}}
    | {type: 'loginError', payload: boolean}
    | {type: 'wrongUser', payload: boolean}
    | {type: 'cargando', payload: boolean}
    | {type: 'userBlocked', payload: boolean}
    | {type: 'userNotEnabled', payload: boolean}

export default function LoginReducer (state: LoginState, action: Action): LoginState {
    switch (action.type) {
        case 'handleChange':
            return { 
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'loginError':
            return { 
                ...state,
                error: action.payload
            }
        case 'wrongUser':
            return { 
                ...state,
                wrongUser: action.payload
            }
        case 'cargando':
            return { 
                ...state,
                cargando: action.payload
            }
        case 'userBlocked':
            return { 
                ...state,
                userBlocked: action.payload
            }
        case 'userNotEnabled':
            return { 
                ...state,
                userNotEnabled: action.payload
            }
        default:
            return state;
    }
}