import { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import MenuItem from '@mui/material/MenuItem';
import { Box, TextField } from "@mui/material";

interface Props {
    latAndLng: string,
    street: string,
    handleChangeSearchAddressValue: (name: string, value: string) => void
    setLatAndLng: (value: string) => void
    setAddress: (value: string) => void
}

export default function SearchBox({latAndLng, street, handleChangeSearchAddressValue, setLatAndLng, setAddress}: Props) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : '',
        libraries: ["places"],
    });

    if (!isLoaded){
        return <div>Cargando...</div>;
    } 
    return <Map 
        latAndLng={latAndLng} 
        street={street}
        setLatAndLng={setLatAndLng} 
        setAddress={setAddress} 
        handleChangeSearchAddressValue={handleChangeSearchAddressValue} 
    />;
}

function Map({latAndLng, street, setLatAndLng, setAddress, handleChangeSearchAddressValue}: Props) {
    
    // la latitud y longitud se guardo en el campo placeId separados por coma
    // y lo que hago aca es separarlos para obtener la lat y lng por otro lado
    const latAndLngSplit = latAndLng?.split(',')
    const lat = Number(latAndLngSplit[0])
    const lng = Number(latAndLngSplit[1])

    const center = useMemo(() => ({ lat: lat, lng: lng}), []);
    const [selected, setSelected] = useState(center);

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    return (
        <>
            <div className="places-container">
                <PlacesAutocomplete setSelected={setSelected} setAddress={setAddress} setLatAndLng={setLatAndLng} street={street} handleChangeSearchAddressValue={handleChangeSearchAddressValue} />
            </div>

            <GoogleMap
                zoom={15}
                center={selected ? selected : center}
                mapContainerStyle={containerStyle}
            >
                {selected && <Marker position={selected} />}
            </GoogleMap>
        </>
    );
}

const PlacesAutocomplete = ({ setSelected, setLatAndLng, setAddress, street, handleChangeSearchAddressValue }: any) => {
    const {
        ready,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address: any) => {
        setAddress(address)
        setValue(address, false);
        clearSuggestions();
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat, lng });
        setLatAndLng(lat + ',' + lng)
    };

    return (
        <Box>
            <TextField 
                fullWidth
                value={street}
                label='Dirección'
                disabled={!ready}
                onChange={(e) => {handleChangeSearchAddressValue('street', e.target.value); setValue(e.target.value)} }
            />
            <Box mt={3}>
                {status === "OK" &&
                    data.map(({ place_id, description }) => {
                        return <MenuItem onClick={() => handleSelect(description)} key={place_id} value={description}>{description}</MenuItem>
                    }
                )}
            </Box>
        </Box>

    );
};