import ButtonMui from '@mui/material/Button';

interface Props {
    disabled?: boolean
    variant: "text" | "outlined" | "contained" | undefined
    child: string | JSX.Element
    color?: "primary" | "secondary" | "error" | "inherit" | "warning" | "success" | "info"
    type?: "button" | "submit"
    id?: string | undefined
    handleClick?: (id: string | undefined) => void
}

const Button = ({disabled, variant, child, color, type, id, handleClick}: Props) => {
    return (
        <ButtonMui 
            fullWidth 
            type={type}
            color={color}
            disabled={disabled} 
            variant={variant} 
            onClick={handleClick ? () => handleClick(id) : () => {}}
        >
            {child}
        </ButtonMui>
    )
}

export default Button