import CardMui from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

interface Props {
    icon?: JSX.Element
    name: string
    url: string
}

const CardStyled = styled(CardMui)(() => ({
    background: 'linear-gradient(90deg, rgba(100,170,178,1) 0%, rgba(71,146,155,1) 50%, rgba(58,164,177,1) 100%)',
    border: '1px solid #4DBEB9',
    boxShadow: '3px 3px 6px gray',
    borderRadius: 20,
    height: '130px',
    color: '#FFF',
    '&:hover': {
        background: 'linear-gradient(90deg, rgba(100,153,178,1) 0%, rgba(71,125,155,1) 50%, rgba(58,126,177,1) 100%)',
        cursor: 'pointer'
    }
}));

const CardContentStyled = styled(CardContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '13px',
    textAlign: 'center'
}));

const TypographyStyled = styled(Typography)(() => ({
    fontWeight: 'bolder',
}));

const Card = ({icon, name, url}: Props) => {

    return (
        <CardStyled>
            <Link to={`/${url}`} style={{color: '#fff', textDecoration: 'none'}}>
                <CardContentStyled>
                    {icon}
                    <TypographyStyled gutterBottom>{name}</TypographyStyled>
                </CardContentStyled>
            </Link>
        </CardStyled>
    )
}

export default Card