import { 
    Box, 
    Button, 
    Paper, 
    TableContainer, 
    Table, 
    TableBody, 
    TableHead, 
    TableRow, 
    TableFooter, 
    TablePagination, 
    Grid,
    FormControl,
    Divider,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import Pagination from '../Pagination';
import InsuranceContext from '../context/Insurances/InsuranceContext';
import { Link } from 'react-router-dom'
import Loader from '../Loader';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ListPermissions from '../../utils/ListPermissions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ButtonCustom = styled(Button)(() => ({
    backgroundColor: '#64AAB2',
    '&:hover': {
        backgroundColor: '#73c9d3'
    },
}));

const Index = () => {

    const {
        state, 
        getInsurances,
        closeAlert,
        handleChangeSelect,
        getInsurancePlan,
        clearValuesItemsPlan
    } = useContext(InsuranceContext)

    useEffect(() => {
        getInsurances()
        if(state.insuranceSelected){
            getInsurancePlan(state.insuranceSelected)
        }
    }, [state.insuranceSelected])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(!state.data.length || state.loading){
        return <Loader />
    }

    const planState = (planStateId: number) => {
        switch (planStateId) {
            case 1:
                return 'Activo'
            case 2:
                return 'Inactivo'
            case 3:
                return 'Eliminado'
            default:
                return 'Activo'
        }
    }

    return (
        <Box sx={{ margin: '30px 15px 0px 15px' }}>
            <h4>Planes de Obras Sociales</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container alignItems='center' mb={3}>
                {(ListPermissions().crearObrasSociales?.status && state.insuranceSelected > 0) &&
                    <Grid item xs={6} sm={6} md={4}>
                        <ButtonCustom variant='outlined' onClick={() => {closeAlert(); clearValuesItemsPlan()}}>
                            <Link to={`/agregar-plan/${state.insuranceSelected}`} style={{color: '#fff', textDecoration: 'none', width: '100%'}}>Agregar plan de obra social</Link>
                        </ButtonCustom>
                    </Grid>
                }
                {(ListPermissions().crearObrasSociales?.status && state.insuranceSelected <= 0) &&
                   <Grid item xs={6} sm={6} md={4}>
                        <Tooltip title='Para agregar un plan primero debes seleccionar una obra social'>
                            <Button sx={{color: 'rgba(0, 0, 0, 0.26)', border: '1px solid rgba(0, 0, 0, 0.12)'}} variant='outlined'>Agregar plan de obra social</Button>
                        </Tooltip>
                   </Grid>
                }
                <Grid item xs={6} sm={6} md={ListPermissions().crearObrasSociales?.status ? 8 : 12}>
                    <FormControl fullWidth variant='standard' margin='dense'>
                        <InputLabel>Obra Social</InputLabel>
                        <Select
                            fullWidth
                            label="Obra Social"
                            name='insuranceSelected'
                            value={state.insuranceSelected ? state.insuranceSelected : ''}
                            onChange={handleChangeSelect}
                        >
                            {state.data.map((d) => (
                                <MenuItem key={d.insurancesId} value={d.insurancesId}>{d.idFopbaInsider} - {d.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {state.dataInsurancePlan.length > 0 &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Descripción</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Estado</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 
                                ? state.dataInsurancePlan.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : state.dataInsurancePlan
                            ).map((row) => (
                                <StyledTableRow
                                    key={row.plansId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.code}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.description}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">{planState(row.planStateId)}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">
                                        <Link to={`/detalle-de-plan/${state.insuranceSelected}/${row.plansId}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                            <RemoveRedEyeOutlinedIcon />
                                        </Link>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={state.data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    
                                    labelRowsPerPage='Filas por página'
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}

export default Index