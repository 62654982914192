import { Grid, TextField, Box, Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { useContext, useState } from "react"
import UserContext from "../context/Users/UserContext"
import { styled } from '@mui/material/styles';
import Pagination from '../Pagination';
import Divider from '@mui/material/Divider';
import Loader from '../Loader';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {

    const { 
        handleChangeFile,
        addMultipleUsers,
        state 
    } = useContext(UserContext)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addMultipleUsers()
    }

    // concateno en un mismo array los usuarios que dieron error y los usuarios que se crearon correctamente
    const multipleUsers = state.multipleUsers.usersError.concat(state.multipleUsers.usersCreated)
    
    if(state.multipleUsersLoading){
        return <Loader />
    }

    return (
        <Box sx={{ margin: '20px 15px 30px 15px' }} component='form' onSubmit={handleSubmit}>
            <h4>Multiples Usuarios</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container alignItems='center' justifyContent='space-between' sx={{mb: 4}}>
                <Grid item xs={12} sm={6} md={5}>
                    <TextField
                        fullWidth
                        placeholder='Usuario'
                        name='usersFile'
                        variant="outlined"
                        type="file"
                        onChange={handleChangeFile}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <Button fullWidth type='submit' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}}} disabled={!state.usersFile}>Procesar</Button>
                </Grid>
            </Grid>
            
            {multipleUsers.length ?   
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="center">ID</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Nombre</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Apellido</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {multipleUsers.map((row) => {
                                // separo nombre lastname y cuit ya que cuando hay un error viene el user todo junto
                                const name = row.user ? row.user.split(" ")[0] : ''
                                const lastname = row.user ? row.user.split(" ")[1] : ''
                                const cuit = row.user ? row.user.split(" ")[3] : ''

                                return <StyledTableRow
                                    key={row.usersId ? row.usersId : cuit}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'td, th': {color: `${row.user ? 'red' : 'green'}`}  }}
                                >
                                    <StyledTableCell sx={{padding: '5px'}} component="th" scope="row" align="center">
                                        {row.usersId ? row.usersId : cuit}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{row.name ? row.name : name}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{row.lastname ? row.lastname : lastname}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="center">{row.reason ? row.reason : 'OK'}</StyledTableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={state.data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            : null}
        </Box>
    )
}

export default Index