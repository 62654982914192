import React from 'react'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChapterList, InsurancePlanItems } from '../../interfaces/Insurances';

const DialogStyled = styled(Dialog)(() => ({
    '.MuiDialog-paper': {
        width: 600
    }
}))

interface Props {
    chapterList: ChapterList[]
    openModal: boolean
    add: boolean
    handleCloseModal: () => void
    handleChangePlanCodes: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeChapter: (event:SelectChangeEvent<number>) => void
    planItems: InsurancePlanItems
    addCodes: () => void
}

const ModalAddCodes = ({chapterList, openModal, add, handleCloseModal, handleChangePlanCodes, handleChangeChapter, planItems, addCodes}: Props) => {
    
    return (
        <DialogStyled
            open={openModal}
            onClose={handleCloseModal}
        >
            <DialogContent>
                <Box>
                    <TextField
                        margin="normal"
                        label="Nomenclador"
                        name='code'
                        variant="standard"
                        fullWidth
                        value={planItems.itemsCodesPlan.code ? planItems.itemsCodesPlan.code : ''}
                        onChange={handleChangePlanCodes}
                    />
                    <TextField
                        margin="normal"
                        label="Descripción"
                        name='description'
                        variant="standard"
                        fullWidth
                        value={planItems.itemsCodesPlan.description ? planItems.itemsCodesPlan.description : ''}
                        onChange={handleChangePlanCodes}
                    />
                    <FormControl fullWidth variant='standard' margin='normal'>
                        <InputLabel>Capitulo</InputLabel>
                        <Select
                            label="Estado"
                            name='chapterId'
                            value={planItems.itemsCodesPlan.chapterId ? planItems.itemsCodesPlan.chapterId : ''}
                            onChange={handleChangeChapter}
                        >
                            {chapterList.map((c) => (
                                <MenuItem key={c.chaptersId} value={c.chaptersId}>{c.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="normal"
                        label="Arancel"
                        name='customsDuty'
                        type='number'
                        variant="standard"
                        fullWidth
                        value={planItems.itemsCodesPlan.customsDuty ? planItems.itemsCodesPlan.customsDuty : ''}
                        onChange={handleChangePlanCodes}
                    />
                    <TextField
                        margin="normal"
                        label="Porcentaje Afiliado"
                        name='member'
                        type='number'
                        variant="standard"
                        fullWidth
                        value={planItems.itemsCodesPlan.member ? planItems.itemsCodesPlan.member : ''}
                        onChange={handleChangePlanCodes}
                    />
                    <DialogActions>
                        <Button onClick={handleCloseModal} variant='contained' color='inherit'>Volver</Button>
                        <Button onClick={add ? addCodes : handleCloseModal} variant='contained'>{add ? 'Agregar' : 'Guardar'}</Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </DialogStyled>
    )
}

export default ModalAddCodes