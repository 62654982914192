import { useContext, useEffect, useState } from "react"
import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import DentalRecordsContext from "../context/DentalRecords/DentalRecordsContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link, useParams } from "react-router-dom";
import Button from '../Button';
import ListPermissions from "../../utils/ListPermissions";
import ConfirmModal from "./ConfirmModal";
import Odontogram from "./Odontogram/Odontogram";

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#64AAB2',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F1F7FE',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Detail = () => {

    const { dentalRecordState,setProviders, getDentalRecordsById, getInsurances, getDentalRecordStates, getInsuranceStatus, getCirles, getSexTypes, modifyDentalRecordStatus, getDentalDetail, getIvaTypes, getLocalidades } = useContext(DentalRecordsContext)

    const {id, status} = useParams();

    useEffect(() => {
        setProviders([]);
        getDentalRecordsById(Number(id))
        getInsurances()
        getInsuranceStatus()
        getCirles()
        getDentalDetail()
        getSexTypes()
        getDentalRecordStates()
        getIvaTypes()
        getLocalidades()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [approved, setApproved] = useState(false);

    const handleOpenConfirmModal = () => {
        setOpenConfirmModal(!openConfirmModal)
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadFiles = (data: string, fileName: string) => {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      };
      
      //función para descargar los archivos
    const handleDownloadArchives = () => {
        dentalRecordState.items.dentalRecordsFiles.forEach((record: any, index: number) => {
        const [contentType, base64Data] = record.dentalFile.split(';base64,');
        const fileExtension = contentType.split('/')[1];
        const fileName = `Adjunto Nº${index + 1}.${fileExtension}`;
        downloadFiles(base64Data, fileName);
        });
    };
    

    if(dentalRecordState.loading){
        return <Loader />
    }

    const insuranceData = dentalRecordState.dataInsurance.find(item => item.insurancesId === dentalRecordState.items.patientDetail.insuranceId)
    const ivaTypeData = dentalRecordState.ivaTypesData.find(item => item.ivaTypeId === dentalRecordState.items.patientDetail.ivaTypeId)?.description
    const localidadSelected = dentalRecordState.localidadesData.find(item => item.townsId === Number(dentalRecordState.items.patientDetail.town))?.name
    return (
        <Box sx={{ margin: '20px 15px 0px 15px' }}>
            <Box sx={{background: "#BEEBFF", padding: '15px', borderRadius: 2}}>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Typography sx={{fontWeight: 'bold'}}>F.O.P.B.A</Typography>
                    </Grid>
                    {dentalRecordState.items.createDate ? 
                    <Grid item>
                        <Typography sx={{fontWeight: 'bold'}}>Fecha de Creación: {dentalRecordState.items.createDate}</Typography>
                    </Grid>
                    : null }
                    {dentalRecordState.items.bond ? 
                    <Grid item>
                        <Typography sx={{fontWeight: 'bold'}}>N° de Bono: {dentalRecordState.items.bond}</Typography>
                    </Grid>
                    : null }
                    {dentalRecordState.items.token ? 
                    <Grid item>
                        <Typography sx={{fontWeight: 'bold'}}>Token: {dentalRecordState.items.token}</Typography>
                    </Grid>
                    : null }
                </Grid>
            </Box>
            <h4>Datos Personales del Paciente</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

            <Grid container spacing={3} mb={3}>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Nombre:</TypographyStyled>{dentalRecordState.items.patientDetail.name}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Apellido: </TypographyStyled>{dentalRecordState.items.patientDetail.lastname}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>N° Afiliado: </TypographyStyled>{dentalRecordState.items.patientDetail.code}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>DNI: </TypographyStyled>{dentalRecordState.items.patientDetail.document}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Domicilio: </TypographyStyled>{dentalRecordState.items.patientDetail.streetNumber ? dentalRecordState.items.patientDetail.street + ' ' + dentalRecordState.items.patientDetail.streetNumber : dentalRecordState.items.patientDetail.street}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Localidad: </TypographyStyled>{localidadSelected}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Sexo: </TypographyStyled>{dentalRecordState.sexTypesList.find(item => item.sexTypeId === dentalRecordState.items.patientDetail.sex)?.description}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Teléfono: </TypographyStyled>{dentalRecordState.items.patientDetail.phone}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Condición Fiscal: </TypographyStyled>{ivaTypeData}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                     <TypographyStyled>Boca Sana: </TypographyStyled>{dentalRecordState.items.healthyMouth ? "SI" : "NO"}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Cantidad de Piezas: </TypographyStyled>{`${dentalRecordState.items.amountPieces ?? 0}`}
                </Grid>
            </Grid>

            <Box sx={{background: "#BEEBFF", padding: '15px', borderRadius: 2}} mb={3}>
                <Grid container justifyContent='space-between'>
                    {dentalRecordState.items.professionalDetail.circleId ?
                        <Grid item container md={6}>
                            <TypographyStyled>Entidad Primaria:</TypographyStyled>{dentalRecordState.circleList.filter(item => item.circlesId === dentalRecordState.items.professionalDetail.circleId).map(item => item.idFopbaInsider + ' - ' + item.name)}
                        </Grid>
                    : null}
                    <Grid item container md={3}>
                        <TypographyStyled>Obra Social:</TypographyStyled>{insuranceData?.idFopbaInsider} - {insuranceData?.name}
                    </Grid>
                </Grid>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow> 
                            <StyledTableCell sx={{padding: '5px'}} align="left">Nomenclador</StyledTableCell>
                            <StyledTableCell sx={{padding: '5px'}} align="left">Pieza</StyledTableCell>
                            <StyledTableCell sx={{padding: '5px'}} align="left">Cara</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 
                            ? dentalRecordState.items.treatments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : dentalRecordState.items.treatments
                        ).map((row: any) => (
                            <StyledTableRow
                                key={row.treatmentsId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell sx={{padding: '5px'}} align="left">{`${row?.textCode?? ''} - ${row?.description ?? ''}`}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} component="th" scope="row" align="left">{`${row?.pieceNumber ?? ''} - ${dentalRecordState.dentalDetail.pieces.find(item => item.piecesId === row.pieceId)?.description}`}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} align="left">{row?.faceName}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                colSpan={3}
                                count={dentalRecordState.items.treatments.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Filas por pagina',
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage='Filas por Página'
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={() => <Pagination count={dentalRecordState.items.treatments.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            {dentalRecordState.items.observers ?
                <Grid item container sm={12} md={12} xs={12} mt={4} mb={4}>
                    <TypographyStyled>Diagnóstico General: </TypographyStyled>{dentalRecordState.items.observers}
                </Grid>
            : null }

            <Grid container>
                <Grid item md={6} mt={3}>
                    <MuiButton variant="contained" onClick={handleDownloadArchives} disabled={dentalRecordState.items.dentalRecordsFiles.length === 0}>
                        Descargar Archivos Adjuntos
                    </MuiButton>
                </Grid>
            </Grid>

            <h4>Datos Personales del Profesional</h4>
            {/* con el profesionalId obtener los datos del profesional */}
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <Grid container spacing={3} mb={7}>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Nombre:</TypographyStyled>{dentalRecordState.items.professionalDetail.professionalName}
                </Grid>
                <Grid item container xs={6} sm={6} md={4}>
                    <TypographyStyled>Apellido:</TypographyStyled>{dentalRecordState.items.professionalDetail.professionalLastname}
                </Grid>
                <Grid item container xs={12} sm={12} md={4}>
                    <TypographyStyled>N° Matrícula {dentalRecordState.items.isNationalEnrollment ? 'Nacional: ' : 'Provincial: '}</TypographyStyled>{dentalRecordState.items.professionalEnrollment}
                </Grid>
            </Grid>

            <h4>Historial</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell sx={{padding: '5px'}} align="left">Estado</StyledTableCell>
                            <StyledTableCell sx={{padding: '5px'}} align="left">Comentario</StyledTableCell>
                            <StyledTableCell sx={{padding: '5px'}} align="left">Fecha</StyledTableCell>
                            <StyledTableCell sx={{padding: '5px'}} align="left">Usuario</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 
                            ? dentalRecordState.items.histories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : dentalRecordState.items.histories
                        ).map((row) => (
                            <StyledTableRow
                                key={row.dentalRecordHistoriesId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell sx={{padding: '5px'}} component="th" scope="row" align="left">{dentalRecordState.dentalRecordStates.find(item => item.dentalRecordStatesId === row.status)?.description}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} align="left">{row.comment}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} align="left">{row.date}</StyledTableCell>
                                <StyledTableCell sx={{padding: '5px'}} align="left">{row.userId}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                colSpan={3}
                                count={dentalRecordState.items.treatments.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Filas por pagina',
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage='Filas por Página'
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={() => <Pagination count={dentalRecordState.items.treatments.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Box sx={{mt:2}}>
                      <Odontogram pushProviders={() =>{}} providers={dentalRecordState?.items?.providers ?? []}  disabled={true}/>
            </Box>

            {/* muestro botones si tiene permiso para modificar fichas y si el estado de la ficha es “Pendiente FOPBA” */}
            {ListPermissions().modificarFichas?.status && Number(status) === 3 ? 
                <Grid container justifyContent='center' spacing={3} alignItems='center' mt={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <Button child='Rechazar' handleClick={() => {handleOpenConfirmModal(); setApproved(false)}} variant='outlined' color="error" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Button child='Aprobar' handleClick={() => {handleOpenConfirmModal(); setApproved(true)}} variant='contained' color="secondary" />
                    </Grid>
                </Grid>
            : null}



            <Grid container justifyContent='end' mt={5} mb={4}>
                <Grid item xs={4} sm={4} md={3}>
                    <Button 
                        child={
                            <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/fichas'}>
                                Volver
                            </Link>
                        } 
                        variant='outlined' 
                        color='secondary'
                    />
                </Grid>
            </Grid>

            <ConfirmModal
                openModal={openConfirmModal}
                handleCloseModal={handleOpenConfirmModal}
                modifyDentalRecordStatus={modifyDentalRecordStatus}
                status={approved ? 4 : 6}
                id={Number(id)}
                approved={approved}
            />
        </Box>
    )
}

export default Detail