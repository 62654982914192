import { useContext, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListPermissions from '../utils/ListPermissions'
import Typography from '@mui/material/Typography';
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../interfaces/Login';
// Icons
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FeedIcon from '@mui/icons-material/Feed';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ReplayIcon from '@mui/icons-material/Replay';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import UserContext from './context/Users/UserContext';
import Card from './Card';

const TypographyStyled = styled(Typography)(() => ({
    color: '#64AAB2',
    fontWeight: 'bolder',
    marginRight: 8
}));

export default function Home() {

    const { state, getUserById, getProfiles, getCirculos } = useContext(UserContext);

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    
    const profileCircle = decoded.profileId === 4

    useEffect(() => {
        getCirculos()
        getUserById(decoded.sub)
        getProfiles()
    }, []);
      
    const profileName = state.profiles.find(profile => profile.profilesId === decoded.profileId);
    const circleName = state.circles.find(c => c.circlesId === decoded.circleId);

    return (
        <Box sx={{ margin: '20px 15px 0px 15px' }}>
            <Grid container spacing={3} mt={2} mb={8} justifyContent='space-between'>
                <Grid item container xs={12} sm={6} md={profileCircle ? 6 : 4}>
                    <TypographyStyled variant='h5'>Hola </TypographyStyled><Typography variant='h5'>{state.items.name} {state.items.lastname}!</Typography>
                </Grid>
                <Grid item container xs={12} sm={6} md={profileCircle ? 6 : 4}>
                    <TypographyStyled variant='h5'>Rol:</TypographyStyled><Typography variant='h5'>{decoded.rol.description}</Typography>
                </Grid>
                <Grid item container xs={12} sm={6} md={profileCircle ? 6 : 4}>
                    <TypographyStyled variant='h5'>Perfil:</TypographyStyled><Typography variant='h5'>{profileName?.description}</Typography>
                </Grid>
                {profileCircle ?
                    <Grid item container xs={12} sm={6} md={profileCircle ? 6 : 4}>
                        <TypographyStyled variant='h5'>Circulo:</TypographyStyled><Typography variant='h5'>{circleName?.idFopbaInsider} - {circleName?.name}</Typography>
                    </Grid>
                : null}
            </Grid>
            <Typography variant='h6'>Secciones</Typography>
            <Divider sx={{background: '#64AAB2'}} />
            <Grid container justifyContent='flex-start' spacing={3} mt={1} mb={2}>
                {ListPermissions().listarUsuario?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Usuarios'
                            url='usuarios'
                            icon={<PersonOutlineOutlinedIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().crearUsuario?.status && !profileCircle ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Alta Masiva'
                            url='multiple-usuarios'
                            icon={<SupervisorAccountOutlinedIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().manejoRoles?.status && !profileCircle ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Roles'
                            url='roles'
                            icon={<AssignmentIndIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().manejoPermisos?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Permisos'
                            url='permisos'
                            icon={<AdminPanelSettingsIcon />}
                        />
                    </Grid>
                : null}
                {!profileCircle ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Estado Prestador'
                            url='estado-prestador'
                            icon={<AccessibilityIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarObrasSociales?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Obras Sociales'
                            url='obras-sociales'
                            icon={<MedicalInformationIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarObrasSociales?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Planes'
                            url='planes'
                            icon={<FactCheckIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarObrasSociales?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Afiliados'
                            url='afiliados'
                            icon={<FactCheckIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarCirculos?.status && !profileCircle ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Círculos'
                            url='circulos'
                            icon={<RadioButtonUncheckedIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarFichas?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Fichas'
                            url='fichas'
                            icon={<ReceiptLongIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarEmpadronamientos?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Empadronamiento Búsqueda'
                            url='empadronamiento'
                            icon={<AppRegistrationIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarEmpadronamientos?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card
                            name='Reempadronamiento Global'
                            url='reempadronamiento'
                            icon={<ReplayIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().listarEmpadronamientos?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Reempadronamiento Individual'
                            url='reempadronamiento-particular'
                            icon={<GroupAddIcon />}
                        />
                    </Grid>
                : null}
                {ListPermissions().contactos?.status ?
                    <Grid item xs={6} sm={4} md={2.4}>
                        <Card 
                            name='Datos Fopba'
                            url='contacto'
                            icon={<FeedIcon />}
                        />
                    </Grid>
                : null}
            </Grid>
        </Box>
    );
}