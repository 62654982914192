import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import Theme from './Theme';
import LoginProvider from './components/context/Login/LoginProvider';
import RolesProvider from './components/context/Roles/RolesProvider';
import UserProvider from './components/context/Users/UserProvider';
import PermisosProvider from './components/context/Permisos/PermisosProvider';
import CirculoProvider from './components/context/Circulos/CirculoProvider';
import InsuranceProvider from './components/context/Insurances/InsuranceProvider';
import ProfileProvider from './components/context/Profile/ProfileProvider';
import ContactProvider from './components/context/Contact/ContactProvider';
import AffiliateProvider from './components/context/Affiliate/AffiliateProvider';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import EmpadronamientoProvider from './components/context/Empadronamiento/EmpadronamientoProvider';
import DentalRecordsProvider from './components/context/DentalRecords/DentalRecordsProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const viewLogin = window.location.pathname === '/'

// si no tiene seteado el token y esta en alguna vista lo redirijo al login para que inicie sesion
if(!localStorage.getItem('token') && !viewLogin){
  window.location.href = '/'
}

root.render(
  <BrowserRouter>
    <ThemeProvider theme={Theme}>
      <LoginProvider>
        <RolesProvider>
          <PermisosProvider>
            <CirculoProvider>
              <InsuranceProvider>
                <UserProvider>
                  <ProfileProvider>
                    <ContactProvider>
                      <AffiliateProvider>
                        <EmpadronamientoProvider>
                          <DentalRecordsProvider>
                            <App />
                          </DentalRecordsProvider>
                        </EmpadronamientoProvider>
                      </AffiliateProvider>
                    </ContactProvider>
                  </ProfileProvider>
                </UserProvider>
              </InsuranceProvider>
            </CirculoProvider>
          </PermisosProvider>
        </RolesProvider>
      </LoginProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
