import { useReducer } from 'react'
import axios from 'axios';
import LoginReducer from './LoginReducer';
import LoginContext from './LoginContext';
import jwt_decode from "jwt-decode";
import { LoginState, tokenDecode } from '../../../interfaces/Login';

const INITIAL_STATE: LoginState = {
    email: '',
    contraseña: '',
    error: false,
    cargando: false,
    wrongUser: false,
    userBlocked: false,
    userNotEnabled: false
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const LoginProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(LoginReducer, INITIAL_STATE)

    const getPermisos = async () => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'offset': 0,
            'limit': 20
        };

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');

        await axios.get(`${process.env.REACT_APP_BACKOFFICE_API}roles/${decoded.rolesId}/policies`, {headers})
        .then(response => {
            let permissions: any = [];
            response.data.map((d: any) => {
                if(d.policies.length){
                    return d.policies.map((p: any) => {
                        permissions.push(p)
                    })
                }
            })
            // seteo array de permisos en localStorage
            localStorage.setItem('permissions', JSON.stringify(permissions));
            // redirect a home luego de hacer login y tener los permisos almacenados
            window.location.href = '/inicio'
            
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            } else if(error.response.status === 403){
                window.location.href = '/inicio'
            }
        })
    }

    const doLogin = async (email: string, contraseña: string) => {

        const data = {
            "username" : email,
            "password" : contraseña,
            "access" : "backoffice"
        }

        dispatch({type: 'cargando', payload: true})

        if(email && contraseña){
            await axios.post(`${process.env.REACT_APP_AUTENTICACION_API}login`, data)
            .then(response => {
                if(response.status === 201){
                    // una vez que se haga login seteo token en localStorage
                    localStorage.setItem('token', response.data.access_token);
                    getPermisos()
                    dispatch({type: 'loginError', payload: false})
                    dispatch({type: 'wrongUser', payload: false})
                    dispatch({type: 'userBlocked', payload: false})
                    dispatch({type: 'userNotEnabled', payload: false})
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch({type: 'wrongUser', payload: true})
                }
                if(error.response.data?.block){
                    dispatch({type: 'userBlocked', payload: true})
                }
                if(error.response?.message === 'userNotEnabled'){
                    dispatch({type: 'userNotEnabled', payload: true})
                }
                dispatch({type: 'cargando', payload: false})
            })
        } else {
            dispatch({type: 'loginError', payload: true})
            dispatch({type: 'cargando', payload: false})
        }
    }

    const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChange', payload: {name: e.target.name, value: e.target.value}})
        dispatch({type: 'wrongUser', payload: false})
    }

    return(
        <LoginContext.Provider value={{
            state,
            doLogin,
            handleChangeValues
        }}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginProvider