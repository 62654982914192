import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link, useParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useContext, useEffect } from "react";
import UserContext from "../context/Users/UserContext";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "moment/locale/es";
import moment from "moment";
import "./style.css";
import Loader from "../Loader";
import Confirm from "../../components/Confirm";
import Alert from "../Alert";
import ListPermissions from "../../utils/ListPermissions";
import jwt_decode from "jwt-decode";
import { tokenDecode } from '../../interfaces/Login';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const UserDetail = () => {
    const {
        getUserById,
        getProvincias,
        getDepartamentos,
        getLocalidades,
        getCirculos,
        handleChangeUbicacion,
        handleChangeInput,
        handleChangeCheckbox,
        handleChangeSelect,
        handleToggle,
        handleChangeDatePicker,
        editUser,
        getProfiles,
        getDocumentTypes,
        getRoles,
        deleteUser,
        handleOpenConfirm,
        handleCloseConfirm,
        closeAlert,
        handleChangeDocumentType,
        handleChangeSelectCirculos,
        handleClickCheck,
        getIvaTypes,
        state,
    } = useContext(UserContext);
    const { id } = useParams();

    useEffect(() => {
        if(!state.items.usersId){
            getUserById(Number(id));
            getProfiles();
            getDocumentTypes(true);
            getRoles();
            getCirculos();
            getIvaTypes();
            getProvincias();
        } else if(state.items.usersId && !state.loadingDatosDemograficos){
            getDepartamentos();
            getLocalidades();
        }
    }, [state.items.usersId, state.items.state, state.items.department]);

    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    useEffect(() => {
        if(Number(localidadSelected?.postalCode) !== 0){
            state.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
        }
    }, [localidadSelected])
    
    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    if (!state.items.usersId && !state.loading && !state.loadingDatosDemograficos) {
        return <Loader />;
    }

    const fieldsDisabled = !ListPermissions().editarUsuario?.status || state.items.userState?.description === 'Inactivo';

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.items.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.items.department))

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    const profileCircle = decoded.profileId === 4

    const circlesWithStatusTrue = state.items.circlesChecked.filter(item => item.status === true)
    const circuloSelected = state.circles.filter(item => item.circlesId === state.items.circlesId[0])
    // formateo valor para select de circulo que se muestra cuando el perfil es circulo
    const formatValueCircle = {
        circlesId: circuloSelected.length ? circuloSelected[0].circlesId : 0,
        name: circuloSelected.length ? circuloSelected[0].name : '',
        status: true,
        idFopbaInsider: circuloSelected.length ? circuloSelected[0].idFopbaInsider : ''
    }

    return (
        <Box
            className="container"
            component="form"
            sx={{ margin: "20px 15px 30px 15px" }}
        >
            <h4>Datos de Acceso</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container>
                <Grid item xs={6} sm={4} md={4} alignItems="center">
                    <FormControl
                        fullWidth
                        variant="standard"
                        margin="normal"
                        required
                        error={!state.items.profileId && state.userEdited}
                    >
                        <InputLabel>Perfil</InputLabel>
                        <Select
                            className="widthField"
                            label="Perfil"
                            name="profileId"
                            value={`${state.items.profileId ? state.items.profileId : ""}`}
                            disabled={fieldsDisabled || profileCircle}
                            onChange={handleChangeSelect}
                        >
                            {state.profiles.map((profile) => (
                                <MenuItem key={profile.profilesId} value={profile.profilesId}>
                                    {profile.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        margin="normal"
                        required
                        error={!state.items.rolId && state.userEdited}
                    >
                        <InputLabel>Rol</InputLabel>
                        <Select
                            className="widthField"
                            label="Rol"
                            name="rolId"
                            value={`${state.items.rolId ? state.items.rolId : ""}`}
                            disabled={fieldsDisabled || profileCircle}
                            onChange={handleChangeSelect}
                        >
                            {state.roles.map((r) => (
                                <MenuItem key={r.rolesId} value={r.rolesId}>
                                    {r.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                    <FormControlLabel
                        sx={{ margin: "18px 0px 0px 20%" }}
                        disabled={!state.items.block}
                        control={
                            <Checkbox
                                checked={state.items.block}
                                name="block"
                                onChange={handleChangeCheckbox}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Bloqueado"
                    />
                </Grid>
                {/* Perfil General */}
                {state.items.profileId === 2 && (
                    <Grid item xs={6} sm={6} md={12}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            error={!circlesWithStatusTrue.length && state.userAdded}
                            margin="normal"
                        >
                            <Autocomplete
                                multiple
                                fullWidth
                                className="widthField"
                                disabled={profileCircle}
                                value={circlesWithStatusTrue}
                                getOptionLabel={(option) => {
                                    const circleName = state.circles.find(c => c.name === option.name)?.idFopbaInsider
                                    return option.name ? `${circleName} - ${option.name}` : ''
                                }}
                                options={state.circles}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            disabled={state.items.circles.find(item => item.circleId === option.circlesId)?.using}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            onChange={(e) => handleToggle(Number(option.circlesId), e.target.checked, option.name)} 
                                            style={{ marginRight: 8 }}
                                            checked={circlesWithStatusTrue.findIndex(item => item.circlesId === option.circlesId && item.status) !== -1}
                                        />
                                        {option.idFopbaInsider + ' - ' + option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!state.items.state && state.userAdded}
                                        key={params.id}
                                        required
                                        name="circlesId"
                                        label="Círculos"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                        
                )}
                {/* Perfil Circulo */}
                {state.items.profileId === 4 && (
                    <Grid item xs={6} sm={6} md={12}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            error={!circlesWithStatusTrue.length && state.userAdded}
                            margin="normal"
                        >
                            <Autocomplete
                                fullWidth
                                className="widthField"
                                value={formatValueCircle}
                                onChange={(event, newValue) =>
                                    handleChangeSelectCirculos(newValue ? [newValue.circlesId] : [], "circlesId")
                                }
                                getOptionLabel={(option) => {
                                    return option.name ? option.idFopbaInsider + ' - ' + option.name : option.name;
                                }}
                                options={state.circles}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!state.items.state && state.userAdded}
                                        key={params.id}
                                        required
                                        name="circlesId"
                                        label="Circulos"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <h4>Datos Generales</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Nombre"
                        name="name"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.name && state.userEdited}
                        value={state.items.name}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Apellido"
                        name="lastname"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.lastname && state.userEdited}
                        value={state.items.lastname}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <TextField
                        margin="normal"
                        label="Cuil / Cuit"
                        name="cuil"
                        required
                        inputProps={{ maxLength: 13 }}
                        variant="standard"
                        className="widthField"
                        error={!state.items.cuil && state.userEdited}
                        value={state.items.cuil ? state.items.cuil : ""}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 2 : 3}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        margin="normal"
                        required
                        error={!state.documentTypeId && state.userEdited}
                    >
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                            className="widthField"
                            label="Tipo de Documento"
                            name="documentTypeId"
                            value={state.documentTypeId}
                            disabled={fieldsDisabled}
                            onChange={handleChangeDocumentType}
                        >
                            {state.documentType.map((d) => (
                                <MenuItem key={d.documentTypeId} value={d.documentTypeId}>
                                {d.code}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 2 : 3}>
                    <TextField
                        margin="normal"
                        label="Número de Documento"
                        name="documentValue"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.documentValue && state.userEdited}
                        value={state.items.documentValue ? state.items.documentValue : ""}
                        inputProps={state.documentTypeId === 1 ? { maxLength: 8 } : {}}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={state.items.profileId === 2 ? 4 : 6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha de Nacimiento"
                            value={state.items.bornDate ? state.items.bornDate : null}
                            disabled={fieldsDisabled}
                            onChange={(newValue) =>
                                handleChangeDatePicker(
                                moment(newValue).format("YYYY-MM-DD"),
                                "bornDate"
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!state.items.bornDate && state.userEdited}
                                    variant="standard"
                                    required
                                    className="widthField"
                                    margin="normal"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                {state.items.profileId === 2 ? 
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            required
                            margin="normal"
                            error={!state.items.ivaTypesId && state.userEdited}
                        >
                            <InputLabel>Condición Fiscal</InputLabel>
                            <Select
                                label="Condición Fiscal"
                                name="ivaTypesId"
                                value={`${state.items.ivaTypesId ? state.items.ivaTypesId : ""}`}
                                onChange={handleChangeSelect}
                            >
                                {state.ivaTypeList.map(item => (
                                    <MenuItem key={item.ivaTypeId} value={item.ivaTypeId}>{item.description}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                : null}
            </Grid>
            {state.items.profileId === 2 ?
                <>
                    <h4>Datos Demográficos</h4>
                    <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    fullWidth
                                    value={{
                                        statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                        name: provinciaSelected?.name ? provinciaSelected.name : ''
                                    }}
                                    disabled={fieldsDisabled}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={state.provincias}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!state.items.state && state.userEdited}
                                            key={params.id}
                                            required
                                            label="Provincia"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                        name: departamentSelected?.name ? departamentSelected.name : ''
                                    }}
                                    disabled={fieldsDisabled}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={state.departamentos}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!state.items.department && state.userEdited}
                                        key={params.id}
                                        required
                                        label="Partido"
                                        />
                                    )}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Autocomplete
                                    value={{
                                        townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                        name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                                    }}
                                    disabled={fieldsDisabled}
                                    onOpen={!state.localidades.length ? getLocalidades : () => {}}
                                    onChange={(event, newValue) =>
                                        handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                    }
                                    getOptionLabel={(option) => {
                                        return option.name;
                                    }}
                                    options={localidadesFormat}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={!state.items.town && state.userEdited}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                        />
                                    )}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="normal"
                                label="Calle"
                                name="street"
                                required
                                variant="standard"
                                fullWidth
                                value={state.items.street}
                                error={!state.items.street && state.userEdited}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="normal"
                                label="Entre Calle"
                                name="betweenStreet"
                                variant="standard"
                                fullWidth
                                value={state.items.betweenStreet}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <TextField
                                margin="normal"
                                label="Observación"
                                name="observations"
                                variant="standard"
                                fullWidth
                                value={state.items.observations}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Número"
                                name="streetNumber"
                                required
                                variant="standard"
                                fullWidth
                                error={!state.items.streetNumber && state.userEdited}
                                value={state.items.streetNumber ? state.items.streetNumber : ""}
                                disabled={fieldsDisabled}
                                onChange={handleChangeInput}
                            />
                            <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.items.streetNumber === 'S/N'} />} label="Sin Número" />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Piso"
                                name="floor"
                                variant="standard"
                                fullWidth
                                inputProps={{maxLength: "2"}}
                                value={state.items.floor ? state.items.floor : ""}
                                disabled={fieldsDisabled}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Depto"
                                name="apartment"
                                variant="standard"
                                fullWidth
                                value={state.items.apartment ? state.items.apartment : ""}
                                disabled={fieldsDisabled}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <TextField
                                margin="normal"
                                label="Código Postal"
                                name="postalCode"
                                required
                                variant="standard"
                                fullWidth
                                error={!state.items.postalCode && state.userEdited}
                                value={state.items.postalCode ? state.items.postalCode : ""}
                                disabled={fieldsDisabled}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                    </Grid>
                </>
            : null}
            <h4>Datos de Contacto</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name="phone"
                        required
                        variant="standard"
                        className="widthField"
                        error={!state.items.phone && state.userEdited}
                        value={state.items.phone ? state.items.phone : ""}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 18 }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <TextField
                        margin="normal"
                        label="Teléfono / Celular"
                        name="cellPhone"
                        variant="standard"
                        className="widthField"
                        value={state.items.cellPhone ? state.items.cellPhone : ""}
                        disabled={fieldsDisabled}
                        onChange={handleChangeInput}
                        inputProps={{ maxLength: 18 }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        margin="normal"
                        label="Email"
                        name="email"
                        required
                        variant="standard"
                        onChange={handleChangeInput}
                        className="widthField"
                        disabled={state.items.userState?.description === 'Inactivo'}
                        value={state.items.email}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                    <FormControl
                        fullWidth
                        variant="standard"
                        required
                        margin="normal"
                        error={state.items.isProfessional === "" && state.userEdited}
                    >
                        <InputLabel>Es Profesional</InputLabel>
                        <Select
                            className="widthField"
                            label="Es Profesional"
                            name="isProfessional"
                            value={state.items.isProfessional}
                            disabled={fieldsDisabled || profileCircle}
                            onChange={handleChangeSelect}
                        >
                            <MenuItem value="true">Si</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <h4>Cambio de Clave</h4>
            <Divider sx={{ borderColor: "#64AAB2", mb: 2 }} />
            <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Contraseña"
                        name="password"
                        required
                        type="password"
                        variant="standard"
                        className="widthField"
                        disabled={fieldsDisabled || profileCircle}
                        error={state.passwordsDontMatch}
                        onChange={handleChangeInput}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        margin="normal"
                        label="Repetir Contraseña"
                        name="repeatPassword"
                        required
                        type="password"
                        variant="standard"
                        className="widthField"
                        disabled={fieldsDisabled || profileCircle}
                        helperText={
                        state.passwordsDontMatch ? "Las contraseñas no coinciden" : ""
                        }
                        error={state.passwordsDontMatch}
                        onChange={handleChangeInput}
                    />
                </Grid>
            </Grid>
            <Grid mt={4}>
                {state.messageError && state.messageError.length ? 
                    state.messageError.map((message, index) => (
                        <Typography className="errorMessage" variant="body1" key={index}>
                            {message}
                        </Typography>
                    ))
                : null}
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mt: 6 }}>
                {ListPermissions().eliminarUsuario?.status && (
                    <Grid item md={2}>
                        <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            color="error"
                            onClick={handleOpenConfirm}
                        >
                            Eliminar
                        </Button>
                    </Grid>
                )}
                <Grid
                    container
                    item
                    md={ListPermissions().eliminarUsuario?.status ? 6 : 12}
                    justifyContent="end"
                >
                    <Grid item md={ListPermissions().eliminarUsuario?.status ? 6 : 3}>
                        <Button fullWidth type="button" variant="outlined">
                            <Link
                                to="/usuarios"
                                style={{
                                color: "#4e4e4e",
                                textDecoration: "none",
                                width: "100%",
                                }}
                            >
                                Volver
                            </Link>
                        </Button>
                    </Grid>
                    {ListPermissions().editarUsuario?.status && (
                        <Grid item md={ListPermissions().eliminarUsuario?.status ? 6 : 3}>
                            <Button
                                fullWidth
                                type="button"
                                disabled={state.buttonDisabled}
                                variant="contained"
                                sx={{
                                background: "#42C5B7",
                                    "&:hover": { background: "#389b90" },
                                }}
                                onClick={() => editUser(id)}
                            >
                                Guardar
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Confirm
                openConfirm={state.openConfirm}
                handleCloseConfirm={handleCloseConfirm}
                handleConfirmId={deleteUser}
                handleConfirm={() => {}}
                idAEliminar={id}
                title="¿Estas seguro?"
                description="Si eliminas este Usuario no podrás volver a recuperarla luego"
            />
            <Alert
                openAlert={state.openAlert}
                handleClose={closeAlert}
                severity="success"
                text={"Usuario editado correctamente"}
            />
        </Box>
    );
};

export default UserDetail;
